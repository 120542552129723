import { useMutation } from '@apollo/client';
import { Brand } from '@calo/types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { debounce } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
import { isNumber } from 'lodash-es';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AppContext } from '../../../App';
import BrandIndicator from '../../../components/BrandIndicator';
import { UPDATE_EXTRA_MEALS } from '../../../libs/graphQL';
import { KDSExtraMeals } from '../../../libs/interfaces';

interface SizeRowProps {
	food: KDSExtraMeals;
	isLastRow: boolean;
	isEditing: boolean;
	setLoading: (value: boolean) => void;
}

const SizeRow = ({ food, isLastRow, isEditing, setLoading }: SizeRowProps) => {
	const [extraMeals, setExtraMeals] = useState(food.extraMeals || 0);
	const [lastUpdatedBy, setLastUpdatedBy] = useState('');
	const [updateExtraMeals] = useMutation(UPDATE_EXTRA_MEALS);
	const theme = useTheme();
	const appContext = useContext(AppContext);
	const isInitialMount = useRef(true);

	const handleIncrement = () => {
		setLastUpdatedBy('local');
		setExtraMeals((prev) => prev + 1);
	};

	const handleDecrement = () => {
		setLastUpdatedBy('local');
		setExtraMeals((prev) => (prev > 0 ? prev - 1 : 0));
	};

	const debouncedUpdate = useCallback(
		debounce(async (extraMeals) => {
			setLoading(true);
			try {
				await updateExtraMeals({
					variables: { kitchen: food.kitchen, data: [{ id: food.foodId, extraMeals, size: food.size }] },
					onError: (e) => {
						toast.error(e.message);
						setLoading(false);
					}
				});
			} catch {
				setLoading(false);
			}
			setLoading(false);
		}, 1000),
		[]
	);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else if (lastUpdatedBy === 'local') {
			debouncedUpdate(extraMeals);
		}
	}, [extraMeals, debouncedUpdate, lastUpdatedBy]);

	useEffect(() => {
		if (isNumber(food.extraMeals) && food.extraMeals !== extraMeals) {
			setLastUpdatedBy('remote');
			setExtraMeals(food.extraMeals);
		}
	}, [food]);

	return (
		<TableRow>
			<TableCell
				sx={{
					fontSize: '17px',
					fontWeight: 600,
					borderColor: isLastRow ? 'transparent' : theme.palette.neutral100
				}}
			>
				{food.name.en}
			</TableCell>
			<TableCell
				sx={{
					fontSize: '17px',
					fontWeight: 600,
					borderColor: isLastRow ? 'transparent' : theme.palette.neutral100
				}}
			>
				{food.size}
			</TableCell>
			<TableCell sx={{ borderColor: isLastRow ? 'transparent' : theme.palette.neutral100 }}>
				<Box>{food.brand !== Brand.CALO && <BrandIndicator brand={food.brand as Brand} />}</Box>
			</TableCell>
			<TableCell
				sx={{
					fontSize: '17px',
					fontWeight: 400,
					pr: 0,
					width: '40%',
					borderColor: isLastRow ? 'transparent' : theme.palette.neutral100
				}}
			>
				<Stack direction="row" alignItems="center" justifyContent="center">
					<IconButton
						disabled={extraMeals === 0 || appContext.isOffline}
						onClick={() => extraMeals > 0 && handleDecrement()}
						sx={{
							visibility: isEditing ? 'visible' : 'hidden',
							color: theme.palette.primary500,
							backgroundColor: theme.palette.primary50,
							p: 1,
							m: 0,
							mr: 2,
							':hover': { color: theme.palette.primary50, backgroundColor: theme.palette.primary500 }
						}}
					>
						<RemoveIcon />
					</IconButton>
					<Box sx={{ p: 2 }}>{extraMeals}</Box>
					<IconButton
						disabled={appContext.isOffline}
						onClick={() => handleIncrement()}
						sx={{
							visibility: isEditing ? 'visible' : 'hidden',
							color: theme.palette.primary500,
							backgroundColor: theme.palette.primary50,
							p: 1,
							m: 0,
							ml: 2,
							':hover': { color: theme.palette.primary50, backgroundColor: theme.palette.primary500 }
						}}
					>
						<AddIcon />
					</IconButton>
				</Stack>
			</TableCell>
		</TableRow>
	);
};

export default SizeRow;
