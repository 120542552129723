import { Button, Stack, Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';

import { caloTheme } from '../../../assets/themes/calo';

interface Props {
	tab: number;
	setTab: (value: 0 | 1) => void;
	resetNameFilter: () => void;
	resetIsExpanded: () => void;
}

const TabsComponent = ({ tab, setTab, resetNameFilter, resetIsExpanded }: Props) => {
	const tabsList = [0, 1];
	return (
		<Stack direction={'row'} justifyContent="start" sx={{ width: '100%', mt: '4px' }}>
			<Tabs
				variant="fullWidth"
				value={tab}
				onChange={(_event, newValue) => {
					setTab(newValue);
					resetNameFilter();
					resetIsExpanded();
				}}
				sx={{
					backgroundColor: caloTheme.palette.neutral50,
					borderRadius: '8px',
					'& .MuiTabs-indicator': {
						display: 'none'
					}
				}}
			>
				{tabsList.map((tabValue) => (
					<Tab
						key={tabValue}
						sx={{
							fontSize: '16px',
							fontWeight: 600,
							textTransform: 'capitalize',
							px: 1,
							color: caloTheme.palette.neutral600,
							borderRadius: '8px',
							'&.MuiTab-root': {
								p: '5px'
							}
						}}
						label={
							<Button
								variant={tab === tabValue ? 'contained' : 'text'}
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									textTransform: 'capitalize',
									borderRadius: '8px',
									width: '116px',
									color: tab === tabValue ? caloTheme.palette.white : 'inherit',
									backgroundColor: tab === tabValue ? caloTheme.palette.primary500 : 'inherit',
									boxShadow: 'none',
									p: '10px 16px',
									'&:hover': {
										color: tab === tabValue ? caloTheme.palette.white : 'inherit',
										backgroundColor: tab === tabValue ? caloTheme.palette.primary500 : 'inherit',
										boxShadow: 'none'
									}
								}}
							>
								{tabValue === 0 ? 'Meals' : 'Components'}
							</Button>
						}
					/>
				))}
			</Tabs>
		</Stack>
	);
};

export default TabsComponent;
