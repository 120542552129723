import { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

import { compact, groupBy, uniqBy } from 'lodash-es';
import { FileManagementType } from '../../libs/enums';
import { Options } from '../../libs/interfaces';

interface SearchBarProps {
	onSearch: (searchText: string, selected: boolean) => void;
	placeholder?: string;
	options: Options<any | null>[];
	searchLoading: boolean;
	searchType: FileManagementType;
}

const SearchBar = ({ searchType, searchLoading, placeholder = 'Search...', onSearch, options }: SearchBarProps) => {
	const [nameInput, setNameInput] = useState('');
	const [selectedOption, setSelectedOption] = useState<any | null>(null);

	const debounced = useDebouncedCallback(async (value) => {
		await handleOnInputChange(value);
	}, 600);

	const handleOnInputChange = async (value: string) => {
		setNameInput(value);
		onSearch(value, false);
	};

	const availableOptions = searchLoading
		? [
				{
					name: 'Loading',
					value: '',
					data: null,
					label: 'Loading...'
				}
			]
		: options;

	const onSearchValue = (
		val: {
			name: string;
			value: any;
			data: any;
			label: string;
		} | null
	) => {
		if (searchType === FileManagementType.meal) {
			const groupedOptions = groupBy(options, 'name');
			return compact(Object.values(groupedOptions).flatMap((opt) => opt.map((meal) => meal.name === val?.name && meal.value)));
		} else {
			return val?.data?.userId || val?.data?.id || '';
		}
	};

	return (
		<Autocomplete
			id="search-id"
			value={{
				name: nameInput,
				value: selectedOption?.id || '',
				data: selectedOption,
				label: nameInput
			}}
			loading={searchLoading}
			options={searchType === FileManagementType.meal ? (uniqBy(availableOptions, 'name') as any) : availableOptions}
			onInputChange={(event: any, val) => {
				event?.type === 'change' && debounced(val);
			}}
			onChange={(event, val) => {
				setSelectedOption(null);
				setNameInput(val?.label || '');
				onSearch(onSearchValue(val), true);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					size="small"
					placeholder={placeholder}
					InputProps={{
						...params.InputProps,
						style: {
							fontSize: '17px',
							fontWeight: 400,
							borderRadius: '8px'
						},
						inputProps: {
							...params.inputProps,
							className: '',
							style: {
								fontSize: '17px',
								fontWeight: 400
							}
						}
					}}
				/>
			)}
			renderOption={(props, option) => (nameInput ? <li {...props}>{option.label} </li> : null)}
		/>
	);
};

export default SearchBar;
