import { Icon as Iconify } from '@iconify/react';
import { Button, Typography } from '@mui/material';

import { caloTheme } from '../../assets/themes/calo';
import Icon from '../Icon/Icon';

interface NotificationSidebarProps {
	requestNumber: number;
	requestLabel: 'customer' | 'cx' | 'alert';
}

const NotificationSidebar = ({ requestNumber, requestLabel }: NotificationSidebarProps) => {
	const notificationIcon =
		requestLabel === 'alert' ? 'notificationAlert' : requestLabel === 'customer' ? 'notificationCustomer' : 'notificationCX';
	const backgroundColor =
		requestLabel === 'alert'
			? caloTheme.palette.red
			: requestLabel === 'customer'
				? caloTheme.palette.secondaryBlue500
				: caloTheme.palette.secondaryPurple500;

	const getIcon = () => {
		if (notificationIcon === 'notificationAlert') {
			return <Iconify icon="lucide:alert-circle" style={{ color: 'white', marginRight: '-10px', marginLeft: '2px' }} />;
		}
		return <Icon name={notificationIcon} style={{ minWidth: '300%', maxWidth: '300%', padding: 5, marginTop: 4 }} />;
	};

	return (
		<Button
			variant="contained"
			startIcon={getIcon()}
			sx={{
				padding: '6px 4px 6px 4px',
				height: '32px',
				textAlign: 'center',
				maxWidth: '46px',
				minWidth: '40px',
				boxShadow: 'none',
				mx: '2px',
				backgroundColor: backgroundColor,
				':hover': {
					boxShadow: 'none',
					backgroundColor: backgroundColor
				}
			}}
		>
			<Typography
				sx={{
					fontSize: '16px',
					fontWeight: 400,
					ml: '5px',
					mr: '2px',
					mt: '2px',
					textAlign: 'center',
					color: 'white'
				}}
			>
				{requestNumber}
			</Typography>
		</Button>
	);
};
export default NotificationSidebar;
