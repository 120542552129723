import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { alpha, styled, useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { AppContext } from '../../../App';
import { InputField } from '../../../components';
import { Actions } from '../../../libs/enums';
import { MODIFY_BAGGING_DISPATCH } from '../../../libs/graphQL';
import { BaggingDispatchInput, KDSBaggingDispatch } from '../../../libs/interfaces';
import { getIconColors } from '../../../libs/utils/helperFunctions';

interface SectionTableRowProps {
	dispatch: KDSBaggingDispatch;
	tableName: string;
	mainMealTemperatureValue: number;
	handleUpdateMainTemperatureValue: (temperature: number) => void;
	kitchen: string;
	date: string;
	isPreview: boolean;
}

const preventMinus = (e: { code: string; preventDefault: () => void }) => {
	if (e.code === 'Minus') {
		e.preventDefault();
	}
};

const SectionTableRow = ({
	dispatch,
	tableName,
	mainMealTemperatureValue,
	handleUpdateMainTemperatureValue,
	kitchen,
	date,
	isPreview
}: SectionTableRowProps) => {
	const appContext = useContext(AppContext);
	const [extra, setExtra] = useState(dispatch.extra || 0);
	const [leftOver, setLeftOver] = useState(dispatch.leftovers || 0);
	const [startTemperatureValue, setStartTemperatureValue] = useState(dispatch.startTemperature ? dispatch.startTemperature : 0);
	const [endTemperatureValue, setEndTemperatureValue] = useState(dispatch.endTemperature ? dispatch.endTemperature : 0);
	const [action, setAction] = useState(dispatch.action || '');
	const [isStartTemperatureClicked, setIsStartTemperatureClicked] = useState(false);
	const [isEndTemperatureClicked, setIsEndTemperatureClicked] = useState(false);
	const theme = useTheme();
	const [modifyBaggingDispatch, { loading }] = useMutation(MODIFY_BAGGING_DISPATCH);
	const [actionCommentType, setActionCommentType] = useState('select');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	useEffect(() => {
		if (!loading) {
			if (dispatch.extra && dispatch.extra !== extra) setExtra(dispatch.extra);
			if (dispatch.leftovers && dispatch.leftovers !== leftOver) setLeftOver(dispatch.leftovers);
			if (dispatch.startTemperature && Number(dispatch.startTemperature) !== startTemperatureValue) {
				setStartTemperatureValue(dispatch.startTemperature ? Number(dispatch.startTemperature) : 0);
			}
			if (dispatch.endTemperature && Number(dispatch.endTemperature) !== endTemperatureValue) {
				setEndTemperatureValue(dispatch.endTemperature ? Number(dispatch.endTemperature) : 0);
			}
			if (dispatch.action && dispatch.action !== action) {
				setAction(dispatch.action);
			}
		}
	}, [dispatch]);

	const handleModifyBaggingDispatchExtra = async (extra: number) => {
		setExtra(extra);
		const input: BaggingDispatchInput = {
			kitchen,
			date,
			actual: dispatch.total + extra,
			extra: extra
		};
		await modifyBaggingDispatch({
			variables: { id: dispatch.id, input },
			onCompleted: () => {
				toast.success('Extra updated successfully');
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const handleUpdateStartTemperature = async (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | number) => {
		if (Number(value) !== startTemperatureValue) {
			setStartTemperatureValue(Number(value));
			handleUpdateMainTemperatureValue(Number(value));
			const input: BaggingDispatchInput = {
				kitchen,
				date,
				startTemperature: Number(value)
			};
			await modifyBaggingDispatch({
				variables: { id: dispatch.id, input },
				onCompleted: () => {
					toast.success('Meal start temperature updated successfully');
				},
				onError: (e) => {
					toast.error(e.message);
				}
			});
		}
	};

	const handleUpdateEndTemperature = async (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | number) => {
		if (Number(value) !== endTemperatureValue) {
			setEndTemperatureValue(Number(value));
			handleUpdateMainTemperatureValue(Number(value));
			const input: BaggingDispatchInput = {
				kitchen,
				date,
				endTemperature: Number(value)
			};
			await modifyBaggingDispatch({
				variables: { id: dispatch.id, input },
				onCompleted: () => {
					toast.success('Meal end temperature updated successfully');
				},
				onError: (e) => {
					toast.error(e.message);
				}
			});
		}
	};

	const handleUpdateAction = async (action: string) => {
		const input: BaggingDispatchInput = {
			kitchen,
			date,
			action: action
		};
		await modifyBaggingDispatch({
			variables: { id: dispatch.id, input },
			onCompleted: () => {
				toast.success('Action updated successfully');
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const handleStartTemperatureClicked = () => {
		if (mainMealTemperatureValue === 0) {
			return;
		} else if (!isStartTemperatureClicked) {
			setStartTemperatureValue(mainMealTemperatureValue);
			handleUpdateStartTemperature(mainMealTemperatureValue);
			setIsStartTemperatureClicked(true);
		} else if (isStartTemperatureClicked) {
			setIsStartTemperatureClicked(false);
		}
	};

	const handleEndTemperatureClicked = () => {
		if (mainMealTemperatureValue === 0) {
			return;
		} else if (!isEndTemperatureClicked) {
			setEndTemperatureValue(mainMealTemperatureValue);
			handleUpdateEndTemperature(mainMealTemperatureValue);
			setIsEndTemperatureClicked(true);
		} else if (isEndTemperatureClicked) {
			setIsEndTemperatureClicked(false);
		}
	};

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const handleActionChange = (value: string) => {
		setActionCommentType('select');
		setAction(value);
		handleUpdateAction(value);
	};

	const handleActionInputChange = (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!value) {
			setActionCommentType('select');
			return;
		}
		setAction(String(value));
		setActionCommentType('Other');
		handleUpdateAction(String(value));
	};

	const iconColors = getIconColors(tableName, theme);

	const StyledMenu = styled((props: MenuProps) => (
		<Menu
			elevation={0}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			{...props}
		/>
	))(({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 180,
			color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '4px 0'
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5)
				},
				'&:active': {
					backgroundColor: alpha(theme.palette.primary500, theme.palette.action.selectedOpacity)
				}
			}
		}
	}));

	return (
		<TableRow hover>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', fontWeight: 600 }}>{dispatch.foodName?.en}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.foodSize ? dispatch.foodSize : '[N/A]'}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.packages && dispatch.packages?.length > 0 ? dispatch.packages.map((p) => p.name).join(', ') : '-'}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{dispatch.total}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.total + extra - dispatch.modified}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{dispatch.modified}</TableCell>
			{!isPreview && (
				<TableCell sx={{ fontSize: '14px', textAlign: 'center' }}>
					<Stack sx={{ px: 2 }} direction="row" alignItems="center" justifyContent="space-between">
						<IconButton
							disabled={extra === 0 || appContext.isOffline}
							onClick={() => extra > 0 && handleModifyBaggingDispatchExtra(extra - 1)}
							sx={{
								color: iconColors.color,
								backgroundColor: iconColors.backgroundS,
								p: 1,
								':hover': { color: iconColors.backgroundS, backgroundColor: iconColors.color }
							}}
						>
							<RemoveIcon />
						</IconButton>
						<Box>{extra}</Box>
						<IconButton
							disabled={appContext.isOffline}
							onClick={() => handleModifyBaggingDispatchExtra(extra + 1)}
							sx={{
								color: iconColors.color,
								backgroundColor: iconColors.backgroundS,
								p: 1,
								':hover': { color: iconColors.backgroundS, backgroundColor: iconColors.color }
							}}
						>
							<AddIcon />
						</IconButton>
					</Stack>
				</TableCell>
			)}
			{!isPreview && <TableCell sx={{ fontSize: '14px', textAlign: 'center' }}>{leftOver}</TableCell>}
			{!isPreview && (
				<TableCell sx={{ fontSize: '17px', fontWeight: 600, textAlign: 'center' }}>
					<InputField
						type="number"
						inputMode="numeric"
						disabled={appContext.isOffline}
						value={startTemperatureValue}
						placeholder="0"
						onChange={(event) => handleUpdateStartTemperature(event)}
						onClick={() => handleStartTemperatureClicked()}
						debounce
						inputProps={{ min: 0 }}
						onKeyPress={preventMinus}
						endAdornment={
							<InputAdornment position="end">
								<Box
									sx={{
										fontSize: '16px',
										fontWeight: 500,
										color: !startTemperatureValue || appContext.isOffline ? theme.palette.neutral600 : iconColors.color,
										':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
									}}
								>
									°C
								</Box>
							</InputAdornment>
						}
						sx={{
							textTransform: 'none',
							fontSize: '16px',
							fontWeight: 500,
							border: '1px solid transparent',
							borderRadius: '4px',
							width: '100px',
							height: '51px',
							textAlign: 'center',
							p: '10px',
							input: { textAlign: 'center' },
							color: !startTemperatureValue || appContext.isOffline ? theme.palette.neutral600 : iconColors.color,
							backgroundColor: !startTemperatureValue || appContext.isOffline ? theme.palette.neutral100 : iconColors.backgroundS,
							':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
						}}
					/>
				</TableCell>
			)}
			{!isPreview && (
				<TableCell sx={{ fontSize: '17px', fontWeight: 600, textAlign: 'center' }}>
					<InputField
						type="number"
						inputMode="numeric"
						disabled={appContext.isOffline}
						value={endTemperatureValue}
						placeholder="0"
						onChange={(event) => handleUpdateEndTemperature(event)}
						onClick={() => handleEndTemperatureClicked()}
						debounce
						inputProps={{ min: 0 }}
						onKeyPress={preventMinus}
						endAdornment={
							<InputAdornment position="end">
								<Box
									sx={{
										fontSize: '16px',
										fontWeight: 500,
										color: !endTemperatureValue || appContext.isOffline ? theme.palette.neutral600 : iconColors.color,
										':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
									}}
								>
									°C
								</Box>
							</InputAdornment>
						}
						sx={{
							textTransform: 'none',
							fontSize: '16px',
							fontWeight: 500,
							border: '1px solid transparent',
							borderRadius: '4px',
							width: '100px',
							height: '51px',
							textAlign: 'center',
							p: '10px',
							input: { textAlign: 'center' },
							color: !endTemperatureValue || appContext.isOffline ? theme.palette.neutral600 : iconColors.color,
							backgroundColor: !endTemperatureValue || appContext.isOffline ? theme.palette.neutral100 : iconColors.backgroundS,
							':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
						}}
					/>
				</TableCell>
			)}
			{!isPreview && (
				<TableCell sx={{ p: '10px', py: 2, fontSize: '14px', textAlign: 'center' }}>
					<div>
						{actionCommentType === 'select' && (
							<Button
								disabled={appContext.isOffline}
								id="demo-customized-button"
								aria-controls={open ? 'demo-customized-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								variant="text"
								disableElevation
								onClick={(event) => {
									!anchorEl && setAnchorEl(event.currentTarget);
								}}
								endIcon={<KeyboardArrowDownIcon />}
								sx={{
									textTransform: 'capitalize',
									fontSize: action === '' ? '16px' : '14px',
									fontWeight: 600,
									width: '150px',
									height: '50px',
									color: theme.palette.neutral600,
									':hover': { color: theme.palette.neutral600 }
								}}
							>
								{action === '' ? 'Action' : `${action}`}
							</Button>
						)}
						<StyledMenu
							id="demo-customized-menu"
							MenuListProps={{
								'aria-labelledby': 'demo-customized-button'
							}}
							anchorEl={anchorEl}
							open={open}
							onClose={() => setAnchorEl(null)}
						>
							<MenuItem
								sx={{ fontWeight: 600, py: 0.2 }}
								value={Actions.continueChilling}
								onClick={() => {
									handleActionChange(Actions.continueChilling);
									setAnchorEl(null);
								}}
							>
								<Box>Continue Chilling</Box>
							</MenuItem>
							<Divider sx={{ my: 0.5 }} />
							<MenuItem
								sx={{ fontWeight: 600, py: 0.2 }}
								value={Actions.discard}
								onClick={() => {
									handleActionChange(Actions.discard);
									setAnchorEl(null);
								}}
							>
								<Box>Discard</Box>
							</MenuItem>
							<Divider sx={{ my: 0.5 }} />
							<MenuItem
								sx={{ fontWeight: 600, py: 0.2 }}
								value={Actions.employeeRetraining}
								onClick={() => {
									handleActionChange(Actions.employeeRetraining);
									setAnchorEl(null);
								}}
							>
								<Box>Employee Retraining</Box>
							</MenuItem>
							<Divider sx={{ my: 0.5 }} />
							<MenuItem
								sx={{ fontWeight: 600, py: 0.2 }}
								value={Actions.equipmentMaintenance}
								onClick={() => {
									handleActionChange(Actions.equipmentMaintenance);
									setAnchorEl(null);
								}}
							>
								<Box>Equipment Maintenance</Box>
							</MenuItem>
							<Divider sx={{ my: 0.5 }} />
							<MenuItem
								sx={{ fontWeight: 600, py: 0.2 }}
								value={'Other'}
								onClick={() => {
									setActionCommentType('Other');
									setAnchorEl(null);
								}}
							>
								<Box>Other</Box>
							</MenuItem>
						</StyledMenu>
						{actionCommentType === 'Other' && (
							<InputField
								type="text"
								inputMode="text"
								disabled={appContext.isOffline}
								debounce
								fullWidth={true}
								value={action}
								onBlur={() => setActionCommentType('select')}
								onChange={(event) => handleActionInputChange(event)}
								sx={{
									color: theme.palette.neutral600,
									backgroundColor: theme.palette.neutral100,
									border: '1px solid ' + theme.palette.neutral600,
									fontWeight: 600,
									width: '150px',
									height: '50px',
									px: 1,
									borderRadius: '5px'
								}}
							/>
						)}
					</div>
				</TableCell>
			)}
		</TableRow>
	);
};

export default SectionTableRow;
