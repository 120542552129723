import { useMemo } from 'react';

import { FoodComponentStation } from '@calo/dashboard-types';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

interface StationsDropdownProps {
	selectStationHandler: (station: keyof typeof FoodComponentStation) => void;
	stations: string[];
}

const StationsDropdown = ({ selectStationHandler, stations }: StationsDropdownProps) => {
	const availableStations = useMemo(
		() => Object.keys(FoodComponentStation).filter((station) => !stations.includes(station)),
		[stations]
	);

	return (
		<Stack sx={{ display: 'flex', direction: 'row', width: '100%', justifyContent: 'space-between', paddingTop: '12px' }}>
			<label style={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px', marginBottom: '8px' }}>
				Areas of Work for the Cook
			</label>
			<TextField
				select
				name="cookStations"
				label="Search Stations"
				InputProps={{ style: { borderRadius: 8, position: 'relative' } }}
			>
				{availableStations.map((station) => (
					<MenuItem
						key={station}
						value={station}
						onClick={() => selectStationHandler(station as keyof typeof FoodComponentStation)}
					>
						<Box>{FoodComponentStation[station as keyof typeof FoodComponentStation]}</Box>
					</MenuItem>
				))}
			</TextField>
		</Stack>
	);
};

export default StationsDropdown;
