import { orderBy } from 'lodash-es';
import React, { useContext } from 'react';

import { Food, Kitchen } from '@calo/types';
import { Box, CircularProgress, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled, useTheme } from '@mui/material/styles';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { FileManagementType } from '../../../libs/enums';
import { CheckboxesState, KDSDeliveryData, MealCheckBox } from '../../../libs/interfaces';
import { formatLabel } from '../../../libs/utils/helperFunctions';
import FileManagementMealTableRow from './FileManagementMealTableRow';
import FileManagementTableRow from './FileManagementTableRow/FileManagementTableRow';

interface FileManagementTableProps {
	searchResults: KDSDeliveryData[];
	kitchen: string;
	searchLoading: boolean;
	foodSearchLoading: boolean;
	selectedDataType: FileManagementType;
	checkboxes: CheckboxesState;
	foodSearchResults: any[];
	mealCheckboxes: MealCheckBox;
	setMealCheckboxes: React.Dispatch<React.SetStateAction<MealCheckBox>>;
	setCheckboxes: React.Dispatch<React.SetStateAction<CheckboxesState>>;
}

const FileManagementTable = ({
	searchResults,
	foodSearchResults,
	kitchen,
	foodSearchLoading,
	searchLoading,
	selectedDataType,
	checkboxes,
	setCheckboxes,
	mealCheckboxes,
	setMealCheckboxes
}: FileManagementTableProps) => {
	const theme = useTheme();
	const appContext = useContext(AppContext);

	const handleCheckboxChange = (userId: string, field: keyof CheckboxesState) => {
		setCheckboxes((prevCheckboxes) => ({
			...prevCheckboxes,
			[field]: prevCheckboxes[field].includes(userId)
				? prevCheckboxes[field].filter((id) => id !== userId)
				: [...prevCheckboxes[field], userId]
		}));
	};

	const StyledTableCell = styled(TableCell)(() => ({
		[`&.${tableCellClasses.head}`]: {
			color: caloTheme.palette.neutral900,
			border: 'none',
			fontSize: 14,
			fontWeight: 400,
			lineHeight: '20px'
		}
	}));

	const handleMealCheckboxChange = (meal: Food, isBackLabelToggle: boolean) => {
		const newMealCheckboxes = { ...mealCheckboxes };
		if (!newMealCheckboxes[meal.id]) {
			newMealCheckboxes[meal.id] = { meal, amount: 1, size: meal.size };
		}
		if (isBackLabelToggle) {
			newMealCheckboxes[meal.id].isBackLabelSelected = !newMealCheckboxes[meal.id]?.isBackLabelSelected;
		} else {
			newMealCheckboxes[meal.id].isFrontLabelSelected = !newMealCheckboxes[meal.id]?.isFrontLabelSelected;
		}
		if (!newMealCheckboxes[meal.id].isBackLabelSelected && !newMealCheckboxes[meal.id].isFrontLabelSelected) {
			delete newMealCheckboxes[meal.id];
		}
		setMealCheckboxes(newMealCheckboxes);
	};

	const handleAllMealsCheckboxChange = (isBackLabelToggle: boolean) => {
		const isChecked =
			Object.values(mealCheckboxes).filter((mcb) => (isBackLabelToggle ? mcb.isBackLabelSelected : mcb.isFrontLabelSelected))
				.length === foodSearchResults.length;
		const newMealCheckboxes: any = {};
		for (const food of foodSearchResults) {
			newMealCheckboxes[food.id] = {
				meal: food,
				amount: 1,
				size: food.size,
				isBackLabelSelected: isBackLabelToggle ? !isChecked : mealCheckboxes[food.id]?.isBackLabelSelected,
				isFrontLabelSelected: isBackLabelToggle ? mealCheckboxes[food.id]?.isFrontLabelSelected : !isChecked
			};
			if (!newMealCheckboxes[food.id].isBackLabelSelected && !newMealCheckboxes[food.id].isFrontLabelSelected) {
				delete newMealCheckboxes[food.id];
			}
		}
		setMealCheckboxes(newMealCheckboxes);
	};

	const handleAllCheckboxChange =
		(property: keyof CheckboxesState) => (_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) => {
			const isChecked = checkboxes[property].length !== searchResults.length;
			const newCheckboxes: CheckboxesState = { ...checkboxes };
			newCheckboxes[property] = isChecked ? searchResults.map((delivery) => delivery.id) : [];
			setCheckboxes(newCheckboxes);
		};

	const renderCheckBoxHeader = (type: keyof CheckboxesState) => (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: 10 }}>
			<span>
				{formatLabel(type)}
				<br />
			</span>
			<Checkbox
				checked={checkboxes[type].length === searchResults.length && searchResults.length !== 0}
				disabled={appContext.isOffline || searchResults.length === 0}
				onChange={handleAllCheckboxChange(type)}
				inputProps={{ 'aria-label': 'controlled' }}
				style={{ height: '12px' }}
			/>
		</div>
	);

	return (
		<Box sx={{ width: '100%' }}>
			{(
				selectedDataType === FileManagementType.meal
					? foodSearchLoading && foodSearchResults.length === 0
					: searchLoading && searchResults.length === 0
			) ? (
				<CircularProgress sx={{ mt: 1, ml: 1, color: 'white', justifyContent: 'center' }} size={25} />
			) : (
				(searchResults || foodSearchResults) && (
					<Table sx={{ width: '100%' }}>
						<TableHead>
							{selectedDataType === FileManagementType.customer ? (
								<TableRow style={{ backgroundColor: caloTheme.palette.neutral50 }}>
									<StyledTableCell sx={{ minWidth: '25%', pl: 1 }} style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
										Customer Name
									</StyledTableCell>
									<StyledTableCell sx={{ minWidth: '35%', pl: 1 }}>Meals</StyledTableCell>
									<StyledTableCell sx={{ minWidth: '10%', textAlign: 'center' }}>Phone Number </StyledTableCell>
									<StyledTableCell sx={{ minWidth: '9%', textAlign: 'center' }}>Driver Name </StyledTableCell>
									<StyledTableCell sx={{ minWidth: '9%', textAlign: 'center' }}>Delivery Time </StyledTableCell>
									<StyledTableCell sx={{ minWidth: '7%', textAlign: 'right' }}>{renderCheckBoxHeader('invoice')}</StyledTableCell>
									<StyledTableCell sx={{ minWidth: '7%', textAlign: 'right' }}>
										{renderCheckBoxHeader('frontLabel')}
									</StyledTableCell>
									<StyledTableCell
										sx={{ minWidth: '7%', textAlign: 'right' }}
										style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
									>
										{renderCheckBoxHeader('backLabel')}
									</StyledTableCell>
								</TableRow>
							) : (
								<TableRow style={{ backgroundColor: caloTheme.palette.neutral50 }}>
									<StyledTableCell sx={{ width: '60%', pl: 1 }} style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
										Meal Name
									</StyledTableCell>
									<StyledTableCell sx={{ width: '10%' }}>Size</StyledTableCell>
									<StyledTableCell sx={{ width: '10%' }}>Type</StyledTableCell>
									<StyledTableCell
										sx={{ minWidth: '15%', textAlign: 'right' }}
										style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
									>
										Front Labels
										<Checkbox
											checked={
												Object.values(mealCheckboxes).filter((mcb) => mcb.isFrontLabelSelected).length ===
													foodSearchResults.length && foodSearchResults.length !== 0
											}
											disabled={appContext.isOffline || foodSearchResults.length === 0}
											onChange={() => handleAllMealsCheckboxChange(false)}
											inputProps={{ 'aria-label': 'controlled' }}
											style={{ height: '12px' }}
										/>
									</StyledTableCell>
									<StyledTableCell
										sx={{ minWidth: '15%', textAlign: 'right' }}
										style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
									>
										Back Labels
										<Checkbox
											checked={
												Object.values(mealCheckboxes).filter((mcb) => mcb.isBackLabelSelected).length ===
													foodSearchResults.length && foodSearchResults.length !== 0
											}
											disabled={appContext.isOffline || foodSearchResults.length === 0}
											onChange={() => handleAllMealsCheckboxChange(true)}
											inputProps={{ 'aria-label': 'controlled' }}
											style={{ height: '12px' }}
										/>
									</StyledTableCell>
								</TableRow>
							)}
						</TableHead>
						{(
							selectedDataType === FileManagementType.customer
								? !searchLoading && searchResults.length === 0
								: !foodSearchLoading && foodSearchResults.length === 0
						) ? (
							<TableBody>
								<TableRow>
									<TableCell
										colSpan={7}
										sx={{
											minWidth: 150,
											p: 1,
											fontFamily: 'Bebas Neue',
											fontSize: '28px',
											textTransform: 'uppercase',
											color: theme.palette.neutral600,
											textAlign: 'center',
											border: 'none'
										}}
									>
										{selectedDataType === FileManagementType.customer ? 'No Customers' : 'No Meals'}
									</TableCell>
								</TableRow>
							</TableBody>
						) : selectedDataType === FileManagementType.customer ? (
							<TableBody>
								{searchResults.map((delivery) => (
									<FileManagementTableRow
										key={delivery.id}
										checkboxes={checkboxes}
										setCheckboxes={(label: keyof CheckboxesState) => handleCheckboxChange(delivery.id, label)}
										delivery={delivery}
									/>
								))}
							</TableBody>
						) : (
							<TableBody>
								{orderBy(foodSearchResults, [(item) => (mealCheckboxes[item.id] ? 1 : 0)], ['desc'])
									.filter((meal) => (meal.kitchen || Kitchen.BH1) === kitchen)
									.map((meal, index) => (
										<FileManagementMealTableRow
											meal={meal}
											key={`${meal.id}-${index}`}
											mealCheckboxes={mealCheckboxes}
											handleMealCheckboxChange={handleMealCheckboxChange}
										/>
									))}
							</TableBody>
						)}
					</Table>
				)
			)}
		</Box>
	);
};
export default FileManagementTable;
