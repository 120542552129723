import { startCase } from 'lodash-es';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { DeliveryTicketData, DeliveryTicketStatus, DeliveryTicketType } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import BrandIndicator from '../../../components/BrandIndicator';
import ConfirmationMessage from '../../../components/ConfirmationMessage';
import Icon from '../../../components/Icon/Icon';
import { APPROVE_DELIVERY_TICKET, REJECT_DELIVERY_TICKET } from '../../../libs/graphQL';
import { User } from '../../../libs/interfaces';
import { getUserAttributes } from '../../../libs/utils/cognitoUserHelpers';
import { formatDate } from '../../../libs/utils/helperFunctions';

interface SwapCardProps {
	id: string;
	data: DeliveryTicketData & { downloadedAt?: string };
	date: string;
	createdBy: User;
	resolvedBy?: User;
	resolvedAt?: string;
	kitchen: Kitchen;
	country: Country;
	brand: Brand;
	downloadedAt?: string;
	zone?: string;
	status: DeliveryTicketStatus;
	createdAt: string;
	tabValue: number;
	index: number;
	isMutationRunning: boolean;
	setIsMutationRunning: (data: boolean) => void;
}

const SwapCard = ({
	id,
	data,
	kitchen,
	brand,
	createdAt,
	status,
	createdBy,
	resolvedBy,
	resolvedAt,
	zone,
	downloadedAt,
	tabValue,
	index,
	isMutationRunning,
	setIsMutationRunning
}: SwapCardProps) => {
	const [isOpened, setIsOpened] = useState(false);
	const [isTicketOpened, setIsTicketOpened] = useState(tabValue === 0 ? true : false);
	const [confirmationMessage, setConfirmationMessage] = useState('Are you sure you want to approve this swap?');
	const [action, setAction] = useState(true);
	const appContext = useContext(AppContext);
	const theme = useTheme();
	const [approveDeliveryTicket, { loading: approveLoading }] = useMutation(APPROVE_DELIVERY_TICKET);
	const [rejectDeliveryTicket, { loading: rejectLoading }] = useMutation(REJECT_DELIVERY_TICKET);

	const hasCoolerBag = data?.manualTicketData?.coolerBag;
	const hasCutlery = data?.manualTicketData?.cutlery;
	const hasCoolerBagOrCutlery = hasCoolerBag || hasCutlery;

	const handleApproveDeliveryTicket = async () => {
		setIsMutationRunning(true);
		await approveDeliveryTicket({
			variables: { id: id, kitchen: kitchen, type: data.type, user: getUserAttributes(appContext.user) },
			onCompleted: () => {
				appContext.refetchFoods();
				setIsMutationRunning(false);
				toast.success('Ticket approved successfully');
			},
			onError: (e) => {
				toast.error(e.message);
				setIsMutationRunning(false);
			}
		});
	};

	const handleRejectDeliveryTicket = async () => {
		setIsMutationRunning(true);
		await rejectDeliveryTicket({
			variables: { id: id, kitchen: kitchen, type: data.type, user: getUserAttributes(appContext.user) },
			onCompleted: () => {
				appContext.refetchFoods();
				setIsMutationRunning(false);
				toast.success('Ticket rejected successfully');
			},
			onError: (e) => {
				toast.error(e.message);
				setIsMutationRunning(false);
			}
		});
	};

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			fontSize: 19,
			fontWeight: 600,
			lineHeight: '23px',
			color: theme.palette.neutral900,
			border: 'none',
			width: '33%'
		}
	}));

	return (
		<Card
			sx={{
				minWidth: '100%',
				boxShadow: 0,
				maxHeight: isTicketOpened ? 'auto' : 'auto',
				border: '2px solid' + theme.palette.neutral100,
				borderColor:
					status === DeliveryTicketStatus.APPROVED
						? theme.palette.primary500
						: status === DeliveryTicketStatus.REJECTED
							? theme.palette.red
							: theme.palette.neutral100,
				borderRadius: '8px',
				mb: 2
			}}
		>
			<Stack
				direction={'row'}
				justifyContent={'space-between'}
				alignItems="center"
				sx={{ mx: 2, mt: 1 }}
				onClick={() => setIsTicketOpened(!isTicketOpened)}
			>
				<Typography
					component="div"
					sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: 16, fontWeight: 400, width: '16%' }}
				>
					#{index}{' '}
					{data.type === DeliveryTicketType.MEAL
						? 'Meal Swap'
						: data.type === DeliveryTicketType.MANUAL
							? 'Manual Delivery'
							: 'Other'}
					{brand !== Brand.CALO && (
						<Typography sx={{ ml: 2, fontWeight: 400, fontSize: '23px', lineHeight: '28px', color: '#FF9900' }} component="span">
							M
						</Typography>
					)}
				</Typography>
				<Typography
					component="div"
					sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '28%', justifyContent: 'center', display: 'flex' }}
				>
					{data.userName}
				</Typography>
				<Typography
					component="div"
					sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
				>
					{data.phoneNumber}
				</Typography>
				<Typography
					component="div"
					sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
				>
					{`${data.driver?.name} ${data.driver?.code ? `(#${data.driver.code})` : ''} ` || '---'}
				</Typography>
				<Typography
					component="div"
					sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
				>
					{data.deliveryShortId || 'xxx-xxx'}
				</Typography>
				<Typography
					component="div"
					sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
				>
					{startCase(data.deliveryTime)}
				</Typography>
				<Typography
					component="div"
					sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
				>
					{zone}
				</Typography>
				<Typography component="div" sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '6%' }}>
					<Stack flexDirection={'row'} justifyContent={'space-between'}>
						{<Icon name={'CXRequest'} width={'28px'} style={{ minWidth: '24px' }} />}
						{downloadedAt && (
							<Icon name="downloaded" width={'28px'} style={{ minWidth: '22px', marginRight: '8px', marginTop: '-6px' }} />
						)}
						{isTicketOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</Stack>
				</Typography>
			</Stack>

			{isTicketOpened && (
				<>
					<CardContent>
						{data.type === DeliveryTicketType.MEAL ? (
							<>
								<Typography>Meals</Typography>
								<Table sx={{ width: '100%', alignContent: 'center', mb: 1 }}>
									<TableHead>
										{data?.mealTicketData &&
											data.mealTicketData.map((item, i) => (
												<TableRow key={i} sx={{ backgroundColor: caloTheme.palette.primary50 }}>
													<StyledTableCell align={'center'}>
														{item.oldFood?.name?.en} ({item.oldFood?.size})
													</StyledTableCell>
													<StyledTableCell align={'center'}>
														<Typography component="span" sx={{ position: 'relative', right: '-50px', top: '-15px' }}>
															{brand !== Brand.CALO && <BrandIndicator brand={brand as Brand} />}
														</Typography>
														<ArrowForwardIosIcon />
													</StyledTableCell>
													<StyledTableCell align={'center'}>
														{item.newFood?.name?.en} ({item.newFood?.size})
													</StyledTableCell>
												</TableRow>
											))}
									</TableHead>
								</Table>
							</>
						) : data.type === DeliveryTicketType.MANUAL ? (
							<Stack direction={'column'}>
								<Stack direction={'row'} alignItems="start" sx={{ mb: 1, borderRadius: '8px', width: '100%' }}>
									<Typography
										component="div"
										sx={{
											p: 2,
											backgroundColor: theme.palette.secondaryBlue50,
											width: '100%',
											borderRadius: '8px',
											fontSize: 19,
											fontWeight: 600,
											lineHeight: '23px',
											fontFamily: 'Roboto',
											fontStyle: 'normal'
										}}
									>
										{data.manualTicketData?.foods.map(
											(food, index) =>
												` ${food.name.en} (${food.size}) ${index === data.manualTicketData?.foods.length - 1 ? '' : '-'}`
										)}
									</Typography>
								</Stack>
								{hasCoolerBagOrCutlery && (
									<Stack direction={'row'} justifyContent={'flex-start'} sx={{ backgroundColor: caloTheme.palette.primary50 }}>
										{hasCoolerBag && <Typography sx={{ p: 1.5, fontWeight: 500, fontSize: 18 }}> Cooler Bag</Typography>}
										{hasCutlery && <Typography sx={{ p: 1.5, fontWeight: 500, fontSize: 18 }}> Cutlery</Typography>}
									</Stack>
								)}
							</Stack>
						) : (
							<>
								<Typography>Add</Typography>

								<Stack direction={'row'} justifyContent={'flex-start'} sx={{ backgroundColor: caloTheme.palette.primary50 }}>
									{data?.otherTicketData?.coolerBag && (
										<Typography sx={{ p: 1.5, fontWeight: 500, fontSize: 18 }}> Cooler Bag</Typography>
									)}
									{data?.otherTicketData?.cutlery && (
										<Typography sx={{ p: 1.5, fontWeight: 500, fontSize: 18 }}> Cutlery</Typography>
									)}
								</Stack>
							</>
						)}
						{data.comment && (
							<Stack direction={'column'} justifyContent="space-between" alignItems="start" sx={{ borderRadius: '8px' }}>
								<Typography component="div" sx={{ fontSize: 14, fontWeight: 400, lineHeight: '17px' }}>
									Comment:
								</Typography>
								<Typography component="div" sx={{ fontSize: 16, fontWeight: 600, lineHeight: '19px', px: 1 }}>
									{data.comment}
								</Typography>
							</Stack>
						)}

						<Stack direction={'row'} justifyContent={'space-between'} alignItems="center" sx={{ mb: '-16px' }}>
							<Stack justifyContent={'space-between'} display="flex" flexDirection={'column'} alignItems="start" sx={{ mt: 1 }}>
								<Stack direction={'row'} justifyContent={'space-between'} alignItems="center" sx={{ mb: 1 }}>
									<Typography
										component="div"
										display={'flex'}
										flexDirection="row"
										sx={{ fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
									>
										Created By:
									</Typography>
									<Typography component="div" sx={{ marginLeft: '4px', fontSize: 14, fontWeight: 600, lineHeight: '17px' }}>
										{startCase(createdBy.name)} on {formatDate(createdAt, 'HH:mm dd/MM/yyyy')}
									</Typography>
								</Stack>
								{resolvedBy && resolvedAt && (
									<Stack direction={'row'} justifyContent={'space-between'} alignItems="center">
										<Typography
											component="div"
											display={'flex'}
											flexDirection="row"
											sx={{ fontSize: 12, fontWeight: 400, lineHeight: '14px' }}
										>
											Resolved By:
										</Typography>
										<Typography component="div" sx={{ marginLeft: '4px', fontSize: 14, fontWeight: 600, lineHeight: '17px' }}>
											{startCase(resolvedBy.name)} on {formatDate(resolvedAt, 'HH:mm dd/MM/yyyy')}
										</Typography>
									</Stack>
								)}
							</Stack>

							<Stack direction={'row'} justifyContent="end" alignItems="center">
								{(status === DeliveryTicketStatus.PENDING || status === DeliveryTicketStatus.APPROVED) && (
									<LoadingButton
										loading={rejectLoading}
										variant="text"
										onClick={() => {
											if (status === DeliveryTicketStatus.PENDING) {
												setAction(false);
												setConfirmationMessage(
													`Are you sure you want to reject this ${data.type === DeliveryTicketType.MEAL ? 'swap' : 'delivery'}?`
												);
												setIsOpened(true);
											}
										}}
										disabled={status === DeliveryTicketStatus.APPROVED || appContext.isOffline || isMutationRunning}
										sx={{
											textTransform: 'capitalize',
											fontSize: '19px',
											fontWeight: 600,
											height: '50px',
											color: status === DeliveryTicketStatus.APPROVED ? theme.palette.primary500 : theme.palette.red,
											':hover': {
												color: status === DeliveryTicketStatus.APPROVED ? theme.palette.primary500 : theme.palette.red,
												background: status === DeliveryTicketStatus.APPROVED ? 'white' : '#FAEEEE'
											},
											':disabled': {
												color: status === DeliveryTicketStatus.APPROVED ? theme.palette.primary500 : theme.palette.neutral600
											}
										}}
									>
										{status === DeliveryTicketStatus.APPROVED ? 'Approved' : 'Reject'}
									</LoadingButton>
								)}
								{(status === DeliveryTicketStatus.PENDING || status === DeliveryTicketStatus.REJECTED) && (
									<LoadingButton
										loading={approveLoading}
										variant={status === DeliveryTicketStatus.REJECTED ? 'text' : undefined}
										onClick={() => {
											if (status === DeliveryTicketStatus.PENDING) {
												setAction(true);
												setConfirmationMessage(
													`Are you sure you want to approve this ${data.type === DeliveryTicketType.MEAL ? 'swap' : data.type === DeliveryTicketType.MANUAL ? 'delivery' : 'Addition'}?`
												);
												setIsOpened(true);
											}
										}}
										disabled={status === DeliveryTicketStatus.REJECTED || appContext.isOffline || isMutationRunning}
										sx={{
											px: 2,
											textTransform: 'none',
											fontSize: '19px',
											m: 2,
											color: status === DeliveryTicketStatus.REJECTED ? theme.palette.red : 'white',
											backgroundColor: status === DeliveryTicketStatus.REJECTED ? 'white' : theme.palette.primary500,
											':hover': {
												backgroundColor: status === DeliveryTicketStatus.REJECTED ? 'white' : theme.palette.primary600
											},
											':disabled': {
												color: status === DeliveryTicketStatus.REJECTED ? theme.palette.neutral600 : theme.palette.neutral600,
												backgroundColor: status === DeliveryTicketStatus.REJECTED ? 'white' : theme.palette.neutral100
											}
										}}
									>
										{status === DeliveryTicketStatus.REJECTED
											? 'Rejected'
											: data.type === DeliveryTicketType.MEAL
												? 'Confirm Swap'
												: data.type === DeliveryTicketType.MANUAL
													? 'Confirm Delivery'
													: 'Confirm Add'}
									</LoadingButton>
								)}
							</Stack>
						</Stack>
					</CardContent>
				</>
			)}
			<ConfirmationMessage
				isOpened={isOpened}
				message={confirmationMessage}
				accept={'Yes'}
				reject={'No'}
				handleAccept={action ? handleApproveDeliveryTicket : handleRejectDeliveryTicket}
				handleClose={() => setIsOpened(false)}
			/>
		</Card>
	);
};
export default SwapCard;
