import {
	Box,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { caloTheme } from '../../../assets/themes/calo';
import EmptyMessage from '../../../components/EmptyMessage';
import { FoodComponent } from '../../../libs/interfaces';
import IngredientsTableRow from './IngredientsTableRow';

interface IngredientsTableProps {
	foodComponent: FoodComponent;
	selectChildComponent: (compId: string, name: string) => void;
	newCookedWeight: number;
}

const IngredientsTable = ({ foodComponent, selectChildComponent, newCookedWeight }: IngredientsTableProps) => {
	const theme = useTheme();
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			color: theme.palette.common.black,
			fontSize: 28,
			fontWeight: 400,
			fontFamily: caloTheme.typography.fontFamily,
			textTransform: 'uppercase',
			padding: '10px',
			border: 'none'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 19,
			backgroundColor: theme.palette.neutral50,
			color: theme.palette.common.black,
			border: '2',
			fontFamily: 'Roboto',
			lineHeight: '23px'
		}
	}));

	return (
		<Table sx={{ my: 2, width: '100%', minWidth: '100%' }}>
			<TableHead>
				<TableRow sx={{ pl: '5px' }}>
					<StyledTableCell sx={{ p: 1, pl: 2, minWidth: '100%' }}>
						Ingredients
						<Box sx={{ borderBottom: 2, borderColor: theme.palette.neutral100, p: '10px' }} />
					</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<Stack display="flex" flexDirection="row" justifyContent="space-between" sx={{ mx: 2 }}>
					<Typography>
						<IconButton
							sx={{
								backgroundColor: caloTheme.palette.primary500,
								mt: '-2px',
								mr: 1
							}}
						/>
						Quantity Before Prep.
					</Typography>
					<Typography>
						<IconButton
							sx={{
								backgroundColor: caloTheme.palette.secondaryYellow500,
								mt: '-2px',
								mr: 1
							}}
						/>
						Quantity After Prep.
					</Typography>
				</Stack>
				{!foodComponent.ingredients || (foodComponent.ingredients?.length === 0 && !foodComponent.childComponents) ? (
					<EmptyMessage label={'no ingredients'} />
				) : (
					<IngredientsTableRow
						foodComponent={foodComponent}
						selectChildComponent={selectChildComponent}
						newCookedWeight={newCookedWeight}
					/>
				)}
			</TableBody>
		</Table>
	);
};

export default IngredientsTable;
