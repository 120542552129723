import { useQuery } from '@apollo/client';
import { Food } from '@calo/types';
import { isEqual, uniqBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FileManagementType } from '../libs/enums';
import { GraphQLClient, LIST_FOOD_BY_FILTERS_QUERY } from '../libs/graphQL';
import { FileManagementFoodFilter } from '../libs/interfaces';

interface StateType {
	foodData: Food[];
	filteredFood: Food[];
	isLoading: boolean;
	page: number;
	totalResults: number;
	total: number;
	limit: number;
}

const DEFAULT_LIMIT = 50;

const useListFoodQuery = (
	mealFilter: FileManagementFoodFilter,
	initialFoodFilter: FileManagementFoodFilter,
	selectedDataType: FileManagementType
) => {
	const [state, setState] = useState<StateType>({
		foodData: [],
		filteredFood: [],
		isLoading: false,
		page: 0,
		totalResults: 0,
		total: 0,
		limit: DEFAULT_LIMIT
	});

	useEffect(() => {
		if (!mealFilter.name && selectedDataType === FileManagementType.meal) {
			handleRefetchData();
		}
	}, [mealFilter, selectedDataType]);

	const { loading, fetchMore, refetch } = useQuery(LIST_FOOD_BY_FILTERS_QUERY, {
		variables: {
			filters: {
				ids: mealFilter.ids?.length > 0 ? mealFilter.ids : undefined,
				kitchen: mealFilter.kitchen,
				name: mealFilter.name,
				foodType: mealFilter.type.length > 0 ? mealFilter.type : undefined,
				sizes: mealFilter.size.length > 0 ? mealFilter.size.map((size) => size.toUpperCase()) : undefined,
				usedOnMenu: mealFilter.date ? [{ type: 'mainMenu', date: mealFilter.date }] : undefined
			},
			page: 0,
			limit: state.limit
		},
		refetchWritePolicy: 'merge',
		partialRefetch: false,
		skip: selectedDataType !== FileManagementType.meal,
		onCompleted: (data) => {
			if (data?.listFoodsByFilters?.data) {
				const newData = data.listFoodsByFilters.data;
				const total = data.listFoodsByFilters.meta.total;
				if (mealFilter.name && mealFilter.ids.length === 0) {
					//Searching for meal name only dropdown options not the whole list
					setState((prevState) => ({
						...prevState,
						isLoading: false,
						filteredFood: uniqBy([...newData], 'id'),
						totalResults: uniqBy([...newData], 'id').length,
						total
					}));
				} else if (!mealFilter.name && mealFilter.ids.length > 0) {
					//once selecting an option show all meals with the same name in the list
					setState((prevState) => ({
						...prevState,
						isLoading: false,
						foodData: uniqBy([...newData], 'id'),
						totalResults: uniqBy([...newData], 'id').length,
						total
					}));
				} else {
					if (isEqual(initialFoodFilter, mealFilter)) {
						setState((prevState) => ({
							...prevState,
							isLoading: false,
							foodData: uniqBy([...prevState.foodData, ...newData], 'id'),
							filteredFood: mealFilter.name ? uniqBy([...newData], 'id') : [],
							totalResults: uniqBy([...prevState.foodData, ...newData], 'id').length,
							total
						}));
					} else {
						setState((prevState) => ({
							...prevState,
							page: 0,
							isLoading: false,
							foodData: uniqBy([...newData], 'id'),
							filteredFood: [],
							totalResults: uniqBy([...newData], 'id').length,
							total
						}));
					}
				}
			}
		},
		onError: (error) => {
			toast.error(`Error fetching food data: ${error.message}`);
		}
	});

	const mergeFetchedData = (fetchMoreResult: any) => {
		if (fetchMoreResult?.listFoodsByFilters?.data) {
			const newFoods = fetchMoreResult.listFoodsByFilters.data;
			const combinedFoodData = uniqBy([...(state.foodData || []), ...(newFoods || [])], 'id');
			setState((prevState) => ({
				...prevState,
				foodData: uniqBy(combinedFoodData, 'id'),
				totalResults: uniqBy(combinedFoodData, 'id').length,
				isLoading: false
			}));
		}
	};

	const handleFetchMoreFood = () => {
		if (state.totalResults < state.total) {
			const newPage = state.page + 1;
			setState((prevState) => ({ ...prevState, isLoading: true }));
			fetchMore({
				variables: {
					filters: {
						ids: mealFilter.ids?.length > 0 ? mealFilter.ids : undefined,
						kitchen: mealFilter.kitchen,
						name: mealFilter.name,
						foodType: mealFilter.type.length > 0 ? mealFilter.type : undefined,
						sizes: mealFilter.size.length > 0 ? mealFilter.size.map((size) => size.toUpperCase()) : undefined,
						usedOnMenu: mealFilter.date ? [{ type: 'mainMenu', date: mealFilter.date }] : undefined
					},
					limit: state.limit,
					page: newPage
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					mergeFetchedData(fetchMoreResult);
					setState((prevState) => ({ ...prevState, page: newPage, isLoading: false }));
				}
			});
		}
	};

	const clearData = () => {
		setState(() => ({
			foodData: [],
			filteredFood: [],
			isLoading: true,
			page: 0,
			totalResults: 0,
			total: 0,
			limit: DEFAULT_LIMIT
		}));
		// eslint-disable-next-line unicorn/no-useless-undefined
		GraphQLClient.clearStore();
	};

	const handleRefetchData = async () => {
		refetch();
	};

	return {
		foodLoading: loading || state.isLoading,
		filteredFood: state.filteredFood,
		food: state.foodData,
		hasNextFood: state.total > state.totalResults,
		handleFetchMoreFood: handleFetchMoreFood,
		clearFoodData: clearData
	};
};

export default useListFoodQuery;
