import { useEffect, useRef, useState } from 'react';

import { getFormattedTimeFromSeconds } from '../libs/utils/helperFunctions';

// Custom hook for counting time
const useCounter = (seconds: number, shouldCount: boolean) => {
	const [count, setCount] = useState(seconds);
	const [isCounting, setIsCounting] = useState(shouldCount);
	const intervalRef = useRef<NodeJS.Timer | undefined>();

	useEffect(() => {
		setIsCounting(shouldCount);
	}, [shouldCount]);

	useEffect(() => {
		clearInterval(intervalRef.current);
		if (isCounting) {
			intervalRef.current = setInterval(() => {
				setCount((prevCount) => prevCount + 1);
			}, 1000);
		} else {
			clearInterval(intervalRef.current);
			setIsCounting(false);
		}

		return () => clearInterval(intervalRef.current);
	}, [seconds, isCounting]);

	const stopCounting = () => {
		setIsCounting(false);
	};

	const startCounting = () => {
		setIsCounting(true);
	};

	const formattedTime = getFormattedTimeFromSeconds(count);

	return { formattedTime, isCounting, stopCounting, startCounting };
};

export default useCounter;
