import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { KDSFoodComponent } from '../../../libs/interfaces';
import { getCookedRawFactor, weightCalculator } from '../../../libs/utils/foodComponentHelper';

interface SectionTableRowProps {
	foodComponent: KDSFoodComponent;
}

const SectionTableRow = ({ foodComponent }: SectionTableRowProps) => {
	const theme = useTheme();
	const childWeight = foodComponent.child?.weight || 0;
	const cookedRawFactor = getCookedRawFactor(foodComponent);

	return (
		<TableRow hover>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '19px', fontWeight: 600 }}>{foodComponent.name.en}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '20px' }}>
				{weightCalculator(
					foodComponent,
					foodComponent.weight,
					foodComponent.count,
					foodComponent.cupsQuantity,
					foodComponent.quantities || [],
					cookedRawFactor
				) +
					childWeight * cookedRawFactor}{' '}
				g
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '20px' }}>
				{foodComponent.kitchenWastageInputs?.preppedWeight ? foodComponent.kitchenWastageInputs?.preppedWeight + ' g' : ''}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '20px' }}>
				{weightCalculator(
					foodComponent,
					foodComponent.cookedWeight,
					foodComponent.count,
					foodComponent.cupsQuantity,
					foodComponent.quantities || []
				) + childWeight}{' '}
				g
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '20px' }}>
				{foodComponent.kitchenWastageInputs?.chilledWeight ? foodComponent.kitchenWastageInputs?.chilledWeight + ' g' : ''}
			</TableCell>
			{/* Cost */}
			<TableCell sx={{ pl: 2, py: 2, fontSize: '16px', fontWeight: 600, color: theme.palette.neutral600 }}>
				{foodComponent.cost ? foodComponent?.cost : 0}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '16px', fontWeight: 600, color: theme.palette.neutral600 }}>
				{foodComponent.kitchenWastageInputs?.wastage ? foodComponent.kitchenWastageInputs?.wastage : '0'}
			</TableCell>
			{/* leftOver Cost */}
			<TableCell sx={{ pl: 2, py: 2, fontSize: '16px', fontWeight: 600, color: theme.palette.neutral600 }}>
				{(foodComponent.kitchenWastageInputs?.wastage || 0) * (foodComponent?.cost || 0)}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '16px', fontWeight: 600, color: theme.palette.neutral600 }}>
				{foodComponent.kitchenWastageInputs?.action ? foodComponent.kitchenWastageInputs?.action : 'No Action'}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '20px' }}>
				{(foodComponent.kitchenWastageInputs?.preppedWeight || 0) * (foodComponent.purchasingCost || 0)}
			</TableCell>
		</TableRow>
	);
};

export default SectionTableRow;
