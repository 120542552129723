import { useState } from 'react';

import { Kitchen } from '@calo/types';
import { Button, Divider, FormControlLabel, FormGroup, Paper, Radio, RadioGroup, Stack, Switch, Typography } from '@mui/material';

import { caloTheme } from '../../../../assets/themes/calo';
import { Session } from '../../../../libs/interfaces';

interface FilterPopupProps {
	filtersRef: any;
	kitchen: string;
	filters: { shift: Session; showCustom: boolean };
	setFilters: (value: { shift: Session; showCustom: boolean }) => void;
}

const FilterPopup = ({ kitchen, filtersRef, filters, setFilters }: FilterPopupProps) => {
	const [filterPop, setFilterPop] = useState({
		shift: filters.shift,
		showCustom: filters.showCustom
	});
	const handleFilter = () => {
		setFilters({ ...filterPop });
		filtersRef.current?.close();
	};
	return (
		<Paper sx={{ flexGrow: 1, overflow: 'hidden' }}>
			<Stack display={'flex'} flexDirection="column" sx={{ width: '96%' }}>
				<Typography
					component="div"
					sx={{
						m: 2,
						fontSize: '16px',
						lineHeight: '19px',
						fontWeight: 600,
						width: '100%',
						justifyContent: 'start',
						display: 'flex',
						flexDirection: 'row'
					}}
				>
					Shift
				</Typography>

				<FormGroup sx={{ width: '100%' }}>
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						defaultValue={filterPop.shift}
						name="radio-buttons-group"
						sx={{ mr: 1 }}
					>
						<FormControlLabel
							disabled={kitchen !== Kitchen.BH1}
							disableTypography={true}
							sx={{
								display: kitchen === Kitchen.BH1 ? '' : 'none',
								fontWeight: 400,
								width: '100%',
								fontSize: '20px',
								lineHeight: '24px',
								fontFamily: caloTheme.typography.fontFamily,
								justifyContent: 'space-between',
								my: '8px'
							}}
							labelPlacement="start"
							value={Session.all}
							onChange={() => setFilterPop({ ...filterPop, shift: Session.all })}
							control={<Radio />}
							label="All"
						/>
						<FormControlLabel
							disabled={kitchen === Kitchen.BH1}
							disableTypography={true}
							sx={{
								display: kitchen === Kitchen.BH1 ? 'none' : '',
								fontWeight: 400,
								width: '100%',
								fontSize: '20px',
								lineHeight: '24px',
								fontFamily: caloTheme.typography.fontFamily,
								justifyContent: 'space-between',
								my: '8px'
							}}
							labelPlacement="start"
							value={Session.morning}
							onChange={() => setFilterPop({ ...filterPop, shift: Session.morning })}
							control={<Radio />}
							label="Morning"
						/>
						<Divider sx={{ display: kitchen === Kitchen.BH1 ? 'none' : '' }} />
						<FormControlLabel
							disabled={kitchen === Kitchen.BH1}
							disableTypography={true}
							sx={{
								display: kitchen === Kitchen.BH1 ? 'none' : '',
								fontWeight: 400,
								width: '100%',
								fontSize: '20px',
								lineHeight: '24px',
								fontFamily: caloTheme.typography.fontFamily,
								justifyContent: 'space-between',
								my: '8px'
							}}
							labelPlacement="start"
							value={Session.evening}
							onChange={() => setFilterPop({ ...filterPop, shift: Session.evening })}
							control={<Radio />}
							label="Evening"
						/>
						<Divider sx={{}} />
					</RadioGroup>
				</FormGroup>
				<FormGroup sx={{ width: '100%', my: '8px' }}>
					<FormControlLabel
						sx={{
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '19px',
							ml: 2,
							fontFamily: caloTheme.typography.fontFamily,
							justifyContent: 'space-between'
						}}
						control={
							<Switch
								onClick={() => setFilterPop({ ...filterPop, showCustom: !filterPop.showCustom })}
								checked={filterPop.showCustom}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						disableTypography={true}
						label="Hide Custom Meals"
						labelPlacement="start"
					/>
				</FormGroup>
			</Stack>
			<Button
				variant="contained"
				sx={{
					mb: 2,
					ml: 1,
					width: '94%',
					backgroundColor: caloTheme.palette.primary500,
					boxShadow: 'none',
					textTransform: 'none',
					color: 'white',
					textAlign: 'center',
					height: '39px',
					borderRadius: '8px',
					fontWeight: 600,
					fontSize: '16px'
				}}
				onClick={() => handleFilter()}
			>
				{' '}
				Done
			</Button>
		</Paper>
	);
};
export default FilterPopup;
