import { gql } from '@apollo/client';

export const LOCALIZED_FIELDS = gql`
	fragment LocalizedFields on Localized {
		ar
		en
	}
`;

export const SHORTAGES_FIELDS = gql`
	fragment ShortagesFields on ComponentShortage {
		id
		foodId
		foodSize
		weight
		foodName {
			en
			ar
		}
		cookingStations {
			name
			status
			endTime
		}
		createdAt
	}
`;

export const FOOD_FIELDS = gql`
	${LOCALIZED_FIELDS}
	fragment FoodFields on Food {
		componentIds
		count
		country
		date
		id
		kitchen
		deliveryTime
		isCustom
		type
		size
		name {
			...LocalizedFields
		}
		isAssemblyRequired
		sizes {
			id
			size
			quantity
			purchasingCost
			components {
				id
				quantity
			}
			extraMeals
		}
		portioningStatus {
			session
			readyForAssemblyTime
			assemblingTime {
				startedAt
				intervals {
					pausedAt
					resumedAt
				}
			}
			readyForPortioningTime
			portioningTime {
				startedAt
				intervals {
					pausedAt
					resumedAt
				}
			}
			endTime
		}
		brand
		tags
		portioningURL
		assemblyURL
	}
`;

export const CHILD_FIELDS = gql`
	fragment ChildFields on Child {
		count
		weight
	}
`;

export const FOOD_COMPONENTS_FIELDS = gql`
	${LOCALIZED_FIELDS}
	${SHORTAGES_FIELDS}
	${CHILD_FIELDS}
	fragment FoodComponentsFields on FoodComponent {
		cookedWeight
		kitchen
		id
		date
		country
		purchasingCost
		cookingStations {
			name
			status
			processTime {
				startedAt
				intervals {
					pausedAt
					resumedAt
				}
			}
			endTime
		}
		count
		cost
		name {
			...LocalizedFields
		}
		weight
		kitchenWastageInputs {
			preppedWeight
			chilledWeight
			wastage
			action
		}
		brand
		measurementUnit
		cups {
			size
			value
		}
		quantities
		ingredients {
			id
			quantity
		}
		childComponents {
			id
			quantity
		}
		method {
			description
			attachment
			station
		}
		foodsIds
		cupsQuantity {
			cup
			quantity
		}
		shortages {
			...ShortagesFields
		}
		child {
			...ChildFields
		}
		standards {
			id
			name
			description
			imageURLs
		}
	}
`;

export const BAGGING_DISPATCH_FIELDS = gql`
	${LOCALIZED_FIELDS}
	fragment BaggingDispatchFields on BaggingDispatch {
		id
		session
		foodId
		foodCost
		foodName {
			...LocalizedFields
		}
		foodSize
		foodType
		total
		actual
		extra
		modified
		leftovers
		startTemperature
		endTemperature
		action
		purchasingCost
		isCustomFood
		packages {
			id
			name
		}
	}
`;

export const BAGGING_SESSION_FIELDS = gql`
	${BAGGING_DISPATCH_FIELDS}
	fragment BaggingSessionFields on BaggingSession {
		id
		date
		kitchen
		startTime
		endTime
		roomTemperature
		session
		status
		dispatches {
			...BaggingDispatchFields
		}
	}
`;

export const BAGGING_STATUS_FIELDS = gql`
	${BAGGING_DISPATCH_FIELDS}
	${BAGGING_SESSION_FIELDS}
	fragment baggingStatusFields on SessionInfo {
		kitchen
		date
		sessions {
			...BaggingSessionFields
		}
		dispatches {
			...BaggingDispatchFields
		}
	}
`;

export const INGREDIENTS_FIELDS = gql`
	${LOCALIZED_FIELDS}
	fragment IngredientsFields on Ingredient {
		id
		name {
			...LocalizedFields
		}
		quantity
		kitchen
		date
		brand
		wastage
		measurementUnit
		internalName
		purchasingCost
		header
		weight
	}
`;

export const MEAL_TICKET_FOOD_FIELDS = gql`
	${LOCALIZED_FIELDS}
	fragment MealTicketFoodFields on MealTicketFood {
		id
		name {
			...LocalizedFields
		}
		foodType
		size
		positionIndex
		slug
	}
`;

export const ADDRESS_FIELDS = gql`
	fragment AddressFields on Address {
		id
		lat
		lng
		country
		name
		region
		city
		district
		street
		building
		type
		apartment
		office
		kitchen
		customDeliveryTime
	}
`;

export const USER_FIELDS = gql`
	fragment UserFields on User {
		id
		name
		email
	}
`;

export const DRIVER_FIELDS = gql`
	fragment DriverFields on Driver {
		id
		name
		code
	}
`;

export const DELIVERY_TICKETS_FIELDS = gql`
	${MEAL_TICKET_FOOD_FIELDS}
	${ADDRESS_FIELDS}
	${USER_FIELDS}
	${DRIVER_FIELDS}
	fragment DeliveryTicketFields on DeliveryTicket {
		id
		data {
			type
			deliveryId
			deliveryTime
			deliveryShortId
			deliveryOldShortId
			userId
			autoApprove
			driver {
				...DriverFields
			}
			userName
			phoneNumber
			mealTicketData {
				oldFood {
					...MealTicketFoodFields
				}
				newFood {
					...MealTicketFoodFields
				}
			}
			addressTicketData {
				oldAddress {
					...AddressFields
				}
				newAddress {
					...AddressFields
				}
				oldDriver {
					...DriverFields
				}
				newDriver {
					...DriverFields
				}
				newDeliveryTime
			}
			manualTicketData {
				foods {
					...MealTicketFoodFields
				}
				cutlery
				coolerBag
			}
			otherTicketData {
				cutlery
				coolerBag
			}
			comment
		}
		date
		createdBy {
			...UserFields
		}
		resolvedBy {
			...UserFields
		}
		downloadedAt
		resolvedAt
		kitchen
		zone
		country
		brand
		status
		createdAt
	}
`;

export const CHEF_FIELDS = gql`
	fragment ChefFields on Chef {
		kitchen
		brand
		country
		name
		session
		stations
	}
`;

export const COMPONENT_BATCH_FIELDS = gql`
	${USER_FIELDS}
	fragment ComponentBatchFields on ComponentBatch {
		session
		date
		brand
		country
		chef
		batchNumber
		foodComponentId
		id
		kitchen
		measurementUnit
		weight
		quality {
			id
			commentImageURLs
			standards {
				id
				name
				rating
			}
			comment
			createdAt
			status
			actor {
				...UserFields
			}
		}
	}
`;

export const SHOPPING_MENU_FIELDS = gql`
	${LOCALIZED_FIELDS}
	fragment ShoppingMenuFields on ShoppingMenu {
		country
		day
		food {
			id
			isCustom
			isDone
			name {
				...LocalizedFields
			}
			order
			size
			type
			modifiedDeliveryIds {
				downloadSequence
				deliveryIds
			}
			portioningURL
		}
		id
		kitchen
	}
`;

export const DRIVER_DATA_FIELDS = gql`
	fragment DriverDispatchesFields on DriverDispatch {
		id
		driver {
			id
			driverName
			phoneNumber
			email
		}
		caloBags
		mealoBags
		vanTemperature
		departureTime
	}
`;

export const EXACT_FOOD_COMPONENTS_FIELDS = gql`
	${LOCALIZED_FIELDS}
	${INGREDIENTS_FIELDS}
	fragment SingleFoodComponentsFields on SingleFoodComponent {
		cookedWeight
		cookedRawFactor
		kitchen
		id
		country
		cookingStations {
			name
			status
			endTime
		}
		count
		cost
		name {
			...LocalizedFields
		}
		weight
		kitchenWastageInputs {
			preppedWeight
			chilledWeight
			wastage
			action
		}
		measurementUnit
		cups {
			size
			value
		}
		quantities
		brand
		ingredients {
			...IngredientsFields
		}
		childComponents {
			brand
			cookedWeight
			cookingStations {
				endTime
				name
				status
			}
			cost
			count
			country
			cups {
				size
				value
			}
			cupsQuantity {
				cup
				quantity
			}
			date
			foodsIds
			id
			ingredients {
				id
				quantity
			}
			kitchen
			kitchenWastageInputs {
				action
				chilledWeight
				preppedWeight
				wastage
			}
			measurementUnit
			method {
				attachment
				description
				station
			}
			name {
				ar
				en
			}
			quantities
			quantity
			tk
			weight
		}
		measurementUnit
		method {
			description
			attachment
			station
		}
		foodsIds
		cupsQuantity {
			cup
			quantity
		}
		standards {
			id
			name
			description
			imageURLs
		}
	}
`;

export const KITCHEN_SESSION_FIELDS = gql`
	fragment KDSKitchenFields on KDSKitchen {
		id
		sessions
	}
`;

export const CUSTOMER_COMPLAINTS_DATA_FIELDS = gql`
	${USER_FIELDS}
	fragment ComplaintsFields on Complaint {
		id
		category
		type
		date
		kitchen
		resolvedAt
		operationsId
		reviewedBy {
			...UserFields
		}
		resolvedBy {
			...UserFields
		}
		createdBy {
			...UserFields
		}
		user {
			userId
			name
			email
			phoneNumber
		}
		createdDate
		createdAt
		attachment
		attachments
		status
		meal {
			id
			name
			size
			slug
		}
		comments {
			id
			name
			text
		}
		deliveryTime
		driver {
			id
			name
		}
		reviewedAt
		deliveryId
	}
`;

export const DELIVERY_FIELDS = gql`
	${LOCALIZED_FIELDS}
	fragment DeliveryFields on Delivery {
		id
		userId
		food {
			id
			name {
				...LocalizedFields
			}
			size
			tags
			type
			isCustom
			skipped
			isSwapped
		}
		name
		phoneNumber
		kitchen
		day
		status
		skipped
		shortId
		driver {
			id
			name
		}
		brand
		downloadedDocs
		time
		customDeliveryTime
		pickupTime
	}
`;

export const DOWNLOAD_DOCS_FIELDS = gql`
	fragment DeliveryDownloadedDocsFields on DeliveryDocumentDownload {
		deliveryId
		day
		downloadedDocs
		country
		kitchen
		brand
	}
`;

export const GIFT_FIELDS = gql`
	fragment GiftFields on Gift {
		attachments
		comment
		updatedAt
		createdAt
		date
		deliveryId
		deliveryTime
		id
		kitchen
		zone
		phoneNumber
		type
		userId
		userName
		createdBy {
			email
			id
			name
		}
		items {
			amount
			id
			type
			item {
				id
				size
				slug
				name {
					ar
					en
				}
			}
		}
	}
`;

export const QUALITY_METRIC_FIELDS = gql`
	${LOCALIZED_FIELDS}
	fragment QualityMetricFields on QualityMetric {
		id
		day
		kitchen
		shift
		isFinalized
		food {
			id
			cacheKey
			name {
				...LocalizedFields
			}
			componentsIds
		}
		components {
			id
			cacheKey
			name {
				...LocalizedFields
			}
			cost
			weight
			consistencyScore
			numberOfRejected
			totalReviewed
			weightOfRejected
			cookedWeight
		}
	}
`;

export const PRODUCTIVITY_METRIC_FIELDS = gql`
	${LOCALIZED_FIELDS}
	fragment ProductivityMetricsFields on ProductivityMetrics {
		id
		day
		kitchen
		foods {
			id
			cacheKey
			name {
				...LocalizedFields
			}
			numberOfComponents
			type
			tags
			isSandwich
			metrics {
				cacheKey
				shift
				numberOfFoods
				numberOfModified
				portioningTime
				predictedPortioningTimePerMeal
			}
		}
	}
`;
