import { gql } from '@apollo/client';

import {
	BAGGING_DISPATCH_FIELDS,
	BAGGING_SESSION_FIELDS,
	CHEF_FIELDS,
	COMPONENT_BATCH_FIELDS,
	CUSTOMER_COMPLAINTS_DATA_FIELDS,
	DELIVERY_TICKETS_FIELDS,
	DRIVER_DATA_FIELDS,
	FOOD_COMPONENTS_FIELDS,
	FOOD_FIELDS,
	SHOPPING_MENU_FIELDS
} from './fragments';

export const FOOD_COMPONENT_STATUS_CHANGE_SUBSCRIPTION = gql`
	${FOOD_COMPONENTS_FIELDS}
	subscription subscribeToUpdateFoodComponentStatus {
		subscribeToUpdateFoodComponentStatus {
			...FoodComponentsFields
		}
	}
`;

export const FOOD_PORTIONING_CHANGE_SUBSCRIPTION = gql`
	${FOOD_FIELDS}
	subscription subscribeToUpdateFoodPortioning {
		subscribeToUpdateFoodPortioning {
			...FoodFields
		}
	}
`;

export const SUBSCRIBE_TO_BAGGING_SESSION = gql`
	${BAGGING_SESSION_FIELDS}
	subscription subscribeToBaggingSession {
		subscribeToBaggingSession {
			...BaggingSessionFields
		}
	}
`;

export const SUBSCRIBE_TO_BAGGING_DISPATCH = gql`
	${BAGGING_DISPATCH_FIELDS}
	subscription subscribeToBaggingDispatch {
		subscribeToBaggingDispatch {
			...BaggingDispatchFields
		}
	}
`;

export const SUBSCRIBE_TO_DELIVERY_TICKETS_CHANGES = gql`
	${DELIVERY_TICKETS_FIELDS}
	subscription subscribeToDeliveryTicketChanges {
		subscribeToDeliveryTicketChanges {
			...DeliveryTicketFields
		}
	}
`;

export const SUBSCRIBE_TO_BATCH_UPDATE_FOODS_PORTIONING_STATUS = gql`
	${FOOD_FIELDS}
	subscription subscribeToBatchUpdateFoodsPortioningStatus {
		subscribeToBatchUpdateFoodsPortioningStatus {
			...FoodFields
		}
	}
`;

export const SUBSCRIBE_TO_COMPONENT_BATCH_CHANGES = gql`
	${COMPONENT_BATCH_FIELDS}
	subscription subscribeToComponentBatchChanges {
		subscribeToComponentBatchChanges {
			...ComponentBatchFields
		}
	}
`;

export const SUBSCRIBE_TO_CHEF_CHANGES = gql`
	${CHEF_FIELDS}
	subscription subscribeToChefChanges {
		subscribeToChefChanges {
			...ChefFields
		}
	}
`;

export const SUBSCRIBE_TO_UPDATE_SHOPPING_MENU = gql`
	${SHOPPING_MENU_FIELDS}
	subscription subscribeToUpdateShoppingMenu {
		subscribeToUpdateShoppingMenu {
			...ShoppingMenuFields
		}
	}
`;

export const SUBSCRIBE_TO_DRIVER_CHANGES = gql`
	${DRIVER_DATA_FIELDS}
	subscription subscribeToUpdateDriverDispatch {
		subscribeToUpdateDriverDispatch {
			...DriverDispatchesFields
		}
	}
`;

export const SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE = gql`
	${FOOD_COMPONENTS_FIELDS}
	subscription subscribeToBatchCreateComponentShortage {
		subscribeToBatchCreateComponentShortage {
			...FoodComponentsFields
		}
	}
`;

export const SUBSCRIBE_TO_RESOLVE_COMPLAINTS = gql`
	${CUSTOMER_COMPLAINTS_DATA_FIELDS}
	subscription subscribeToResolveComplaint {
		subscribeToResolveComplaint {
			...ComplaintsFields
		}
	}
`;
