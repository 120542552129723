import { uniq } from 'lodash-es';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Kitchen } from '@calo/types';
import { Box, CircularProgress, Stack } from '@mui/material';

import EmptyMessage from '../../../components/EmptyMessage';
import { useListComponentBatchesWithPaginationQuery } from '../../../hooks';
import { QualityMetrics, Session } from '../../../libs/interfaces';
import ComponentsSectionTable from '../ComponentsTable/SectionTable';
import MealsSectionTable from '../MealsTable/SectionTable';

interface Props {
	qualityMetrics: QualityMetrics[];
	tab: 0 | 1;
	kitchen: Kitchen;
	shift?: Session;
	date: string;
	loading: boolean;
	name?: string;
	filterText: string;
	isExpanded: boolean;
	setIsExpanded: (isExpanded: boolean) => void;
}

const Section = ({ qualityMetrics, tab, kitchen, shift, date, name, loading, filterText, isExpanded, setIsExpanded }: Props) => {
	const dates = qualityMetrics.length ? uniq(qualityMetrics?.map((qualityMetric) => qualityMetric.day)) : [date];
	const { batches, handleFetchMoreBatches, hasNext } = useListComponentBatchesWithPaginationQuery(kitchen, dates, shift);

	return (
		<Box sx={{ width: '100%', mx: 2 }}>
			{qualityMetrics.length > 0 ? (
				tab === 0 ? (
					qualityMetrics.map((qualityMetric, index) => (
						<MealsSectionTable
							key={qualityMetric.id}
							qualityMetric={qualityMetric}
							name={name}
							filterText={index === 0 ? filterText : undefined}
							isExpanded={isExpanded}
							setIsExpanded={setIsExpanded}
						/>
					))
				) : (
					<InfiniteScroll
						dataLength={qualityMetrics.length}
						next={handleFetchMoreBatches}
						hasMore={hasNext}
						loader={
							hasNext ? (
								<Stack direction="column" alignItems="center">
									<CircularProgress sx={{ textAlign: 'center', mt: 2 }} />
								</Stack>
							) : null
						}
						style={{ overflow: 'hidden', width: '100%' }}
					>
						{qualityMetrics.map((qualityMetric, index) => (
							<ComponentsSectionTable
								key={qualityMetric.id}
								qualityMetric={qualityMetric}
								batches={batches.filter((batch) => batch.date === qualityMetric.day && batch.session === qualityMetric.shift)}
								name={name}
								filterText={index === 0 ? filterText : undefined}
								isExpanded={isExpanded}
								setIsExpanded={setIsExpanded}
							/>
						))}
					</InfiniteScroll>
				)
			) : (
				<EmptyMessage label={loading ? 'Loading' : 'No data'} style={{ marginTop: '10', textAlign: 'center' }} />
			)}
		</Box>
	);
};

export default Section;
