import { useState } from 'react';

import { uniqBy } from 'lodash-es';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';

import { GraphQLClient, LIST_CUSTOMER_COMPLAINTS, SUBSCRIBE_TO_RESOLVE_COMPLAINTS } from '../libs/graphQL';
import { ComplaintFilterProps } from '../libs/interfaces';

const useListCustomerComplaints = (filters: ComplaintFilterProps) => {
	const [complaintsList, setComplaints] = useState<any>([]);
	const [page, setPage] = useState(0);
	const [total, setTotal] = useState<number>(0);
	const limit = 50;
	const hasNext = (page + 1) * limit < total;

	useSubscription(SUBSCRIBE_TO_RESOLVE_COMPLAINTS);
	const { loading, refetch, fetchMore } = useQuery(LIST_CUSTOMER_COMPLAINTS, {
		variables: {
			query: {
				filters: {
					...filters
				},
				limit: limit,
				page: 0
			}
		},
		onCompleted: (data) => {
			if (data?.listComplaints?.data) {
				setComplaints(data?.listComplaints?.data);
			}
			if (data?.listComplaints?.total) {
				setTotal(data?.listComplaints?.total);
			}
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	const handleFetchMore = () => {
		if (hasNext) {
			const pageNum = page + 1;
			fetchMore({
				variables: {
					query: {
						filters: { ...filters },
						limit: limit,
						page: pageNum
					}
				},
				updateQuery: (prev: any, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					if (prev) {
						setPage(pageNum + 1);
						const newComplaints = uniqBy(
							[...(prev.listComplaints?.data || []), ...(fetchMoreResult?.listComplaints?.data || [])],
							'id'
						);

						return Object.assign({}, prev, {
							listComplaints: {
								data: newComplaints,
								total: prev?.listComplaints?.total
							}
						});
					}
				}
			});
		}
	};

	const clearData = () => {
		setPage(0);
		setComplaints([]);
		// eslint-disable-next-line unicorn/no-useless-undefined
		GraphQLClient.clearStore();
	};

	return {
		complaintsLoading: loading,
		complaintsList,
		hasNext: hasNext,
		handleFetchMoreComplaints: handleFetchMore,
		clearComplaintsData: clearData,
		refetchComplaints: refetch
	};
};

export default useListCustomerComplaints;
