import { isNil } from 'lodash-es';

import { FoodComponentMethodStep, MeasurementUnit } from '@calo/dashboard-types';
import { ComponentService } from '@calo/services';

import { FoodComponent } from '../../libs/interfaces';

export const formatWeightText = (weight: number, weightPerMeasurmentUnit: number, measurementUnit: MeasurementUnit) =>
	measurementUnit === MeasurementUnit.g
		? weight + ' ' + measurementUnit
		: `${weight} g` + ` (${weight / (weightPerMeasurmentUnit || 1) + ' ' + measurementUnit})`;

export const calculateFractionWeight = (fc: FoodComponent, quantity: number, newCookedWeight: number, ingWeight?: number) => {
	const totalWeight = ComponentService.calculateTotalComponentAbsoluteWeight(fc);
	const fraction = (quantity * (ingWeight || 1)) / totalWeight;
	const ingQuantity = newCookedWeight * fc.cookedRawFactor * fraction;
	return ingQuantity;
};

export const addStepsToMethods = (methods: FoodComponentMethodStep[]): (FoodComponentMethodStep & { step: number })[] => {
	let currentStation: string | undefined = 'initialStation';
	let currentStep = 0;

	return methods.map((method) => {
		if (
			(!method.station && !(isNil(currentStation) || currentStation === '')) ||
			(method.station && method.station !== currentStation)
		) {
			currentStation = method.station;
			currentStep = 0;
		} else {
			currentStep++;
		}

		return { ...method, step: currentStep };
	});
};
