import { keyBy } from 'lodash-es';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';
import { Dictionary } from '@calo/types';
import { Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../App';
import EmptyMessage from '../../components/EmptyMessage';
import { FoodComponentStation } from '../../libs/enums';
import {
	FOOD_COMPONENT_STATUS_CHANGE_SUBSCRIPTION,
	LIST_FOOD_COMPONENTS_QUERY,
	SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE
} from '../../libs/graphQL';
import { KDSFoodComponent } from '../../libs/interfaces';
import { useDocumentMedia } from '../../libs/utils/document';
import { getComponentsWithNonZeroCount } from '../../libs/utils/foodComponentHelper';
import { getActiveShoragesCountForAllSections } from './ComponentsCheckHelpers';
import SectionTable from './Desktop/SectionTable';
import DownloadPopup from './DownloadPopup';
import MobileSectionTable from './Mobile/SectionTable/MobileSectionTable';

interface ComponentsCheckProps {
	kitchen: string;
	date: string;
}

const ComponentsCheck = ({ kitchen, date }: ComponentsCheckProps) => {
	const theme = useTheme();
	const appContext = useContext(AppContext);
	const [filters, setFilters] = useState('');
	const [focus, setFocus] = useState<string>('');
	const { isMobile } = useDocumentMedia();
	const [selectedComponentStation, setSelectedComponentStation] = useState<string>(Object.keys(FoodComponentStation)[0]);
	const exportComponentStatusModalRef = useRef<any>();

	useSubscription(FOOD_COMPONENT_STATUS_CHANGE_SUBSCRIPTION);
	useSubscription(SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE);

	const { data, subscribeToMore, refetch } = useQuery(LIST_FOOD_COMPONENTS_QUERY, {
		variables: { kitchen, date },
		onError: () => {
			toast.error('Error while loading foodComponents');
		}
	});

	const moreComponents = () =>
		subscribeToMore({
			document: SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscribeToBatchCreateComponentShortage = subscriptionData.data.subscribeToBatchCreateComponentShortage;
				const keyedById: Dictionary<KDSFoodComponent> = keyBy(subscribeToBatchCreateComponentShortage, 'id');
				const updatedComponents: KDSFoodComponent[] = (prev.listFoodComponents.data as KDSFoodComponent[]).map((c) => ({
					...c,
					shortages: keyedById[c.id] ? keyedById[c.id].shortages : c.shortages
				}));

				for (const [key, value] of Object.entries(keyedById)) {
					if (!updatedComponents.some((c) => c.id === key)) {
						updatedComponents.push(value);
					}
				}

				return Object.assign({}, prev, {
					listFoodComponents: {
						data: [...updatedComponents]
					}
				});
			}
		});

	useEffect(() => {
		moreComponents();
	}, []);

	useEffect(() => {
		refetch();
	}, [kitchen, date]);

	const foodComponents = (data?.listFoodComponents?.data || []) as KDSFoodComponent[];
	const foodComponentsWithNonZeroCount = getComponentsWithNonZeroCount(foodComponents);
	const shortages = getActiveShoragesCountForAllSections(foodComponents);

	if (isMobile) {
		return (
			<Box sx={{ width: '100%', my: 2, display: 'flex', flexDirection: 'column', maxHeight: 'auto' }}>
				<FormControl sx={{ width: '100%', my: 2, px: 1 }}>
					<Box>
						<Select
							sx={{
								width: '60%',
								borderRadius: '8px'
							}}
							MenuProps={{
								PaperProps: {
									style: {
										marginLeft: '-8px',
										maxHeight: '300px'
									}
								}
							}}
							value={selectedComponentStation}
							onChange={(data: any) => setSelectedComponentStation(data.target.value)}
						>
							{Object.entries(FoodComponentStation).map(([key, value]) => (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							))}
						</Select>

						{shortages[selectedComponentStation] && shortages[selectedComponentStation].total > 0 && (
							<Stack
								display={'flex'}
								sx={{
									justifyContent: 'space-between',
									alignItems: 'center',
									backgroundColor: theme.palette.secondaryRed50,
									mt: '12px',
									borderRadius: '10px',
									p: '10px'
								}}
								flexDirection={'row'}
							>
								<Typography>Shortage Reported</Typography>
								<Stack sx={{ backgroundColor: 'red', ml: '6px', borderRadius: '15px' }}>
									<Typography sx={{ color: 'white', mx: '6px' }}>{shortages[selectedComponentStation].total}</Typography>
								</Stack>
							</Stack>
						)}
						<Button
							variant="outlined"
							sx={{
								ml: '15px',
								fontSize: '16px',
								padding: '10px',
								color: theme.palette.neutral900,
								borderColor: theme.palette.neutral700,
								borderRadius: '10px',
								textTransform: 'none',
								'&:hover': {
									backgroundColor: 'transparent',
									borderColor: theme.palette.neutral700
								},
								width: '35%'
							}}
							onClick={() => exportComponentStatusModalRef.current?.open()}
						>
							Download
						</Button>
						<DownloadPopup
							exportComponentStatusModalRef={exportComponentStatusModalRef}
							theme={theme}
							kitchen={kitchen}
							mt="-5px"
							spacing={2}
							width="100%"
							stackDirection="column"
						/>
					</Box>
				</FormControl>

				<Stack direction="row" sx={{ px: 1 }}>
					{foodComponentsWithNonZeroCount.length > 0 ? (
						<MobileSectionTable
							key={selectedComponentStation}
							selectedComponentStation={selectedComponentStation as FoodComponentStation}
							setSelectedComponentStation={setSelectedComponentStation}
							foodComponents={foodComponentsWithNonZeroCount.filter(
								(fc) => fc.cookingStations.findIndex((s) => s.name === selectedComponentStation) !== -1
							)}
						/>
					) : (
						<EmptyMessage style={{ textAlign: 'center', width: '100%' }} label="No Data" />
					)}
				</Stack>
			</Box>
		);
	} else {
		return (
			<Box>
				<Box
					sx={{
						position: 'absolute',
						top: '70px',
						width: appContext.isSidebarOpened ? 'calc(100% - 240px)' : '100%',
						overflow: 'visible'
					}}
				>
					<Stack direction={'row'} alignItems="center" justifyContent={'stretch'} sx={{ mx: '30px', mt: '5px', mb: 2 }}>
						{Object.entries(FoodComponentStation).map(([key, value], index) => (
							<Button
								key={index}
								disabled={foodComponents.length === 0}
								value={filters}
								onClick={() => {
									setFocus(key);
									setFilters(key);
								}}
								sx={{
									textTransform: 'capitalize',
									fontSize: appContext.isSidebarOpened ? '14px' : '16px',
									fontWeight: 600,
									flexShrink: 0,
									flexGrow: 1,
									backgroundColor: theme.palette.white,
									px: 0,
									borderBottom: `2px solid ${focus === key ? theme.palette.primary500 : theme.palette.neutral100}`,
									borderRadius: 0,
									color: focus === key ? theme.palette.primary500 : theme.palette.neutral500,
									'&:hover': {
										backgroundColor: 'transparent'
									}
								}}
							>
								{value}
							</Button>
						))}
						<Button
							variant="outlined"
							sx={{
								ml: '30px',
								fontSize: '14px',
								padding: '10px 20px',
								color: 'black',
								borderColor: 'black',
								borderRadius: '10px',
								textTransform: 'none',
								'&:hover': {
									backgroundColor: 'transparent',
									borderColor: 'black'
								}
							}}
							onClick={() => exportComponentStatusModalRef.current?.open()}
						>
							Download
						</Button>
					</Stack>
				</Box>
				<Box sx={{ mt: '-80px', width: '100%' }}>
					<Stack direction="row" sx={{ mx: '15px', my: '5px', mt: '50px' }}>
						{foodComponentsWithNonZeroCount.length > 0 ? (
							Object.keys(FoodComponentStation).map((station, index) => (
								<SectionTable
									tableName={station as FoodComponentStation}
									shortages={shortages}
									setSelectedTable={(key) => {
										setFocus(key);
										setFilters(key);
									}}
									foodComponents={foodComponentsWithNonZeroCount.filter(
										(fc) => fc.cookingStations.findIndex((s) => s.name === station) !== -1
									)}
									key={index}
									focus={focus}
								/>
							))
						) : (
							<EmptyMessage style={{ marginTop: '120px' }} label="No Data" />
						)}
					</Stack>
				</Box>
				<DownloadPopup exportComponentStatusModalRef={exportComponentStatusModalRef} theme={theme} kitchen={kitchen} />
			</Box>
		);
	}
};

export default ComponentsCheck;
