import React, { useContext } from 'react';

import { Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Food } from '@calo/types';
import { AppContext } from '../../../../App';
import { caloTheme } from '../../../../assets/themes/calo';
import { MealCheckBox } from '../../../../libs/interfaces';

interface FileManagementMealTableRowProps {
	meal: any;
	mealCheckboxes: MealCheckBox;
	handleMealCheckboxChange: (meal: Food, isBackLabelToggle: boolean) => void;
}

const FileManagementMealTableRow: React.FC<FileManagementMealTableRowProps> = ({
	meal,
	mealCheckboxes,
	handleMealCheckboxChange
}) => {
	const appContext = useContext(AppContext);

	return (
		<TableRow style={{ borderBottomColor: caloTheme.palette.neutral300 }} sx={{ borderBottom: 1 }}>
			<TableCell sx={{ width: '60%', border: 'none', pl: 1 }}>
				<Typography padding="8px" fontWeight="400" fontSize="16px" lineHeight="20px" textTransform="capitalize">
					{`${meal?.name.en}`}
				</Typography>
			</TableCell>
			<TableCell sx={{ width: '10%', border: 'none' }}>
				<Stack display="flex" flexDirection="row" sx={{ width: '100%' }} justifyContent="start">
					<Typography
						key={meal.id}
						textTransform="capitalize"
						sx={{
							textAlign: 'start',
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '10px'
						}}
					>
						{meal.size}
					</Typography>
				</Stack>
			</TableCell>
			<TableCell
				sx={{
					border: 'none',
					textTransform: 'capitalize',
					fontWeight: 400,
					fontSize: '16px',
					lineHeight: '20px'
				}}
			>
				{meal.type.map((type: any, index: number) => `${type} ${meal.type.length - 1 === index ? '' : '& '} `)}
			</TableCell>
			<TableCell
				sx={{
					border: 'none',
					textTransform: 'capitalize',
					textAlign: 'right',
					fontWeight: 400,
					fontSize: '16px',
					lineHeight: '20px'
				}}
			>
				<Checkbox
					checked={!!mealCheckboxes[meal.id]?.isFrontLabelSelected}
					disabled={appContext.isOffline}
					onChange={() => handleMealCheckboxChange(meal, false)}
					inputProps={{ 'aria-label': 'controlled' }}
					style={{ maxHeight: '12px' }}
				/>
			</TableCell>
			<TableCell
				sx={{
					border: 'none',
					textTransform: 'capitalize',
					textAlign: 'right',
					fontWeight: 400,
					fontSize: '16px',
					lineHeight: '20px'
				}}
			>
				<Checkbox
					checked={!!mealCheckboxes[meal.id]?.isBackLabelSelected}
					disabled={appContext.isOffline}
					onChange={() => handleMealCheckboxChange(meal, true)}
					inputProps={{ 'aria-label': 'controlled' }}
					style={{ maxHeight: '12px' }}
				/>
			</TableCell>
		</TableRow>
	);
};
export default FileManagementMealTableRow;
