import { gql } from '@apollo/client';

import {
	BAGGING_DISPATCH_FIELDS,
	BAGGING_SESSION_FIELDS,
	BAGGING_STATUS_FIELDS,
	CHEF_FIELDS,
	COMPONENT_BATCH_FIELDS,
	CUSTOMER_COMPLAINTS_DATA_FIELDS,
	DELIVERY_FIELDS,
	DELIVERY_TICKETS_FIELDS,
	DRIVER_DATA_FIELDS,
	EXACT_FOOD_COMPONENTS_FIELDS,
	FOOD_COMPONENTS_FIELDS,
	FOOD_FIELDS,
	GIFT_FIELDS,
	KITCHEN_SESSION_FIELDS,
	LOCALIZED_FIELDS,
	PRODUCTIVITY_METRIC_FIELDS,
	QUALITY_METRIC_FIELDS,
	SHOPPING_MENU_FIELDS
} from './fragments';

export const LIST_FOOD_QUERY = gql`
	${FOOD_FIELDS}
	query getFoods($date: String!, $kitchen: Kitchen!, $shift: String, $brand: String) {
		listFoods(date: $date, kitchen: $kitchen, shift: $shift, brand: $brand) {
			data {
				...FoodFields
			}
		}
	}
`;

export const LIST_FOOD_COMPONENTS_QUERY = gql`
	${FOOD_COMPONENTS_FIELDS}
	query getFoodComponents(
		$date: String!
		$kitchen: Kitchen!
		$station: String
		$shift: String
		$brand: String
		$withShortages: Boolean
	) {
		listFoodComponents(
			date: $date
			kitchen: $kitchen
			station: $station
			shift: $shift
			brand: $brand
			withShortages: $withShortages
		) {
			data {
				...FoodComponentsFields
			}
		}
	}
`;

export const LIST_FOOD_COMPONENTS_BY_NAME_QUERY = gql`
	${LOCALIZED_FIELDS}
	query getFoodComponentsByName($kitchen: Kitchen!, $brand: String, $name: String) {
		listFoodComponentsByName(kitchen: $kitchen, brand: $brand, name: $name) {
			data {
				id
				date
				kitchen
				name {
					...LocalizedFields
				}
			}
		}
	}
`;
export const GET_FOOD_COMPONENT_QUERY = gql`
	${EXACT_FOOD_COMPONENTS_FIELDS}
	query getFoodComponent($id: String) {
		getFoodComponent(id: $id) {
			...SingleFoodComponentsFields
		}
	}
`;

export const BAGGING_STATUS_QUERY = gql`
	${BAGGING_STATUS_FIELDS}
	query baggingStatus($date: String!, $kitchen: Kitchen!) {
		baggingStatus(date: $date, kitchen: $kitchen) {
			...baggingStatusFields
		}
	}
`;

export const LIST_BAGGING_SESSION_QUERY = gql`
	${BAGGING_SESSION_FIELDS}
	query listBaggingSession(
		$kitchen: Kitchen
		$startDate: String
		$endDate: String
		$shift: String
		$actions: [String]
		$limit: Int
		$next: String
	) {
		listBaggingSession(
			kitchen: $kitchen
			startDate: $startDate
			endDate: $endDate
			shift: $shift
			actions: $actions
			limit: $limit
			next: $next
		) {
			next
			count
			scannedCount
			sessions {
				...BaggingSessionFields
			}
		}
	}
`;

export const LIST_BAGGING_DISPATCH_QUERY = gql`
	${BAGGING_DISPATCH_FIELDS}
	query listBaggingDispatch($kitchen: Kitchen, $date: String, $session: String) {
		listBaggingDispatch(kitchen: $kitchen, date: $date, session: $session) {
			...BaggingDispatchFields
		}
	}
`;

export const EXPORT_BAGGING_SESSION_QUERY = gql`
	query exportBaggingSession(
		$kitchen: Kitchen
		$startDate: String
		$endDate: String
		$shift: String
		$actions: [String]
		$fileType: String
	) {
		exportBaggingSession(
			kitchen: $kitchen
			startDate: $startDate
			endDate: $endDate
			shift: $shift
			actions: $actions
			fileType: $fileType
		) {
			data
		}
	}
`;

export const LIST_FOOD_COMPONENTS_BY_RANGE_QUERY = gql`
	${FOOD_COMPONENTS_FIELDS}
	query getFoodComponentsByRange(
		$kitchen: Kitchen
		$startDate: String
		$endDate: String
		$limit: Int
		$next: String
		$filters: ListFoodComponentByRangeFilters
	) {
		listFoodComponentByRange(
			kitchen: $kitchen
			startDate: $startDate
			endDate: $endDate
			limit: $limit
			next: $next
			filters: $filters
		) {
			next
			components {
				...FoodComponentsFields
			}
		}
	}
`;

export const LIST_DELIVERY_TICKETS_QUERY = gql`
	${DELIVERY_TICKETS_FIELDS}
	query listDeliveryTickets($query: listDeliveryTicketsInput) {
		listDeliveryTickets(query: $query) {
			data {
				...DeliveryTicketFields
			}
		}
	}
`;

export const LIST_CHEF_QUERY = gql`
	${CHEF_FIELDS}
	query getChefs($kitchen: Kitchen!) {
		listChefs(kitchen: $kitchen) {
			data {
				id
				...ChefFields
			}
		}
	}
`;

export const LIST_COMPONENT_BATCHES_QUERY = gql`
	${COMPONENT_BATCH_FIELDS}
	query getListComponentBatches($date: RangeInput!, $kitchen: Kitchen!, $shift: String) {
		listComponentBatches(date: $date, kitchen: $kitchen, shift: $shift) {
			data {
				...ComponentBatchFields
			}
		}
	}
`;

export const GET_SHOPPING_MENU_QUERY = gql`
	${SHOPPING_MENU_FIELDS}
	query fetchShoppingMenu($id: String!, $kitchen: Kitchen!) {
		getShoppingMenu(id: $id, kitchen: $kitchen) {
			...ShoppingMenuFields
		}
	}
`;

export const LIST_DRIVER_DISPATCH = gql`
	${DRIVER_DATA_FIELDS}
	query listDriverDispatches($date: String!, $deliveryTime: String, $kitchen: Kitchen!) {
		listDriverDispatches(date: $date, deliveryTime: $deliveryTime, kitchen: $kitchen) {
			...DriverDispatchesFields
		}
	}
`;

export const LIST_KITCHEN_SESSIONS = gql`
	${KITCHEN_SESSION_FIELDS}
	query listKitchens($kitchen: Kitchen!) {
		listKitchens(kitchen: $kitchen) {
			data {
				...KDSKitchenFields
			}
		}
	}
`;

export const LIST_CUSTOMER_COMPLAINTS = gql`
	${CUSTOMER_COMPLAINTS_DATA_FIELDS}
	query listComplaints($query: ListComplaintInput) {
		listComplaints(query: $query) {
			data {
				...ComplaintsFields
			}
			total
		}
	}
`;

export const LIST_DELIVERY_QUERY = gql`
	${DELIVERY_FIELDS}
	query listDeliveries($query: ListDeliveriesInput) {
		listDeliveries(query: $query) {
			data {
				...DeliveryFields
			}
			total
		}
	}
`;

export const LIST_FOOD_BY_FILTERS_QUERY = gql`
	${FOOD_FIELDS}
	query listFoodsByFilters($filters: FoodFiltersInput, $limit: Int, $page: Int) {
		listFoodsByFilters(filters: $filters, limit: $limit, page: $page) {
			data {
				...FoodFields
			}
			meta {
				total
				limit
				page
			}
		}
	}
`;

export const LIST_GIFT_REQUEST = gql`
	${GIFT_FIELDS}
	query listGifts($query: ListGiftsReqInput) {
		listGifts(query: $query) {
			next
			data {
				...GiftFields
			}
		}
	}
`;

export const LIST_QUALITY_METRICS_QUERY = gql`
	${QUALITY_METRIC_FIELDS}
	query listQualityMetrics($startDate: String, $endDate: String, $kitchen: Kitchen, $shift: Session) {
		listQualityMetrics(startDate: $startDate, endDate: $endDate, kitchen: $kitchen, shift: $shift) {
			data {
				...QualityMetricFields
			}
		}
	}
`;

export const LIST_PRODUCTIVITY_METRICS_QUERY = gql`
	${PRODUCTIVITY_METRIC_FIELDS}
	query listProductivityMetrics($startDate: String, $endDate: String, $kitchen: Kitchen) {
		listProductivityMetrics(startDate: $startDate, endDate: $endDate, kitchen: $kitchen) {
			data {
				...ProductivityMetricsFields
			}
		}
	}
`;
