import { useEffect, useRef, useState } from 'react';

import { Card, Chip, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';

import { caloTheme } from '../../../../assets/themes/calo';
import { Popup } from '../../../../components';
import { FCStatus } from '../../../../libs/enums';
import { KDSFood, KDSFoodComponent } from '../../../../libs/interfaces';
import { getFoodComponentsSortedByNameLengthAndLastCookingStationStatus } from '../../../../libs/utils/helperFunctions';

interface MealStatusMobileRowProps {
	food: KDSFood;
	foodComponents: KDSFoodComponent[];
}

const MealStatusMobileRow = ({ foodComponents, food }: MealStatusMobileRowProps) => {
	const [componentLeft, setComponentLeft] = useState(0);
	const expandRef = useRef<any>();

	useEffect(() => {
		setComponentLeft(
			foodComponents.filter(
				(foodComponent) =>
					foodComponent && foodComponent.cookingStations.some((cookingStation) => cookingStation.status === FCStatus.pending)
			).length
		);
	}, [foodComponents, food]);

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const checkIsFoodComponentReady = (foodComponent: KDSFoodComponent) =>
		!foodComponent.cookingStations.some((cookingStation) => cookingStation.status === FCStatus.pending);

	return (
		<>
			<Card
				variant="outlined"
				key={food.id}
				onClick={() =>
					getFoodComponentsSortedByNameLengthAndLastCookingStationStatus(foodComponents).length - 4 > 0 &&
					expandRef.current?.open()
				}
				sx={{
					boxShadow: 'none',
					height: 'auto',
					minHeight: '2px',
					alignContent: 'center',
					maxWidth: '96%',
					borderRadius: '8px',
					marginBottom: 2,
					borderColor: componentLeft === 0 ? caloTheme.palette.primary500 : caloTheme.palette.neutral100
				}}
			>
				<Box display={'flex'} flexDirection={'column'} sx={{}}>
					<Stack display={'flex'} flexDirection={'row'} justifyContent="space-between">
						<Typography
							sx={{
								m: 2,
								lineHeight: '19px',
								fontSize: '16px',
								fontFamily: caloTheme.palette.neutral900,
								fontWeight: 600,
								letterSpacing: '-2%',
								textTransform: 'capitalize'
							}}
						>
							{food.name.en}
						</Typography>
						{componentLeft === 0 && (
							<Typography
								sx={{
									m: 2,
									lineHeight: '19px',
									fontSize: '16px',
									fontFamily: caloTheme.palette.neutral900,
									fontWeight: 400,
									color: caloTheme.palette.primary500
								}}
							>
								Done
							</Typography>
						)}
					</Stack>
					<Stack
						display={componentLeft === 0 ? 'none' : 'flex'}
						flexDirection={'row'}
						justifyContent="space-between"
						sx={{ mx: 2 }}
					>
						<Typography sx={{ lineHeight: '19px', fontSize: '16px', fontFamily: caloTheme.palette.neutral900, fontWeight: 400 }}>
							{componentLeft} Components left
						</Typography>
					</Stack>
					<Grid container sx={{ mb: 1, mx: 2, width: '96%' }}>
						{getFoodComponentsSortedByNameLengthAndLastCookingStationStatus(foodComponents)
							.slice(0, 4)
							.map(
								(foodComponent) =>
									foodComponent && (
										<Grid key={foodComponent.id} item sx={{ minWidth: '25%', my: 1, mr: 1 }}>
											<Card
												variant="outlined"
												style={{
													boxShadow: 'none',
													fontSize: '19px',
													borderRadius: '240px'
												}}
											>
												<Stack
													display={'flex'}
													flexDirection={'row'}
													sx={{ m: 1, alignItems: 'center', minHeight: '12px', maxHeight: '124px', overflow: 'hidden' }}
												>
													<Chip
														sx={{
															width: '12px',
															height: '12px',
															borderRadius: '240px',
															mr: '6px',
															bgcolor: checkIsFoodComponentReady(foodComponent)
																? caloTheme.palette.primary500
																: caloTheme.palette.red
														}}
													/>
													<Typography
														variant="h6"
														sx={{
															flexShrink: 0,
															mt: '2px',
															lineHeight: '19px',
															fontSize: '16px',
															fontFamily: caloTheme.palette.neutral900,
															fontWeight: 600,
															letterSpacing: '-2%'
														}}
													>
														{foodComponent.name.en}
													</Typography>
												</Stack>
											</Card>
										</Grid>
									)
							)}
					</Grid>
				</Box>
				{getFoodComponentsSortedByNameLengthAndLastCookingStationStatus(foodComponents).length - 4 > 0 && (
					<Stack display={'flex'} flexDirection={'row'} justifyContent="center" sx={{ my: 1 }}>
						{getFoodComponentsSortedByNameLengthAndLastCookingStationStatus(foodComponents).length - 4} More Components
					</Stack>
				)}
			</Card>
			<Popup title={''} ref={expandRef} onClose={() => expandRef.current.close()} style={{ maxWidth: 'mobile' }}>
				<Box>
					<Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
						<Typography
							sx={{ m: 2, lineHeight: '19px', fontSize: '16px', fontFamily: caloTheme.palette.neutral900, fontWeight: 600 }}
						>
							{food.name.en}
						</Typography>
						<Typography
							sx={{
								m: 2,
								lineHeight: '19px',
								fontSize: '12px',
								fontFamily: caloTheme.palette.neutral900,
								fontWeight: 600,
								color: caloTheme.palette.primary500
							}}
						>
							{componentLeft === 0 ? 'Done' : ''}
						</Typography>
					</Stack>
					<Grid container sx={{ maxHeight: 'auto', mb: 1 }}>
						{getFoodComponentsSortedByNameLengthAndLastCookingStationStatus(foodComponents).map(
							(foodComponent) =>
								foodComponent && (
									<Grid key={foodComponent.id} item sx={{ minWidth: '25%', my: '6px', ml: 2 }}>
										<Card
											variant="outlined"
											style={{
												boxShadow: 'none',
												fontSize: '19px',
												borderRadius: '240px'
											}}
										>
											<Stack
												display={'flex'}
												flexDirection={'row'}
												sx={{ m: 1, alignItems: 'center', minHeight: '12px', maxHeight: '124px', overflow: 'hidden' }}
											>
												<Chip
													sx={{
														width: '12px',
														height: '12px',
														borderRadius: '240px',
														mr: '6px',
														bgcolor: checkIsFoodComponentReady(foodComponent)
															? caloTheme.palette.primary500
															: caloTheme.palette.red
													}}
												/>
												<Typography
													variant="h6"
													sx={{
														flexShrink: 0,
														mt: '2px',
														lineHeight: '19px',
														fontSize: '16px',
														fontFamily: caloTheme.palette.neutral900,
														fontWeight: 600,
														letterSpacing: '-2%'
													}}
												>
													{foodComponent.name.en}
												</Typography>
											</Stack>
										</Card>
									</Grid>
								)
						)}
					</Grid>
				</Box>
			</Popup>
		</>
	);
};

export default MealStatusMobileRow;
