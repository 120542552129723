import { useContext, useState } from 'react';

import { Icon } from '@iconify/react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { AppContext } from '../../App';
import { FoodPortioningAction, FoodPortioningStatus, UpdateFoodPortioningStatusOperation } from '../../libs/enums';
import { PortioningStatusAttributes } from '../../libs/interfaces';
import PlayIcon from './PlayIcon';

interface PortionActionButtonProps {
	attributes: PortioningStatusAttributes;
	loading: boolean;
	isMobile?: boolean;
	handleUpdateFoodPortioningStatus: (operation: UpdateFoodPortioningStatusOperation) => void;
}

const PortionActionButton = (props: PortionActionButtonProps) => {
	const { attributes, loading, isMobile, handleUpdateFoodPortioningStatus } = props;

	const theme = useTheme();
	const appContext = useContext(AppContext);
	const [isFinishing, setIsFinishing] = useState(false);

	const isPortioning =
		attributes.status === FoodPortioningStatus.pausePortioning || attributes.status === FoodPortioningStatus.resumePortioning;

	const isAssembling =
		attributes.status === FoodPortioningStatus.pauseAssembling || attributes.status === FoodPortioningStatus.resumeAssembling;

	const isPaused =
		attributes.status === FoodPortioningStatus.pauseAssembling || attributes.status === FoodPortioningStatus.pausePortioning;

	const modifiedButtonText = isPortioning || isAssembling ? attributes.buttonText.split(' ')[0] : attributes.buttonText;

	const disabledButtonColor = (() => {
		if (
			attributes.buttonText === FoodPortioningAction.finishPortioning ||
			attributes.buttonText === FoodPortioningAction.completed
		) {
			return theme.palette.neutral600;
		} else if (
			attributes.buttonText === FoodPortioningAction.startPortioning ||
			attributes.buttonText === FoodPortioningAction.startAssembling ||
			attributes.buttonText === FoodPortioningAction.pauseAssembling ||
			attributes.buttonText === FoodPortioningAction.resumeAssembling
		) {
			return attributes.backgroundColor;
		} else {
			return attributes.color;
		}
	})();

	const getPortioningStatusOperation = () => {
		switch (attributes.buttonText) {
			case FoodPortioningAction.startPortioning:
				return UpdateFoodPortioningStatusOperation.startPortioning;
			case FoodPortioningAction.pausePortioning:
				return UpdateFoodPortioningStatusOperation.pausePortioning;
			case FoodPortioningAction.resumePortioning:
				return UpdateFoodPortioningStatusOperation.resumePortioning;
			case FoodPortioningAction.startAssembling:
				return UpdateFoodPortioningStatusOperation.startAssembling;
			case FoodPortioningAction.pauseAssembling:
				return UpdateFoodPortioningStatusOperation.pauseAssembling;
			case FoodPortioningAction.resumeAssembling:
				return UpdateFoodPortioningStatusOperation.resumeAssembling;
			default:
				return UpdateFoodPortioningStatusOperation.startPortioning;
		}
	};

	const finishPortioning = () => {
		setIsFinishing(true);
		handleUpdateFoodPortioningStatus(UpdateFoodPortioningStatusOperation.ended);
	};
	const finishAssembling = () => {
		setIsFinishing(true);
		handleUpdateFoodPortioningStatus(UpdateFoodPortioningStatusOperation.readyToBePortioned);
	};

	return (
		<Stack
			display={'flex'}
			flexDirection="row"
			gap={'10px'}
			height="39px"
			width={'100%'}
			justifyContent={'center'}
			sx={{ mb: isMobile ? 1 : 0 }}
		>
			<Button
				disabled={attributes.isDisabled || loading || appContext.isOffline}
				onClick={() => handleUpdateFoodPortioningStatus(getPortioningStatusOperation())}
				startIcon={
					isAssembling || isPortioning ? (
						isPaused ? (
							<PlayIcon />
						) : (
							<Icon icon="fluent:pause-circle-20-regular" height="24" width="24" />
						)
					) : null
				}
				sx={{
					width: '47%',
					height: '100%',
					color: attributes.color,
					borderRadius: '8px',
					backgroundColor:
						attributes.buttonText === FoodPortioningAction.pending && isMobile
							? theme.palette.neutral100
							: attributes.backgroundColor,
					textTransform: 'capitalize',
					'&:disabled': {
						color: disabledButtonColor,
						backgroundColor:
							attributes.buttonText === FoodPortioningAction.pending && isMobile ? theme.palette.neutral100 : theme.palette.white
					},
					'&:hover': {
						color: attributes.backgroundColor,
						backgroundColor: attributes.color
					}
				}}
			>
				<Typography fontWeight="600" fontSize="16px">
					{!isFinishing && loading ? 'Saving...' : modifiedButtonText}
				</Typography>
			</Button>
			{(isAssembling || isPortioning) && (
				<Button
					disabled={attributes.isDisabled || loading || appContext.isOffline}
					onClick={isPortioning ? finishPortioning : finishAssembling}
					startIcon={isMobile ? <CheckRoundedIcon sx={{ color: theme.palette.white }} /> : null}
					sx={{
						width: isMobile ? '47%' : '28%',
						height: '100%',
						minWidth: '28%',
						borderRadius: '8px',
						color: theme.palette.white,
						backgroundColor: theme.palette.secondaryBlue500,
						textTransform: 'capitalize',
						'&:hover': {
							color: attributes.color,
							backgroundColor: theme.palette.secondaryBlue500
						},
						'&:disabled': {
							color: theme.palette.white,
							backgroundColor: theme.palette.secondaryBlue500
						}
					}}
				>
					{isMobile ? (
						<Typography fontWeight="600" fontSize="16px">
							{isFinishing && loading ? 'Saving...' : 'Finish'}
						</Typography>
					) : (
						<CheckRoundedIcon sx={{ color: theme.palette.white }} />
					)}
				</Button>
			)}
		</Stack>
	);
};

export default PortionActionButton;
