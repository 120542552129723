import { differenceInSeconds } from 'date-fns';
import { useEffect, useMemo } from 'react';

import { Typography, useTheme } from '@mui/material';

import useCounter from '../../../hooks/useCounter';
import { FoodPortioningStatus } from '../../../libs/enums';
import { PortioningStatus, PortioningStatusAttributes } from '../../../libs/interfaces';
import { getSecondsBetweenIntervals } from '../../../libs/utils/helperFunctions';

interface PortionStatusItemProps {
	attributes: PortioningStatusAttributes;
	portioningStatus: PortioningStatus | undefined;
	time: string;
}

const ProcessTime = ({ attributes, portioningStatus, time }: PortionStatusItemProps) => {
	const theme = useTheme();

	const { status, intervals } = attributes;

	const { initialSeconds, shouldCount } = useMemo(() => {
		let initialSeconds = 0;

		const allIntervals = intervals || [];
		const intervalSeconds = getSecondsBetweenIntervals(allIntervals);
		const shouldCount = allIntervals.length === 0 || !!allIntervals[allIntervals.length - 1].resumedAt;

		let startTime = new Date(time);
		let endTime = new Date();

		if (allIntervals.length > 0 && !allIntervals[allIntervals.length - 1].resumedAt) {
			endTime = new Date(allIntervals[allIntervals.length - 1].pausedAt);
		}

		if (status === FoodPortioningStatus.completed && portioningStatus?.portioningTime?.startedAt) {
			endTime = new Date(time);
			startTime = new Date(portioningStatus.portioningTime.startedAt);
		}

		const timeDiffInSeconds = differenceInSeconds(endTime, startTime);
		initialSeconds = timeDiffInSeconds - intervalSeconds;

		if (initialSeconds < 0) {
			initialSeconds = 0;
		}

		return { initialSeconds, shouldCount };
	}, [intervals, status, time, portioningStatus?.portioningTime?.startedAt]);

	const { formattedTime, stopCounting, startCounting } = useCounter(initialSeconds, shouldCount);

	useEffect(() => {
		if (status === FoodPortioningStatus.resumePortioning) {
			startCounting();
		} else {
			stopCounting();
		}
	}, [status]);

	return (
		<Typography
			fontSize="14px"
			width="80px"
			py="5px"
			borderRadius="15px"
			sx={{ backgroundColor: theme.palette.secondaryBlue100, color: theme.palette.secondaryBlue700, textAlign: 'center' }}
		>
			{formattedTime}
		</Typography>
	);
};

export default ProcessTime;
