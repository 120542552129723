import { useContext } from 'react';

import Box from '@mui/material/Box';

import EmptyMessage from '../../../components/EmptyMessage';
import { QualityTrackingContext } from '../QualityTrackingContext';
import Filter from './Filter';
import QualityTable from './QualityTable';
import StationFilter from './StationFilter';

const DesktopQualityTracking = () => {
	const { isLoading, kitchen, selectedStation, setSelectedStation, shifts, chefs, foodComponentsWithBatches, date } =
		useContext(QualityTrackingContext);

	return (
		<Box sx={{ width: '100%', minHeight: '100vh' }} id="scrollableDiv">
			<Filter />
			<StationFilter selectedStation={selectedStation} setSelectedStation={setSelectedStation} />
			{foodComponentsWithBatches.length > 0 ? (
				<QualityTable date={date} kitchen={kitchen} chefs={chefs} foodComponents={foodComponentsWithBatches} shift={shifts} />
			) : (
				<EmptyMessage label={isLoading ? 'Loading' : 'No data'} style={{ marginTop: '10', textAlign: 'center' }} />
			)}
		</Box>
	);
};

export default DesktopQualityTracking;
