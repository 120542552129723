import { sortBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';

import EmptyMessage from '../../components/EmptyMessage';
import { KDSFood, KDSFoodComponent, Session } from '../../libs/interfaces';
import { useDocumentMedia } from '../../libs/utils/document';
import { getFoodsWithNonZeroQuantity } from '../../libs/utils/foodHelper';
import AssemblyCheckListMobile from './AssemblyCheckListMobile';
import AssemblyCheckTable from './AssemblyCheckTable';

interface AssemblyCheckProps {
	shift: Session;
	kitchen: string;
	foods: KDSFood[];
	foodComponents: KDSFoodComponent[];
}

const AssemblyCheck = ({ shift, foods, kitchen }: AssemblyCheckProps) => {
	const foodsWithNonZeroQuantity = getFoodsWithNonZeroQuantity(foods);
	const { isMobile } = useDocumentMedia();
	const [filters, setFilters] = useState({
		showCustom: true,
		shift: shift
	});

	useEffect(() => {
		setFilters({ ...filters, shift: shift });
	}, [shift]);

	const filteredFoods = filters.showCustom ? foodsWithNonZeroQuantity.filter((food) => !food.isCustom) : foodsWithNonZeroQuantity;
	const data = useMemo(() => {
		const shiftFilteredFoods = filters.shift
			? filteredFoods.filter((meal) => meal.deliveryTime.includes(filters.shift))
			: filteredFoods;
		const assemblyRequiredFoods = shiftFilteredFoods.filter((f) => f.isAssemblyRequired === true);
		const orderedFoods = sortBy(assemblyRequiredFoods, 'name.en');

		const startAssemblingFood: KDSFood[] = [];
		const pauseAssemblingFood: KDSFood[] = [];
		const resumeAssemblingFood: KDSFood[] = [];
		const notReadyFood: KDSFood[] = [];
		const startPortioningFood: KDSFood[] = [];
		const pausePortioningFood: KDSFood[] = [];
		const resumePortioningFood: KDSFood[] = [];
		const completedFood: KDSFood[] = [];

		for (const food of orderedFoods) {
			const portioningStatus = food.portioningStatus?.find((s) => s.session === filters.shift);
			if (portioningStatus?.readyForPortioningTime) {
				completedFood.push(food);
			} else if (food.isAssemblyRequired && !portioningStatus?.assemblingTime) {
				startAssemblingFood.push(food);
			} else if (food.isAssemblyRequired && portioningStatus?.assemblingTime && !portioningStatus?.readyForPortioningTime) {
				const intervals = portioningStatus.assemblingTime.intervals;
				if (!intervals) {
					resumeAssemblingFood.push(food);
				} else if (intervals[intervals.length - 1].resumedAt) {
					resumeAssemblingFood.push(food);
				} else {
					pauseAssemblingFood.push(food);
				}
			} else if (portioningStatus?.portioningTime) {
				const intervals = portioningStatus.portioningTime.intervals;
				if (!intervals) {
					resumePortioningFood.push(food);
				} else if (intervals[intervals.length - 1].resumedAt) {
					resumePortioningFood.push(food);
				} else {
					pausePortioningFood.push(food);
				}
			} else if (portioningStatus?.readyForPortioningTime || !portioningStatus?.endTime) {
				startPortioningFood.push(food);
			}
		}

		return {
			resumeAssemblingFood,
			pauseAssemblingFood,
			startAssemblingFood,
			resumePortioningFood,
			pausePortioningFood,
			startPortioningFood,
			notReadyFood,
			completedFood
		};
	}, [foods, filters, filteredFoods, foodsWithNonZeroQuantity]);

	if (isMobile) {
		return (
			<Box sx={{ width: '95%', display: 'flex', flexDirection: 'column' }}>
				{foodsWithNonZeroQuantity.some((f) => f.isAssemblyRequired === true) ? (
					<AssemblyCheckListMobile data={data} kitchen={kitchen} filters={filters} setFilters={setFilters} />
				) : (
					<EmptyMessage label={'No data'} />
				)}
			</Box>
		);
	} else {
		return (
			<Box sx={{ width: '98%', minHeight: '100vh' }}>
				{foodsWithNonZeroQuantity.some((f) => f.isAssemblyRequired === true) ? (
					<AssemblyCheckTable data={data} kitchen={kitchen} filters={filters} setFilters={setFilters} />
				) : (
					<EmptyMessage label={'no data'} />
				)}
			</Box>
		);
	}
};

export default AssemblyCheck;
