import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { FoodComponentStation } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { caloTheme } from '../../../../../assets/themes/calo';
import { InputField } from '../../../../../components';
import { CREATE_CHEF_USER } from '../../../../../libs/graphQL';
import { KDSChefUser, Session } from '../../../../../libs/interfaces';
import { getCountry } from '../../../../../libs/utils/helperFunctions';
import StationsDropdown from '../StationsDropdown';
import StationsList from '../StationsList';

interface NewCookFormProps {
	kitchen: Kitchen;
	handleCloseModal: () => void;
}

const NewCookForm = ({ kitchen, handleCloseModal }: NewCookFormProps) => {
	const [createChefUser] = useMutation(CREATE_CHEF_USER);
	const [newCook, setNewCook] = useState<Omit<KDSChefUser, 'id'>>({
		name: '',
		stations: Object.keys(FoodComponentStation),
		session: Session.morning,
		country: getCountry(kitchen as Kitchen),
		kitchen: kitchen as Kitchen,
		brand: Brand.CALO
	});

	useEffect(() => {
		if (kitchen === Kitchen.BH1) {
			setNewCook((prevCook) => ({ ...prevCook, session: Session.all }));
		}
	}, [kitchen]);

	const handleClear = () => {
		setNewCook({
			name: '',
			stations: [],
			session: Session.morning,
			kitchen: kitchen as Kitchen,
			country: getCountry(kitchen as Kitchen),
			brand: Brand.CALO
		});
		handleCloseModal();
	};

	const removeStation = (station: string) => {
		setNewCook((prevChef) => ({ ...prevChef, stations: prevChef.stations.filter((s) => s !== station) }));
	};

	const handleCreateChef = async () => {
		await createChefUser({
			variables: { data: newCook },
			onCompleted: () => {
				handleClear();
				toast.success('Chef created successfully');
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const selectStationHandler = (stationKey: keyof typeof FoodComponentStation) => {
		const existingStation = newCook.stations.includes(stationKey);
		if (!existingStation) {
			setNewCook((prevChef) => ({ ...prevChef, stations: [...prevChef.stations, stationKey] }));
		}
	};

	return (
		<Box display={'flex'} style={{ width: '100%' }} flexDirection={'column'} sx={{ height: '100%' }}>
			<Stack style={{ width: '100%' }}>
				<label style={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px', paddingBottom: '8px' }}>Cook Name</label>
				<InputField
					debounce
					value={newCook.name}
					placeholder="Enter Cook Name"
					sx={{ width: '100%', border: 1, borderRadius: '8px', padding: 1 }}
					onChange={(data: any) => setNewCook((prevCook) => ({ ...prevCook, name: data }))}
				/>
			</Stack>

			<Stack sx={{ display: 'flex', direction: 'row', width: '100%', justifyContent: 'space-between', paddingTop: '12px' }}>
				<label style={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px', paddingBottom: '8px' }}>Shift</label>
				<TextField
					select
					name="cookShift"
					value={newCook.session}
					placeholder={'Select Shift'}
					disabled={kitchen === Kitchen.BH1}
					InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
				>
					{kitchen === Kitchen.BH1 && (
						<MenuItem value={Session.all} onClick={() => setNewCook((prevCook) => ({ ...prevCook, session: Session.all }))}>
							<Box>All Shift</Box>
						</MenuItem>
					)}
					<MenuItem value={Session.morning} onClick={() => setNewCook((prevCook) => ({ ...prevCook, session: Session.morning }))}>
						<Box>Morning Shift</Box>
					</MenuItem>

					<MenuItem value={Session.evening} onClick={() => setNewCook((prevCook) => ({ ...prevCook, session: Session.evening }))}>
						<Box>Evening Shift</Box>
					</MenuItem>
				</TextField>
			</Stack>

			<StationsDropdown selectStationHandler={selectStationHandler} stations={newCook.stations} />
			<StationsList stations={newCook.stations} stationClickHandler={removeStation} />

			<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 'auto' }}>
				<Button
					variant="text"
					sx={{
						color: caloTheme.palette.neutral500,
						fontWeight: 600,
						fontSize: '19px',
						lineHeight: '23px',
						marginLeft: '16px'
					}}
					onClick={() => handleClear()}
				>
					Close
				</Button>
				<Button
					variant="contained"
					sx={{
						py: '12px',
						width: '50%',
						color: caloTheme.palette.white,
						backgroundColor: caloTheme.palette.primary500,
						borderRadius: '8px',
						fontWeight: 600,
						fontSize: '19px',
						lineHeight: '23px',
						boxShadow: 'none'
					}}
					disabled={!newCook.name || !newCook.session}
					onClick={handleCreateChef}
				>
					Add Cook
				</Button>
			</Stack>
		</Box>
	);
};
export default NewCookForm;
