import { startCase } from 'lodash-es';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DateRange } from '@mui/lab/DateRangePicker';
import { FormControl, InputAdornment, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { caloTheme } from '../../../assets/themes/calo';
import { InputField } from '../../../components';
import DateRangePickerMUI from '../../../components/DateRangePickerMUI';
import { Session } from '../../../libs/interfaces';

interface FilterItemsProps {
	toggleFilterDrawer: (data: boolean) => void;
	shift: Session;
	handleShiftChange: (value: Session) => void;
	handleResultsButtonClick: () => void;
	dateRange: DateRange<Date>;
	setDateRange: (data: DateRange<Date>) => void;
	kitchen: string;
	availableShifts: Session[];
	name?: string;
	setName: (data: string) => void;
	tab: number;
}

const FilterItems = ({
	toggleFilterDrawer,
	shift,
	handleShiftChange,
	handleResultsButtonClick,
	dateRange,
	setDateRange,
	availableShifts,
	name,
	setName,
	tab
}: FilterItemsProps) => {
	return (
		<Stack
			direction="column"
			justifyContent="space-between"
			alignItems="center"
			spacing={2}
			sx={{ m: 2, width: 300, height: '100%', my: 4 }}
		>
			<Stack direction="column" justifyContent="space-between" alignItems="start" spacing={1} sx={{ mx: 3, width: '100%' }}>
				<Box sx={{ fontSize: '23px', mt: 1 }}>Filter</Box>
				<IconButton
					aria-label="close"
					onClick={() => toggleFilterDrawer(false)}
					sx={{
						position: 'absolute',
						right: 10,
						top: 0,
						fontWeight: 600,
						color: 'black'
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box sx={{ fontSize: '12px' }}>Delivery Shift</Box>
				<Stack direction="column" justifyContent="space-evenly" alignItems="start" spacing={1} sx={{ my: 1, width: '100%' }}>
					<FormControl sx={{ width: '100%' }}>
						<Select
							value={shift}
							IconComponent={() => <KeyboardArrowDownIcon />}
							onChange={(e) => handleShiftChange(e.target.value as Session)}
							sx={{
								border: '1px solid ' + caloTheme.palette.neutral700,
								borderRadius: '8px',
								fontSize: '16px',
								color: caloTheme.palette.neutral900,
								height: '55px',
								':hover': {
									border: 0
								},
								'&:focus': {
									border: 0
								}
							}}
						>
							{availableShifts.map((shiftValue) => (
								<MenuItem key={shiftValue} sx={{ fontWeight: 600, py: 0.5 }} value={shiftValue}>
									{startCase(shiftValue)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Stack>
				<Box sx={{ fontSize: '12px' }}>Date</Box>
				<Box sx={{ width: '100%' }}>
					<DateRangePickerMUI dateRange={dateRange} setDateRange={setDateRange} />
				</Box>
				<Box sx={{ fontSize: '12px' }}>{tab === 0 ? 'Meal' : 'Component'} Name</Box>
				<Box sx={{ width: '100%' }}>
					<InputField
						debounce
						value={name}
						placeholder={`Enter ${tab === 0 ? 'Meal' : 'Component'} Name`}
						sx={{ width: '100%', border: 1, borderRadius: '8px', padding: 1 }}
						onChange={(event: any) => setName(event)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" onClick={() => setName('')}>
									<CloseIcon />
								</IconButton>
							</InputAdornment>
						}
					/>
				</Box>
			</Stack>
			<Stack direction="column" justifyContent="space-evenly" alignItems="center" spacing={1} sx={{ width: '100%', p: 2 }}>
				<Button
					variant="contained"
					onClick={() => handleResultsButtonClick()}
					sx={{
						textTransform: 'capitalize',
						fontSize: '19px',
						width: '228px',
						color: 'white'
					}}
				>
					Apply
				</Button>
			</Stack>
		</Stack>
	);
};
export default FilterItems;
