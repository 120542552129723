import { format } from 'date-fns/fp';
import { groupBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { Dictionary, Kitchen } from '@calo/types';

import { useListChefsQuery, useListComponentBatchesQuery } from '../../hooks';
import {
	FoodComponentWithBatches,
	KDSFoodComponent,
	KDSFoodComponentBatch,
	QualityCheckStatus,
	Session
} from '../../libs/interfaces';
import { useDocumentMedia } from '../../libs/utils/document';
import { getComponentsWithNonZeroCount } from '../../libs/utils/foodComponentHelper';
import DesktopQualityTracking from './Desktop/DesktopQualityTracking';
import MobileQualityTracking from './Mobile/MobileQualityTracking';
import { QualityTrackingContext } from './QualityTrackingContext';

const defaultStandards = [
	{
		id: '1',
		name: 'Color'
	},
	{
		id: '2',
		name: 'Taste'
	},
	{
		id: '3',
		name: 'Appearance'
	},
	{
		id: '4',
		name: 'Texture'
	},
	{
		id: '5',
		name: 'Smell'
	}
];

interface QualityTrackingProps {
	kitchen: string;
	date: string;
	shift: Session;
	foodComponents: KDSFoodComponent[];
}

const QualityTracking = ({ shift, date, kitchen, foodComponents }: QualityTrackingProps) => {
	const { isMobile } = useDocumentMedia();
	const [selectedStation, setSelectedStation] = useState<string>('commissary');
	const [shiftState, setShiftState] = useState<Session>(shift);

	const [selectedCheckStatuses, setSelectedCheckStatuses] = useState([
		QualityCheckStatus.accepted,
		QualityCheckStatus.pending,
		QualityCheckStatus.rejected
	]);
	const dateRange = {
		gte: format('yyyy-MM-dd')(new Date(date)),
		lte: format('yyyy-MM-dd')(new Date(date))
	};

	const { componentBatchesLoading, batches, refetchBatches, clearBatchesData } = useListComponentBatchesQuery(
		kitchen,
		dateRange,
		shiftState
	);

	const { chefsLoading, chefs, refetchChefs } = useListChefsQuery(kitchen);
	const foodComponentsWithBatches: FoodComponentWithBatches[] = useMemo(() => {
		const foodComponentsWithNonZeroCount = getComponentsWithNonZeroCount(foodComponents);
		const filteredComponents = foodComponentsWithNonZeroCount.filter(
			(fc) => fc.cookingStations[fc.cookingStations.length - 1].name === selectedStation
		);
		const groupedComponentBatches: Dictionary<KDSFoodComponentBatch[]> = groupBy(batches, 'foodComponentId');
		const foodComponentsWithBatches: FoodComponentWithBatches[] = filteredComponents.map((component) => ({
			...component,
			batches: groupedComponentBatches[component.id],
			standards: component.standards ?? defaultStandards
		}));

		return foodComponentsWithBatches;
	}, [foodComponents, batches, selectedStation, selectedCheckStatuses]);

	useEffect(() => {
		if (shift !== shiftState) {
			setShiftState(shift);
		}
		refetchBatches();
	}, [kitchen, date, shift]);

	useEffect(() => {
		refetchChefs();
	}, [kitchen]);

	const handleClearData = () => {
		clearBatchesData();
		refetchBatches();
	};

	return (
		<QualityTrackingContext.Provider
			value={{
				chefs: chefs,
				date: date,
				kitchen: kitchen as Kitchen,
				shifts: shiftState,
				setShifts: setShiftState,
				clearData: handleClearData,
				batches: batches,
				selectedStation: selectedStation,
				setSelectedStation: setSelectedStation,
				isLoading: chefsLoading || componentBatchesLoading,
				foodComponentsWithBatches,
				selectedCheckStatuses: selectedCheckStatuses,
				setSelectedCheckStatuses: setSelectedCheckStatuses
			}}
		>
			{isMobile ? <MobileQualityTracking /> : <DesktopQualityTracking />}
		</QualityTrackingContext.Provider>
	);
};
export default QualityTracking;
