import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import { isFuture } from 'date-fns';
import { addDays, differenceInDays } from 'date-fns/fp';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { DateRange } from '@mui/lab/DateRangePicker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { caloTheme } from '../../assets/themes/calo';

interface DateRangePickerProps {
	dateRange: DateRange<Date>;
	setDateRange: (data: DateRange<Date>) => void;
	dayRangeLimit?: number;
	disableFutureDates?: boolean;
}

const DateRangePickerMUI = ({ dateRange, setDateRange, dayRangeLimit, disableFutureDates }: DateRangePickerProps) => {
	const tomorrow = addDays(1)(new Date());

	const handleDateRangeChange = (dates: DateRange<Date>) => {
		const potentialMaxDate = dates[0] && dayRangeLimit ? addDays(dayRangeLimit)(new Date(dates[0])) : null;
		let actualMaxDate;
		if (disableFutureDates) {
			actualMaxDate = potentialMaxDate && isFuture(potentialMaxDate) ? tomorrow : potentialMaxDate;
		} else {
			actualMaxDate = potentialMaxDate;
		}
		if (
			dates &&
			dayRangeLimit &&
			dates[0] &&
			dates[1] &&
			differenceInDays(new Date(dates[0]), new Date(dates[1])) > dayRangeLimit
		) {
			setDateRange([dates[0], actualMaxDate]);
		} else {
			setDateRange([dates[0], dates[1]]);
		}
	};

	return (
		<DateRangePicker
			format="dd/MM/yyyy"
			clearIcon={null}
			maxDate={disableFutureDates ? tomorrow : undefined}
			onChange={(dates: any) => handleDateRangeChange(dates)}
			value={dateRange}
			className="date-range-picker-mui"
			calendarIcon={<CalendarTodayOutlinedIcon sx={{ color: caloTheme.palette.neutral900 }} />}
		/>
	);
};
export default DateRangePickerMUI;
