import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Icon as Iconify } from '@iconify/react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DateRange } from '@mui/lab/DateRangePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { useExportQualityMetricsMutation } from '../../../hooks';
import { Session } from '../../../libs/interfaces';
import { getDate } from '../../../libs/utils/helperFunctions';
import { Filters } from '../QualityMetrics';
import FilterItems from './FilterItems';

interface FilterProps {
	filters: Filters;
	setFilters: (data: Filters) => void;
	shift: Session;
	tab: number;
}

const Filter = ({ filters, setFilters, shift, tab }: FilterProps) => {
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [name, setName] = useState<string | undefined>(filters.name);
	const [shiftState, setShiftState] = useState<Session>(filters.shift);
	const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
	const appContext = useContext(AppContext);
	const { handleExportQualityMetrics, loading: exportQualityMetricsLoading } = useExportQualityMetricsMutation();

	useEffect(() => {
		setShiftState(filters.shift);
		setDateRange([new Date(filters.startDate), new Date(filters.endDate)]);
		setName(filters.name);
	}, [filters]);

	const handleResultsButtonClick = () => {
		if (dateRange[0] && dateRange[1]) {
			setIsFilterOpened(false);
			setFilters({
				...filters,
				startDate: dateRange[0] ? getDate(dateRange[0]) : '',
				endDate: dateRange[1] ? getDate(dateRange[1]) : '',
				shift: shiftState,
				name: name,
				isExpanded: false
			});
		} else {
			toast.error('Please select a valid date range');
		}
	};

	return (
		<Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
			<Button
				variant="text"
				color="primary"
				disabled={appContext.isOffline}
				sx={{
					textTransform: 'none',
					fontWeight: 600,
					fontSize: '19px',
					padding: '10px 20px'
				}}
				onClick={() => setIsFilterOpened(true)}
				startIcon={<Iconify icon="mingcute:filter-2-fill" width={21} color={caloTheme.palette.primary500} />}
			>
				Filter
			</Button>
			<LoadingButton
				loading={exportQualityMetricsLoading}
				disableElevation
				disabled={exportQualityMetricsLoading || appContext.isOffline}
				onClick={() =>
					handleExportQualityMetrics({
						startDate: filters.startDate,
						endDate: filters.endDate,
						shift: shift !== Session.all && shiftState === Session.all ? undefined : shiftState,
						kitchen: filters.kitchen,
						type: filters.type
					})
				}
				variant="text"
				color="primary"
				sx={{
					textTransform: 'none',
					fontWeight: 600,
					fontSize: '19px',
					color: caloTheme.palette.neutral900,
					padding: '10px 20px'
				}}
				startIcon={<FileDownloadOutlinedIcon />}
			>
				Download
			</LoadingButton>
			<Drawer anchor={'right'} open={isFilterOpened} onClose={() => setIsFilterOpened(false)}>
				<FilterItems
					toggleFilterDrawer={setIsFilterOpened}
					shift={shiftState}
					handleShiftChange={setShiftState}
					handleResultsButtonClick={handleResultsButtonClick}
					dateRange={dateRange}
					setDateRange={setDateRange}
					kitchen={filters.kitchen}
					availableShifts={filters.availableShifts}
					name={name}
					setName={setName}
					tab={tab}
				/>
			</Drawer>
		</Stack>
	);
};
export default Filter;
