import { FCStatus, FoodComponentStation } from '../../../../libs/enums';
import { KDSFoodComponent } from '../../../../libs/interfaces';
import { getFoodComponentStatusAttributes } from '../../../../libs/utils/helperFunctions';
import { getCombinedShortages } from '../../ComponentsCheckHelpers';
import SectionTableRow from '../SectionTableRow';

interface SectionProps {
	tableName: FoodComponentStation;
	foodComponent: KDSFoodComponent;
	setSelectedTable: (tableName: string) => void;
}

const Section = ({ tableName, foodComponent, setSelectedTable }: SectionProps) => {
	const combinedShortages = getCombinedShortages(foodComponent.shortages, tableName);

	return (
		<>
			<SectionTableRow
				key={foodComponent.id}
				foodComponent={foodComponent}
				isChecked={foodComponent.cookingStations.some(
					(station) => station.name === tableName && station.status === FCStatus.completed
				)}
				statusAttributes={getFoodComponentStatusAttributes(foodComponent.cookingStations, tableName)}
				tableName={tableName}
				setSelectedTable={(v) => setSelectedTable(v)}
			/>
			{combinedShortages
				? Object.entries(combinedShortages).map(
						([type, shortages]) =>
							shortages.ids.length > 0 && (
								<SectionTableRow
									key={shortages.ids.join(';')}
									foodComponent={foodComponent}
									isChecked={type === 'completed' ? true : false}
									statusAttributes={getFoodComponentStatusAttributes(shortages.cookingStations, tableName)}
									tableName={tableName}
									setSelectedTable={(v) => setSelectedTable(v)}
									combinedShortages={shortages}
								/>
							)
					)
				: ''}
		</>
	);
};

export default Section;
