import { useEffect, useState } from 'react';

import { compact, sortBy } from 'lodash-es';

import { Dictionary } from '@calo/types';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';

import { ShiftSelector } from '../../../components';
import CustomFoodsFilter from '../../../components/CustomFoodsFilter';
import EmptyMessage from '../../../components/EmptyMessage';
import { KDSFood, KDSFoodComponent, Session } from '../../../libs/interfaces';
import MealsStatusRow from '../MealsStatusRow';

interface MealsStatusProps {
	kitchen: string;
	foods: KDSFood[];
	foodComponents: Dictionary<KDSFoodComponent>;
	shift: Session;
}

const MealsStatus = ({ shift, kitchen, foodComponents, foods }: MealsStatusProps) => {
	const [isCustomFoodHidden, setIsCustomFoodHidden] = useState(true);
	const [shifts, setShifts] = useState<Session>(shift);
	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);
	const filteredFoods = isCustomFoodHidden
		? foods.filter((food) => !food.isCustom && food.deliveryTime.includes(shifts))
		: foods.filter((food) => food.deliveryTime.includes(shift));

	const handleShiftChange = (shift: Session) => {
		setShifts(() => shift);
	};

	useEffect(() => {
		if (shift !== shifts) {
			setShifts(shift);
		}
	}, [shift]);

	return (
		<>
			<Box>
				<Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ mx: 2 }}>
					<CustomFoodsFilter isCustomFoodHidden={isCustomFoodHidden} setIsCustomFoodHidden={setIsCustomFoodHidden} />
					<ShiftSelector
						kitchen={kitchen}
						shift={shifts}
						allSessions={false}
						key={'Meal-Status-Table'}
						handleShiftChange={handleShiftChange}
						isShiftMenuOpened={isShiftMenuOpened}
						shiftMenuAnchorEl={shiftMenuAnchorEl}
						setShiftMenuAnchorEl={setShiftMenuAnchorEl}
					/>
				</Stack>
			</Box>
			<Box display={'flex'} flexDirection={'column'} sx={{ my: 2, mr: 3 }}>
				{filteredFoods.length > 0 ? (
					sortBy(filteredFoods, 'name.en').map((food) => (
						<MealsStatusRow
							food={food}
							key={food.id}
							foodComponents={compact(food.componentIds.map((id) => foodComponents[`${food.date}#${id}`]))}
						/>
					))
				) : (
					<EmptyMessage label={'No data for the selected filters'} />
				)}
			</Box>
		</>
	);
};
export default MealsStatus;
