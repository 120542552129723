import { useContext } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Range } from '@calo/dashboard-types';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LoadingButton from '@mui/lab/LoadingButton';

import { AppContext } from '../../../../App';
import { EXPORT_COMPONENT_BATCH } from '../../../../libs/graphQL';
import { useDocumentMedia } from '../../../../libs/utils/document';
import { createAutoDownloadLinkFromFile } from '../../../../libs/utils/helperFunctions';

interface ExportProps {
	isDownloadDisabled: boolean;
	kitchen: string;
	date: Range;
	shift: string;
}

const Export = ({ isDownloadDisabled, kitchen, date, shift }: ExportProps) => {
	const appContext = useContext(AppContext);
	const { isMobile } = useDocumentMedia();
	const [exportComponentBatch, { loading }] = useMutation(EXPORT_COMPONENT_BATCH, {
		onCompleted: (data) => {
			createAutoDownloadLinkFromFile(
				data.exportComponentBatch.data,
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'xlsx',
				`Calo_Quality_Tracking_${kitchen}_${date.gte}_${date.lte}_${shift}`
			);
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	return (
		<>
			<LoadingButton
				id="export-button"
				loading={loading}
				disableElevation
				disabled={isDownloadDisabled || loading || appContext.isOffline}
				onClick={() =>
					exportComponentBatch({
						variables: {
							kitchen,
							date,
							shift
						}
					})
				}
				variant="text"
				sx={{
					color: 'black',
					textTransform: 'none',
					fontWeight: 600,
					fontSize: '19px',
					mr: isMobile ? 0 : 2,
					p: 0
				}}
				startIcon={<FileDownloadOutlinedIcon />}
			>
				{isMobile ? '' : 'Download'}
			</LoadingButton>
		</>
	);
};
export default Export;
