import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DateRange } from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { alpha, styled, useTheme } from '@mui/material/styles';

import { caloTheme } from '../../../assets/themes/calo';
import DateRangePicker from '../../../components/DateRangePicker';
import { Actions } from '../../../libs/enums';
import { Session } from '../../../libs/interfaces';

interface FilterItemsProps {
	toggleFilterDrawer: (data: boolean) => void;
	handleAddAction: (data: string) => void;
	handleRemoveAction: (data: string) => void;
	actions: string[];
	isShiftMenuOpened: boolean;
	shiftMenuAnchorEl: HTMLElement | null;
	handleSelectButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	shift: string;
	handleShiftChange: (value: string) => void;
	handleCloseSelectMenu: () => void;
	handleResultsButtonClick: () => void;
	dateRange: DateRange<Date>;
	setDateRange: (data: DateRange<Date>) => void;
	kitchen: string;
}

const FilterItems = ({
	toggleFilterDrawer,
	handleAddAction,
	handleRemoveAction,
	actions,
	isShiftMenuOpened,
	shiftMenuAnchorEl,
	handleSelectButtonClick,
	shift,
	handleShiftChange,
	handleCloseSelectMenu,
	handleResultsButtonClick,
	dateRange,
	setDateRange
}: FilterItemsProps) => {
	const theme = useTheme();

	const StyledMenu = styled((props: MenuProps) => (
		<Menu
			elevation={0}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			{...props}
		/>
	))(({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 240,
			color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '4px 0'
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5)
				},
				'&:active': {
					backgroundColor: alpha(theme.palette.primary500, theme.palette.action.selectedOpacity)
				}
			}
		}
	}));

	return (
		<Box sx={{ width: 290 }} role="presentation">
			<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '28px', my: 1, mx: 3 }}>Filter</Box>
			<IconButton
				aria-label="close"
				onClick={() => toggleFilterDrawer(false)}
				sx={{
					position: 'absolute',
					right: 10,
					top: 10,
					fontWeight: 600,
					color: 'black'
				}}
			>
				<CloseIcon />
			</IconButton>
			<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px', my: 1, mx: 3 }}>Actions</Box>
			<Stack direction="column" justifyContent="space-evenly" alignItems="start" spacing={1} sx={{ mx: 3 }}>
				{Object.values(Actions).map((text) => (
					<Button
						key={text}
						variant="text"
						onClick={() => {
							actions.includes(text) ? handleRemoveAction(text) : handleAddAction(text);
						}}
						sx={{
							textTransform: 'capitalize',
							fontSize: '16px',
							fontWeight: 600,
							borderRadius: '43px',
							border: actions.includes(text) ? '2px solid white' : '2px solid ' + theme.palette.primary500,
							px: 2,
							py: 0.5,
							backgroundColor: actions.includes(text) ? theme.palette.primary100 : 'white',
							':hover': {
								backgroundColor: actions.includes(text) ? 'white' : theme.palette.primary100,
								border: actions.includes(text) ? '2px solid ' + theme.palette.primary500 : '2px solid white'
							}
						}}
					>
						{text}
						{actions.includes(text) ? (
							<IconButton
								sx={{
									width: '8px',
									height: '8px',
									ml: '14px',
									color: theme.palette.primary500,
									':hover': {
										color: actions.includes(text) ? theme.palette.primary500 : theme.palette.primary100,
										backgroundColor: actions.includes(text) ? 'white' : theme.palette.primary500
									}
								}}
								onClick={() => handleRemoveAction(text)}
							>
								<CloseIcon />
							</IconButton>
						) : (
							''
						)}
					</Button>
				))}
			</Stack>
			<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px', my: 1, mx: 3 }}>Date</Box>
			<DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
			<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '23px', my: 1, mx: 3 }}>Shift</Box>
			<Stack direction="column" justifyContent="space-evenly" alignItems="start" spacing={1} sx={{ mx: 3 }}>
				<Button
					id="menu-button"
					aria-controls={isShiftMenuOpened ? 'menu-button' : undefined}
					aria-haspopup="true"
					aria-expanded={isShiftMenuOpened ? 'true' : undefined}
					variant="outlined"
					disableElevation
					onClick={(event) => {
						!shiftMenuAnchorEl && handleSelectButtonClick(event);
					}}
					endIcon={<KeyboardArrowDownIcon />}
					sx={{
						textTransform: 'capitalize',
						fontSize: '16px',
						fontWeight: 600,
						width: '100%',
						border: '2px solid ' + theme.palette.primary500,
						borderRadius: '8px',
						height: '40px',
						'&:hover': { border: '2px solid ' + theme.palette.primary500 }
					}}
				>
					{shift === '' ? 'shift' : shift}
				</Button>
				<StyledMenu
					MenuListProps={{
						'aria-labelledby': 'customized-button'
					}}
					anchorEl={shiftMenuAnchorEl}
					open={isShiftMenuOpened}
					onClose={handleCloseSelectMenu}
				>
					<MenuItem
						sx={{ fontWeight: 600, py: 0.5 }}
						value={Session.all}
						onClick={() => {
							handleShiftChange(Session.all);
							handleCloseSelectMenu();
						}}
					>
						<Box>All Shifts</Box>
					</MenuItem>
					<Divider sx={{ my: 0.5 }} />
					<MenuItem
						sx={{ fontWeight: 600, py: 0.5 }}
						value={Session.morning}
						onClick={() => {
							handleShiftChange(Session.morning);
							handleCloseSelectMenu();
						}}
					>
						<Box>Morning Shift</Box>
					</MenuItem>
					<Divider sx={{ my: 0.5 }} />
					<MenuItem
						sx={{ fontWeight: 600, py: 0.5 }}
						value={Session.evening}
						onClick={() => {
							handleShiftChange(Session.evening);
							handleCloseSelectMenu();
						}}
					>
						<Box>Evening Shift</Box>
					</MenuItem>
				</StyledMenu>
			</Stack>
			<Stack direction="column" justifyContent="space-evenly" alignItems="start" spacing={1} sx={{ mx: 3, my: 1 }}>
				<Button
					variant="contained"
					onClick={() => handleResultsButtonClick()}
					sx={{
						textTransform: 'capitalize',
						fontSize: '19px',
						fontWeight: 600,
						width: '100%',
						color: 'white'
					}}
				>
					Show Results
				</Button>
			</Stack>
		</Box>
	);
};
export default FilterItems;
