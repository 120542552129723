import { sortBy } from 'lodash-es';
import { useEffect, useRef } from 'react';

import { Dictionary } from '@calo/types';
import { Box, Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { caloTheme } from '../../../../assets/themes/calo';
import { FoodComponentStation } from '../../../../libs/enums';
import { KDSFoodComponent } from '../../../../libs/interfaces';
import { getTableAttributes } from '../../../../libs/utils/helperFunctions';
import Section from '../Section';

interface SectionTableProps {
	tableName: FoodComponentStation;
	foodComponents: KDSFoodComponent[];
	setSelectedTable: (tableName: string) => void;
	focus: string;
	shortages: Dictionary<{
		station: string;
		total: number;
	}>;
}

const SectionTable = ({ tableName, foodComponents, focus, setSelectedTable, shortages }: SectionTableProps) => {
	const theme = useTheme();
	const tableAttributes = getTableAttributes(tableName, theme);
	const myRef = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
		if (focus === tableName) {
			myRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		}
	}, [focus, tableName]);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: !focus || focus === tableName ? tableAttributes.color : theme.palette.neutral50,
			color: theme.palette.common.black,
			fontSize: '16px',
			fontWeight: 600
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	return (
		<Box
			ref={myRef}
			sx={{
				mx: '10px',
				height: '100%',
				minHeight: '1000px',
				pt: '120px',
				display: tableName === FoodComponentStation.other && foodComponents.length === 0 ? 'none' : ''
			}}
		>
			<Table stickyHeader sx={{ border: '2px solid white' }}>
				<TableHead>
					<TableRow sx={{ cursor: 'pointer' }}>
						<StyledTableCell padding="none" colSpan={8} sx={{ minWidth: 500, p: '10px', color: theme.palette.primary900 }}>
							<Stack display={'flex'} sx={{ justifyContent: 'space-between' }} flexDirection={'row'}>
								<Typography>{tableAttributes.name}</Typography>
								{shortages[tableName] && shortages[tableName].total > 0 && (
									<Stack display={'flex'} flexDirection={'row'}>
										<Typography>Shortage Reported</Typography>
										<Stack sx={{ backgroundColor: 'red', ml: '6px', borderRadius: '15px' }}>
											<Typography sx={{ color: 'white', mx: '6px' }}>{shortages[tableName].total}</Typography>
										</Stack>
									</Stack>
								)}
							</Stack>
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{foodComponents.length > 0 ? (
						sortBy(foodComponents, 'name.en').map((fc) => (
							<Section key={fc.id} foodComponent={fc} tableName={tableName} setSelectedTable={(v) => setSelectedTable(v)} />
						))
					) : (
						<TableRow hover>
							<TableCell
								colSpan={4}
								sx={{
									minWidth: 150,
									p: 1,
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									textAlign: 'center',
									border: 'none'
								}}
							>
								No components
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Box>
	);
};

export default SectionTable;
