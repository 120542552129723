import { keyBy, sortBy } from 'lodash-es';

import { Box } from '@mui/material';

import EmptyMessage from '../../components/EmptyMessage';
import { KDSFood, KDSFoodComponent } from '../../libs/interfaces';
import { useDocumentMedia } from '../../libs/utils/document';
import { getFoodsWithNonZeroQuantity } from '../../libs/utils/foodHelper';
import ShortageReportingTable from './Table';

interface ShortageReportingProps {
	foods: KDSFood[];
	foodComponents: KDSFoodComponent[];
}

const ShortageReporting = ({ foods, foodComponents }: ShortageReportingProps) => {
	const foodsWithNonZeroQuantity = getFoodsWithNonZeroQuantity(foods);
	const { isMobile } = useDocumentMedia();
	const mobileStyles = {
		box: {
			width: '100%'
		}
	};

	const desktopStyles = {
		box: {
			margin: 10,
			width: '98%',
			padding: 5
		}
	};
	const keyedComponents = keyBy(foodComponents, 'id');
	const sortedFoods = sortBy(foodsWithNonZeroQuantity, 'name.en');

	return (
		<Box style={{ ...(isMobile ? mobileStyles.box : desktopStyles.box) }}>
			{sortedFoods.length > 0 ? (
				<ShortageReportingTable foods={sortedFoods} keyedFoodComponents={keyedComponents} />
			) : (
				<EmptyMessage label={'No data'} style={{ margin: 0 }} />
			)}
		</Box>
	);
};
export default ShortageReporting;
