import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DeliveryTicket } from '../../../../libs/interfaces';
import InvoiceCard from '../InvoiceCard';

interface TicketCollectionProps {
	ticketCollection: DeliveryTicket[];
	selectedCards: string[];
	isSelected: boolean;
	handleTicketClick: (id: string, isSelected: boolean) => void;
}

const TicketCollection = ({ ticketCollection, isSelected, handleTicketClick }: TicketCollectionProps) => {
	const theme = useTheme();

	return (
		<Card
			sx={{
				minWidth: '100%',
				boxShadow: 0,
				maxHeight: 'auto',
				border: '2px solid' + theme.palette.neutral100,
				borderColor: isSelected ? theme.palette.primary500 : theme.palette.neutral100,
				borderRadius: '8px',
				mb: 2
			}}
		>
			{ticketCollection.map((deliveryTicket, index) => (
				<InvoiceCard
					key={deliveryTicket.id}
					deliveryTicket={deliveryTicket}
					isSelected={isSelected}
					isLastItem={index === ticketCollection.length - 1}
					setIsSelected={handleTicketClick}
				/>
			))}
		</Card>
	);
};

export default TicketCollection;
