import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';

import { LIST_CHEF_QUERY, SUBSCRIBE_TO_CHEF_CHANGES } from '../libs/graphQL';
import { KDSChefUser } from '../libs/interfaces';

const useListChefsQuery = (kitchen: string) => {
	const { loading, refetch, subscribeToMore } = useQuery(LIST_CHEF_QUERY, {
		variables: { kitchen },
		onCompleted(data) {
			if (data?.listChefs?.data) {
				setChefs(data.listChefs.data);
			}
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	const [chefs, setChefs] = useState<KDSChefUser[]>([]);

	useSubscription(SUBSCRIBE_TO_CHEF_CHANGES);

	const moreChefs = () =>
		subscribeToMore({
			document: SUBSCRIBE_TO_CHEF_CHANGES,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscribeToComponentChefs = subscriptionData.data.subscribeToChefChanges;
				return Object.assign({}, prev, {
					listChefs: {
						data: [subscribeToComponentChefs, ...prev.listChefs.data]
					}
				});
			}
		});

	useEffect(() => {
		moreChefs();
	}, []);

	return {
		chefsLoading: loading,
		chefs,
		refetchChefs: refetch
	};
};

export default useListChefsQuery;
