import { format } from 'date-fns';
import { forwardRef } from 'react';

import { ComponentService } from '@calo/services';
import {
	Paper,
	Stack,
	styled,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from '@mui/material';

import { FoodComponent, IngredientHeaderItem } from '../../../../libs/interfaces';
import { roundToTwoDecimals } from '../../../../libs/utils/helperFunctions';
import { calculateFractionWeight, formatWeightText } from '../../helpers';
import MethodStep from './MethodStep';

interface Props {
	selectedComponent: FoodComponent;
	newCookedWeight: number;
	date: string;
}

const ExportTable = forwardRef(({ selectedComponent, newCookedWeight, date }: Props, ref: React.ForwardedRef<any>) => {
	const theme = useTheme();

	const structuredIngredients = (() => {
		let currentHeader: string | null | undefined;
		const sortedIngredients = [...selectedComponent.ingredients].sort((a, b) => {
			// Sort ingredients with no header first
			if (!a.header) return -1;
			if (!b.header) return 1;

			if (a.header && b.header) {
				return 0;
			}

			// If only one ingredient has a header, it comes first
			return a.header.localeCompare(b.header);
		});

		const updatedStructuredIngredients: IngredientHeaderItem[] = [];
		for (const ingredient of sortedIngredients) {
			if (ingredient.header !== currentHeader && ingredient.header) {
				currentHeader = ingredient.header;
				updatedStructuredIngredients.push({ type: 'header', header: currentHeader });
			}
			updatedStructuredIngredients.push({ type: 'ingredient', ingredient });
		}

		return updatedStructuredIngredients;
	})();

	const numberOfHeaders = structuredIngredients.reduce((acc, sIngredient) => (sIngredient.type === 'header' ? acc + 1 : acc), 0);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral100,
			color: theme.palette.common.black,
			border: 'none',
			fontSize: 16,
			fontWeight: 600
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 16,
			paddingTop: '2px',
			paddingBottom: '2px'
		}
	}));

	const getRawWeight = () => {
		let rawWeight = selectedComponent.ingredients.reduce(
			(acc, ingredient) => acc + Math.ceil(calculateFractionWeight(selectedComponent, ingredient.quantity, newCookedWeight)),
			0
		);

		selectedComponent.childComponents?.map((childComponent) => {
			const childComponentWeight = calculateFractionWeight(
				selectedComponent,
				ComponentService.calculateComponentWeight(
					childComponent.cups,
					childComponent.measurementUnit,
					childComponent.weight,
					childComponent.quantity || 0
				),
				newCookedWeight
			);
			rawWeight += childComponentWeight;
		});

		return Math.ceil(rawWeight);
	};

	let renderMethod = true;

	return (
		<TableContainer component={Paper} ref={ref} sx={{ display: 'none', marginX: '10px' }}>
			<Stack
				sx={{ display: 'flex', flexDirection: 'row', gap: '8px', marginLeft: '16px', marginTop: '16px', marginBottom: '2px' }}
			>
				<Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{selectedComponent.name.en} |</Typography>
				<Stack sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
					<Typography sx={{ fontSize: '18px' }}>Raw Weight: </Typography>
					<Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{getRawWeight()} |</Typography>
				</Stack>
				<Stack sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
					<Typography sx={{ fontSize: '18px' }}>Cooked Weight: </Typography>
					<Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{newCookedWeight} |</Typography>
				</Stack>
			</Stack>
			<Stack
				sx={{ display: 'flex', flexDirection: 'row', gap: '8px', marginLeft: '16px', marginTop: '2px', marginBottom: '16px' }}
			>
				<Typography sx={{ fontSize: '18px' }}>{selectedComponent.brand} |</Typography>
				<Typography sx={{ fontSize: '18px' }}>{selectedComponent.country} |</Typography>
				<Typography sx={{ fontSize: '18px' }}>{selectedComponent.kitchen} |</Typography>
				<Typography sx={{ fontSize: '18px' }}>For {format(new Date(date), 'dd/MM/yyyy')}</Typography>
			</Stack>
			<Table aria-label="Excel Table">
				<TableHead>
					<TableRow sx={{ bgcolor: theme.palette.neutral300 }}>
						<StyledTableCell sx={{ width: '18%' }}>Ingredient</StyledTableCell>
						<StyledTableCell sx={{ width: '16%', padding: 0 }}>Quantity Before Prep</StyledTableCell>
						<StyledTableCell sx={{ width: '16%', padding: 0 }}>Quantity After Prep</StyledTableCell>
						<StyledTableCell sx={{ width: '5%', padding: 0, textAlign: 'start' }}>Unit</StyledTableCell>
						<StyledTableCell sx={{ width: '45%', textAlign: 'center' }}>Method</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{structuredIngredients.map((sIngredient, index) => {
						if (sIngredient.type === 'header') {
							return (
								<TableRow key={sIngredient.header}>
									<TableCell colSpan={4} sx={{ fontSize: '18px', fontWeight: 700, py: '10px' }}>
										{sIngredient.header}
									</TableCell>
								</TableRow>
							);
						} else {
							const { ingredient } = sIngredient;
							const fractionWeight = calculateFractionWeight(
								selectedComponent,
								ingredient.quantity,
								newCookedWeight,
								ingredient.weight
							);
							const weightBeforePrep =
								fractionWeight * ingredient.wastage < 1
									? roundToTwoDecimals(fractionWeight * ingredient.wastage)
									: Math.ceil(Math.round(fractionWeight * ingredient.wastage));
							const weightAfterPrep =
								fractionWeight < 1 ? roundToTwoDecimals(fractionWeight) : Math.ceil(Math.round(fractionWeight));

							return (
								<TableRow key={ingredient.id + '#' + index} sx={{ fontWeight: 'bold' }}>
									<StyledTableCell>{ingredient.internalName || ingredient.name.en}</StyledTableCell>
									<StyledTableCell sx={{ paddingLeft: 0 }}>
										{formatWeightText(weightBeforePrep, ingredient.weight || 1, ingredient.measurementUnit)}
									</StyledTableCell>
									<StyledTableCell sx={{ paddingLeft: 0 }}>
										{formatWeightText(weightAfterPrep, ingredient.weight || 1, ingredient.measurementUnit)}
									</StyledTableCell>
									<StyledTableCell sx={{ paddingLeft: 0 }}>g</StyledTableCell>
									{renderMethod && (
										<StyledTableCell
											sx={{ width: '45%', verticalAlign: 'top' }}
											rowSpan={
												selectedComponent.ingredients.length + (selectedComponent.childComponents?.length || 0) + numberOfHeaders
											}
										>
											{(renderMethod = false)}
											{selectedComponent.method && <MethodStep methods={selectedComponent.method} />}
										</StyledTableCell>
									)}
								</TableRow>
							);
						}
					})}
					{selectedComponent.childComponents?.map((component, index) => (
						<TableRow key={component.id + '#' + index} sx={{ fontWeight: 'bold' }}>
							<StyledTableCell>{component.name.en}</StyledTableCell>
							<StyledTableCell sx={{ paddingLeft: 0 }}></StyledTableCell>
							<StyledTableCell sx={{ paddingLeft: 0 }}>
								{roundToTwoDecimals(
									calculateFractionWeight(
										selectedComponent,
										ComponentService.calculateComponentWeight(
											component.cups,
											component.measurementUnit,
											component.weight,
											component.quantity || 0
										),
										newCookedWeight
									)
								)}
							</StyledTableCell>
							<StyledTableCell sx={{ paddingLeft: 0 }}>g</StyledTableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
});

export default ExportTable;
