import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { uniq } from 'lodash-es';
import { useState } from 'react';
import { caloTheme } from '../../../../assets/themes/calo';
import { QualityCheckStatus } from '../../../../libs/interfaces';

interface FilterItemsProps {
	toggleFilterDrawer: (data: boolean) => void;
	handleResultsButtonClick: () => void;
	selectedCheckStatuses: QualityCheckStatus[];
	setSelectedCheckStatuses: React.Dispatch<React.SetStateAction<QualityCheckStatus[]>>;
}

const FilterItems = ({
	toggleFilterDrawer,
	handleResultsButtonClick,
	selectedCheckStatuses,
	setSelectedCheckStatuses
}: FilterItemsProps) => {
	const [selectedStatuses, setSelectedstatuses] = useState(selectedCheckStatuses);

	const handleSelectedStatuses = (statuses: QualityCheckStatus[], toAdd: boolean) => {
		if (toAdd) {
			setSelectedstatuses(uniq([...selectedStatuses, ...statuses]));
			return;
		}
		setSelectedstatuses(selectedStatuses.filter((status) => !statuses.includes(status)));
	};

	const checkIsChecked = (status: QualityCheckStatus) => {
		return selectedStatuses.includes(status);
	};
	const handleShowResultButton = () => {
		handleResultsButtonClick();
		setSelectedCheckStatuses(selectedStatuses);
	};
	return (
		<Box sx={{ width: 290, height: '100%', position: 'relative' }} role="presentation">
			<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '28px', my: 1, mx: 3 }}>Filter</Box>
			<IconButton
				aria-label="close"
				onClick={() => toggleFilterDrawer(false)}
				sx={{
					position: 'absolute',
					right: 10,
					top: 10,
					fontWeight: 600,
					color: 'black'
				}}
			>
				<CloseIcon />
			</IconButton>
			<FormControl sx={{ marginLeft: '24px', marginTop: 2 }}>
				<FormLabel>Quality check</FormLabel>
				<FormGroup sx={{ marginLeft: 2 }}>
					<FormControlLabel
						control={
							<Checkbox
								onChange={() =>
									handleSelectedStatuses([QualityCheckStatus.accepted], !checkIsChecked(QualityCheckStatus.accepted))
								}
								checked={checkIsChecked(QualityCheckStatus.accepted)}
							/>
						}
						label="Approved"
					/>
					<FormControlLabel
						control={
							<Checkbox
								onChange={() =>
									handleSelectedStatuses([QualityCheckStatus.rejected], !checkIsChecked(QualityCheckStatus.rejected))
								}
								checked={checkIsChecked(QualityCheckStatus.rejected)}
							/>
						}
						label="Rejected"
					/>
					<FormControlLabel
						control={
							<Checkbox
								onChange={() => handleSelectedStatuses([QualityCheckStatus.pending], !checkIsChecked(QualityCheckStatus.pending))}
								checked={checkIsChecked(QualityCheckStatus.pending)}
							/>
						}
						label="Pending"
					/>
				</FormGroup>
			</FormControl>
			<Stack
				direction="column"
				justifyContent="space-evenly"
				alignItems="start"
				spacing={1}
				sx={{ mx: 3, my: 1, position: 'absolute', bottom: 0, width: '85%' }}
			>
				<Button
					variant="contained"
					onClick={handleShowResultButton}
					sx={{
						textTransform: 'capitalize',
						fontSize: '19px',
						fontWeight: 600,
						color: 'white',
						width: '100%'
					}}
				>
					Show Results
				</Button>
			</Stack>
		</Box>
	);
};
export default FilterItems;
