import { createContext } from 'react';

import { IQualityTrackingContext, Session } from '../../libs/interfaces';

export const QualityTrackingContext = createContext<IQualityTrackingContext>({
	chefs: [],
	date: '',
	kitchen: '',
	shifts: Session.all,
	setShifts: () => '',
	clearData: () => undefined,
	batches: [],
	selectedStation: '',
	setSelectedStation: () => '',
	isLoading: false,
	foodComponentsWithBatches: [],
	selectedCheckStatuses: [],
	setSelectedCheckStatuses: () => []
});
