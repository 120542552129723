import { styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { ProductivityMetricsFood } from '../../../../libs/interfaces';
import { getCurrencyFromKitchen, roundToTwoDecimals } from '../../../../libs/utils';
import { formatTime, getTotalCostPerMinute } from '../../helpers';
import { Filters } from '../../ProductivityMetrics';

interface Props {
	productivityMetricsFood: ProductivityMetricsFood;
	filters: Filters;
}

const SectionTableRow = ({ productivityMetricsFood, filters }: Props) => {
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			color: theme.palette.text.primary,
			fontSize: '14px',
			width: `${100 / 7}%`
		}
	}));
	const shiftData = productivityMetricsFood.metrics.find((metric) => metric.shift === filters.shift);
	const totalCostPerMinute = getTotalCostPerMinute(filters.actual);

	return (
		<TableRow>
			<StyledTableCell sx={{ textAlign: 'left !important' }}>{productivityMetricsFood.name.en}</StyledTableCell>
			<StyledTableCell>{productivityMetricsFood.numberOfComponents}</StyledTableCell>
			<StyledTableCell>
				{shiftData?.numberOfModified ?? 0}/{shiftData?.numberOfFoods ?? 0}
			</StyledTableCell>
			<StyledTableCell>{formatTime(shiftData?.portioningTime ?? 0)}</StyledTableCell>
			<StyledTableCell>
				{formatTime((shiftData?.predictedPortioningTimePerMeal ?? 5000) * (shiftData?.numberOfFoods ?? 0))}
			</StyledTableCell>
			<StyledTableCell>{formatTime((shiftData?.portioningTime ?? 0) / (shiftData?.numberOfFoods || 1))}</StyledTableCell>
			<StyledTableCell>
				{getCurrencyFromKitchen(filters.kitchen) +
					' ' +
					roundToTwoDecimals(((shiftData?.portioningTime ?? 0) / 1000 / 60) * totalCostPerMinute)}
			</StyledTableCell>
		</TableRow>
	);
};

export default SectionTableRow;
