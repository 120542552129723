import { Divider, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { sortBy } from 'lodash-es';
import { useState } from 'react';
import { caloTheme } from '../../../../assets/themes/calo';
import { KDSChefUser } from '../../../../libs/interfaces';

interface SelectCookPopupProps {
	chefs: KDSChefUser[];
	handleSelectChef: (chef: string) => void;
}

const SelectCookPopup = ({ handleSelectChef, chefs }: SelectCookPopupProps) => {
	const [search, setSearch] = useState('');
	const sortedChefs = sortBy(chefs, (chef) => chef.name.toLowerCase());

	return (
		<Box display="flex" flexDirection="column" width="100%">
			<Typography sx={{ color: caloTheme.palette.neutral700, mb: 1 }}>Search Cook</Typography>
			<TextField
				label="Cook name"
				value={search}
				onChange={(event) => setSearch(event.target.value)}
				sx={{
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderRadius: '10px'
						}
					}
				}}
			/>
			<Divider sx={{ mt: 1 }} />
			<List sx={{ maxHeight: 200, overflow: 'auto' }}>
				{sortedChefs
					.filter((chef) => chef.name.toLowerCase().includes(search.toLowerCase()))
					.map((chef) => (
						<ListItem button key={chef.id} onClick={() => handleSelectChef(chef.name)}>
							<ListItemText primary={chef.name} />
						</ListItem>
					))}
			</List>
		</Box>
	);
};
export default SelectCookPopup;
