import React, { useContext } from 'react';

import { Icon } from '@iconify/react';
import { Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { AppContext } from '../../../../App';
import { caloTheme } from '../../../../assets/themes/calo';
import { DocsType } from '../../../../libs/enums';
import { CheckboxesState, KDSDeliveryData } from '../../../../libs/interfaces';

interface FileManagementTableRowProps {
	delivery: KDSDeliveryData;
	checkboxes: CheckboxesState;
	setCheckboxes: (label: keyof CheckboxesState, value: boolean) => void;
}

const FileManagementTableRow: React.FC<FileManagementTableRowProps> = ({ delivery, checkboxes, setCheckboxes }) => {
	const appContext = useContext(AppContext);
	const handleCheckboxChange = (label: keyof CheckboxesState, checked: boolean) => {
		if (!appContext.isOffline) {
			setCheckboxes(label, checked);
		}
	};

	const renderIconButton = (label: keyof CheckboxesState, selectedDel: KDSDeliveryData) => {
		const exportedDoc = selectedDel.downloadedDocs.includes(label as DocsType);
		if (exportedDoc) {
			return (
				<Icon
					icon="octicon:check-16"
					color={caloTheme.palette.primary500}
					width="24px"
					style={{
						textAlign: 'center',
						marginTop: 'auto',
						marginBottom: 'auto',
						backgroundColor: caloTheme.palette.primary50,
						borderRadius: 12,
						padding: 4
					}}
				/>
			);
		}
		return null;
	};

	const renderCheckbox = (label: keyof CheckboxesState, delivery: KDSDeliveryData) => (
		<TableCell sx={{ border: 'none', width: '10%', textAlign: 'right' }}>
			<Stack direction="row" width="90%" sx={{ display: 'flex', justifyContent: 'flex-end', mr: -5 }}>
				{renderIconButton(label, delivery)}
				<Checkbox
					checked={checkboxes[label]?.includes(delivery.id)}
					disabled={appContext.isOffline}
					onChange={(event) => handleCheckboxChange(label, event.target.checked)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			</Stack>
		</TableCell>
	);

	return (
		<TableRow style={{ borderBottomColor: caloTheme.palette.neutral300 }} sx={{ borderBottom: 1 }}>
			<TableCell sx={{ width: '15%', border: 'none', pl: 1 }}>
				<Typography padding="6px" fontWeight="400" fontSize="16px" lineHeight="20px" textTransform="capitalize">
					{delivery?.name}
				</Typography>
			</TableCell>
			<TableCell sx={{ width: '25%', border: 'none', p: 1 }}>
				<Stack display="flex" flexDirection="row" sx={{ width: '100%' }} justifyContent="start">
					<Typography
						key={delivery.id}
						textTransform="capitalize"
						sx={{
							width: '100%',
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '20px'
						}}
					>
						{delivery.food.map((meal) => `${meal.name.en} ${meal.isCustom ? 'Custom Meal' : ''}`).join(', ')}
					</Typography>
				</Stack>
			</TableCell>
			<TableCell
				sx={{
					border: 'none',
					textAlign: 'center',
					fontWeight: 400,
					fontSize: '16px',
					lineHeight: '20px',
					width: '10%'
				}}
			>
				{delivery.phoneNumber}
			</TableCell>
			<TableCell
				sx={{
					border: 'none',
					textAlign: 'center',
					fontWeight: 400,
					fontSize: '16px',
					lineHeight: '20px',
					width: '10%'
				}}
			>
				{delivery.driver?.name}
			</TableCell>
			<TableCell
				sx={{
					border: 'none',
					textAlign: 'center',
					fontWeight: 400,
					fontSize: '16px',
					lineHeight: '20px',
					width: '10%'
				}}
			>
				{delivery.time}
			</TableCell>
			{renderCheckbox('invoice', delivery)}
			{renderCheckbox('frontLabel', delivery)}
			{renderCheckbox('backLabel', delivery)}
		</TableRow>
	);
};
export default FileManagementTableRow;
