import { compact, omit } from 'lodash-es';
import { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { useMutation } from '@apollo/client';
import { MeasurementUnit } from '@calo/dashboard-types';
import { Dictionary } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';

import { AppContext } from '../../../../App';
import { Image as ImageComponent, PreviewPopUp } from '../../../../components';
import { QualityRating } from '../../../../libs/enums';
import { CREATE_COMPONENT_BATCH, UPDATE_COMPONENT_BATCH } from '../../../../libs/graphQL';
import { KDSFoodComponent, KDSFoodComponentBatch, QualityCheckStatus, Session } from '../../../../libs/interfaces';
import { getUserAttributes } from '../../../../libs/utils/cognitoUserHelpers';
import { useDocumentMedia } from '../../../../libs/utils/document';
import { weightCalculator } from '../../../../libs/utils/foodComponentHelper';
import PreviousQualities from './PreviousQualities';
import QualityStandardBody from './QualityStandardBody';
import ReviewDrawerCommentSection from './ReviewDrawerCommentSection';

interface ReviewQualityDrawerProps {
	isReviewDrawerOpenned: boolean;
	setIsReviewDrawerOpenned: React.Dispatch<React.SetStateAction<boolean>>;
	foodComponent: KDSFoodComponent;
	foodComponentBatch?: KDSFoodComponentBatch;
	selectedCook?: string;
	date: string;
	shift: Session;
}

const ReviewQualityDrawer = ({
	foodComponentBatch,
	isReviewDrawerOpenned,
	setIsReviewDrawerOpenned,
	foodComponent,
	selectedCook,
	date,
	shift
}: ReviewQualityDrawerProps) => {
	const [comment, setComment] = useState('');
	const [standardRatings, setStandardRatings] = useState<Dictionary<QualityRating | null>>({});
	const appContext = useContext(AppContext);
	const { isMobile } = useDocumentMedia();
	const [displayImages, setDisplayImages] = useState<{ url: string; path: string }[]>([]);
	const [previewImage, setPreviewImage] = useState<string | null>(null);

	const handleRateStandard = (rating: QualityRating, standardId: string) => {
		setStandardRatings({
			...standardRatings,
			[standardId]: standardRatings[standardId] && standardRatings[standardId] === rating ? null : rating
		});
	};
	const [createComponentBatch] = useMutation(CREATE_COMPONENT_BATCH);
	const [updateComponentBatch] = useMutation(UPDATE_COMPONENT_BATCH);

	const previewImagePopUpRef = useRef<any>();

	const updateExistingBatch = async (values: any) => {
		await updateComponentBatch({
			variables: {
				id: foodComponentBatch!.id,
				input: {
					...omit(foodComponentBatch, 'id'),
					quality: values
				}
			},
			onCompleted: () => {
				setIsReviewDrawerOpenned(false);
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const createNewBatch = async (quality: any) => {
		await createComponentBatch({
			variables: {
				data: {
					date: date,
					session: shift,
					weight: weightCalculator(
						foodComponent,
						foodComponent.weight,
						foodComponent.count,
						foodComponent.cupsQuantity,
						foodComponent.quantities
					),
					measurementUnit: MeasurementUnit.g,
					quality,
					batchNumber: (foodComponent.batches ?? []).length + 1,
					kitchen: foodComponent.kitchen,
					chef: selectedCook || null,
					foodComponentId: foodComponent.id,
					brand: foodComponent.brand,
					country: foodComponent.country
				}
			},
			onCompleted: () => {
				setIsReviewDrawerOpenned(false);
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const openImagePreview = (imageURL: string) => {
		setPreviewImage(imageURL);
		previewImagePopUpRef.current?.open();
	};

	const handleApproveOrRejectButton = (status: QualityCheckStatus) => {
		const quality = {
			id: uuid(),
			status,
			actor: getUserAttributes(appContext.user),
			createdAt: new Date().toUTCString(),
			comment,
			commentImageURLs: displayImages.map((di) => di.path),
			standards: (foodComponent.standards ?? []).map((standard) => ({
				id: uuid(),
				name: standard.name,
				rating: standardRatings[standard.id]
			}))
		};
		if (foodComponentBatch) {
			updateExistingBatch([
				quality,
				...(foodComponentBatch?.quality ?? []).filter((quality) => quality.status !== QualityCheckStatus.pending)
			]);
		} else {
			createNewBatch([quality]);
		}
		setStandardRatings({});
		setComment('');
		setDisplayImages([]);
	};

	const removeAddedImage = (image: string) => {
		setDisplayImages(displayImages.filter((di) => di.url !== image));
	};

	return (
		<Drawer
			PaperProps={{ style: isMobile ? { width: '100%' } : {} }}
			id="another testing id"
			anchor={'right'}
			open={isReviewDrawerOpenned}
			onClose={() => setIsReviewDrawerOpenned(false)}
		>
			<Box
				id="testing id"
				sx={{
					padding: 1,
					position: 'relative',
					width: isMobile ? '100%' : 400,
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					overflow: 'auto'
				}}
				role="presentation"
			>
				<Box sx={{ height: 50, minHeight: 50 }}>
					<IconButton
						aria-label="left"
						onClick={() => setIsReviewDrawerOpenned(false)}
						sx={{
							position: 'absolute',
							left: isMobile ? 10 : undefined,
							right: isMobile ? undefined : 10,
							top: 10,
							fontWeight: 600,
							color: 'black'
						}}
					>
						{isMobile ? <KeyboardArrowLeftIcon /> : <CloseIcon />}
					</IconButton>
				</Box>
				<Box
					sx={{
						width: '100%',
						minHeight: 200,
						height: '200px',
						overflow: 'hidden',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: 3
					}}
				>
					<ImageComponent
						alt={`${foodComponent.name?.en} Image`}
						style={{ width: 280, height: 'auto' }}
						key={foodComponent.id}
						url={`${process.env.REACT_APP_BUCKET_URL}/food-component/${foodComponent.id.split('#')[1]}/square@1x.jpg`}
					/>
				</Box>
				<Typography variant="h5" fontWeight={600} marginTop={1}>
					{foodComponent.name.en}
				</Typography>
				<Typography variant="caption">{selectedCook ?? ''}</Typography>
				<PreviousQualities foodComponentBatch={foodComponentBatch} openImagePreview={openImagePreview} />
				<Typography variant="caption" color={'grey'} marginTop={2}>
					Standards
				</Typography>
				{foodComponent.standards.map((standard) => (
					<QualityStandardBody
						handleRateStandard={handleRateStandard}
						standard={standard}
						standardRate={standardRatings[standard.id]}
						key={standard.id}
					/>
				))}
				<Typography marginTop={3} variant="caption">
					Comments (optional)
				</Typography>
				<Box>
					<ReviewDrawerCommentSection
						comment={comment}
						displayImages={displayImages}
						removeAddedImage={removeAddedImage}
						setComment={setComment}
						setDisplayImages={setDisplayImages}
						foodComponentId={foodComponent.id}
					/>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', mb: 2 }}>
						<Button
							variant="outlined"
							color="error"
							disabled={compact(Object.values(standardRatings)).length !== foodComponent.standards.length}
							onClick={() => handleApproveOrRejectButton(QualityCheckStatus.rejected)}
							sx={{ minWidth: 100, borderRadius: 2, textTransform: 'none', fontSize: 18 }}
						>
							Reject
						</Button>
						<Button
							variant="outlined"
							color="primary"
							disabled={compact(Object.values(standardRatings)).length !== foodComponent.standards.length}
							onClick={() => handleApproveOrRejectButton(QualityCheckStatus.accepted)}
							sx={{ minWidth: 100, borderRadius: 2, textTransform: 'none', fontSize: 18 }}
						>
							Approve
						</Button>
					</Box>
				</Box>
				<PreviewPopUp
					ref={previewImagePopUpRef}
					onClose={() => previewImagePopUpRef.current?.close()}
					previewImage={previewImage}
				/>
			</Box>
		</Drawer>
	);
};

export default ReviewQualityDrawer;
