import { useEffect, useState } from 'react';

import { Box, Card, Chip, Grid, Stack, Typography } from '@mui/material';

import { caloTheme } from '../../../assets/themes/calo';
import { FCStatus } from '../../../libs/enums';
import { KDSFood, KDSFoodComponent } from '../../../libs/interfaces';
import { getFoodComponentsSortedByNameLengthAndLastCookingStationStatus } from '../../../libs/utils/helperFunctions';

interface MealsStatusRowProps {
	food: KDSFood;
	foodComponents: KDSFoodComponent[];
}

const MealsStatusRow = ({ foodComponents, food }: MealsStatusRowProps) => {
	const [componentLeft, setComponentLeft] = useState(0);

	useEffect(() => {
		setComponentLeft(
			foodComponents.filter(
				(foodComponent) =>
					foodComponent && foodComponent.cookingStations.some((cookingStation) => cookingStation.status === FCStatus.pending)
			).length
		);
	}, [foodComponents, food]);

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const checkIsFoodComponentReady = (foodComponent: KDSFoodComponent) =>
		!foodComponent.cookingStations.some((cookingStation) => cookingStation.status === FCStatus.pending);

	return (
		<Card
			variant="outlined"
			sx={{
				boxShadow: 'none',
				height: 'auto',
				minHeight: '2px',
				mx: 2,
				alignContent: 'center',
				minWidth: '100%',
				marginBottom: 2,
				borderRadius: '8px',
				borderColor: componentLeft === 0 ? caloTheme.palette.primary500 : caloTheme.palette.neutral100
			}}
		>
			<Box display={'flex'} flexDirection={'column'}>
				<Stack display={'flex'} flexDirection={'row'} justifyContent="space-between" sx={{ width: '100%' }}>
					<Typography
						sx={{
							m: 2,
							lineHeight: '19px',
							fontSize: '16px',
							fontFamily: caloTheme.palette.neutral900,
							fontWeight: 600,
							letterSpacing: '-2%'
						}}
					>
						{food.name.en}
					</Typography>
					{componentLeft === 0 && (
						<Typography
							sx={{ m: 2, lineHeight: '19px', fontSize: '16px', fontFamily: caloTheme.palette.neutral900, fontWeight: 400 }}
						>
							Done
						</Typography>
					)}
					{componentLeft !== 0 && (
						<Typography
							sx={{ m: 2, lineHeight: '19px', fontSize: '16px', fontFamily: caloTheme.palette.neutral900, fontWeight: 400 }}
						>
							{componentLeft} Components left
						</Typography>
					)}
				</Stack>
				<Grid container sx={{ maxHeight: 'auto', overflow: 'hidden', mb: 1 }}>
					{getFoodComponentsSortedByNameLengthAndLastCookingStationStatus(foodComponents).map(
						(foodComponent) =>
							foodComponent && (
								<Grid key={foodComponent.id} item sx={{ my: '6px', ml: 2 }}>
									<Card
										variant="outlined"
										style={{
											boxShadow: 'none',
											fontSize: '19px',
											borderRadius: '8px'
										}}
									>
										<Stack
											display={'flex'}
											flexDirection={'row'}
											sx={{
												m: 1,
												alignItems: 'center',
												minHeight: '12px'
											}}
										>
											<Chip
												sx={{
													width: '12px',
													height: '12px',
													borderRadius: '240px',
													mr: '6px',
													bgcolor: checkIsFoodComponentReady(foodComponent) ? caloTheme.palette.primary500 : caloTheme.palette.red
												}}
											/>
											<Typography
												variant="h6"
												sx={{
													flexShrink: 0,
													mt: '2px',
													lineHeight: '19px',
													fontSize: '16px',
													fontFamily: caloTheme.palette.neutral900,
													fontWeight: 600,
													letterSpacing: '-2%'
												}}
											>
												{foodComponent.name.en}
											</Typography>
										</Stack>
									</Card>
								</Grid>
							)
					)}
				</Grid>
			</Box>
		</Card>
	);
};

export default MealsStatusRow;
