import { differenceInSeconds } from 'date-fns';
import { useEffect, useMemo } from 'react';

import { Typography, useTheme } from '@mui/material';

import { useCounter } from '../../../hooks';
import { CombinedShortages, KDSFoodComponent, ProcessTime } from '../../../libs/interfaces';
import { getSecondsBetweenIntervals } from '../../../libs/utils/helperFunctions';

interface ComponentProcessTimeProps {
	foodComponent: KDSFoodComponent;
	processTime: ProcessTime;
	endTime: string | null;
	combinedShortages: CombinedShortages | undefined;
}

const ComponentProcessTime = ({ foodComponent, combinedShortages, processTime, endTime }: ComponentProcessTimeProps) => {
	const theme = useTheme();

	const { initialSeconds, shouldCount } = useMemo(() => {
		let initialSeconds = 0;
		let shouldCount = false;

		if (combinedShortages) {
			return { initialSeconds, shouldCount };
		}

		const allIntervals = processTime.intervals || [];
		const intervalSeconds = getSecondsBetweenIntervals(allIntervals);

		if (processTime) {
			if (allIntervals.length <= 0 || allIntervals[allIntervals.length - 1].resumedAt) {
				shouldCount = true;
			}

			const start = new Date(processTime.startedAt);
			const end = endTime
				? new Date(endTime)
				: shouldCount
					? new Date()
					: new Date(allIntervals[allIntervals.length - 1].pausedAt);

			initialSeconds = differenceInSeconds(new Date(end), new Date(start)) - intervalSeconds;
		}

		if (initialSeconds < 0) {
			initialSeconds = 0;
		}

		return { initialSeconds, shouldCount };
	}, [processTime, foodComponent, endTime]);

	const { formattedTime, stopCounting, startCounting } = useCounter(initialSeconds, shouldCount);

	const styles: { [key: string]: string } = {
		['px']: '10px'
	};

	useEffect(() => {
		if (!processTime) {
			return;
		}
		const allIntervals = processTime.intervals || [];

		if (endTime || (allIntervals.length > 0 && !allIntervals[allIntervals.length - 1].resumedAt)) {
			return stopCounting();
		}

		startCounting();
	}, [foodComponent, endTime]);

	return (
		<Typography
			sx={{
				borderRadius: '16px',
				py: '6px',
				textAlign: 'center',
				cursor: 'pointer',
				backgroundColor: theme.palette.primary100,
				fontWeight: 700,
				fontSize: '14px',
				lineHeight: '17px',
				color: theme.palette.primary700,
				...styles
			}}
		>
			{formattedTime}
		</Typography>
	);
};

export default ComponentProcessTime;
