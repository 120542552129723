import { useContext } from 'react';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Button, Stack, Typography, useTheme, Checkbox } from '@mui/material';

import { BATCH_UPDATE_FOODS_PORTIONING_STATUS, UPDATE_FC_STATUS_QUERY } from '../../../../../libs/graphQL';
import { CombinedShortages, FoodComponentStationOperation, KDSFoodComponent } from '../../../../../libs/interfaces';
import { FoodComponentStation } from '../../../../../libs/enums';
import { AppContext } from '../../../../../App';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PlayIcon from '../../../../../components/PortionActionButton/PlayIcon';

type ActionButtonsProps = {
	foodComponent: KDSFoodComponent;
	tableName: FoodComponentStation;
	statusAttributes: {
		isCheckable: boolean;
		lastUncheckedStation: string;
	};
} & (
	| {
			combinedShortages: CombinedShortages;
			isChecked: boolean;
			setIsCheckedState: React.Dispatch<React.SetStateAction<boolean>>;
	  }
	| { combinedShortages: undefined }
);

const ActionButtons = ({ foodComponent, tableName, statusAttributes, ...props }: ActionButtonsProps) => {
	const appContext = useContext(AppContext);
	const theme = useTheme();

	const [mutateFoodComponent, { loading }] = useMutation(UPDATE_FC_STATUS_QUERY);
	const [mutateFoodsPortioningStatus] = useMutation(BATCH_UPDATE_FOODS_PORTIONING_STATUS);

	const cookingStation = foodComponent.cookingStations.find((station) => station.name === tableName);
	const allIntervals = cookingStation?.processTime?.intervals || [];

	const canStart = !cookingStation?.processTime && !cookingStation?.endTime;
	const canResume =
		cookingStation?.processTime?.startedAt && allIntervals.length > 0 && !allIntervals[allIntervals.length - 1].resumedAt;
	const canPause =
		cookingStation?.processTime?.startedAt && (allIntervals.length === 0 || allIntervals[allIntervals.length - 1].resumedAt);

	const handleFoodComponentStationAction = async (operation: FoodComponentStationOperation) => {
		await mutateFoodComponent({
			variables: {
				id: foodComponent.id,
				kitchen: foodComponent.kitchen,
				station: tableName,
				operation: operation,
				shortagesIds: props.combinedShortages?.ids || []
			},
			onCompleted: async () => {
				const lastStationName = foodComponent.cookingStations.at(-1)?.name;
				if (lastStationName === tableName && operation === FoodComponentStationOperation.end) {
					await mutateFoodsPortioningStatus({
						variables: {
							kitchen: foodComponent.kitchen,
							foodsIds: foodComponent.foodsIds || []
						}
					});
				}
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	if (props.combinedShortages) {
		return (
			<Stack
				sx={{
					display: 'flex',
					flexDirection: 'column',
					my: '-4px',
					alignItems: 'flex-end',
					width: '100%',
					justifyContent: 'flex-end'
				}}
			>
				<Checkbox
					checked={props.isChecked}
					disabled={!statusAttributes.isCheckable || loading || appContext.isOffline || props.isChecked}
					onChange={(event) => {
						if (!props.isChecked) {
							props.setIsCheckedState(event.target.checked);
							handleFoodComponentStationAction(FoodComponentStationOperation.end);
						}
					}}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			</Stack>
		);
	}

	if (canStart) {
		return (
			<Stack sx={{ display: 'flex', flexDirection: 'column', my: '-4px', alignItems: 'flex-end', width: '100%' }}>
				<Button
					onClick={() => handleFoodComponentStationAction(FoodComponentStationOperation.start)}
					disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
					sx={{
						width: '100%',
						height: '100%',
						color: theme.palette.primary500,
						border: '1px solid ' + theme.palette.primary500,
						borderRadius: '8px',
						textTransform: 'capitalize',
						'&:disabled': {
							borderColor: theme.palette.neutral200
						},
						'&:hover': {}
					}}
				>
					<Typography fontWeight="600" fontSize="16px">
						Start Now
					</Typography>
				</Button>
			</Stack>
		);
	}

	return (
		<Stack sx={{ my: '-4px', display: 'flex', flexDirection: 'row', gap: '12px', justifyContent: 'flex-end' }}>
			<Button
				onClick={() =>
					handleFoodComponentStationAction(canPause ? FoodComponentStationOperation.pause : FoodComponentStationOperation.resume)
				}
				disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
				startIcon={canResume ? <PlayIcon /> : <Icon icon="fluent:pause-circle-20-regular" height="24" width="24" />}
				sx={{
					width: '110px',
					height: '100%',
					color: theme.palette.primary500,
					backgroundColor: theme.palette.primary100,
					borderRadius: '8px',
					textTransform: 'capitalize'
				}}
			>
				<Typography fontWeight="600" fontSize="16px">
					{canPause ? 'Pause' : 'Resume'}
				</Typography>
			</Button>
			<Button
				disabled={!statusAttributes.isCheckable || loading || appContext.isOffline}
				onClick={() => handleFoodComponentStationAction(FoodComponentStationOperation.end)}
				sx={{
					backgroundColor: theme.palette.primary500,
					borderRadius: '8px',
					width: '38px',
					minWidth: '48px',
					'&:hover': {
						backgroundColor: theme.palette.primary500
					},
					'&:disabled': {
						backgroundColor: theme.palette.primary200
					}
				}}
			>
				<CheckRoundedIcon sx={{ color: theme.palette.white }} />
			</Button>
		</Stack>
	);
};

export default ActionButtons;
