import {
	CupSizes,
	DeliveryTicketData,
	DeliveryTicketStatus,
	FoodComponentMethodStep,
	GiftItem,
	GiftItemType,
	KDSPermission,
	LinkedComponent,
	MeasurementUnit,
	Range
} from '@calo/dashboard-types';
import { Brand, Country, DeliveryStatus, DeliveryTime, DietType, Food, FoodDietType, Kitchen, Localized } from '@calo/types';

import { DocsType, FCStatus, FoodComponentStation, FoodPortioningStatus, FoodType, QualityRating } from './enums';

export interface KDSFoodComponent {
	id: string;
	name: Localized;
	count: number;
	weight: number;
	cookedWeight: number;
	measurementUnit: MeasurementUnit;
	cookingStations: KDSFoodComponentStation[];
	date: string; //yyyy-mm-dd
	country: Country;
	kitchen: string;
	kitchenWastageInputs?: FCKitchenWastageInputs;
	brand?: Brand;
	cups?: CupSizes[];
	quantities?: number[];
	ingredients: FCKDSIngredient[];
	childComponents?: LinkedComponent[];
	method?: FoodComponentMethodStep[];
	foodsIds?: string[];
	batches?: KDSFoodComponentBatch[];
	cost: number;
	cupsQuantity?: CupsQuantity[];
	shortages?: KDSComponentShortage[];
	purchasingCost?: number;
	child?: {
		count: number;
		weight: number;
	};
	standards: {
		id: string;
		name: string;
		description?: string;
		imageURLs?: string[];
	}[];
}

export interface FoodComponent {
	id: string;
	name: Localized;
	count: number;
	weight: number;
	cookedRawFactor: number;
	cookedWeight: number;
	measurementUnit: MeasurementUnit;
	cookingStations: KDSFoodComponentStation[];
	date: string; //yyyy-mm-dd
	country: Country;
	kitchen: string;
	kitchenWastageInputs?: FCKitchenWastageInputs;
	cups?: CupSizes[];
	quantities?: number[];
	quantity?: number;
	brand?: Brand;
	ingredients: KDSIngredient[];
	childComponents?: FoodComponent[];
	method?: FoodComponentMethodStep[];
	foodsIds?: string[];
	cost: number;
	batches?: KDSFoodComponentBatch[];
	cupsQuantity?: CupsQuantity[];
}

export interface FCKitchenWastageInputs {
	preppedWeight?: number;
	chilledWeight?: number;
	wastage?: number;
	action?: string;
}

export interface KDSFoodComponentStation {
	name: FoodComponentStation;
	status: FCStatus;
	endTime: string;
	processTime?: ProcessTime;
}

export interface KDSFood {
	id: string;
	name: Localized;
	count: number;
	componentIds: string[];
	date: string;
	country: string;
	kitchen: string;
	sizes: FoodSize[];
	isAssemblyRequired: boolean;
	deliveryTime: string[];
	portioningStatus?: PortioningStatus[];
	isCustom?: boolean;
	type: FoodType[];
	brand?: Brand;
	tags?: (DietType | string)[];
	portioningURL?: string;
	assemblyURL?: string;
}

export interface ComponentShortageInput {
	foodId: string;
	foodName: Localized;
	foodSize: string;
	weight: number;
	componentId: string;
}

export interface FoodSize {
	id: string;
	size: string;
	quantity: number;
	components: ComponentsQuantity[];
	extraMeals?: number;
	purchasingCost?: number;
}

export interface ComponentsQuantity {
	id: string;
	quantity: number;
}

export interface KDSComponentShortage {
	id: string;
	foodId: string;
	foodName: Localized;
	foodSize: string;
	weight: number;
	cookingStations: KDSFoodComponentStation[];
	createdAt: string;
}

export interface PortioningStatus {
	session: string;
	readyForAssemblyTime?: string;
	assemblingTime?: ProcessTime;
	readyForPortioningTime?: string;
	portioningTime?: ProcessTime;
	endTime?: string;
}

export interface ProcessTime {
	startedAt: string;
	intervals?: PausedInterval[];
}

export interface PausedInterval {
	pausedAt: string;
	resumedAt?: string;
}

export interface PortioningStatusAttributes {
	status: FoodPortioningStatus;
	time?: string;
	intervals?: PausedInterval[];
	buttonText: string;
	color: string;
	backgroundColor: string;
	isDisabled: boolean;
}

export interface KDSBaggingSession {
	id: string; //date#session
	kitchen: string;
	date: string;
	startTime: string;
	endTime: string;
	roomTemperature: number;
	session: string;
	dispatches?: KDSBaggingDispatch[];
	status: SessionStatus;
}

export interface KDSBaggingDispatch {
	id: string; //date#session#foodId
	session: Session; //morning|evening|all
	foodId: string;
	foodName: Localized;
	foodSize: string;
	foodType: FoodType[];
	total: number;
	actual: number;
	extra: number;
	modified: number;
	leftovers: number;
	startTemperature: number;
	endTemperature: number;
	action: string;
	foodCost: number;
	purchasingCost?: number;
	isCustomFood?: boolean;
	packages?: Package[];
}

export interface Package {
	id: string;
	name: string;
}

export enum Session {
	morning = 'morning',
	evening = 'evening',
	all = 'all'
}

export enum SessionStatus {
	started = 'started',
	ended = 'ended',
	pending = 'pending'
}

export enum FoodComponentStationOperation {
	start = 'start',
	pause = 'pause',
	resume = 'resume',
	end = 'end'
}

export interface CombinedShortages {
	ids: string[];
	totalWeight: number;
	cookingStations: KDSFoodComponentStation[];
}

export interface KDSSessionInfo {
	sessions: KDSBaggingSession[];
	dispatches: KDSBaggingDispatch[];
	kitchen: string;
	date: string;
}

export interface BaggingSessionInput {
	kitchen: string;
	date: string;
	startTime?: string;
	endTime?: string;
	roomTemperature?: number;
	session: string;
	status: SessionStatus;
}

export interface BaggingDispatchInput {
	kitchen: string;
	session?: Session;
	foodId?: string;
	foodName?: Localized;
	date: string;
	foodSize?: string;
	foodType?: FoodType[];
	total?: number;
	actual?: number;
	extra?: number;
	leftovers?: number;
	startTemperature?: number;
	endTemperature?: number;
	action?: string;
	purchasingCost?: number;
	isCustomFood?: boolean;
}

export interface KDSSessionListResponse {
	sessions: KDSBaggingSession[];
	next: string;
	count: number;
	scannedCount: number;
}

export interface PortioningStatus {
	session: string;
	endTime?: string;
	isAssembled?: boolean;
}

export interface ArchiveSession {
	listBaggingSession: KDSSessionListResponse;
}

export interface KDSIngredient {
	id: string;
	name: Localized;
	quantity: number;
	date: string;
	kitchen: string;
	brand?: Brand;
	measurementUnit: MeasurementUnit;
	internalName?: string;
	wastage: number;
	purchasingCost?: number;
	header?: string;
	weight?: number;
}

export type IngredientHeaderItem =
	| {
			type: 'header';
			header: string;
	  }
	| { type: 'ingredient'; ingredient: KDSIngredient };

export interface FCKDSIngredient {
	id: string;
	quantity: number;
}

export interface KDSFoodComponentWastageResponse {
	components: KDSFoodComponent[];
	next: string;
}

export interface FoodComponentWastage {
	listFoodComponentByRange: KDSFoodComponentWastageResponse;
}

export interface FoodComponentStat {
	id: string;
	name: Localized;
	count: number;
	weight: number;
	cookedWeight: number;
	cookingStations: KDSFoodComponentStation[];
	country: Country;
	kitchen: string;
}

export interface DateRangeFilter {
	startDate: string;
	endDate: string;
}

export interface Options<T> {
	value: string;
	data: T;
	label: string;
	name: string;
}

export interface DeliveryTicket {
	id: string;
	data: DeliveryTicketData & { autoApprove?: boolean };
	date: string;
	createdBy: User;
	resolvedBy?: User;
	resolvedAt?: string;
	downloadedAt?: string;
	kitchen: Kitchen;
	country: Country;
	brand: Brand;
	status: DeliveryTicketStatus;
	createdAt: string;
	index: number;
	zone?: string;
}

export interface User {
	id: string;
	name: string;
	phoneNumber: string;
	email: string;
	country: Country;
	kitchen: Kitchen[];
	permissions?: KDSPermission[];
	group?: string;
}

export interface KDSAppContext {
	user: User;
	isOffline: boolean;
	isFilterOpen: boolean;
	isSidebarOpened: boolean;
	refetchFoods: () => void;
	setIsFilterOpen: (value: boolean) => void;
}

export interface KDSExtraMeals {
	id: string;
	foodId: string;
	size: string;
	extraMeals: number;
	kitchen: Kitchen;
	name: Localized;
	type: FoodType[];
	brand: Brand;
	isCustom?: boolean;
}

export interface KDSChefUser {
	id: string;
	name: string;
	session: Session;
	stations: string[];
	kitchen: Kitchen;
	brand: Brand;
	country: Country;
	deletedAt?: string;
}

export enum QualityCheckStatus {
	accepted = 'Accepted',
	rejected = 'Rejected',
	pending = 'Pending'
}

export interface KDSFoodComponentBatch {
	id: string; //batch#kitchen
	date: string;
	session: Session;
	batchNumber: number;
	foodComponentId: string;
	chef: string;
	measurementUnit: string;
	weight: number;
	quality: Quality[];
	deletedAt?: string;
	deletedBy?: User;
	brand: Brand;
	kitchen: Kitchen;
	country: Country;
}

export interface ShoppingMenu {
	id: string;
	food: ShoppingMenuFood[];
	kitchen: string;
	day: string;
	country: string;
}

export interface ShoppingMenuFood {
	id: string;
	name: {
		en: string;
		ar: string;
	};
	order: number;
	size?: string;
	isDone?: boolean;
	isCustom?: boolean;
	type: FoodType;
	status?: FoodPortioningStatus;
	modifiedDeliveryIds: ModifiedDeliveryIds;
	portioningURL?: string;
	assemblyURL?: string;
}

export interface ModifiedDeliveryIds {
	downloadSequence?: number;
	deliveryIds: string[];
}

export interface KDSDriver {
	id: string;
	driver: {
		driverName: string;
		id: string;
		phoneNumber: string;
		email: string;
	};
	caloBags: number;
	mealoBags: number;
	vanTemperature: number;
	departureTime: number;
}

export interface CupsQuantity {
	cup: number;
	quantity: number;
}
export interface MobileFiltersPages {
	shift: Session;
	showCustom: boolean;
}

export interface FoodComponentDataPortionPage {
	startPortioningFood: KDSFood[];
	pausePortioningFood: KDSFood[];
	resumePortioningFood: KDSFood[];
	startAssemblingFood: KDSFood[];
	pauseAssemblingFood: KDSFood[];
	resumeAssemblingFood: KDSFood[];
	notReadyFood: KDSFood[];
	completedFood: KDSFood[];
}
export interface CupsQuantity {
	cup: number;
	quantity: number;
}

export interface Complaint {
	id: string;
	category: string;
	type: string;
	kitchen: Kitchen;
	date: string;
	operationsId?: string;
	user: {
		userId: string;
		name: string;
		phoneNumber: string;
		email: string;
	};
	comments?: ComplaintComment[];
	attachment?: boolean;
	attachments?: string[];
	status: DeliveryTicketStatus;
	meal?: {
		id: string;
		name: string;
		size: string;
		slug: string;
	};
	createdBy: {
		id: string;
		name: string;
		email: string;
	};
	resolvedBy?: {
		id: string;
		name: string;
		email: string;
	};
	resolvedAt?: string;
	createdAt: string;
	deliveryTime: DeliveryTime;
	driver?: {
		name: string;
		id: string;
	};
	deliveryId: string;
}

export interface ComplaintComment {
	id: string;
	name: string;
	text: string;
}

export interface ComplaintFilterProps {
	kitchen: string;
	date: Range;
	type?: string;
	category?: string;
}

export interface SpecialRequestFilterProps {
	kitchen: string;
	date: Range;
	session: Session;
	zone?: string;
}
export interface Actor {
	name: string;
	email: string;
	id: string;
}

export interface Quality {
	status: QualityCheckStatus;
	actor: Actor;
	createdAt: string;
	comment?: string;
	id: string;
	commentImageURLs: string[];
	standards: {
		id: string;
		name: string;
		rating: QualityRating;
	}[];
}

export interface RejectedStations {
	[key: string]: boolean;
}

export interface KDSDeliveryData {
	brand: Brand;
	day: string;
	food: KDSDeliveryFood[];
	id: string;
	kitchen: Kitchen;
	operationId?: string;
	name: string;
	phoneNumber: string;
	shortId: string; //Invoice number
	skipped: boolean;
	status: DeliveryStatus[];
	userId: string;
	driver: {
		id: string;
		name: string;
	};
	downloadedDocs: DocsType[];
	giftedItems?: Record<GiftItemType, GiftItem[]>;
	time: DeliveryTime;
	customDeliveryTime?: string;
	pickupTime?: string;
}

export interface KDSDeliveryFood {
	id: string;
	isCustom: boolean;
	isSwapped: boolean;
	size: string;
	skipped: boolean;
	tags: string[];
	type: string[];
	name: Localized;
}
export interface GetKDSComplaintsListReq {
	filters: ComplaintFilterProps;
	limit?: number;
	page: string;
}
export interface GetKDSDeliveryListReq {
	filters: KDSDeliveryListFilters;
	limit?: number;
	page: string;
}
export interface KDSDeliveryListFilters {
	userIds?: string[];
	name?: string;
	phoneNumber?: string;
	shortId?: string;
	day: Range;
	status: DeliveryStatus[];
	skipped: boolean;
	country: string;
	kitchen: string;
	brand: Brand;
	deliveryTime?: DeliveryTime;
	driverId?: string;
}

export interface CheckboxesState {
	invoice: string[];
	backLabel: string[];
	frontLabel: string[];
}

export interface FilterSizeState {
	s: boolean;
	m: boolean;
	l: boolean;
	xl: boolean;
	xs: boolean;
	r: boolean;
}

export interface FilterTypeState {
	lunch: boolean;
	dinner: boolean;
	breakfast: boolean;
	juice: boolean;
	dessert: boolean;
	snack: boolean;
	caloKids: boolean;
	coffee: boolean;
}

export interface MealCheckBox {
	[key: string]: {
		meal: Food;
		amount: number;
		size: string;
		isBackLabelSelected?: boolean;
		isFrontLabelSelected?: boolean;
	};
}

export interface FileManagementFoodFilter {
	kitchen: string;
	date: string | null;
	size: string[];
	type: FoodType[];
	name: string | undefined;
	ids: string[];
}

export interface SpecialRequest {
	id: string;
	type: string[];
	kitchen: Kitchen;
	date: string;
	zone?: string;
	operationsId?: string;
	userName: string;
	userId: string;
	phoneNumber: string;
	comment?: string;
	attachments?: string[];
	createdBy: {
		id: string;
		name: string;
		email: string;
	};
	updatedAt?: string;
	deliveryId: string;
	items: SpecialRequestItems[];
	createdAt: string;
	deliveryTime: DeliveryTime;
	driver?: {
		name: string;
		id: string;
	};
}
export interface SpecialRequestItems {
	type: SpecialRequestType;
	amount: number;
	id: string;
	item?: SpecialRequestMealItem;
}

interface SpecialRequestMealItem {
	id: string;
	name: Localized;
	size: string;
}

export enum SpecialRequestType {
	meal = 'meal',
	flowers = 'flowers',
	other = 'other',
	cake = 'cake',
	merch = 'merch'
}
export interface FilterTimeState {
	morning: boolean;
	evening: boolean;
}

export interface IQualityTrackingContext {
	chefs: KDSChefUser[];
	date: string;
	kitchen: string;
	shifts: Session;
	setShifts: React.Dispatch<React.SetStateAction<Session>>;
	clearData: () => void;
	batches: KDSFoodComponentBatch[];
	selectedStation: string;
	setSelectedStation: React.Dispatch<React.SetStateAction<string>>;
	isLoading: boolean;
	foodComponentsWithBatches: FoodComponentWithBatches[];
	selectedCheckStatuses: QualityCheckStatus[];
	setSelectedCheckStatuses: React.Dispatch<React.SetStateAction<QualityCheckStatus[]>>;
}

export interface QualityTrackingFilters {
	startDate: string;
	endDate: string;
}

export interface FoodComponentWithBatches extends KDSFoodComponent {
	batches: KDSFoodComponentBatch[];
}

export interface QualityMetrics {
	id: string;
	day: string;
	shift: Session;
	isFinalized: boolean;
	kitchen: Kitchen;
	food: QualityMetricsFood[];
	components: QualityMetricsFoodComponent[];
}

export interface QualityMetricsFood {
	id: string;
	name: Localized;
	componentsIds: string[];
}

export interface QualityMetricsFoodComponent {
	id: string;
	name: Localized;
	cost: number;
	weight: number;
	consistencyScore: number;
	numberOfRejected: number;
	totalReviewed: number;
	weightOfRejected: number;
	cookedWeight: number;
}

export interface ProductivityMetrics {
	id: string;
	day: string;
	kitchen: Kitchen;
	foods: ProductivityMetricsFood[];
}

export interface ProductivityMetricsFood {
	id: string;
	name: Localized;
	numberOfComponents: number;
	metrics: FoodMetrics[];
	type: FoodType[];
	tags: FoodDietType[];
	isSandwich: boolean;
}

export interface FoodMetrics {
	shift: Session;
	numberOfFoods: number;
	numberOfModified: number;
	portioningTime?: number;
	predictedPortioningTimePerMeal?: number;
}
