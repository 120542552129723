import { gql } from '@apollo/client';

import {
	BAGGING_DISPATCH_FIELDS,
	BAGGING_SESSION_FIELDS,
	CHEF_FIELDS,
	COMPONENT_BATCH_FIELDS,
	CUSTOMER_COMPLAINTS_DATA_FIELDS,
	DELIVERY_TICKETS_FIELDS,
	DRIVER_DATA_FIELDS,
	FOOD_COMPONENTS_FIELDS,
	FOOD_FIELDS
} from './fragments';

export const UPDATE_FC_STATUS_QUERY = gql`
	${FOOD_COMPONENTS_FIELDS}
	mutation updateFoodComponentStatus(
		$id: String!
		$kitchen: Kitchen!
		$operation: FoodComponentStationOperation!
		$station: CookingStationName!
		$shortagesIds: [String]
	) {
		updateFoodComponentStatus(id: $id, kitchen: $kitchen, operation: $operation, station: $station, shortagesIds: $shortagesIds) {
			...FoodComponentsFields
		}
	}
`;

export const UPDATE_FOOD_PORTIONING_STATUS = gql`
	${FOOD_FIELDS}
	mutation updateFoodPortioningStatus(
		$id: String!
		$kitchen: Kitchen!
		$session: String!
		$operation: UpdateFoodPortioningStatusOperation!
	) {
		updateFoodPortioningStatus(kitchen: $kitchen, id: $id, session: $session, operation: $operation) {
			...FoodFields
		}
	}
`;

export const UPDATE_WASTAGE_TRACKING = gql`
	${FOOD_COMPONENTS_FIELDS}
	mutation updateFCWastageInputs($id: String!, $kitchen: Kitchen!, $inputs: FCWastageInputs) {
		updateFCWastageInputs(id: $id, kitchen: $kitchen, inputs: $inputs) {
			...FoodComponentsFields
		}
	}
`;

export const START_BAGGING_SESSION = gql`
	${BAGGING_SESSION_FIELDS}
	mutation startBaggingSession($input: BaggingSessionInput!) {
		startBaggingSession(input: $input) {
			...BaggingSessionFields
		}
	}
`;

export const MODIFY_BAGGING_SESSION = gql`
	${BAGGING_SESSION_FIELDS}
	mutation modifyBaggingSession($id: String, $input: BaggingSessionInput!) {
		modifyBaggingSession(id: $id, input: $input) {
			...BaggingSessionFields
		}
	}
`;

export const ADD_BAGGING_DISPATCH = gql`
	${BAGGING_DISPATCH_FIELDS}
	mutation addBaggingDispatch($input: BaggingDispatchInput!) {
		addBaggingDispatch(input: $input) {
			...BaggingDispatchFields
		}
	}
`;

export const MODIFY_BAGGING_DISPATCH = gql`
	${BAGGING_DISPATCH_FIELDS}
	mutation modifyBaggingDispatch($id: String, $input: BaggingDispatchInput!) {
		modifyBaggingDispatch(id: $id, input: $input) {
			...BaggingDispatchFields
		}
	}
`;

export const EXPORT_FOOD_COMPONENTS_WASTAGE = gql`
	mutation exportFoodComponentsWastage($kitchen: Kitchen, $startDate: String, $endDate: String) {
		exportFoodComponentWastage(kitchen: $kitchen, startDate: $startDate, endDate: $endDate) {
			data
		}
	}
`;

export const EXPORT_FOOD_COMPONENTS_STATUS = gql`
	mutation exportFoodComponentStatus($kitchen: Kitchen, $startDate: String, $endDate: String) {
		exportFoodComponentStatus(kitchen: $kitchen, startDate: $startDate, endDate: $endDate) {
			data
		}
	}
`;

export const EXPORT_FOOD_PORTIONING_STATUS = gql`
	mutation exportFoodPortioningStatus($kitchen: Kitchen, $startDate: String, $endDate: String, $showCustom: Boolean) {
		exportFoodPortioningStatus(kitchen: $kitchen, startDate: $startDate, endDate: $endDate, showCustom: $showCustom) {
			data
		}
	}
`;

export const BATCH_UPDATE_FOODS_PORTIONING_STATUS = gql`
	${FOOD_FIELDS}
	mutation batchUpdateFoodsPortioningStatus($kitchen: Kitchen, $foodsIds: [String]) {
		batchUpdateFoodsPortioningStatus(kitchen: $kitchen, foodsIds: $foodsIds) {
			...FoodFields
		}
	}
`;
export const EXPORT_FOOD_COMPONENTS_SHORTAGE = gql`
	mutation exportFoodComponentsShortage($kitchen: Kitchen, $startDate: String, $endDate: String) {
		exportFoodComponentShortage(kitchen: $kitchen, startDate: $startDate, endDate: $endDate) {
			data
		}
	}
`;

export const APPROVE_DELIVERY_TICKET = gql`
	${DELIVERY_TICKETS_FIELDS}
	mutation approveDeliveryTicket($id: String, $kitchen: Kitchen, $type: DeliveryTicketType, $user: UserInput) {
		approveDeliveryTicket(id: $id, kitchen: $kitchen, type: $type, user: $user) {
			...DeliveryTicketFields
		}
	}
`;

export const REJECT_DELIVERY_TICKET = gql`
	${DELIVERY_TICKETS_FIELDS}
	mutation rejectDeliveryTicket($id: String, $kitchen: Kitchen, $type: DeliveryTicketType, $user: UserInput) {
		rejectDeliveryTicket(id: $id, kitchen: $kitchen, type: $type, user: $user) {
			...DeliveryTicketFields
		}
	}
`;

export const UPDATE_EXTRA_MEALS = gql`
	${FOOD_FIELDS}
	mutation updateExtraMeals($kitchen: Kitchen, $data: [UpdateExtraMealsInput]) {
		updateExtraMeals(kitchen: $kitchen, data: $data) {
			...FoodFields
		}
	}
`;

export const EXPORT_DOCUMENTS = gql`
	mutation exportDocuments($day: String, $docTypes: [DocsType]!, $ids: [String]!, $kitchen: Kitchen, $shift: String) {
		exportDocuments(day: $day, docTypes: $docTypes, ids: $ids, kitchen: $kitchen, shift: $shift) {
			data
		}
	}
`;

export const EXPORT_INVOICES_FOR_MEAL = gql`
	mutation exportMealInvoices($id: String, $kitchen: Kitchen, $shoppingMenuFoodSlug: String) {
		exportMealInvoices(id: $id, kitchen: $kitchen, shoppingMenuFoodSlug: $shoppingMenuFoodSlug) {
			data
		}
	}
`;

export const CREATE_CHEF_USER = gql`
	${CHEF_FIELDS}
	mutation createChef($data: ChefInput) {
		createChef(data: $data) {
			...ChefFields
		}
	}
`;

export const CREATE_COMPONENT_BATCH = gql`
	${COMPONENT_BATCH_FIELDS}
	mutation createComponentBatch($data: ComponentBatchInput) {
		createComponentBatch(data: $data) {
			...ComponentBatchFields
		}
	}
`;

export const UPDATE_COMPONENT_BATCH = gql`
	${COMPONENT_BATCH_FIELDS}
	mutation updateComponentBatch($id: String, $input: ComponentBatchInput) {
		updateComponentBatch(id: $id, input: $input) {
			...ComponentBatchFields
		}
	}
`;

export const DELETE_COMPONENT_BATCH = gql`
	mutation deleteComponentBatch($id: String, $kitchen: String) {
		deleteComponentBatch(id: $id, kitchen: $kitchen) {
			message
		}
	}
`;

export const UPDATE_DRIVER_DISPATCH = gql`
	${DRIVER_DATA_FIELDS}
	mutation updateDriverDispatch($id: String, $input: DriverDispatchInput) {
		updateDriverDispatch(id: $id, input: $input) {
			...DriverDispatchesFields
		}
	}
`;

export const BATCH_CREATE_COMPONENT_SHORTAGE = gql`
	${FOOD_COMPONENTS_FIELDS}
	mutation batchCreateComponentShortage($input: [ComponentShortageInput], $kitchen: Kitchen) {
		batchCreateComponentShortage(input: $input, kitchen: $kitchen) {
			...FoodComponentsFields
		}
	}
`;

export const UPDATE_CUSTOMER_COMPLAINTS = gql`
	${CUSTOMER_COMPLAINTS_DATA_FIELDS}
	mutation resolveComplaint($id: String, $status: ComplaintStatus, $kitchen: Kitchen, $user: UserInput) {
		resolveComplaint(id: $id, status: $status, kitchen: $kitchen, user: $user) {
			...ComplaintsFields
		}
	}
`;

export const EXPORT_COMPLAINTS_LIST_QUERY = gql`
	mutation exportComplaintList($filters: ComplaintsFiltersInput) {
		exportComplaintList(filters: $filters) {
			data
		}
	}
`;

export const UPDATE_TICKETS_DOWNLOADEDAT = gql`
	${DELIVERY_TICKETS_FIELDS}
	mutation updateDeliveryTicketsDownloadedAt($ids: [String]!, $kitchen: Kitchen!) {
		updateDeliveryTicketsDownloadedAt(ids: $ids, kitchen: $kitchen) {
			...DeliveryTicketFields
		}
	}
`;

export const EXPORT_COMPONENT_BATCH = gql`
	mutation exportComponentBatch($kitchen: Kitchen, $date: RangeInput, $shift: String) {
		exportComponentBatch(kitchen: $kitchen, date: $date, shift: $shift) {
			data
		}
	}
`;

export const UPDATE_CHEF_USER = gql`
	${CHEF_FIELDS}
	mutation updateChef($id: String, $input: ChefInput) {
		updateChef(id: $id, input: $input) {
			...ChefFields
		}
	}
`;

export const DELETE_CHEF_USER = gql`
	${CHEF_FIELDS}
	mutation deleteChef($id: String!, $kitchen: Kitchen!) {
		deleteChef(id: $id, kitchen: $kitchen) {
			...ChefFields
		}
	}
`;

export const EXPORT_FOOD_LABELS = gql`
	mutation exportFoodLabels($input: ExportFoodLabelsReq) {
		exportFoodLabels(input: $input) {
			data
		}
	}
`;

export const GET_IMAGE_UPLOAD_URL = gql`
	mutation getImageUploadURL($path: String) {
		getImageUploadURL(path: $path) {
			data
		}
	}
`;

export const EXPORT_QUALITY_METRICS = gql`
	mutation exportQualityMetrics(
		$startDate: String
		$endDate: String
		$kitchen: Kitchen
		$shift: Session
		$type: ExportMetricsTypeEnum
	) {
		exportQualityMetrics(startDate: $startDate, endDate: $endDate, kitchen: $kitchen, shift: $shift, type: $type) {
			data
		}
	}
`;
