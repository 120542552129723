import { useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { KDSPermission } from '@calo/dashboard-types';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { caloTheme } from '../../assets/themes/calo';
import { RoutePaths } from '../../libs/enums';
import { User } from '../../libs/interfaces';
import { useDocumentMedia } from '../../libs/utils/document';
import { getDate, getMaxDate, getMinDate, setKitchenToLocalStorage } from '../../libs/utils/helperFunctions';
import DatePicker from '../DatePicker';
import NotificationSidebar from '../NotificationSidebar/NotificationSidebar';

const drawerWidth = 260;

interface SidebarProps {
	isOpen: boolean;
	date: string;
	setDate: (date: string) => void;
	kitchen: string;
	setKitchen: (kitchen: string) => void;
	user: User;
	notificationReq: {
		lockTimeNotificationReq: { cxRequest: number; customerRequest: number };
		logisticsNotificationReq: { cxRequest: number; customerRequest: number };
		shortagesNotificationReq: number;
	};
	setIsOpen: (value: boolean) => void;
	signOut: ((data?: any | undefined) => void) | undefined;
}

const Sidebar = ({ signOut, isOpen, date, setDate, kitchen, setKitchen, user, notificationReq, setIsOpen }: SidebarProps) => {
	const { isMobile } = useDocumentMedia();
	const location = useLocation();
	const userPermissions = user?.permissions as KDSPermission[];
	const userKitchenList = user?.kitchen;

	const handleKitchenChange = (event: SelectChangeEvent) => {
		setKitchenToLocalStorage(event.target.value);
		setKitchen(event.target.value);
	};

	useEffect(() => {
		if (userKitchenList) {
			setKitchenToLocalStorage(userKitchenList[0]);
			setKitchen(userKitchenList[0]);
		}
	}, []);

	const list = useMemo(() => {
		const allRoutes = [
			{
				title: 'Components Check',
				route: RoutePaths.componentsCheck,
				permission: KDSPermission.VIEW_COMPONENTS_CHECK,
				isActive: matchPath(location.pathname, RoutePaths.componentsCheck)
			},
			{
				title: 'Meals Status',
				route: RoutePaths.mealsStatus,
				permission: KDSPermission.VIEW_MEALS_STATUS,
				isActive: matchPath(location.pathname, RoutePaths.mealsStatus)
			},
			{
				title: 'Assembly Check',
				route: RoutePaths.assemblyCheck,
				permission: KDSPermission.VIEW_ASSEMBLY_CHECK,
				isActive: matchPath(location.pathname, RoutePaths.assemblyCheck)
			},
			{
				title: 'Portioning Display',
				route: RoutePaths.portioningDisplay,
				permission: KDSPermission.VIEW_PORTIONING_DISPLAY,
				isActive: matchPath(location.pathname, RoutePaths.portioningDisplay)
			},
			{
				title: 'Wastage Tracking',
				route: RoutePaths.wastageTracking,
				permission: KDSPermission.VIEW_WASTAGE_TRACKING,
				isActive: matchPath(location.pathname, RoutePaths.wastageTracking)
			},
			{
				title: 'Quality Tracking',
				route: RoutePaths.qualityTracking,
				permission: KDSPermission.VIEW_QUALITY_TRACKING,
				isActive: matchPath(location.pathname, RoutePaths.qualityTracking)
			},
			{
				title: 'Shortage Reporting',
				route: RoutePaths.shortageReporting,
				permission: KDSPermission.VIEW_SHORTAGE_REPORTING,
				isActive: matchPath(location.pathname, RoutePaths.shortageReporting)
			},
			{
				title: 'Shortage Display',
				route: RoutePaths.shortageDisplay,
				permission: KDSPermission.VIEW_SHORTAGE_DISPLAY,
				isActive: matchPath(location.pathname, RoutePaths.shortageDisplay)
			},
			{
				title: 'Wastage Archive',
				route: RoutePaths.wastageArchive,
				permission: KDSPermission.VIEW_WASTAGE_ARCHIVE,
				isActive: matchPath(location.pathname, RoutePaths.wastageArchive)
			},
			{
				title: 'Pre-Dispatch Check',
				route: RoutePaths.preDispatchCheck,
				permission: KDSPermission.VIEW_PRE_DISPATCH_CHECK,
				isActive: matchPath(location.pathname, RoutePaths.preDispatchCheck)
			},
			{
				title: 'Pre-Dispatch Archive',
				route: RoutePaths.preDispatchArchive,
				permission: KDSPermission.VIEW_PRE_DISPATCH_ARCHIVE,
				isActive: matchPath(location.pathname, RoutePaths.preDispatchArchive)
			},
			{
				title: 'Recipe GuideBook',
				route: RoutePaths.recipeGuideBook,
				permission: KDSPermission.VIEW_RECIPE_GUIDEBOOK,
				isActive: matchPath(location.pathname, RoutePaths.recipeGuideBook)
			},
			{
				title: 'Driver Dispatch',
				route: RoutePaths.driverDispatch,
				permission: KDSPermission.VIEW_DRIVER_DISPATCH,
				isActive: matchPath(location.pathname, RoutePaths.driverDispatch)
			},
			{
				title: 'Lock Time Changes',
				route: RoutePaths.lockTimeChanges,
				permission: KDSPermission.VIEW_LOCK_TIME_CHANGES,
				isActive: matchPath(location.pathname, RoutePaths.lockTimeChanges)
			},
			{
				title: 'Logistics Changes',
				route: RoutePaths.logisticsChanges,
				permission: KDSPermission.VIEW_LOGISTICS_CHANGES,
				isActive: matchPath(location.pathname, RoutePaths.logisticsChanges)
			},
			{
				title: 'Complaints',
				route: RoutePaths.customerComplaints,
				permission: KDSPermission.VIEW_CUSTOMER_COMPLAINTS,
				isActive: matchPath(location.pathname, RoutePaths.customerComplaints)
			},
			{
				title: 'File Management',
				route: RoutePaths.fileManagement,
				permission: KDSPermission.VIEW_FILE_MANAGEMENT,
				isActive: matchPath(location.pathname, RoutePaths.fileManagement)
			},
			{
				title: 'Special Request',
				route: RoutePaths.specialRequest,
				permission: KDSPermission.VIEW_SPECIAL_REQUEST,
				isActive: matchPath(location.pathname, RoutePaths.specialRequest)
			},
			{
				title: 'Quality Metrics',
				route: RoutePaths.qualityMetrics,
				permission: KDSPermission.VIEW_QUALITY_METRICS,
				isActive: matchPath(location.pathname, RoutePaths.qualityMetrics)
			},
			{
				title: 'Productivity Metrics',
				route: RoutePaths.productivityMetrics,
				permission: KDSPermission.VIEW_PRODUCTIVITY_METRICS,
				isActive: matchPath(location.pathname, RoutePaths.productivityMetrics)
			}
		];

		return allRoutes.filter((route) => userPermissions.includes(route.permission));
	}, [location.pathname, userPermissions]);

	const DrawerHeader = styled('div')(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: 18,
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'center'
	}));

	return (
		<Drawer
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: drawerWidth,
					boxSizing: 'border-box'
				}
			}}
			variant={isMobile ? 'temporary' : 'persistent'}
			anchor="left"
			open={isOpen}
			onClose={() => setIsOpen(!isOpen)}
		>
			<DrawerHeader>
				<p>KDS</p>
			</DrawerHeader>
			<Divider />
			<List sx={{ width: '100%', maxWidth: 260, flexGrow: 1 }} component="nav">
				{list.map((route, index) => (
					<Link className="link" key={index} to={route.route}>
						<ListItemText
							sx={{
								m: 0,
								py: 2,
								pr: '2px',
								pl: '12px',
								...(route.isActive ? { backgroundColor: '#E6F3EC', color: 'black' } : {})
							}}
						>
							<Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', justifyContent: 'space-between' }}>
								<Typography sx={{ width: '80%', fontFamily: caloTheme.typography.fontFamily }}>{route.title}</Typography>
								{(route.title === 'Lock Time Changes' || route.title === 'Logistics Changes') && (
									<Box display={'flex'} flexDirection={'row'} sx={{ justifyContent: 'end', ml: '1px', mt: '-4px' }}>
										{route.title === 'Lock Time Changes' ? (
											<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
												<NotificationSidebar
													requestLabel="customer"
													requestNumber={notificationReq.lockTimeNotificationReq.customerRequest}
												/>
												<NotificationSidebar
													requestLabel="cx"
													requestNumber={notificationReq.lockTimeNotificationReq.cxRequest}
												/>
											</Stack>
										) : (
											<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
												<NotificationSidebar
													requestLabel="customer"
													requestNumber={notificationReq.logisticsNotificationReq.customerRequest}
												/>
												<NotificationSidebar
													requestLabel="cx"
													requestNumber={notificationReq.logisticsNotificationReq.cxRequest}
												/>
											</Stack>
										)}
									</Box>
								)}
								{route.title === 'Shortage Display' && (
									<Box display={'flex'} flexDirection={'row'} sx={{ justifyContent: 'end', ml: '1px', mt: '-4px' }}>
										<NotificationSidebar requestLabel="alert" requestNumber={notificationReq.shortagesNotificationReq} />
									</Box>
								)}
							</Stack>
						</ListItemText>
					</Link>
				))}
			</List>
			<Divider sx={{ pb: 5 }} />
			<Stack
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				sx={{ p: 2, pb: 0, cursor: 'pointer' }}
				onClick={signOut}
			>
				<Typography sx={{ ml: 1 }}>Logout</Typography>
				<LogoutRoundedIcon color="error" className="logoutIcon" />
			</Stack>
			<Box sx={{ p: 2, pb: 0 }}>
				<FormControl sx={{ width: '100%' }}>
					<Select value={kitchen} onChange={handleKitchenChange} inputProps={{ 'aria-label': 'Without label' }}>
						{userKitchenList.map((value) => (
							<MenuItem key={value} value={value}>
								{value} Kitchen
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box sx={{ p: 2, pb: 0 }}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						date={new Date(date)}
						minDate={getMinDate()}
						maxDate={getMaxDate()}
						label="Date"
						setDate={(date: Date | null) => {
							if (date) {
								setDate(getDate(date));
							}
						}}
						displayKeyboardView={true}
					/>
				</LocalizationProvider>
			</Box>
		</Drawer>
	);
};

export default Sidebar;
