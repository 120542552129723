import { differenceInSeconds, format as formatTime, intervalToDuration, parse } from 'date-fns';
import { addDays, format, subDays } from 'date-fns/fp';
import { omit, orderBy } from 'lodash-es';

import { CountryService } from '@calo/services';
import { Country, Dictionary, Kitchen } from '@calo/types';
import { Theme } from '@mui/material/styles';

import {
	ComplaintReason,
	FCStatus,
	FoodComponentStation,
	FoodPortioningAction,
	FoodPortioningFoodType,
	FoodPortioningStatus,
	FoodType,
	Page,
	PreDispatchCheckHeader,
	Shift
} from '../enums';
import {
	FilterSizeState,
	FilterTimeState,
	FilterTypeState,
	KDSBaggingDispatch,
	KDSChefUser,
	KDSDeliveryData,
	KDSFoodComponent,
	KDSFoodComponentStation,
	PausedInterval,
	PortioningStatus,
	PortioningStatusAttributes,
	Session
} from '../interfaces';

export const getTableAttributes = (header: string, theme: Theme) => {
	switch (header) {
		case 'breakfast':
			return {
				name: PreDispatchCheckHeader.breakfast,
				color: theme.palette.secondaryYellow100,
				condition: FoodType.breakfast
			};
		case 'lunchAndDinner':
			return {
				name: PreDispatchCheckHeader.lunchAndDinner,
				color: theme.palette.primary100,
				condition: FoodType.lunch,
				condition2: FoodType.dinner
			};
		case 'snack':
			return {
				name: PreDispatchCheckHeader.snack,
				color: theme.palette.secondaryBlue100,
				condition: FoodType.snack
			};
		case 'addons':
			return {
				name: PreDispatchCheckHeader.addons,
				color: theme.palette.secondaryPurple100,
				condition: FoodType.addons
			};
		case 'dessert':
			return {
				name: PreDispatchCheckHeader.dessert,
				color: theme.palette.secondaryPink100,
				condition: FoodType.dessert
			};
		case 'caloKids':
			return {
				name: PreDispatchCheckHeader.caloKids,
				color: theme.palette.secondaryOrange100,
				condition: FoodType.caloKids
			};
		case 'commissary':
			return { name: FoodComponentStation.commissary, color: theme.palette.primary100 };
		case 'butchery':
			return { name: FoodComponentStation.butchery, color: theme.palette.secondaryPurple100 };
		case 'hot':
			return { name: FoodComponentStation.hot, color: theme.palette.secondaryYellow100 };
		case 'bakeryAndPastry':
			return { name: FoodComponentStation.bakeryAndPastry, color: theme.palette.secondaryBlue200 };
		case 'saucier':
			return { name: FoodComponentStation.saucier, color: theme.palette.primary100 };
		case 'roasting':
			return { name: FoodComponentStation.roasting, color: theme.palette.secondaryRed50 };
		case 'gardeManger':
			return { name: FoodComponentStation.gardeManger, color: theme.palette.secondaryPink50 };
		case 'portioning':
			return { name: FoodComponentStation.portioning, color: theme.palette.secondaryTeal50 };
		case 'merchandise':
			return { name: FoodComponentStation.merchandise, color: theme.palette.secondaryOrange50 };
		case 'other':
			return { name: FoodComponentStation.other, color: theme.palette.secondaryBlue200 };
		default:
			return { name: FoodComponentStation.other, color: theme.palette.secondaryBlue200 };
	}
};

export const getKitchenFromLocalStorage = (): string =>
	localStorage.getItem('Kitchen') ? String(localStorage.getItem('Kitchen')) : 'BH001';

export const setKitchenToLocalStorage = (kitchen: string): void => localStorage.setItem('Kitchen', kitchen);

export const setShiftToLocalStorage = (shift: string, page: Page, kitchen: string) =>
	localStorage.setItem(`Shift#${page}#${kitchen}`, shift);

export const getShiftFromLocalStorage = (page: Page, kitchen: string): string =>
	localStorage.getItem(`Shift#${page}#${kitchen}`) || 'morning';

export const handleShiftForKitchen = (
	page: Page,
	kitchen: string,
	shift: Session,
	handleShiftChange: (shift: string) => void
): void => {
	const shiftFromLocalStorage = getShiftFromLocalStorage(page, kitchen);
	const morningEveningShifts = [Session.morning, Session.evening];
	if (shift === Session.all && shiftFromLocalStorage !== Session.all) {
		handleShiftChange(Session.all);
	} else if (morningEveningShifts.includes(shift) && !morningEveningShifts.includes(shiftFromLocalStorage as Session)) {
		handleShiftChange(Session.morning);
	} else {
		handleShiftChange(shiftFromLocalStorage);
	}
};

export const getIconColors = (header: string, theme: Theme) => {
	switch (header) {
		case 'breakfast':
			return {
				background: theme.palette.secondaryYellow100,
				color: theme.palette.secondaryYellow500,
				backgroundS: theme.palette.secondaryYellow50
			};
		case 'lunchAndDinner':
			return {
				background: theme.palette.primary100,
				color: theme.palette.primary500,
				backgroundS: theme.palette.primary50
			};
		case 'snack':
			return {
				background: theme.palette.secondaryBlue100,
				color: theme.palette.secondaryBlue500,
				backgroundS: theme.palette.secondaryBlue50
			};
		case 'addons':
			return {
				background: theme.palette.secondaryPurple100,
				color: theme.palette.secondaryPurple500,
				backgroundS: theme.palette.secondaryPurple50
			};
		case 'dessert':
			return {
				background: theme.palette.secondaryPink100,
				color: theme.palette.secondaryPink500,
				backgroundS: theme.palette.secondaryPink50
			};
		case 'caloKids':
			return {
				background: theme.palette.secondaryOrange100,
				color: theme.palette.secondaryOrange500,
				backgroundS: theme.palette.secondaryOrange50
			};
		default:
			return {
				background: theme.palette.primary100,
				color: theme.palette.primary500,
				backgroundS: theme.palette.primary50
			};
	}
};
export const getTimeNow = () => format('kk:mm:ss')(Date.now());
export const getMinDate = () => subDays(1)(Date.now());
export const getMaxDate = () => addDays(3)(Date.now());
export const getActiveWorkingDayDate = () => addDays(1)(Date.now());
export const getDate = (date: Date) => format('yyyy-MM-dd')(new Date(date));
export const formatDate = (date: Date | string, dateFormat: string) => format(dateFormat)(new Date(date));
export const getTime = (date: string) => format('kk:mm')(Date.parse(date));
export const getShift = (shift: string) => {
	switch (shift) {
		case 'all':
			return Shift.all;
		case 'morning':
			return Shift.morning;
		case 'evening':
			return Shift.evening;
		default:
			return Shift.all;
	}
};
export const zeroPad = (num: number | undefined) => (num ? String(num).padStart(2, '0') : '00');

export const getFormattedTimeFromSeconds = (seconds: number) => {
	const { hours, minutes, seconds: remainingSeconds } = intervalToDuration({ start: 0, end: seconds * 1000 });

	return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(remainingSeconds)}`;
};

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = [];
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.codePointAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers as Array<number>);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });

	return blob;
};

export const createAutoDownloadLinkFromFile = (file: string, contentType: string, extension: string, exportFileName: string) => {
	const blob = b64toBlob(file, contentType);
	const blobUrl = URL.createObjectURL(blob);
	createAutoDownloadLinkFromUrl(blobUrl, extension, exportFileName);
};

export const SetShiftToLocalStorage = (shift: string, page: string) => localStorage.setItem(`Shift#${page}`, shift);

export const GetShiftFromLocalStorage = (page: string): string => localStorage.getItem(`Shift#${page}`) || 'morning';
export const getTimeZone = (kitchen: Kitchen) => {
	switch (kitchen) {
		case Kitchen.BH1:
			return 'Asia/Bahrain';
		case Kitchen.SA1:
			return 'Asia/Riyadh';
		case Kitchen.AE1:
			return 'Asia/Dubai';
		case Kitchen.KW1:
			return 'Asia/Kuwait';
		case Kitchen.QA1:
			return 'Asia/Qatar';
		case Kitchen.GB1:
			return 'Europe/London';
		default:
			return 'Asia/Bahrain';
	}
};

export const getCountry = (kitchen: Kitchen) => {
	switch (kitchen) {
		case Kitchen.BH1:
			return Country.BH;
		case Kitchen.SA1:
		case Kitchen.SA2:
			return Country.SA;
		case Kitchen.AE1:
			return Country.AE;
		case Kitchen.KW1:
			return Country.KW;
		case Kitchen.QA1:
			return Country.QA;
		case Kitchen.GB1:
			return Country.GB;
		default:
			return Country.BH;
	}
};

export const getTimeInTimeZone = (date: string, kitchen: Kitchen) => {
	const localDate = new Date(date).toLocaleString('en-US', { timeZone: getTimeZone(kitchen) });
	return format('kk:mm')(Date.parse(localDate));
};

export const getTimeNowInTimeZone = (kitchen: Kitchen) => getTimeInTimeZone(new Date().toISOString(), kitchen);

export const getFoodPortioningStatusAttributes = (
	portioningStatus: PortioningStatus,
	foodType: FoodPortioningFoodType,
	page: string,
	theme: Theme
): PortioningStatusAttributes => {
	switch (foodType) {
		case FoodPortioningFoodType.completedFood: {
			let time: string | undefined;
			if (page === Page.assemblyCheck) {
				time = portioningStatus.assemblingTime?.intervals
					? portioningStatus.assemblingTime.intervals[portioningStatus.assemblingTime?.intervals.length - 1].resumedAt
					: portioningStatus.readyForPortioningTime;
			} else {
				time = portioningStatus.endTime;
			}
			return {
				status: FoodPortioningStatus.completed,
				intervals:
					page === Page.assemblyCheck ? portioningStatus.assemblingTime?.intervals : portioningStatus.portioningTime?.intervals,
				time: time,
				buttonText: 'Finished',
				color: theme.palette.primary500,
				backgroundColor: theme.palette.white,
				isDisabled: true
			};
		}
		case FoodPortioningFoodType.startAssemblingFood: {
			return {
				status: FoodPortioningStatus.readyToAssemble,
				buttonText: page === Page.assemblyCheck ? FoodPortioningAction.startAssembling : FoodPortioningAction.pending,
				color: page === Page.assemblyCheck ? theme.palette.white : theme.palette.secondaryYellow500,
				backgroundColor: theme.palette.secondaryYellow500,
				isDisabled: page === Page.assemblyCheck ? false : true
			};
		}
		case FoodPortioningFoodType.pauseAssemblingFood: {
			return {
				status: page === Page.assemblyCheck ? FoodPortioningStatus.pauseAssembling : FoodPortioningStatus.Assembling,
				time: page === Page.assemblyCheck ? portioningStatus.assemblingTime?.startedAt : undefined,
				intervals: portioningStatus.assemblingTime?.intervals,
				buttonText: page === Page.assemblyCheck ? FoodPortioningAction.resumeAssembling : FoodPortioningAction.pending,
				color: page === Page.assemblyCheck ? theme.palette.white : theme.palette.secondaryYellow500,
				backgroundColor: theme.palette.secondaryYellow500,
				isDisabled: page === Page.assemblyCheck ? false : true
			};
		}
		case FoodPortioningFoodType.resumeAssemblingFood: {
			return {
				status: page === Page.assemblyCheck ? FoodPortioningStatus.resumeAssembling : FoodPortioningStatus.Assembling,
				time: page === Page.assemblyCheck ? portioningStatus.assemblingTime?.startedAt : undefined,
				intervals: portioningStatus.assemblingTime?.intervals,
				buttonText: page === Page.assemblyCheck ? FoodPortioningStatus.pauseAssembling : FoodPortioningAction.pending,
				color: page === Page.assemblyCheck ? theme.palette.white : theme.palette.secondaryYellow500,
				backgroundColor: theme.palette.secondaryYellow500,
				isDisabled: page === Page.assemblyCheck ? false : true
			};
		}

		case FoodPortioningFoodType.startPortioningFood: {
			return {
				status: FoodPortioningStatus.readyToPortion,
				time: portioningStatus.portioningTime?.startedAt,
				buttonText: page === Page.assemblyCheck ? FoodPortioningAction.completed : FoodPortioningAction.startPortioning,
				color: page === Page.assemblyCheck ? theme.palette.secondaryBlue500 : theme.palette.white,
				backgroundColor: page === Page.assemblyCheck ? theme.palette.white : theme.palette.primary500,
				isDisabled: page === Page.assemblyCheck ? true : false
			};
		}
		case FoodPortioningFoodType.pausePortioningFood: {
			return {
				status: FoodPortioningStatus.pausePortioning,
				time: portioningStatus.portioningTime?.startedAt,
				intervals: portioningStatus.portioningTime?.intervals,
				buttonText: page === Page.assemblyCheck ? FoodPortioningAction.pending : FoodPortioningAction.resumePortioning,
				color: theme.palette.secondaryBlue500,
				backgroundColor: theme.palette.secondaryBlue100,
				isDisabled: page === Page.assemblyCheck ? true : false
			};
		}
		case FoodPortioningFoodType.resumePortioningFood: {
			return {
				status: FoodPortioningStatus.resumePortioning,
				time: portioningStatus.portioningTime?.startedAt,
				intervals: portioningStatus.portioningTime?.intervals,
				buttonText: page === Page.assemblyCheck ? FoodPortioningAction.pending : FoodPortioningAction.pausePortioning,
				color: theme.palette.secondaryBlue500,
				backgroundColor: theme.palette.secondaryBlue100,
				isDisabled: page === Page.assemblyCheck ? true : false
			};
		}
		case FoodPortioningFoodType.notReadyFood: {
			return {
				status: FoodPortioningStatus.notReadyYet,
				buttonText: FoodPortioningAction.pending,
				color: theme.palette.neutral600,
				backgroundColor: theme.palette.white,
				isDisabled: true
			};
		}
		default: {
			return {
				status: FoodPortioningStatus.notReadyYet,
				buttonText: FoodPortioningAction.pending,
				color: theme.palette.neutral600,
				backgroundColor: theme.palette.white,
				isDisabled: true
			};
		}
	}
};

export const getSecondsBetweenIntervals = (intervals: PausedInterval[]) => {
	const totalSeconds = intervals.reduce((total, interval) => {
		if (interval.resumedAt) {
			return total + differenceInSeconds(new Date(interval.resumedAt), new Date(interval.pausedAt));
		}
		return total;
	}, 0);
	return totalSeconds;
};

export const checkIfDatesMatch = (date1: string, date2: string) => getDate(new Date(date1)) === getDate(new Date(date2));

export const getTimeAndDateInTimeZone = (date: string, kitchen: Kitchen) => {
	const localDate = new Date(date).toLocaleString('en-US', { timeZone: getTimeZone(kitchen) });
	return format('kk:mm - dd/MM/yyyy')(Date.parse(localDate));
};

export const createAutoDownloadLinkFromUrl = (url: string, extension?: string, exportFileName?: string) => {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.href = url;
	a.target = '_blank';
	if (extension && exportFileName) {
		a.download = `${exportFileName}.${extension}`;
	}
	a.click();
	a.remove();
	window.URL.revokeObjectURL(url);
};

export const createAutoDownloadLinkFromArrayOfUrls = (urls: string[]) => {
	for (const url of urls) {
		createAutoDownloadLinkFromUrl(url);
	}
};

export const removeTypename = (obj: any): any => {
	if (Array.isArray(obj)) {
		return obj.map((item) => removeTypename(item));
	} else if (typeof obj === 'object' && obj !== null) {
		const newObj = { ...obj };
		delete newObj.__typename;
		for (const key of Object.keys(newObj)) {
			newObj[key] = removeTypename(newObj[key]);
		}
		return newObj;
	} else {
		return obj;
	}
};

export const getFoodComponentStatusAttributes = (
	cookingStations: KDSFoodComponentStation[],
	selectedComponentStation: string
) => {
	const lastUncheckedIndex = cookingStations.findIndex((station) => station.status === FCStatus.pending);
	const isFound = lastUncheckedIndex !== -1;
	const lastUncheckedStation = cookingStations[lastUncheckedIndex];
	const lastStation = cookingStations[cookingStations.length - 1];
	const currentStationIndex = cookingStations.findIndex((station) => station.name === selectedComponentStation);
	const currentStation = cookingStations[currentStationIndex];

	if (isFound && lastUncheckedStation.name !== selectedComponentStation && currentStation.status === FCStatus.completed) {
		return { isCheckable: true, lastUncheckedStation: 'Completed' };
	} else if (isFound && currentStation.status === FCStatus.pending && lastUncheckedStation === currentStation) {
		return { isCheckable: true, lastUncheckedStation: lastUncheckedStation.name };
	} else if (!isFound && lastStation?.name === selectedComponentStation) {
		return { isCheckable: true, lastUncheckedStation: 'Completed' }; //if all checked allow unchecking last one
	} else if (!isFound && lastStation?.status === FCStatus.completed && lastStation.name !== selectedComponentStation) {
		return { isCheckable: false, lastUncheckedStation: 'Completed' }; //if all checked don't allow unchecking any other station except the last
	} else {
		return { isCheckable: false, lastUncheckedStation: lastUncheckedStation?.name };
	}
};

export const KitchenShortCut = (kitchen: Kitchen | string) => {
	switch (kitchen) {
		case Kitchen.BH1:
			return 'BH1';
		case Kitchen.SA1:
			return 'SA1';
		case Kitchen.SA2:
			return 'SA2';
		case Kitchen.AE1:
			return 'AE1';
		case Kitchen.KW1:
			return 'KW1';
		case Kitchen.QA1:
			return 'QA1';
		case Kitchen.GB1:
			return 'GB1';
		default:
			return 'BH1';
	}
};

export const getFoodComponentsSortedByNameLengthAndLastCookingStationStatus = (foodComponents: KDSFoodComponent[]) =>
	[...foodComponents]
		.sort((a, b) => {
			if (a.name.en.length > b.name.en.length) {
				return 1;
			} else if (a.name.en.length < b.name.en.length) {
				return -1;
			} else {
				return 0;
			}
		})
		.sort((a, b) => {
			if (
				a.cookingStations[a.cookingStations.length - 1].status === 'pending' &&
				b.cookingStations[b.cookingStations.length - 1].status === 'completed'
			) {
				return -1;
			} else if (
				a.cookingStations[a.cookingStations.length - 1].status === 'completed' &&
				b.cookingStations[b.cookingStations.length - 1].status === 'pending'
			) {
				return 1;
			} else {
				return 0;
			}
		});

export const decodeHtmlSymbols = (str: string): string => {
	const symbols = [
		['amp', '&'],
		['apos', "'"],
		['lt', '<'],
		['gt', '>'],
		['quot', '"'],
		['#39', "'"],
		['#x2F', '/']
	];

	for (const [symbol, char] of symbols) {
		const regex = new RegExp(`&${symbol};`, 'g');
		str = str.replace(regex, char);
	}

	return str;
};

export const handleMealSize = (size: string) => {
	switch (size) {
		case 'L':
			return 'Large';
		case 'S':
			return 'Small';
		case 'M':
			return 'Medium';
		default:
			return size;
	}
};

export const isHoursMinFormat = (input: string) => /^([01]\d|2[0-3]):([0-5]\d)$/.test(input);

export const getTimeAndDateNowInTimeZone = (kitchen: Kitchen) => getTimeAndDateInTimeZone(new Date().toISOString(), kitchen);

export const makeCustomMealRepresentative = (dispatches: KDSBaggingDispatch[]) => {
	//order them by so we always get the same food that will represent rest
	const orderedDispatches = orderBy(dispatches, 'id');
	let total = 0;
	let actual = 0;
	let extra = 0;
	let modified = 0;
	let leftovers = 0;
	for (const element of orderedDispatches) {
		total += element.total;
		actual += element.actual;
		extra += element.extra;
		modified += element.modified;
		leftovers += element.leftovers;
	}
	const representative: KDSBaggingDispatch = {
		...orderedDispatches[0],
		foodName: {
			en: 'Custom Meals',
			ar: 'Custom Meals'
		},
		total,
		actual,
		extra,
		modified,
		leftovers
	};
	return representative;
};

export const roundToTwoDecimals = (numb: number) => Math.round(numb * 100) / 100;

export const getPercentage = (value: number) => {
	return `${roundToTwoDecimals(value * 100)}%`;
};

export const getCurrencyFromKitchen = (kitchen: Kitchen) => {
	const country = CountryService.getCountryForKitchen(kitchen);
	return CountryService.resolveCurrency(country);
};

export const areChefsEqual = (chef1: KDSChefUser, chef2: KDSChefUser) => {
	if (chef1.name !== chef2.name || chef1.session !== chef1.session) {
		return false;
	}
	if (chef1.stations.length !== chef2.stations.length) {
		return false;
	}

	return !chef1.stations.some((station) => !chef2.stations.includes(station));
};

export const getComplaintsOptoins = () => ({
	[ComplaintReason.HEALTH_RELATED_CONCERNS_COMPLAINTS]: [
		{ value: 'FOOD_POISONING', label: 'Food poisoning' },
		{ value: 'STOMACH_ACHE', label: 'Stomach ache' },
		{ value: 'FEELING_SICK', label: 'Feeling sick' },
		{ value: 'OTHER', label: 'Other' }
	],
	[ComplaintReason.COLD_SECTION_RELATED_ERRORS]: [
		{ value: 'WRONG_MEAL', label: 'Wrong meal' },
		{ value: 'PRESENTATION_OF_THE_MEAL', label: 'Presentation of the meal' },
		{ value: 'MISSING_COMPONENT', label: 'Missing component' },
		{ value: 'WRONG_PORTION', label: 'Wrong portion' },
		{ value: 'WRONG_MEAL_PREPARED', label: 'Wrong meal prepared' },
		{ value: 'WRONG_COMPONENT', label: 'Wrong component' },
		{ value: 'TORN_TORTILLA', label: 'Torn tortilla' },
		{ value: 'TORN_WRAPPING_PAPER', label: 'Torn wrapping paper' },
		{
			value: 'WRAPPING_PAPER_INSIDE_THE_SANDWICH',
			label: 'Wrapping paper inside the sandwich'
		},
		{ value: 'OTHER', label: 'Other' }
	],
	[ComplaintReason.SPILLED_LIQUIDS_OR_FOOD_COMPLAINTS]: [
		{ value: 'SPILLED_SOUP', label: 'Spilled Soup' },
		{ value: 'SPILLED_FOOD', label: 'Spilled Food' },
		{ value: 'SPILLED_SAUCE', label: 'Spilled Sauce' },
		{ value: 'SPILLED_COFFEE', label: 'Spilled coffee' },
		{ value: 'OTHER', label: 'Other' }
	],
	[ComplaintReason.DISPATCH_RELATED_ERRORS]: [
		{ value: 'MISSING_SAUCE', label: 'Missing sauce' },
		{ value: 'WRONG_PORTION', label: 'Wrong portion' },
		{ value: 'WRONG_MEAL_SNACK', label: 'Wrong meal/snack' },
		{ value: 'MISSING_LABEL', label: 'Missing label' },
		{ value: 'SPILLED_FOOD', label: 'Spilled food' },
		{ value: 'MISSING_MEAL_SNACK', label: 'Missing meal/snack' },
		{ value: 'MISSING_COMPONENTS', label: 'Missing components' },
		{ value: 'OPENED_CONTAINER', label: 'Opened container' },
		{ value: 'MISSING_CUTLERY', label: 'Missing cutlery' },
		{ value: 'CANCELLED', label: 'Cancelled' },
		{ value: 'WRONG_ADD-ON', label: 'Wrong add-on' },
		{ value: 'MISSING_ADD-ON', label: 'Missing add-on' },
		{ value: 'WRONG_LABEL', label: 'Wrong label' },
		{ value: 'EXTRA_MEAL_SNACK', label: 'Extra meal/snack' },
		{ value: 'PRESENTATION_OF_THE_MEAL', label: 'Presentation of the meal' },
		{ value: 'WRONG_SAUCE', label: 'Wrong sauce' },
		{ value: 'WRONG_COMPONENT', label: 'Wrong component' },
		{ value: 'OPENED_CUTLERY_SET', label: 'Opened cutlery set' },
		{ value: 'WRONG_CARD', label: 'Wrong card' },
		{ value: 'WRONG_SAUCE_PORTION', label: 'Wrong sauce portion' },
		{ value: 'DAMAGED_CONTAINER', label: 'Damaged container' },
		{ value: 'PRINTING_ERROR', label: 'Printing error' },
		{ value: 'OPENED_BAG', label: 'Opened bag' },
		{ value: 'EMPTY_LABEL', label: 'Empty label' },
		{
			value: 'COMPONENT_WAS_NOT_REMOVED',
			label: 'Component was not removed'
		},
		{
			value: 'WRONG_COMPONENT_WAS_REMOVED',
			label: 'Wrong component was removed'
		},
		{ value: 'OTHER', label: 'Other' }
	],
	[ComplaintReason.QUALITY_RELATED_COMPLAINTS]: [
		{ value: 'SMELL', label: 'Smell' },
		{ value: 'TEXTURE', label: 'Texture' },
		{ value: 'UNDERCOOKED', label: 'Undercooked' },
		{ value: 'OVERCOOKED', label: 'Overcooked' },
		{ value: 'SALTY', label: 'Salty' },
		{ value: 'NOT_CLEAN', label: 'Not clean' },
		{ value: 'COLOR', label: 'Color' },
		{ value: 'ROTTEN', label: 'Rotten' },
		{ value: 'WILTED', label: 'Wilted' },
		{ value: 'TASTE', label: 'Taste' },
		{ value: 'QUALITY', label: 'Quality' },
		{ value: 'MOLD', label: 'Mold' },
		{ value: 'RANCID', label: 'Rancid' },
		{ value: 'TORN_TORTILLA', label: 'Torn tortilla' },
		{ value: 'SMELL_OF_THE_BAG', label: 'Smell of the bag' },
		{ value: 'WATERY', label: 'Watery' },
		{ value: 'MEAL_LUMPS', label: 'Meal lumps' },
		{ value: 'FUNGUS', label: 'Fungus' },
		{ value: 'PRESENTATION_OF_THE_MEAL', label: 'Presentation of the meal' },
		{
			value: 'CHEMICAL_SMELL_AND_INSECTICIDE_TASTE',
			label: 'Chemical smell & Insecticide taste'
		},
		{ value: 'SMELL_OF_THE_CHICKEN', label: 'Smell of the chicken' },
		{ value: 'CONTAINER_LAMINATION', label: 'Container lamination' },
		{ value: 'DRY', label: 'Dry' },
		{ value: 'UNCLEANED_SHRIMP', label: 'Uncleaned shrimp' },
		{ value: 'GAS_TASTE_AND_SMELL', label: 'Gas taste & smell' },
		{ value: 'UNCUT_COMPONENT', label: 'Uncut component' },
		{ value: 'OTHER', label: 'Other' }
	],
	[ComplaintReason.FOOD_RELATED_FOREIGN_OBJECTS_FOUND]: [
		{ value: 'VEGGIES', label: 'Veggies' },
		{ value: 'PEELS', label: 'Peels' },
		{ value: 'STEM', label: 'Stem' },
		{ value: 'OTHER', label: 'Other' }
	],
	[ComplaintReason.NON_FOOD_RELATED_FOREIGN_OBJECTS_FOUND]: [
		{ value: 'HAIR', label: 'Hair' },
		{ value: 'PLASTIC', label: 'Plastic' },
		{ value: 'SEEDS', label: 'Seeds' },
		{ value: 'PAPER', label: 'Paper' },
		{ value: 'BONE', label: 'Bone' },
		{ value: 'STONE', label: 'Stone' },
		{ value: 'WOOD', label: 'Wood' },
		{ value: 'SHELLS', label: 'Shells' },
		{ value: 'GLASS', label: 'Glass' },
		{ value: 'METAL', label: 'Metal' },
		{ value: 'ALUMINUM', label: 'Aluminum' },
		{ value: 'NAIL', label: 'Nail' },
		{ value: 'CUTLERY_FOREIGN_OBJECT', label: 'Cutlery foreign object' },
		{ value: 'TAPE', label: 'Tape' },
		{ value: 'NYLON', label: 'Nylon' },
		{ value: 'INSECT', label: 'Insect' },
		{ value: 'SAND', label: 'Sand' },
		{ value: 'GLOVE_PIECE', label: 'Glove piece' },
		{ value: 'OLIVE_PIT', label: 'Olive pit' },
		{ value: 'RICE_SACK_THREAD', label: 'Rice sack thread' },
		{ value: 'STYROFOAM', label: 'Styrofoam' },
		{ value: 'OTHER', label: 'Other' }
	]
});

export const getSelectableOptions = () => {
	const options = getComplaintsOptoins();
	const filteredOptions = omit(options, [ComplaintReason.FOREIGN_OBJECTS_FOUND]);

	return filteredOptions;
};

export const getAllComplaintTypesMap = (invert = false) =>
	Object.values(getComplaintsOptoins()).reduce((acc: Dictionary<string>, arr) => {
		for (const { value, label } of arr) {
			if (invert) {
				acc[label] = value;
			} else {
				acc[value] = label;
			}
		}
		return acc;
	}, {});

export const getComplainsOptionsForReason = (reason: any) => {
	const options = getComplaintsOptoins();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore

	return options[ComplaintReason[reason]] || options[reason];
};

export const initialFilterSizeState = (): FilterSizeState => ({
	s: false,
	m: false,
	l: false,
	xl: false,
	xs: false,
	r: false
});

export const initialFilterTypeState = (): FilterTypeState => ({
	lunch: false,
	dinner: false,
	breakfast: false,
	juice: false,
	dessert: false,
	snack: false,
	caloKids: false,
	coffee: false
});

export const initialFilterTimeState = (): FilterTimeState => ({
	morning: false,
	evening: false
});

export const getDriverNameById = (driverId: string, deliveryData: KDSDeliveryData[]) => {
	const delivery = deliveryData.find((singleDelivery) => singleDelivery.driver?.id === driverId);
	return delivery ? delivery.driver.name : '';
};

export const formatLabel = (label: string) => {
	const words = label.replace(/([A-Z])/g, ' $1').split(' ');
	words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
	return words.join(' ');
};

export const isValidURL = (url: string) => {
	try {
		new URL(url);
		return true;
	} catch {
		return false;
	}
};

export const formatDeliveryTime = (time: string | undefined) => {
	if (!time) return '---';
	try {
		const parsedTime = parse(time, 'HH:mm', Date.now());
		return formatTime(parsedTime, 'hh:mm a');
	} catch (error) {
		console.error('Error parsing time:', time, error);
		return `Invalid Time ${time}`;
	}
};
