import { useState } from 'react';

import { MeasurementUnit } from '@calo/dashboard-types';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { QualityRating } from '../../../../libs/enums';
import { KDSFoodComponentBatch, QualityMetricsFoodComponent } from '../../../../libs/interfaces';
import { getCurrencyFromKitchen, getPercentage, roundToTwoDecimals } from '../../../../libs/utils/helperFunctions';
import { renderDotColor } from '../../../QualityTracking/utils';
import BatchDrawer from './BatchDrawer';

const calculateBatchStandardsScore = (batch: KDSFoodComponentBatch): number => {
	const standardsScores: number[] = batch.quality[0]?.standards.map((standard) => {
		switch (standard.rating) {
			case QualityRating.bad:
				return 0;
			case QualityRating.neutral:
				return 0.5;
			case QualityRating.good:
				return 1;
			default:
				return 0;
		}
	});

	const batchScore = standardsScores?.reduce((acc, score) => acc + score, 0) / (standardsScores?.length ?? 1);
	return batchScore;
};

const getBatchWeightPerGram = (batch: KDSFoodComponentBatch, cookedWeight: number) =>
	batch.measurementUnit === MeasurementUnit.piece ? batch.weight * (cookedWeight ?? 1) : batch.weight;

interface props {
	foodComponentBatch: KDSFoodComponentBatch;
	qualityMetricsFoodComponent: QualityMetricsFoodComponent;
}

const SectionTableRowFolded = ({ foodComponentBatch, qualityMetricsFoodComponent }: props) => {
	const [isReviewDrawerOpened, setIsReviewDrawerOpened] = useState(false);
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			color: theme.palette.neutral900,
			fontSize: '16px',
			border: 'none',
			textAlign: 'center',
			width: '15%'
		}
	}));

	return (
		<TableRow>
			<StyledTableCell
				onClick={() => setIsReviewDrawerOpened(true)}
				sx={{
					textAlign: 'left !important',
					cursor: 'pointer',
					fontWeight: 600,
					fontSize: '16px',
					color: caloTheme.palette.hyperlink + ' !important'
				}}
			>
				<CircleIcon sx={{ color: renderDotColor(foodComponentBatch.quality[0]?.status), fontSize: '10px', marginRight: '4px' }} />
				Batch {foodComponentBatch.batchNumber}
			</StyledTableCell>
			<StyledTableCell>{foodComponentBatch.session}</StyledTableCell>
			<StyledTableCell>{foodComponentBatch.chef}</StyledTableCell>
			<StyledTableCell>
				{roundToTwoDecimals(foodComponentBatch.weight)} {foodComponentBatch.measurementUnit}
			</StyledTableCell>
			<StyledTableCell>{getPercentage(calculateBatchStandardsScore(foodComponentBatch) || 0)}</StyledTableCell>
			<StyledTableCell>
				{getCurrencyFromKitchen(foodComponentBatch.kitchen)}{' '}
				{roundToTwoDecimals(
					qualityMetricsFoodComponent.cost * getBatchWeightPerGram(foodComponentBatch, qualityMetricsFoodComponent.cookedWeight)
				)}
			</StyledTableCell>
			<StyledTableCell sx={{ width: '8% !important' }}></StyledTableCell>
			<BatchDrawer
				isReviewDrawerOpened={isReviewDrawerOpened}
				setIsReviewDrawerOpened={setIsReviewDrawerOpened}
				foodComponent={qualityMetricsFoodComponent}
				foodComponentBatch={foodComponentBatch}
			/>
		</TableRow>
	);
};

export default SectionTableRowFolded;
