import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';

import { ExportMetricsType } from '../libs/enums';
import { EXPORT_QUALITY_METRICS } from '../libs/graphQL';
import { Session } from '../libs/interfaces';
import { createAutoDownloadLinkFromFile } from '../libs/utils/helperFunctions';

const useExportQualityMetricsMutation = () => {
	const [exportQualityMetrics, { loading }] = useMutation(EXPORT_QUALITY_METRICS);

	const handleExportQualityMetrics = async ({
		startDate,
		endDate,
		kitchen,
		shift,
		type
	}: {
		startDate: string;
		endDate: string;
		shift?: Session;
		kitchen: string;
		type: ExportMetricsType;
	}) => {
		await exportQualityMetrics({
			variables: { startDate, endDate, kitchen, shift, type },
			onCompleted: (data) => {
				if (data?.exportQualityMetrics.data) {
					createAutoDownloadLinkFromFile(
						data.exportQualityMetrics.data,
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						'xlsx',
						`Quality-Metrics-${kitchen}-from-${startDate}-to-${endDate}-${type}`
					);
				} else {
					toast.error('Something went wrong');
				}
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	return { handleExportQualityMetrics, loading };
};

export default useExportQualityMetricsMutation;
