import { Fragment, useCallback, useRef } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import { FoodComponent } from '../../../libs/interfaces';
import ExportTable from './ExportTable';

interface ExportProps {
	isDownloadDisabled: boolean;
	newCookedWeight: number;
	selectedComponent: FoodComponent | null;
	date: string;
}

const Export = ({ selectedComponent, isDownloadDisabled, newCookedWeight, date }: ExportProps) => {
	const theme = useTheme();
	const tableRef = useRef();

	const onExport = useCallback(async () => {
		if (!selectedComponent) {
			return;
		}

		const originalDisplay = document.body.style.display;
		if (tableRef.current) {
			const htmlTableContent: any = (tableRef.current as any).cloneNode(true);

			htmlTableContent.id = 'clone';
			document.body.style.display = 'none';
			htmlTableContent.style.display = 'block';

			document.documentElement.insertBefore(htmlTableContent, document.body.nextSibling);

			window.addEventListener('afterprint', () => {
				document.body.style.display = originalDisplay;
				document.documentElement.removeChild(htmlTableContent);
				htmlTableContent.style.display = 'none';
			});

			window.print();
		}
	}, [selectedComponent, newCookedWeight]);

	return (
		<>
			<div>
				<Fragment>
					<Grid container direction="row" justifyContent="space-between" alignItems="center">
						<LoadingButton
							loading={false}
							id="export-button"
							aria-controls={'export-button'}
							aria-haspopup="true"
							disableElevation
							onClick={onExport}
							variant="text"
							color="primary"
							sx={{
								textTransform: 'none',
								fontWeight: 600,
								fontSize: '19px',
								m: 2
							}}
							endIcon={
								<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M14 10.5V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V10.5"
										stroke={isDownloadDisabled ? theme.palette.neutral600 : theme.palette.primary500}
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M4.66675 7.16663L8.00008 10.5L11.3334 7.16663"
										stroke={isDownloadDisabled ? theme.palette.neutral600 : theme.palette.primary500}
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M8 10.5V2.5"
										stroke={isDownloadDisabled ? theme.palette.neutral600 : theme.palette.primary500}
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							}
						>
							Print
						</LoadingButton>
					</Grid>
				</Fragment>
			</div>
			{selectedComponent && (
				<ExportTable newCookedWeight={newCookedWeight} selectedComponent={selectedComponent} date={date} ref={tableRef} />
			)}
		</>
	);
};

export default Export;
