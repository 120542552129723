import { useContext, useRef, useState } from 'react';

import { Kitchen } from '@calo/types';
import GroupIcon from '@mui/icons-material/Group';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../../../App';
import ShiftSelector from '../../../../components/ShiftSelector';
import { Session } from '../../../../libs/interfaces';
import CookPopup from '../../Common/CookPopup';
import Export from '../../Common/Export';
import FilterItems from '../../Common/Filter';
import { QualityTrackingContext } from '../../QualityTrackingContext';

const Filter = () => {
	const theme = useTheme();
	const appContext = useContext(AppContext);
	const { date, shifts, kitchen, setShifts, batches, chefs, selectedCheckStatuses, setSelectedCheckStatuses } =
		useContext(QualityTrackingContext);

	const addCookModalRef = useRef<any>();

	const [isFilterOpened, setIsFilterOpened] = useState(false);

	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);

	const handleResultsButtonClick = () => {
		setIsFilterOpened(false);
	};

	const handleShiftChange = (shift: Session) => {
		setShifts(shift);
	};

	const handleCloseModal = () => addCookModalRef.current.close();

	return (
		<Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ width: '100%' }}>
			<Stack direction={'row'} justifyContent="flex-end" alignItems="center" spacing={1} sx={{ mr: 3 }}>
				<Button
					variant="text"
					color={'primary'}
					disabled={appContext.isOffline}
					sx={{
						textTransform: 'none',
						fontWeight: 600,
						fontSize: '19px',
						backgroundColor: 'inherits',
						border: '2px solid ' + theme.palette.white,
						':hover': {
							backgroundColor: 'inherits',
							border: 'inherits'
						}
					}}
					onClick={() => setIsFilterOpened(true)}
					startIcon={
						<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M14.6666 1.5H1.33325L6.66659 7.80667V12.1667L9.33325 13.5V7.80667L14.6666 1.5Z"
								stroke={appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					}
				>
					Filter
				</Button>
				<Export isDownloadDisabled={batches.length === 0} kitchen={kitchen} date={{ gte: date, lte: date }} shift={shifts} />
				<Button
					id="add-cook-button"
					onClick={() => addCookModalRef.current?.open()}
					variant="text"
					startIcon={<GroupIcon />}
					sx={{
						textTransform: 'none',
						fontWeight: 600,
						mr: 1,
						fontSize: '19px',
						color: 'black'
					}}
				>
					Cooks
				</Button>

				<ShiftSelector
					kitchen={kitchen}
					shift={shifts}
					allSessions={false}
					key={'Quality-Table'}
					handleShiftChange={handleShiftChange}
					isShiftMenuOpened={isShiftMenuOpened}
					shiftMenuAnchorEl={shiftMenuAnchorEl}
					setShiftMenuAnchorEl={setShiftMenuAnchorEl}
				/>
			</Stack>
			<Drawer anchor={'right'} open={isFilterOpened} onClose={() => setIsFilterOpened(false)}>
				<FilterItems
					toggleFilterDrawer={setIsFilterOpened}
					handleResultsButtonClick={handleResultsButtonClick}
					selectedCheckStatuses={selectedCheckStatuses}
					setSelectedCheckStatuses={setSelectedCheckStatuses}
				/>
			</Drawer>
			<CookPopup kitchen={kitchen as Kitchen} chefs={chefs} ref={addCookModalRef} closeModal={handleCloseModal} />
		</Grid>
	);
};

export default Filter;
