import { FoodComponentMethodStep } from '@calo/dashboard-types';
import { Typography } from '@mui/material';

import { caloTheme } from '../../../../assets/themes/calo';
import { getTableAttributes } from '../../../../libs/utils/helperFunctions';
import { addStepsToMethods } from '../../helpers';
import MethodStepRow from './MethodStepRow';

interface MethodStepProps {
	methods: FoodComponentMethodStep[];
}

const MethodStep = ({ methods }: MethodStepProps) => {
	const MethodStep = addStepsToMethods(methods);

	return (
		<>
			{MethodStep.map((f) => (
				<>
					{f.step === 0 && (
						<Typography variant="h6">{f.station ? getTableAttributes(f.station, caloTheme).name : <hr />}</Typography>
					)}
					<MethodStepRow key={f.description} method={f} index={f.step} />
				</>
			))}
		</>
	);
};

export default MethodStep;
