import { useState } from 'react';

import { Brand } from '@calo/types';
import { Stack, TableCell, TableRow, Typography, useTheme } from '@mui/material';

import BrandIndicator from '../../../../components/BrandIndicator';
import { FoodComponentStation } from '../../../../libs/enums';
import { CombinedShortages, KDSFoodComponent } from '../../../../libs/interfaces';
import { getTableAttributes } from '../../../../libs/utils/helperFunctions';
import ComponentProcessTime from '../../ComponentProcessTime/ComponentProcessTime';
import ActionButtons from './ActionButtons';

interface SectionTableRowProps {
	statusAttributes: {
		isCheckable: boolean;
		lastUncheckedStation: string;
	};
	tableName: FoodComponentStation;
	isChecked: boolean;
	foodComponent: KDSFoodComponent;
	setSelectedTable: (value: string) => void;
	combinedShortages?: CombinedShortages;
}

const SectionTableRow = ({
	statusAttributes,
	foodComponent,
	tableName,
	combinedShortages,
	isChecked,
	setSelectedTable
}: SectionTableRowProps) => {
	const [isCheckedState, setIsCheckedState] = useState(isChecked);

	const theme = useTheme();
	const tableAttributes = getTableAttributes(statusAttributes.lastUncheckedStation, theme);

	const currentStation = foodComponent.cookingStations.find((station) => station.name === tableName);

	const actionButtons = () => {
		if (combinedShortages) {
			return (
				<ActionButtons
					foodComponent={foodComponent}
					tableName={tableName}
					statusAttributes={statusAttributes}
					combinedShortages={combinedShortages}
					isChecked={isCheckedState}
					setIsCheckedState={setIsCheckedState}
				/>
			);
		} else {
			return (
				<ActionButtons
					foodComponent={foodComponent}
					tableName={tableName}
					statusAttributes={statusAttributes}
					combinedShortages={undefined}
				/>
			);
		}
	};

	return (
		<TableRow hover sx={{ width: '100%', height: '62px' }}>
			<TableCell colSpan={currentStation && currentStation.processTime ? 4 : 3} sx={{ px: '15px', pr: 0, width: '200px' }}>
				<Stack display={'flex'} flexDirection={'column'}>
					<Typography
						sx={{
							fontWeight: 600,
							fontSize: '16px',
							color: statusAttributes.isCheckable || currentStation?.endTime ? '' : theme.palette.neutral600
						}}
					>
						{foodComponent.name.en}
					</Typography>
					{combinedShortages && (
						<Typography
							sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '19px', mt: '14px', color: theme.palette.neutral900 }}
						>
							Quantity: {combinedShortages ? combinedShortages.totalWeight : 0} g
						</Typography>
					)}
				</Stack>
			</TableCell>
			{foodComponent.brand !== Brand.CALO && (
				<TableCell padding="none" sx={{ pr: 3, pb: 1, fontSize: '16px' }}>
					<BrandIndicator brand={foodComponent.brand || Brand.CALO} />
				</TableCell>
			)}
			{!combinedShortages && currentStation && currentStation.processTime ? (
				<TableCell align="right" colSpan={1} sx={{ width: '80px', px: 0 }}>
					<ComponentProcessTime
						combinedShortages={combinedShortages}
						foodComponent={foodComponent}
						processTime={currentStation.processTime}
						endTime={currentStation.endTime}
					/>
				</TableCell>
			) : (
				<TableCell align="right" sx={{ px: 0, py: 0 }}></TableCell>
			)}

			{!currentStation?.processTime && !combinedShortages && (
				<TableCell
					padding="none"
					align="right"
					colSpan={2}
					sx={{ minWidth: '120px', fontWeight: 600, fontSize: '16px', textTransform: 'capitalize' }}
				>
					<Typography
						sx={{
							borderRadius: '16px',
							padding: '8px',
							textAlign: 'center',
							cursor: 'pointer',
							bgcolor: tableAttributes.color,
							fontWeight: 400,
							fontSize: '14px',
							lineHeight: '17px',
							color: theme.palette.neutral900,
							width: '120px',
							marginLeft: 'auto',
							flexGrow: '0',
							display: statusAttributes.lastUncheckedStation === tableName ? 'none' : ''
						}}
						onClick={() => setSelectedTable(statusAttributes.lastUncheckedStation)}
					>
						{FoodComponentStation[statusAttributes.lastUncheckedStation as keyof typeof FoodComponentStation]}
					</Typography>
				</TableCell>
			)}
			<TableCell
				colSpan={
					(currentStation && !currentStation.endTime && currentStation.processTime?.startedAt) || combinedShortages ? 3 : 2
				}
				padding="none"
				align="right"
			>
				{statusAttributes.isCheckable || currentStation?.endTime ? (
					(currentStation && !currentStation.endTime) || combinedShortages ? (
						actionButtons()
					) : (
						<Typography sx={{ color: theme.palette.primary500, mr: '20px', fontWeight: 600, fontSize: '16px' }}>
							Completed
						</Typography>
					)
				) : (
					<Typography sx={{ color: theme.palette.neutral600, fontWeight: 600, mr: 4, fontSize: '16px' }}>Pending</Typography>
				)}
				{combinedShortages && (
					<Typography sx={{ color: theme.palette.red, fontWeight: 400, fontSize: '14px', lineHeight: '17px', mr: 3, mt: '14px' }}>
						Shortage
					</Typography>
				)}
			</TableCell>
		</TableRow>
	);
};

export default SectionTableRow;
