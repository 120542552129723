import Box from '@mui/material/Box';

import EmptyMessage from '../../components/EmptyMessage';
import { KDSFoodComponent } from '../../libs/interfaces';
import { useDocumentMedia } from '../../libs/utils/document';
import { getComponentsWithNonZeroCount } from '../../libs/utils/foodComponentHelper';
import WastageTable from './Desktop/WastageTable';
import WastageTableMobile from './Mobile/WastageTable';

interface WastageTrackingProps {
	foodComponents: KDSFoodComponent[];
}

const WastageTracking = ({ foodComponents }: WastageTrackingProps) => {
	const { isMobile } = useDocumentMedia();
	const foodComponentsWithNonZeroCount = getComponentsWithNonZeroCount(foodComponents);

	if (isMobile) {
		return (
			<Box sx={{ width: '95%', display: 'flex', flexDirection: 'column' }}>
				{foodComponents.length > 0 ? (
					<WastageTableMobile
						key={`wastage-tracking-${foodComponents.length}`}
						foodComponents={foodComponentsWithNonZeroCount || []}
					/>
				) : (
					<EmptyMessage label={'No Data'} />
				)}
			</Box>
		);
	} else {
		return (
			<Box sx={{ width: '96%', minHeight: '100vh' }}>
				{foodComponents.length > 0 ? (
					<WastageTable foodComponents={foodComponentsWithNonZeroCount || []} />
				) : (
					<EmptyMessage label={'No Data'} />
				)}
			</Box>
		);
	}
};

export default WastageTracking;
