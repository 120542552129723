import { FoodComponentStation } from '@calo/dashboard-types';
import { useTheme } from '@mui/material/styles';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface StationsListProps {
	stations: string[];
	stationClickHandler: (station: string) => void;
}

const StationsList = ({ stations, stationClickHandler }: StationsListProps) => {
	const theme = useTheme();

	return (
		<Stack sx={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap', mt: '28px', mx: '8px' }}>
			{stations.map((station) => (
				<Button
					key={station}
					sx={{
						borderRadius: '16px',
						px: '16px',
						backgroundColor: theme.palette.primary100
					}}
					endIcon={<HighlightOffSharpIcon />}
					onClick={() => stationClickHandler(station)}
				>
					{FoodComponentStation[station as keyof typeof FoodComponentStation]}
				</Button>
			))}
		</Stack>
	);
};

export default StationsList;
