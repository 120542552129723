import { useContext, useEffect, useState } from 'react';

import { addDays, format, subDays } from 'date-fns/fp';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../App';
import { caloTheme } from '../../assets/themes/calo';
import EmptyMessage from '../../components/EmptyMessage';
import { LIST_BAGGING_SESSION_QUERY } from '../../libs/graphQL';
import { ArchiveSession, KDSBaggingSession, Session } from '../../libs/interfaces';
import Filter from './Filter';
import SectionPage from './SectionPage';

interface Filters {
	actions: string[];
	startDate: string;
	endDate: string;
	shift: string;
}

interface PreDispatchArchiveProps {
	kitchen: string;
	date: string;
	kitchenSession: Session;
}

const PreDispatchArchive = ({ kitchen, date, kitchenSession }: PreDispatchArchiveProps) => {
	const [filters, setFilters] = useState<Filters>({
		actions: [],
		startDate: format('yyyy-MM-dd')(subDays(5)(Date.parse(date))),
		endDate: format('yyyy-MM-dd')(addDays(1)(Date.parse(date))),
		shift: kitchenSession
	});
	const [isFilterEnabled, setIsFilterEnabled] = useState(false);
	const theme = useTheme();
	const limit = 5;
	const [sessions, setSessions] = useState<KDSBaggingSession[]>([]);
	const [next, setNext] = useState<string | null>(null);
	const queryVariables = {
		kitchen,
		startDate: filters.startDate,
		endDate: filters.endDate,
		shift: filters.shift,
		actions: filters.actions
	};
	const appContext = useContext(AppContext);

	const { fetchMore, refetch, loading } = useQuery(LIST_BAGGING_SESSION_QUERY, {
		variables: { ...queryVariables, limit: isFilterEnabled ? limit : null, next: null },
		onCompleted: (data) => {
			if (!isFilterEnabled && data.listBaggingSession.sessions.length !== 0) {
				setSessions([data.listBaggingSession.sessions[data.listBaggingSession.sessions.length - 1]]);
				const lastSession = data.listBaggingSession.sessions[data.listBaggingSession.sessions.length - 1];
				setFilters({
					actions: [],
					startDate: lastSession.date,
					endDate: lastSession.date,
					shift: kitchenSession
				});
			} else if (!isFilterEnabled && data.listBaggingSession.sessions.length === 0) {
				setSessions([]);
				setFilters({
					actions: [],
					startDate: format('yyyy-MM-dd')(subDays(5)(Date.parse(date))),
					endDate: format('yyyy-MM-dd')(addDays(1)(Date.parse(date))),
					shift: kitchenSession
				});
			} else {
				setSessions(data.listBaggingSession.sessions);
				setNext(data.listBaggingSession.next);
			}
			if (next) scrollingPagination.pageLoader = false;
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite'
	});

	useEffect(() => {
		refetch({ ...queryVariables, limit: isFilterEnabled ? limit : null, next: null });
	}, [filters]);

	useEffect(() => {
		setFilters({
			actions: [],
			startDate: format('yyyy-MM-dd')(subDays(5)(Date.parse(date))),
			endDate: format('yyyy-MM-dd')(addDays(1)(Date.parse(date))),
			shift: kitchenSession
		});
		setIsFilterEnabled(false);
	}, [kitchen]);

	const handleFetchMore = function () {
		if (next) {
			fetchMore({
				variables: { ...queryVariables, limit: limit, next: next },
				updateQuery: (prev: ArchiveSession | undefined, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					if (prev) {
						setNext(fetchMoreResult?.listBaggingSession?.next ? fetchMoreResult.listBaggingSession.next : null);
						setSessions((sessions) => [...sessions, ...fetchMoreResult.listBaggingSession.sessions]);
					}
				}
			});
		}
	};

	const scrollingPagination = {
		pageLoader: false,
		handleScroll: () => {
			const userScrollHeight = window.innerHeight + window.scrollY;
			const windowBottomHeight = document.documentElement.offsetHeight;
			if (userScrollHeight >= windowBottomHeight - 2000 && scrollingPagination.pageLoader === false) {
				scrollingPagination.pageLoader = true;
				handleFetchMore();
			}
		},
		init: () => {
			scrollingPagination.pageLoader = false;
			window.addEventListener('scroll', scrollingPagination.handleScroll, true);
		}
	};

	useEffect(() => {
		if (next) {
			scrollingPagination.init();
		}
		return () => window.removeEventListener('scroll', scrollingPagination.handleScroll);
	}, [next]);

	return (
		<Box sx={{ width: '97%' }}>
			<Stack direction="column" sx={{ ml: '20px', mt: '15px', mb: '10px', width: '100%' }}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					<Filter
						filters={filters}
						setFilters={setFilters}
						setIsFilterEnabled={setIsFilterEnabled}
						isFilterEnabled={isFilterEnabled}
						date={date}
						kitchen={kitchen}
						kitchenSession={kitchenSession}
						isDownloadDisabled={!(sessions.length > 0)}
					/>
				</Grid>
				<Box sx={{ minHeight: '100vh', mt: `${!isFilterEnabled && '-45px'}` }}>
					{sessions.length > 0 && !appContext.isOffline ? (
						loading ? (
							<Box
								sx={{
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									mt: `${isFilterEnabled ? '10px' : '55px'}`
								}}
							>
								Loading ...
							</Box>
						) : (
							sessions.map((session, index) => <SectionPage key={index} session={session} />)
						)
					) : (
						<EmptyMessage label={'no data'} style={{ margin: 0, marginTop: '30px' }} />
					)}
				</Box>
				{!next && isFilterEnabled && !loading && !scrollingPagination.pageLoader ? (
					<Box
						sx={{
							mt: 2,
							fontFamily: caloTheme.typography.fontFamily,
							fontSize: '28px',
							textTransform: 'uppercase',
							color: theme.palette.neutral600,
							textAlign: 'center'
						}}
					>
						The End
					</Box>
				) : (
					isFilterEnabled &&
					!loading && (
						<Stack direction="column" alignItems="center">
							<CircularProgress sx={{ textAlign: 'center', mt: 2 }} />
						</Stack>
					)
				)}
			</Stack>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
};

export default PreDispatchArchive;
