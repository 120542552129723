import { useContext, useRef, useState } from 'react';

import { Kitchen } from '@calo/types';
import GroupIcon from '@mui/icons-material/Group';
import { Box, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../../../App';
import ShiftSelector from '../../../../components/ShiftSelector';
import { FoodComponentStation } from '../../../../libs/enums';
import { Session } from '../../../../libs/interfaces';
import CookPopup from '../../Common/CookPopup';
import Export from '../../Common/Export';
import FilterItems from '../../Common/Filter';
import { QualityTrackingContext } from '../../QualityTrackingContext';

const Filter = () => {
	const theme = useTheme();
	const appContext = useContext(AppContext);

	const {
		date,
		selectedStation,
		setSelectedStation,
		shifts,
		kitchen,
		setShifts,
		batches,
		chefs,
		selectedCheckStatuses,
		setSelectedCheckStatuses
	} = useContext(QualityTrackingContext);

	const addCookModalRef = useRef<any>();

	const [isFilterOpened, setIsFilterOpened] = useState(false);

	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);

	const handleResultsButtonClick = () => {
		setIsFilterOpened(false);
	};

	const handleShiftChange = (shift: Session) => {
		setShifts(shift);
	};

	const handleCloseModal = () => addCookModalRef.current.close();

	return (
		<Grid container direction="row" justifyContent="flex-start" alignItems="center">
			<Box
				sx={{
					width: '95%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignContent: 'center'
				}}
			>
				<Select
					sx={{
						width: '40%',
						borderRadius: '8px'
					}}
					MenuProps={{
						PaperProps: {
							style: {
								marginLeft: '-8px',
								maxHeight: '300px'
							}
						}
					}}
					value={selectedStation}
					onChange={(data: any) => setSelectedStation(data.target.value)}
				>
					{Object.entries(FoodComponentStation).map(([key, value]) => (
						<MenuItem key={key} value={key}>
							{value}
						</MenuItem>
					))}
				</Select>
				<Box sx={{ width: '15%', alignContent: 'center' }}>
					<Button
						variant="text"
						color={'primary'}
						disabled={appContext.isOffline}
						sx={{
							textTransform: 'none',
							backgroundColor: 'inherits',
							':hover': {
								backgroundColor: 'inherits',
								border: 'inherits'
							}
						}}
						onClick={() => setIsFilterOpened(true)}
						startIcon={
							<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M14.6666 1.5H1.33325L6.66659 7.80667V12.1667L9.33325 13.5V7.80667L14.6666 1.5Z"
									stroke={appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						}
					/>
				</Box>
				<Box sx={{ width: '15%', alignContent: 'center' }}>
					<Export isDownloadDisabled={batches.length === 0} kitchen={kitchen} date={{ gte: date, lte: date }} shift={shifts} />
				</Box>
				<Box sx={{ width: '15%', alignContent: 'center' }}>
					<Button
						id="add-cook-button"
						onClick={() => addCookModalRef.current?.open()}
						variant="text"
						startIcon={<GroupIcon />}
						sx={{
							textTransform: 'none',
							fontWeight: 600,
							fontSize: '19px',
							color: 'black',
							padding: 0
						}}
					/>
				</Box>
				<Box sx={{ width: '10%', alignContent: 'center' }}>
					<ShiftSelector
						kitchen={kitchen}
						shift={shifts}
						allSessions={false}
						key={'Quality-Table'}
						handleShiftChange={handleShiftChange}
						isShiftMenuOpened={isShiftMenuOpened}
						shiftMenuAnchorEl={shiftMenuAnchorEl}
						setShiftMenuAnchorEl={setShiftMenuAnchorEl}
						showJustIcon={true}
					/>
				</Box>
			</Box>
			<Drawer anchor={'right'} open={isFilterOpened} onClose={() => setIsFilterOpened(false)}>
				<FilterItems
					toggleFilterDrawer={setIsFilterOpened}
					handleResultsButtonClick={handleResultsButtonClick}
					selectedCheckStatuses={selectedCheckStatuses}
					setSelectedCheckStatuses={setSelectedCheckStatuses}
				/>
			</Drawer>
			<CookPopup kitchen={kitchen as Kitchen} chefs={chefs} ref={addCookModalRef} closeModal={handleCloseModal} />
		</Grid>
	);
};

export default Filter;
