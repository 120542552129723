import { styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { QualityMetrics, QualityMetricsFoodComponent } from '../../../../libs/interfaces';
import { getCurrencyFromKitchen, getPercentage, roundToTwoDecimals } from '../../../../libs/utils/helperFunctions';

interface props {
	qualityMetricsFoodComponent: QualityMetricsFoodComponent;
	qualityMetric: QualityMetrics;
}

const SectionTableRowFolded = ({ qualityMetricsFoodComponent, qualityMetric }: props) => {
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			color: theme.palette.neutral900,
			fontSize: '16px',
			border: 'none',
			textAlign: 'center',
			width: '18%'
		}
	}));

	return (
		<TableRow>
			<StyledTableCell sx={{ textAlign: 'left !important' }}>{qualityMetricsFoodComponent.name?.en}</StyledTableCell>
			<StyledTableCell>{qualityMetric.shift}</StyledTableCell>
			<StyledTableCell>{roundToTwoDecimals(qualityMetricsFoodComponent.weight)} g</StyledTableCell>
			<StyledTableCell>{getPercentage(qualityMetricsFoodComponent.consistencyScore)}</StyledTableCell>
			<StyledTableCell>
				{getCurrencyFromKitchen(qualityMetric.kitchen)}{' '}
				{roundToTwoDecimals(qualityMetricsFoodComponent.cost * qualityMetricsFoodComponent.weight)}
			</StyledTableCell>
			<StyledTableCell sx={{ width: '8% !important' }}></StyledTableCell>
		</TableRow>
	);
};

export default SectionTableRowFolded;
