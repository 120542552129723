import { format } from 'date-fns/fp';
import { useEffect, useMemo, useState } from 'react';

import { FoodDietType, FoodType, Kitchen } from '@calo/types';
import Stack from '@mui/material/Stack';

import EmptyMessage from '../../components/EmptyMessage';
import { useListProductivityMetricsQuery } from '../../hooks';
import { KDSFood, Session } from '../../libs';
import Filter from './Filter';
import Header from './Header';
import { updateTodayProductivityMetrics } from './helpers';
import SectionTable from './MealsTable/SectionTable';

export interface CostVariables {
	numberOfStations: number;
	staffPerStation: number;
	salaryPerStaff: number;
}
export interface Filters {
	kitchen: Kitchen;
	startDate: string;
	endDate: string;
	shift: Session;
	availableShifts: Session[];
	type: FoodType[];
	tags: FoodDietType[];
	onlySandwiches: boolean;
	actual: CostVariables;
	predicted: CostVariables;
}

interface ProductivityMetricsProps {
	kitchen: Kitchen;
	date: string;
	shift: Session;
	foods: KDSFood[];
}

const ProductivityMetricsPage = ({ kitchen, date, shift, foods }: ProductivityMetricsProps) => {
	const defaultCost = {
		numberOfStations: 1,
		staffPerStation: 0,
		salaryPerStaff: 0
	};
	const defaultFilters: Partial<Filters> = {
		kitchen,
		shift: shift ?? Session.all,
		startDate: format('yyyy-MM-dd')(Date.parse(date)),
		endDate: format('yyyy-MM-dd')(Date.parse(date)),
		availableShifts: shift === Session.all ? [Session.all] : [Session.morning, Session.evening],
		type: [],
		tags: [],
		onlySandwiches: false
	};
	const [filters, setFilters] = useState<Filters>({ ...defaultFilters, actual: defaultCost, predicted: defaultCost } as Filters);
	const { productivityMetricsLoading, productivityMetrics } = useListProductivityMetricsQuery({
		startDate: filters.startDate,
		endDate: filters.endDate,
		kitchen
	});

	const updatedProductivityMetrics = useMemo(
		() => updateTodayProductivityMetrics(productivityMetrics, foods, date),
		[productivityMetrics, foods, date]
	);
	const clearFilters = () => {
		setFilters((old) => ({
			...old,
			...defaultFilters
		}));
	};

	useEffect(() => {
		setFilters((old) => ({
			...old,
			...defaultFilters,
			actual: defaultCost,
			predicted: defaultCost
		}));
	}, [kitchen, date, shift]);

	return (
		<Stack direction="column" justifyContent="space-between" alignItems="center" spacing={2} sx={{ width: '100%', mx: 2 }}>
			<Stack direction={'column'} justifyContent="space-between" alignItems="center" spacing={2} sx={{ width: '100%' }}>
				<Header
					kitchen={kitchen}
					title="Actual portioning"
					filters={filters}
					setFilters={setFilters}
					type="actual"
					productivityMetrics={updatedProductivityMetrics}
				/>
				<Header
					kitchen={kitchen}
					title="Predicted portioning"
					filters={filters}
					setFilters={setFilters}
					type="predicted"
					productivityMetrics={updatedProductivityMetrics}
				/>
			</Stack>
			<Filter
				filters={filters}
				setFilters={setFilters}
				clearFilters={clearFilters}
				productivityMetrics={updatedProductivityMetrics}
			/>
			{updatedProductivityMetrics.length > 0 ? (
				updatedProductivityMetrics.map((productivityMetrics, index) => (
					<SectionTable
						key={`${productivityMetrics.id}#${index}`}
						productivityMetrics={productivityMetrics}
						isFirst={index === 0}
						filters={filters}
					/>
				))
			) : (
				<EmptyMessage
					label={productivityMetricsLoading ? 'Loading' : 'No data'}
					style={{ marginTop: '10', textAlign: 'center' }}
				/>
			)}
		</Stack>
	);
};

export default ProductivityMetricsPage;
