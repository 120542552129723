import { ComponentService } from '@calo/services';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InputField } from '../../../components';
import { FoodComponent } from '../../../libs/interfaces';

interface BatchQuantityInputProps {
	setNewCookedWeight: (weight: number) => void;
	numberOfBatches: number;
	setNumberOfBatches: (value: number) => void;
	foodComponent: FoodComponent;
}

const BatchQuantityInput = ({
	setNewCookedWeight,
	foodComponent,
	numberOfBatches,
	setNumberOfBatches
}: BatchQuantityInputProps) => {
	const theme = useTheme();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | string>) => {
		const parsedValue = +event;
		setNumberOfBatches(parsedValue);
		const weight = ComponentService.calculateTotalComponentAbsoluteWeight(foodComponent);
		setNewCookedWeight(Math.round((weight * parsedValue) / (foodComponent.cookedRawFactor ?? 1)));
	};

	return (
		<Box
			sx={{
				paddingX: '16px',
				width: '100%',
				paddingTop: '15px'
			}}
		>
			<Typography
				sx={{
					fontSize: 12,
					margin: 0,
					padding: 0,
					color: theme.palette.neutral700
				}}
			>
				Enter Number of Batches (optional)
			</Typography>
			<InputField
				type="number"
				value={numberOfBatches}
				placeholder="0"
				onChange={handleChange}
				debounce
				fullWidth={true}
				inputMode="numeric"
				sx={{
					border: 0,
					fontSize: '16px',
					fontWeight: 600,
					borderRadius: '8px',
					textTransform: 'none',
					borderWidth: '1px',
					borderColor: theme.palette.neutral700,
					paddingY: '3px',
					paddingX: '10px',
					width: '100%',
					textAlign: 'start',
					input: { textAlign: 'start' },
					color: theme.palette.black
				}}
			/>
		</Box>
	);
};

export default BatchQuantityInput;
