import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import EmptyMessage from '../../../components/EmptyMessage';
import { FoodType, PreDispatchCheckHeader } from '../../../libs/enums';
import { KDSBaggingSession } from '../../../libs/interfaces';
import { getTableAttributes, makeCustomMealRepresentative } from '../../../libs/utils/helperFunctions';
import SectionHeader from '../SectionHeader';
import SectionTable from '../SectionTable';

interface SectionPageProps {
	session: KDSBaggingSession;
}

const SectionPage = ({ session }: SectionPageProps) => {
	const theme = useTheme();

	const getFilteredDispatchesForSection = (session: KDSBaggingSession, tableName: string) => {
		const tableAttributes = getTableAttributes(tableName, theme);
		if (session && session.dispatches && session.dispatches.length > 0) {
			const dispatches = session.dispatches.filter((f) =>
				f.foodType.includes((tableAttributes.condition as FoodType) || (tableAttributes.condition2 as FoodType))
			);
			const custom = dispatches.filter((f) => f.isCustomFood);
			const nonCustom = dispatches.filter((f) => !f.isCustomFood);
			return custom.length > 0 ? [...nonCustom, makeCustomMealRepresentative(custom)] : nonCustom;
		}

		return [];
	};

	return (
		<Stack direction="column" sx={{ mt: '10px', mb: '5px', width: '100%' }}>
			<SectionHeader session={session} />
			{session && session.dispatches && session.dispatches.length > 0 ? (
				Object.keys(PreDispatchCheckHeader).map((tableName, index) => (
					<Box sx={{ width: '100%' }} key={index}>
						{getFilteredDispatchesForSection(session, tableName).length > 0 ? (
							<SectionTable tableName={tableName} dispatches={getFilteredDispatchesForSection(session, tableName)} />
						) : (
							''
						)}
					</Box>
				))
			) : (
				<EmptyMessage label={'no data'} style={{ margin: 0 }} />
			)}
		</Stack>
	);
};

export default SectionPage;
