import { Fragment, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../assets/themes/calo';
import { ShiftSelector } from '../../../components';
import CustomFoodsFilter from '../../../components/CustomFoodsFilter';
import { FoodPortioningFoodType, Page } from '../../../libs/enums';
import { FoodComponentDataPortionPage, MobileFiltersPages, Session } from '../../../libs/interfaces';
import { handleShiftForKitchen, setShiftToLocalStorage } from '../../../libs/utils/helperFunctions';
import AssemblyCheckTableRow from '../AssemblyCheckTableRow';

interface AssemblyCheckTableProps {
	kitchen: string;
	filters: MobileFiltersPages;
	data: FoodComponentDataPortionPage;
	setFilters: (value: MobileFiltersPages) => void;
}

const AssemblyCheckTable = ({ filters, setFilters, data, kitchen }: AssemblyCheckTableProps) => {
	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);

	const theme = useTheme();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral100,
			color: theme.palette.common.black,
			border: 'none',
			fontSize: 16,
			fontWeight: 600
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const handleShiftChange = (shift: string) => {
		setFilters({ ...filters, shift: shift as Session });
		setShiftToLocalStorage(shift, Page.assemblyCheck, kitchen);
	};

	useEffect(() => {
		handleShiftForKitchen(Page.assemblyCheck, kitchen, filters.shift, handleShiftChange);
	}, [kitchen]);

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ m: 2, mb: 0, width: '100%' }}>
				<Stack direction={'row'} alignItems="center" gap={4}>
					<CustomFoodsFilter
						isCustomFoodHidden={filters.showCustom}
						setIsCustomFoodHidden={(v) => setFilters({ ...filters, showCustom: v })}
					/>
				</Stack>
				<ShiftSelector
					kitchen={kitchen}
					allSessions={false}
					shift={filters.shift}
					key={'Assembly-Check-Table'}
					handleShiftChange={handleShiftChange}
					isShiftMenuOpened={isShiftMenuOpened}
					shiftMenuAnchorEl={shiftMenuAnchorEl}
					setShiftMenuAnchorEl={setShiftMenuAnchorEl}
				/>
			</Grid>
			<Table sx={{ m: 2, width: '100%' }}>
				<TableHead>
					<TableRow>
						<StyledTableCell sx={{ width: '34%', p: 1, pl: 2 }}>Meals</StyledTableCell>
						<StyledTableCell sx={{ width: '33%', textAlign: 'start', p: 1 }}>Status</StyledTableCell>
						<StyledTableCell sx={{ width: '33%', textAlign: 'center' }}>Action</StyledTableCell>
					</TableRow>
				</TableHead>
				{Object.values(data).some((d) => d.length > 0) ? (
					<TableBody>
						{Object.entries(data).map(([key, value], i) => (
							<Fragment key={i}>
								{value.map((meal: any) => (
									<AssemblyCheckTableRow
										key={meal.id}
										foodType={key as FoodPortioningFoodType}
										food={meal}
										shift={filters.shift}
									/>
								))}
							</Fragment>
						))}
					</TableBody>
				) : (
					<TableBody>
						<TableRow>
							<TableCell
								colSpan={3}
								sx={{
									minWidth: 150,
									p: 1,
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									textAlign: 'center',
									border: 'none'
								}}
							>
								No meals
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>
		</Box>
	);
};

export default AssemblyCheckTable;
