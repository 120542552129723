import { useRef } from 'react';

import { sortBy } from 'lodash-es';

import { Table, TableCell, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';

import { caloTheme } from '../../../../assets/themes/calo';
import { FoodComponentStation } from '../../../../libs/enums';
import { KDSFoodComponent } from '../../../../libs/interfaces';
import Section from '../Section';

interface SectionTableProps {
	foodComponents: KDSFoodComponent[];
	selectedComponentStation: FoodComponentStation;
	setSelectedComponentStation: (value: FoodComponentStation) => void;
}

const MobileSectionTable = ({ foodComponents, setSelectedComponentStation, selectedComponentStation }: SectionTableProps) => {
	const myRef = useRef<null | HTMLDivElement>(null);

	return (
		<Box
			ref={myRef}
			sx={{
				height: '100%',
				minHeight: '1000px',
				width: '100%'
			}}
		>
			<Table stickyHeader>
				<TableBody>
					{foodComponents.length > 0 ? (
						sortBy(foodComponents, 'name.en').map((fc) => (
							<Section
								key={fc.id}
								foodComponent={fc}
								selectedComponentStation={selectedComponentStation}
								setSelectedComponentStation={setSelectedComponentStation}
							/>
						))
					) : (
						<TableRow hover>
							<TableCell
								colSpan={4}
								sx={{
									p: 1,
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: caloTheme.palette.neutral600,
									textAlign: 'center',
									border: 'none'
								}}
							>
								No components
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Box>
	);
};

export default MobileSectionTable;
