import ReactHtmlParser from 'react-html-parser';

import { FoodComponentMethodStep } from '@calo/dashboard-types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { decodeHtmlSymbols } from '../../../../libs/utils/helperFunctions';
import { generateOvenSettingsTableData } from '../ExportHelpers';

interface MethodStepRowProps {
	method: FoodComponentMethodStep;
	index: number;
}

const MethodStepRow = ({ method, index }: MethodStepRowProps) => {
	const decodedMethods = decodeHtmlSymbols(method.description);

	if (decodedMethods.includes('Steam %')) {
		const tableData = generateOvenSettingsTableData(decodedMethods);
		return (
			<>
				<Typography sx={{ mb: '8px' }}>{index + 1}. Oven Settings:</Typography>
				<TableContainer component={Paper} sx={{ border: '1px solid black', minWidth: '150px' }}>
					<Table>
						<TableHead>
							<TableRow>
								{Object.keys(tableData).map((attributeName, index) => (
									<TableCell key={index} sx={{ padding: '8px' }}>
										{attributeName}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								{Object.values(tableData).map((attributeValue, index) => (
									<TableCell key={index}>{attributeValue}</TableCell>
								))}
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<br />
			</>
		);
	} else if (decodedMethods.includes('<table')) {
		return <>{ReactHtmlParser(decodedMethods)}</>;
	} else {
		return (
			<Typography sx={{ mb: '8px' }}>
				{index + 1}. {decodedMethods.replace(/<[^>]*>/g, '')}
			</Typography>
		);
	}
};

export default MethodStepRow;
