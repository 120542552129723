import { useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Icon } from '@iconify/react';
import { Card, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Modal from '../../../../components/Modal';
import PortionActionButton from '../../../../components/PortionActionButton/PortionActionButton';
import { useExportDocuments } from '../../../../hooks';
import { DocsType, FoodPortioningFoodType, Page, UpdateFoodPortioningStatusOperation } from '../../../../libs/enums';
import { UPDATE_FOOD_PORTIONING_STATUS } from '../../../../libs/graphQL';
import { KDSFood } from '../../../../libs/interfaces';
import { getFoodPortioningStatusAttributes, isValidURL } from '../../../../libs/utils/helperFunctions';
import PortionStatusItem from '../../PortionStatusItem/PortionStatusItem';
import ProcessTime from '../../ProcessTime';

interface PortionListRowMobileProps {
	food: KDSFood;
	foodType: FoodPortioningFoodType;
	shift: string;
	date: string;
	kitchen: string;
}

const PortionListRowMobile = ({ food, foodType, shift, date, kitchen }: PortionListRowMobileProps) => {
	const theme = useTheme();
	const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);
	const { handleExportDocuments, loading: exportDocumentsLoading } = useExportDocuments();
	const [mutateFoodPortioningStatus, { loading }] = useMutation(UPDATE_FOOD_PORTIONING_STATUS);
	const portioningStatus = food.portioningStatus?.find((s) => s.session === shift);
	const attributes = getFoodPortioningStatusAttributes(portioningStatus!, foodType, Page.portioningDisplay, theme);

	const handleUpdateFoodPortioningStatus = async (operation: UpdateFoodPortioningStatusOperation) => {
		if (!loading) {
			await mutateFoodPortioningStatus({
				variables: {
					id: food.id,
					session: shift,
					kitchen: food.kitchen,
					operation: operation
				},
				onError: (e) => {
					toast.error(e.message);
				}
			});
		}
	};
	const handleExportPortioningGuidelines = async () => {
		const ids = food.sizes.map((size) => size.id);
		const docsTypes = [DocsType.portioningGuidelines];
		await handleExportDocuments({ ids, docTypes: docsTypes, kitchen, day: date, shift });
	};

	return (
		<Card
			variant="outlined"
			sx={{
				width: '100%',
				mx: 1,
				mt: 2,
				minHeight: '130px',
				borderColor: attributes.buttonText === 'Finished' ? theme.palette.primary500 : theme.palette.neutral200
			}}
		>
			<Stack display={'flex'} flexDirection={'column'} justifyContent={'space-between'} sx={{ m: 1 }}>
				<Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
					<Typography fontWeight="600" fontSize="19px" textTransform={'capitalize'}>
						{food.name.en}
					</Typography>
					<Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={2}>
						<IconButton
							onClick={() => {
								food.portioningURL && window.open(food.portioningURL, '_blank');
							}}
							sx={{ color: '#367bf5' }}
							disabled={!food.portioningURL || !isValidURL(food.portioningURL)}
						>
							<Icon icon="ph:file-text-duotone" width="1em" height="1em" />
						</IconButton>
						<IconButton disabled={exportDocumentsLoading} onClick={handleExportPortioningGuidelines} sx={{ color: '#367bf5' }}>
							<Icon icon="lucide:external-link" width="1em" height="1em" />
						</IconButton>
					</Stack>
				</Stack>
				<Stack flexDirection="row" gap="10px">
					<PortionStatusItem attributes={attributes} />
					{attributes.time && <ProcessTime attributes={attributes} portioningStatus={portioningStatus} time={attributes.time!} />}
				</Stack>
			</Stack>
			<PortionActionButton
				attributes={attributes}
				handleUpdateFoodPortioningStatus={handleUpdateFoodPortioningStatus}
				loading={loading}
				isMobile={true}
			/>
			<Modal
				loading={loading}
				open={isConfirmationModalOpened}
				setOpen={(isOpened: boolean) => setIsConfirmationModalOpened(isOpened)}
				handleClose={() => setIsConfirmationModalOpened(false)}
				handleAccept={() => handleUpdateFoodPortioningStatus(UpdateFoodPortioningStatusOperation.notEnded)}
				title={'Uncheck ' + food.name.en}
				message={'Are you sure you want to uncheck ' + food.name.en + '?'}
			/>
		</Card>
	);
};

export default PortionListRowMobile;
