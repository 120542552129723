import { sortBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';
import { Brand, DeliveryStatus, Kitchen } from '@calo/types';
import { Backdrop, Box, CircularProgress } from '@mui/material';

import EmptyMessage from '../../components/EmptyMessage';
import useListDeliveryQuery from '../../hooks/useListDeliveryQuery';
import { DeliveryTime, DriverDispatchType, FileManagementType } from '../../libs/enums';
import { LIST_DRIVER_DISPATCH, SUBSCRIBE_TO_DRIVER_CHANGES } from '../../libs/graphQL';
import { KDSDeliveryListFilters, KDSDriver } from '../../libs/interfaces';
import { getCountry } from '../../libs/utils/helperFunctions';
import CaloDriverDispatchTable from './CaloDriverDispatchTable';
import DemandDriverDispatchTable from './DemandDriverDispatchTable';
import DriverTableHeader from './DriverTableHeader';

interface DriverDispatchProps {
	kitchen: string;
	date: string;
}

const DriverDispatch = ({ kitchen, date }: DriverDispatchProps) => {
	const [driverDate, setDriverDate] = useState<string>(date);
	const [deliveryTime, setDeliveryTime] = useState(DeliveryTime.morning);

	const initialFilters: KDSDeliveryListFilters = {
		kitchen: kitchen as Kitchen,
		day: {
			gte: driverDate,
			lte: driverDate
		},
		brand: Brand.CALO,
		country: getCountry(kitchen as Kitchen),
		skipped: false,
		status: [DeliveryStatus.upcoming, DeliveryStatus.paymentRequired],
		deliveryTime: deliveryTime
	};
	const [filters, setFilters] = useState<KDSDeliveryListFilters>(initialFilters);
	const { deliveries, deliveriesLoading } = useListDeliveryQuery(filters, initialFilters, FileManagementType.customer, true);

	const [selectedDataType, setSelectedDataType] = useState<DriverDispatchType>(DriverDispatchType.caloDrivers);
	const {
		loading: driverDispatchLoading,
		data: driverData,
		subscribeToMore
	} = useQuery(LIST_DRIVER_DISPATCH, {
		variables: { date: driverDate, deliveryTime: deliveryTime, kitchen },
		onError: () => {
			toast.error('Error while loading Drivers');
		}
	});
	useSubscription(SUBSCRIBE_TO_DRIVER_CHANGES);
	const moreDriver = () =>
		subscribeToMore({
			document: SUBSCRIBE_TO_DRIVER_CHANGES,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscribeToUpdateDriverDispatchChanges = subscriptionData.data.subscribeToUpdateDriverDispatch;
				const isDriverExist = prev.listDriverDispatches.find(
					(driver: KDSDriver) => driver.id === subscribeToUpdateDriverDispatchChanges.id
				);
				if (!isDriverExist) {
					return Object.assign({}, prev, {
						listDriverDispatches: [subscribeToUpdateDriverDispatchChanges, ...prev.listDriverDispatches]
					});
				}
			}
		});

	useEffect(() => {
		setDriverDate(date);
		setDeliveryTime(DeliveryTime.morning);
	}, [kitchen, date]);

	useEffect(() => {
		if (!driverData?.listDriverDispatches) {
			return;
		}
		const customDriver = driverData.listDriverDispatches.find(
			(ddata: KDSDriver) => ddata.driver.driverName === 'Custom Delivery Time'
		);
		if (customDriver) {
			setFilters({
				...filters,
				day: {
					gte: driverDate,
					lte: driverDate
				},
				driverId: customDriver.driver.id,
				deliveryTime: deliveryTime
			});
		} else {
			setFilters({
				...filters,
				driverId: undefined,
				deliveryTime: deliveryTime
			});
		}
	}, [driverData]);

	useEffect(() => {
		moreDriver();
	}, []);

	return (
		<Box sx={{ width: '98%', minHeight: '100vh' }}>
			<DriverTableHeader
				kitchen={kitchen}
				driverDate={driverDate}
				deliveryTime={deliveryTime}
				deliveries={deliveries || []}
				setDriverDate={setDriverDate}
				setDeliveryTime={(v) => setDeliveryTime(v)}
				selectedDataType={selectedDataType}
				setSelectedDataType={setSelectedDataType}
				drivers={driverData?.listDriverDispatches || []}
			/>

			{selectedDataType === DriverDispatchType.caloDrivers &&
				(driverData?.listDriverDispatches ? (
					<CaloDriverDispatchTable sortedDriversByName={sortBy(driverData.listDriverDispatches, ['driver.driverName'])} />
				) : (
					<EmptyMessage label={'No data'} />
				))}

			{selectedDataType === DriverDispatchType.others &&
				(deliveries && filters.driverId ? (
					<DemandDriverDispatchTable deliveries={deliveries} />
				) : (
					<EmptyMessage label={'No data'} />
				))}

			{selectedDataType !== DriverDispatchType.others && selectedDataType !== DriverDispatchType.caloDrivers && (
				<EmptyMessage label="Something went wrong" />
			)}

			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={driverDispatchLoading || deliveriesLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
};
export default DriverDispatch;
