import { forwardRef, useState } from 'react';

import { Kitchen } from '@calo/types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { Popup } from '../../../../components';
import { KDSChefUser } from '../../../../libs/interfaces';
import { getCountry } from '../../../../libs/utils/helperFunctions';
import CookList from './CookList';
import EditableCookForm from './EditableCookForm';
import NewCookForm from './NewCookForm';

interface CookPopupProps {
	kitchen: Kitchen;
	chefs: KDSChefUser[];
	closeModal: () => void;
}

const CookPopup = forwardRef(({ kitchen, chefs, closeModal }: CookPopupProps, addCookModalRef: React.ForwardedRef<any>) => {
	const [editableCook, setEditableCook] = useState<KDSChefUser | null>();
	const [isNewCook, setIsNewCook] = useState(false);

	const handleClearModal = () => {
		closeModal();
		setIsNewCook(false);
		setEditableCook(null);
	};

	const closeEditableForm = () => setEditableCook(null);

	const getPopupSubtitle = () =>
		!editableCook && !isNewCook ? (
			<IconButton onClick={handleClearModal}>
				<CloseSharpIcon fontSize={'large'} />
			</IconButton>
		) : (
			`${getCountry(kitchen as Kitchen)}-${kitchen}`
		);

	const getPopupTitle = (): React.ReactNode => {
		if (editableCook) {
			return (
				<Button
					sx={{ color: '#000000', fontSize: '23px' }}
					startIcon={<ArrowBackIosIcon />}
					onClick={() => setEditableCook(null)}
				>
					Edit Cook
				</Button>
			);
		} else if (isNewCook) {
			return (
				<Button sx={{ color: '#000000', fontSize: '23px' }} startIcon={<ArrowBackIosIcon />} onClick={() => setIsNewCook(false)}>
					Add Cook
				</Button>
			);
		} else {
			return 'Cooks';
		}
	};

	return (
		<Popup
			ref={addCookModalRef}
			onClose={handleClearModal}
			title={getPopupTitle()}
			subTitle={getPopupSubtitle()}
			style={{ fullWidth: true }}
		>
			<Stack height="458px">
				{!editableCook && !isNewCook && <CookList chefs={chefs} setEditableCook={setEditableCook} setIsNewCook={setIsNewCook} />}
				{editableCook && (
					<EditableCookForm chef={editableCook} handleCloseModal={handleClearModal} closeForm={closeEditableForm} />
				)}
				{isNewCook && <NewCookForm kitchen={kitchen as Kitchen} handleCloseModal={handleClearModal} />}
			</Stack>
		</Popup>
	);
});

export default CookPopup;
