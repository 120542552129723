import { Localized } from '@calo/types';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../assets/themes/calo';
import { KDSFoodComponent } from '../../../libs/interfaces';
import { useDocumentMedia } from '../../../libs/utils/document';
import { weightCalculator } from '../../../libs/utils/foodComponentHelper';

interface ShortageReportTableRowProps {
	foodComponent: KDSFoodComponent;
	foodId: string;
	foodName: Localized;
	size: string;
	componentQtyFactor: number;
	amount: number;
	isChecked: boolean;
	handleAddShortage: (foodId: string, foodName: Localized, foodSize: string, weight: number, componentId: string) => void;
	handleRemoveShortage: (id: string) => void;
}

const ShortageReportTableRow = ({
	foodComponent,
	amount,
	foodId,
	foodName,
	size,
	componentQtyFactor,
	handleAddShortage,
	handleRemoveShortage,
	isChecked
}: ShortageReportTableRowProps) => {
	const { isMobile } = useDocumentMedia();
	const componentCount = amount * componentQtyFactor;
	const shortageCupsQuantity = [{ cup: componentQtyFactor, quantity: amount }];
	const componentWeight = weightCalculator(foodComponent, foodComponent.cookedWeight, componentCount, shortageCupsQuantity);

	const handleCheck = () => {
		if (isChecked) {
			handleRemoveShortage(foodComponent.id);
		} else {
			handleAddShortage(foodId, foodName, size, componentWeight, foodComponent.id);
		}
	};

	const styles = {
		tableRow: {
			mobile: {
				backgroundColor: isChecked ? caloTheme.palette.primary100 : caloTheme.palette.neutral50
			},
			desktop: {}
		},
		tableCellCheckbox: {
			mobile: {
				maxWidth: '4px',
				border: 'none'
			},
			desktop: {
				maxWidth: '4px',
				border: 1,
				borderColor: 'white'
			}
		},
		tableCellFoodComponentName: {
			mobile: {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '14px',
				border: 'none'
			},
			desktop: {
				maxWidth: 220,
				border: 2,
				borderColor: 'white'
			}
		},
		typographyFoodComponentName: {
			mobile: {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '14px'
			},
			desktop: {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '14px'
			}
		},
		tableCellComponentWeight: {
			mobile: {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '14px',
				textAlign: 'end',
				border: 0
			},
			desktop: {
				maxWidth: '6px',
				border: 2,
				borderColor: 'white'
			}
		},
		typographyComponentWeight: {
			mobile: {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '14px',
				textAlign: 'end'
			},
			desktop: {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '14px',
				textAlign: 'end',
				mr: 1
			}
		}
	};

	return (
		<TableRow key={foodComponent.id} style={{ ...(isMobile ? styles.tableRow.mobile : styles.tableRow.desktop) }}>
			<TableCell sx={{ ...(isMobile ? styles.tableCellCheckbox.mobile : styles.tableCellCheckbox.desktop) }}>
				<Checkbox
					checked={isChecked}
					onClick={() => handleCheck()}
					inputProps={{ 'aria-label': 'controlled' }}
					disabled={amount <= 0}
				/>
			</TableCell>
			<TableCell sx={{ ...(isMobile ? styles.tableCellFoodComponentName.mobile : styles.tableCellFoodComponentName.desktop) }}>
				<Typography
					sx={{ ...(isMobile ? styles.typographyFoodComponentName.mobile : styles.typographyFoodComponentName.desktop) }}
				>
					{foodComponent.name.en}
				</Typography>
			</TableCell>
			<TableCell sx={{ ...(isMobile ? styles.tableCellComponentWeight.mobile : styles.tableCellComponentWeight.desktop) }}>
				<Typography sx={{ ...(isMobile ? styles.typographyComponentWeight.mobile : styles.typographyComponentWeight.desktop) }}>
					{componentWeight} g
				</Typography>
			</TableCell>
		</TableRow>
	);
};

export default ShortageReportTableRow;
