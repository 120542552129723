import { Box, Table, TableBody, TableCell, TableHead, TableRow, styled, tableCellClasses } from '@mui/material';
import { caloTheme } from '../../../assets/themes/calo';
import { KDSDeliveryData } from '../../../libs/interfaces';
import DemandDriverDispatchTableRow from './DemandDriverDispatchTableRow';

interface DemandDriverDispatchTableProps {
	deliveries: KDSDeliveryData[];
}

const DemandDriverDispatchTable = ({ deliveries }: DemandDriverDispatchTableProps) => {
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral50,
			color: caloTheme.palette.neutral900,
			border: 'none',
			fontSize: 14,
			fontWeight: 600,
			height: '40px'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	return (
		<Box sx={{ width: '100%' }}>
			{deliveries && (
				<Table sx={{ m: 2, width: '100%' }}>
					<TableHead>
						<TableRow>
							<StyledTableCell style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }} sx={{ minWidth: '254px' }}>
								Customer
							</StyledTableCell>
							<StyledTableCell sx={{ minWidth: '40px', textAlign: 'center' }}>Invoice Number</StyledTableCell>
							<StyledTableCell sx={{ mixWidth: '80px', textAlign: 'center' }}>Pick Up Time</StyledTableCell>
							<StyledTableCell
								style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}
								sx={{ minWidth: '80px', textAlign: 'center' }}
							>
								Custom Delivery Time
							</StyledTableCell>
						</TableRow>
					</TableHead>
					{deliveries && deliveries.length === 0 ? (
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={5}
									sx={{
										minWidth: 150,
										p: 1,
										fontFamily: caloTheme.typography.fontFamily,
										fontSize: '28px',
										textTransform: 'uppercase',
										color: caloTheme.palette.neutral600,
										textAlign: 'center',
										border: 'none'
									}}
								>
									No Drivers
								</TableCell>
							</TableRow>
						</TableBody>
					) : (
						<TableBody>
							{deliveries.map((delivery) => (
								<DemandDriverDispatchTableRow key={delivery.id} delivery={delivery} />
							))}
						</TableBody>
					)}
				</Table>
			)}
		</Box>
	);
};
export default DemandDriverDispatchTable;
