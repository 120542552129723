import { orderBy, sortBy } from 'lodash-es';

import { Table, TableCell, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';

import { caloTheme } from '../../../../assets/themes/calo';
import { FoodComponentWithBatches, KDSChefUser, Session } from '../../../../libs/interfaces';
import { removeTypename } from '../../../../libs/utils/helperFunctions';
import MobileQualityTableRow from '../MobileQualityTableRow';

interface MobileSectionTableProps {
	foodComponents: FoodComponentWithBatches[];
	chefs: KDSChefUser[];
	shift: Session;
	date: string;
}

const MobileSectionTable = ({ foodComponents, chefs, shift, date }: MobileSectionTableProps) => {
	const sortedComponents = sortBy(foodComponents, 'name.en');
	return (
		<Box
			sx={{
				height: '100%',
				minHeight: '1000px',
				width: '100%'
			}}
		>
			<Table>
				<TableBody>
					{sortedComponents.length > 0 ? (
						sortedComponents.map((comp) => (
							<>
								{comp.batches && comp.batches.some((batch) => batch.session === shift) ? (
									orderBy(comp.batches, 'id')
										.filter((batch) => batch.session === shift)
										.map((foodComponentBatch) => (
											<MobileQualityTableRow
												key={foodComponentBatch.id}
												foodComponent={comp}
												foodComponentName={comp.name.en}
												chefs={chefs}
												shift={shift}
												date={date}
												foodComponentBatch={removeTypename(foodComponentBatch)}
												batchesListLength={
													comp.batches
														? comp.batches.filter((b) => b.session === shift)[
																comp.batches.filter((b) => b.session === shift).length - 1
															].batchNumber
														: 0
												}
											/>
										))
								) : (
									<MobileQualityTableRow key={comp.id} foodComponent={comp} chefs={chefs} shift={shift} date={date} />
								)}
							</>
						))
					) : (
						<TableRow hover>
							<TableCell
								colSpan={4}
								sx={{
									p: 1,
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: caloTheme.palette.neutral600,
									textAlign: 'center',
									border: 'none'
								}}
							>
								No data
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Box>
	);
};

export default MobileSectionTable;
