import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { KDSBaggingDispatch } from '../../../libs/interfaces';

interface SectionTableRowProps {
	dispatch: KDSBaggingDispatch;
}

const SectionTableRow = ({ dispatch }: SectionTableRowProps) => {
	const theme = useTheme();
	return (
		<TableRow hover>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', fontWeight: 600, maxWidth: '200px' }}>{dispatch.foodName?.en}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>{dispatch.foodSize}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.packages && dispatch.packages?.length > 0 ? dispatch.packages.map((p) => p.name).join(', ') : '-'}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{dispatch.total}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.total + dispatch.extra - dispatch.modified}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{dispatch.modified}</TableCell>
			<TableCell sx={{ fontSize: '14px', textAlign: 'center' }}>{dispatch.extra} Meal</TableCell>
			<TableCell sx={{ fontSize: '14px', textAlign: 'center' }}>{dispatch.leftovers} Meal</TableCell>
			<TableCell sx={{ py: 2, fontSize: '14px', textAlign: 'center', fontWeight: 600 }}>{dispatch.startTemperature}°C</TableCell>
			<TableCell sx={{ py: 2, fontSize: '14px', textAlign: 'center', fontWeight: 600 }}>{dispatch.endTemperature}°C</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.foodCost ? dispatch.foodCost : 0}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.leftovers && dispatch.foodCost ? dispatch.foodCost * dispatch.leftovers : 0}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{dispatch.purchasingCost || 0}</TableCell>
			<TableCell
				sx={{ pl: '10px', py: 2, fontSize: '14px', fontWeight: 600, color: theme.palette.neutral600, textAlign: 'center' }}
			>
				{dispatch.action ? dispatch.action : 'No Action'}
			</TableCell>
		</TableRow>
	);
};

export default SectionTableRow;
