import { addDays, startOfDay } from 'date-fns';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { DateRange } from '@mui/lab/DateRangePicker';
import { Box, Button, CircularProgress, IconButton, Stack, Theme, Typography } from '@mui/material';

import { Popup } from '../../../components';
import DateRangePicker from '../../../components/DateRangePicker';
import { EXPORT_FOOD_PORTIONING_STATUS } from '../../../libs/graphQL';
import { createAutoDownloadLinkFromFile, getDate } from '../../../libs/utils/helperFunctions';

interface ExportPortioningsPopupProps {
	exportPotioningStatusModalRef: React.RefObject<any>;
	theme: Theme;
	kitchen: string;
	mt?: string;
	spacing?: number;
	width?: string;
	stackDirection?: 'row' | 'column';
	showCustom?: boolean;
}

const ExportPortioningStatusPopup: React.FC<ExportPortioningsPopupProps> = ({
	exportPotioningStatusModalRef,
	theme,
	kitchen,
	mt,
	spacing,
	width,
	stackDirection,
	showCustom = false
}) => {
	const today = startOfDay(new Date());
	const tomorrow = addDays(today, 1);

	const [dateRange, setDateRange] = useState<DateRange<Date>>([today, tomorrow]);

	const [exportFoodPortioningStatus, { loading }] = useMutation(EXPORT_FOOD_PORTIONING_STATUS, {
		onCompleted: (data) => {
			createAutoDownloadLinkFromFile(
				data.exportFoodPortioningStatus.data,
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'xlsx',
				`Food_Portioning_Status_${kitchen}_${dateRange[0] && getDate(dateRange[0])}_${dateRange[1] && getDate(dateRange[1])}`
			);
			toast.success('File downloaded successfully!');
		},
		onError: (error) => {
			console.error(error);
			toast.error('Error while downloading file');
		}
	});

	const handleDownloadButtonClick = () => {
		if (dateRange[0] && dateRange[1]) {
			exportFoodPortioningStatus({
				variables: {
					kitchen,
					startDate: getDate(dateRange[0]),
					endDate: getDate(dateRange[1]),
					showCustom
				}
			});
			exportPotioningStatusModalRef.current?.close();
		} else {
			toast.error('Please select a valid date range');
		}
	};

	return (
		<Popup
			ref={exportPotioningStatusModalRef}
			onClose={() => exportPotioningStatusModalRef.current?.close()}
			title={'Download'}
			style={{
				fullWidth: true,
				maxWidth: 'md'
			}}
		>
			<IconButton
				aria-label="close"
				onClick={() => exportPotioningStatusModalRef.current?.close()}
				sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
			>
				<CloseIcon />
			</IconButton>
			<Stack>
				<Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', mb: '20px', mt: mt || '0px' }}>
					Please Choose the period for which you want to download the data
				</Typography>
				<DateRangePicker
					dateRange={dateRange}
					dayRangeLimit={100}
					setDateRange={(newDateRange) => setDateRange(newDateRange)}
					stackDirection={stackDirection || 'row'}
					startLabel="Start Date"
					endLabel="End Date"
					spacing={spacing || 4}
					margin={0.0000001}
					disableFutureDates
				/>
				<Box display="flex" justifyContent="center">
					<Button
						variant="contained"
						sx={{
							fontSize: '18px',
							padding: '10px 20px',
							color: theme.palette.white,
							borderRadius: '10px',
							width: width || '20%',
							mt: '30px',
							textTransform: 'none'
						}}
						onClick={handleDownloadButtonClick}
						disabled={loading}
					>
						{loading ? <CircularProgress size={24} color="inherit" /> : 'Download'}
					</Button>
				</Box>
			</Stack>
		</Popup>
	);
};

export default ExportPortioningStatusPopup;
