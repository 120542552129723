import { useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Kitchen } from '@calo/types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { caloTheme } from '../../../../../assets/themes/calo';
import { InputField } from '../../../../../components';
import { UPDATE_CHEF_USER } from '../../../../../libs/graphQL';
import { KDSChefUser, Session } from '../../../../../libs/interfaces';
import { areChefsEqual } from '../../../../../libs/utils/helperFunctions';
import StationsDropdown from '../StationsDropdown';
import StationsList from '../StationsList';

interface NewCookFormProps {
	chef: KDSChefUser;
	handleCloseModal: () => void;
	closeForm: () => void;
}

const EditableCookForm = ({ chef, handleCloseModal, closeForm }: NewCookFormProps) => {
	const [updatedChef, setUpdatedChef] = useState({ ...chef });
	const [updateChefUser] = useMutation(UPDATE_CHEF_USER);

	const { kitchen, name, session, stations, brand, country, id } = updatedChef;

	const removeStation = (station: string) => {
		setUpdatedChef((prevChef) => ({ ...prevChef, stations: prevChef.stations.filter((s) => s !== station) }));
	};

	const addStation = (station: string) => {
		const existingStation = stations.find((s) => s === station);
		if (!existingStation) {
			setUpdatedChef((prevChef) => ({ ...prevChef, stations: [...prevChef.stations, station] }));
		}
	};

	const handleUpdateChef = async () => {
		await updateChefUser({
			variables: { id: id, input: { kitchen, name, session, stations, brand, country } },
			onCompleted: () => {
				toast.success('Chef updated successfully');
				closeForm();
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	return (
		<Box display={'flex'} style={{ width: '100%' }} flexDirection={'column'} sx={{ height: '100%' }}>
			<Stack style={{ width: '100%' }}>
				<label style={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px', paddingBottom: '4px' }}>Cook Name</label>
				<InputField
					debounce
					value={name}
					placeholder="Enter Cook Name"
					sx={{ width: '100%', border: 1, borderRadius: '8px', padding: 1 }}
					onChange={(data: any) => setUpdatedChef((prevChef) => ({ ...prevChef, name: data }))}
				/>
			</Stack>

			<Stack sx={{ display: 'flex', direction: 'row', width: '100%', justifyContent: 'space-between', paddingTop: '12px' }}>
				<label style={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px', paddingBottom: '4px' }}>Shift</label>
				<TextField
					select
					name="cookShift"
					value={session}
					placeholder={'Select Shift'}
					disabled={kitchen === Kitchen.BH1}
					InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
				>
					{kitchen === Kitchen.BH1 && (
						<MenuItem value={Session.all} onClick={() => setUpdatedChef((prevChef) => ({ ...prevChef, session: Session.all }))}>
							<Box>All Shift</Box>
						</MenuItem>
					)}
					<MenuItem
						value={Session.morning}
						onClick={() => setUpdatedChef((prevChef) => ({ ...prevChef, session: Session.morning }))}
					>
						<Box>Morning Shift</Box>
					</MenuItem>

					<MenuItem
						value={Session.evening}
						onClick={() => setUpdatedChef((prevChef) => ({ ...prevChef, session: Session.evening }))}
					>
						<Box>Evening Shift</Box>
					</MenuItem>
				</TextField>
			</Stack>

			<StationsDropdown selectStationHandler={addStation} stations={updatedChef.stations} />
			<StationsList stations={stations} stationClickHandler={removeStation} />

			<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 'auto' }}>
				<Button
					variant="text"
					sx={{
						color: caloTheme.palette.neutral500,
						fontWeight: 600,
						fontSize: '19px',
						lineHeight: '23px',
						marginLeft: '16px'
					}}
					onClick={() => handleCloseModal()}
				>
					Close
				</Button>
				<Button
					variant="contained"
					sx={{
						py: '12px',
						width: '50%',
						color: caloTheme.palette.white,
						backgroundColor: caloTheme.palette.primary500,
						borderRadius: '8px',
						fontWeight: 600,
						fontSize: '19px',
						lineHeight: '23px',
						boxShadow: 'none'
					}}
					disabled={areChefsEqual(chef, updatedChef)}
					onClick={() => handleUpdateChef()}
				>
					Confirm Changes
				</Button>
			</Stack>
		</Box>
	);
};
export default EditableCookForm;
