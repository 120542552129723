import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { Popup } from '../../../../components';
import { KDSChefUser, KDSFoodComponentBatch } from '../../../../libs/interfaces';
import SelectCookPopup from '../SelectCookPopup';
import { Styles } from './style';

interface SelectCookButtonProps {
	chefs: KDSChefUser[];
	foodComponentBatch?: KDSFoodComponentBatch;
	selectCookModalRef: any;
	handleSelectChef: (chef: string) => void;
	showJustIcon?: boolean;
	selectedCook?: string;
}

const SelectCookButton = ({
	chefs,
	foodComponentBatch,
	selectCookModalRef,
	handleSelectChef,
	showJustIcon,
	selectedCook
}: SelectCookButtonProps) => (
	<Tooltip title="There are no Chefs" placement="top" arrow disableHoverListener={!!chefs.length}>
		<span>
			<LoadingButton
				loading={false}
				id="delivery-action-button"
				aria-haspopup="true"
				disabled={!chefs.length}
				disableElevation
				onClick={() => selectCookModalRef.current?.open()}
				variant="outlined"
				aria-label="meal-actions"
				sx={showJustIcon ? Styles.justIconStyle : Styles.buttonWithText}
				endIcon={<ArrowDownIcon />}
			>
				{showJustIcon ? '' : foodComponentBatch?.chef || selectedCook || 'Select Cook'}
			</LoadingButton>
			<Popup
				ref={selectCookModalRef}
				onClose={() => selectCookModalRef.current?.close()}
				style={{
					fullWidth: true,
					maxWidth: 'md',
					borderRadius: '10px'
				}}
			>
				<SelectCookPopup chefs={chefs} handleSelectChef={handleSelectChef} />
			</Popup>
		</span>
	</Tooltip>
);

export default SelectCookButton;
