import { format } from 'date-fns/fp';
import { sortBy } from 'lodash-es';

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Stack, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { ProductivityMetrics } from '../../../../libs/interfaces';
import { getFilteredFoods } from '../../helpers';
import { Filters } from '../../ProductivityMetrics';
import SectionTableRow from '../SectionTableRow';

interface Props {
	productivityMetrics: ProductivityMetrics;
	isFirst: boolean;
	filters: Filters;
}

const SectionTable = ({ productivityMetrics, isFirst, filters }: Props) => {
	const theme = useTheme();
	const filteredFoods = getFilteredFoods(filters, productivityMetrics.foods);
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.grey[100],
			color: theme.palette.text.primary,
			fontSize: '14px',
			fontWeight: 500,
			width: `${100 / 7}%`
		}
	}));

	return (
		<Stack
			justifyContent="space-between"
			direction="column"
			alignItems="center"
			spacing={2}
			sx={{ width: '98%', m: 2, mt: isFirst ? '-30px !important' : 0 }}
		>
			<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ my: 2, width: '100%' }}>
				<Typography sx={{ color: theme.palette.neutral900, fontSize: '24px' }}>
					{format('dd-MM-yyy')(new Date(productivityMetrics.day))}
				</Typography>
			</Stack>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell>Meal name</StyledTableCell>
						<StyledTableCell>No. of components</StyledTableCell>
						<StyledTableCell>Modified / Total</StyledTableCell>
						<StyledTableCell>Actual portioning</StyledTableCell>
						<StyledTableCell>Predicted portioning</StyledTableCell>
						<StyledTableCell>
							<Stack direction="row" justifyContent="flex-start" alignItems="center">
								Time per meal
								<Tooltip title="Actual portioning time / total no. of meals" arrow placement="bottom">
									<InfoRoundedIcon sx={{ color: caloTheme.palette.action.active, mx: 1 }} />
								</Tooltip>
							</Stack>
						</StyledTableCell>
						<StyledTableCell>Cost</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredFoods.length > 0 ? (
						sortBy(filteredFoods, 'name.en').map((food) => (
							<SectionTableRow key={`${food.id}#${Math.random()}`} productivityMetricsFood={food} filters={filters} />
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={9}
								sx={{
									minWidth: 150,
									p: 1,
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									textAlign: 'center',
									border: 'none'
								}}
							>
								No Data
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Stack>
	);
};

export default SectionTable;
