import { Typography } from '@mui/material';
import { FoodPortioningStatus } from '../../../libs/enums';
import { PortioningStatusAttributes } from '../../../libs/interfaces';

interface AssemblyStatusItemProps {
	attributes: PortioningStatusAttributes;
}

const AssemblyStatusItem = ({ attributes }: AssemblyStatusItemProps) => {
	const { status, time } = attributes;

	const text =
		status === FoodPortioningStatus.resumeAssembling || status === FoodPortioningStatus.pauseAssembling
			? status.split(' ')[1]
			: status;

	if (time) {
		return <Typography fontSize="20px">{status === FoodPortioningStatus.completed ? text + ' in ' : text + ' for '}</Typography>;
	} else {
		return (
			<Typography fontSize="20px" textTransform={'capitalize'}>
				{status}
			</Typography>
		);
	}
};

export default AssemblyStatusItem;
