import { keyBy } from 'lodash-es';

import Box from '@mui/material/Box';

import EmptyMessage from '../../components/EmptyMessage';
import { KDSFood, KDSFoodComponent, Session } from '../../libs/interfaces';
import { useDocumentMedia } from '../../libs/utils/document';
import { getFoodsWithNonZeroQuantity } from '../../libs/utils/foodHelper';
import MealsStatusTable from './MealsStatusTable';
import MealStatusMobileList from './MealStatusMobileList';

interface MealsStatusProps {
	kitchen: string;
	foods: KDSFood[];
	foodComponents: KDSFoodComponent[];
	shift: Session;
}

const MealsStatus = ({ shift, kitchen, foods, foodComponents }: MealsStatusProps) => {
	const foodsWithNonZeroQuantity = getFoodsWithNonZeroQuantity(foods);
	const { isMobile } = useDocumentMedia();

	return isMobile ? (
		<Box sx={{ width: '95%', my: 2, display: 'flex', flexDirection: 'column', ml: 2.5 }}>
			{foodsWithNonZeroQuantity.length > 0 ? (
				<MealStatusMobileList
					shift={shift}
					kitchen={kitchen}
					foods={foodsWithNonZeroQuantity}
					foodComponents={keyBy(foodComponents, 'id')}
				/>
			) : (
				<EmptyMessage label={'no data'} />
			)}
		</Box>
	) : (
		<div style={{ minHeight: '100vh', width: '99%' }}>
			{foodsWithNonZeroQuantity.length > 0 ? (
				<MealsStatusTable
					shift={shift}
					kitchen={kitchen}
					foods={foodsWithNonZeroQuantity}
					foodComponents={keyBy(foodComponents, 'id')}
				/>
			) : (
				<EmptyMessage label={'no data'} />
			)}
		</div>
	);
};
export default MealsStatus;
