import { sortBy } from 'lodash-es';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { KDSFoodComponent } from '../../../../libs/interfaces';
import WastageTableRow from '../WastageTableRow';

interface WastageTableProps {
	foodComponents: KDSFoodComponent[];
}

const WastageTable = ({ foodComponents }: WastageTableProps) => {
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral100,
			color: theme.palette.common.black,
			border: 'none',
			fontSize: 16,
			fontWeight: 600
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	return (
		<Table stickyHeader sx={{ m: 2, ml: '25px', width: '100%' }}>
			<TableHead>
				<TableRow>
					<StyledTableCell>Component Name</StyledTableCell>
					<StyledTableCell sx={{ textAlign: 'center' }}>Actual Raw Weight</StyledTableCell>
					<StyledTableCell sx={{ textAlign: 'center' }}>Actual Cooked Weight</StyledTableCell>
					<StyledTableCell sx={{ textAlign: 'center' }}>Leftover</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '150px', textAlign: 'center' }}>Wastage</StyledTableCell>
					<StyledTableCell>Purchasing Cost</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{sortBy(foodComponents, 'name.en').map((foodComponent) => (
					<WastageTableRow key={foodComponent.id} foodComponent={foodComponent} />
				))}
			</TableBody>
		</Table>
	);
};

export default WastageTable;
