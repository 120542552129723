import { useEffect, useRef, useState } from 'react';

import { Kitchen } from '@calo/types';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Button, Card, CardContent, FormControl, IconButton, InputAdornment, InputLabel, Stack, Typography } from '@mui/material';

import { caloTheme } from '../../../assets/themes/calo';
import { InputField, Popup } from '../../../components';
import { ProductivityMetrics } from '../../../libs';
import { getCurrencyFromKitchen, roundToTwoDecimals } from '../../../libs/utils';
import { formatTime, getTotalAverageTimePerMeal, getTotalCostPerMinute, getTotalPortioningTime } from '../helpers';
import { CostVariables, Filters } from '../ProductivityMetrics';

interface Props {
	title: string;
	filters: Filters;
	setFilters: React.Dispatch<React.SetStateAction<Filters>>;
	type: 'actual' | 'predicted';
	productivityMetrics: ProductivityMetrics[];
	kitchen: Kitchen;
}

const Header = ({ title, filters, setFilters, type, productivityMetrics, kitchen }: Props) => {
	const ref = useRef<any>();
	const data = type === 'actual' ? filters.actual : filters.predicted;
	const [costVariables, setCostVariables] = useState<CostVariables>(data);
	const totalCostPerMinute = getTotalCostPerMinute(data);
	const totalTime = getTotalPortioningTime(productivityMetrics, filters.shift, type) / (data.numberOfStations ?? 1);
	const totalMinuets = Math.floor(totalTime / 1000 / 60);
	const metrics = [
		{
			label: 'PORTIONING TIME',
			value: formatTime(totalTime)
		},
		{
			label: 'AVERAGE TIME PER MEAL',
			value: formatTime(getTotalAverageTimePerMeal(productivityMetrics, filters.shift, type))
		},
		{
			label: 'COST',
			value: getCurrencyFromKitchen(kitchen) + ' ' + roundToTwoDecimals(totalMinuets * totalCostPerMinute)
		}
	];

	const handleApplyFilters = () => {
		setFilters({
			...filters,
			...(type === 'actual' ? { actual: costVariables } : { predicted: costVariables })
		});
		ref.current?.close();
	};
	const clearCostVariables = () => {
		setCostVariables({ numberOfStations: 1, staffPerStation: 0, salaryPerStaff: 0 });
		setFilters({
			...filters,
			...(type === 'actual'
				? { actual: { numberOfStations: 1, staffPerStation: 0, salaryPerStaff: 0 } }
				: { predicted: { numberOfStations: 1, staffPerStation: 0, salaryPerStaff: 0 } })
		});
		ref.current?.close();
	};

	useEffect(() => {
		clearCostVariables();
	}, [kitchen]);

	return (
		<Stack justifyContent="space-between" direction="column" alignItems="center" spacing={2} sx={{ width: '98%', m: 2 }}>
			<Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
				<Typography sx={{ fontSize: '20px', fontWeight: 500, letterSpacing: '0.15px', color: caloTheme.palette.text.primary }}>
					{title}
				</Typography>
				<IconButton onClick={() => ref.current?.open()}>
					<SettingsRoundedIcon sx={{ color: caloTheme.palette.text.primary }} />
				</IconButton>
			</Stack>
			<Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
				{metrics.map((metric, index) => (
					<Card
						key={index}
						sx={{
							backgroundColor: caloTheme.palette.grey[100],
							boxShadow: 'none',
							borderRadius: '12px',
							width: 'calc(33.33% - 8px)'
						}}
					>
						<CardContent sx={{ '&:last-child': { paddingBottom: 1 } }}>
							<Typography
								sx={{ fontSize: '14px', fontWeight: 500, letterSpacing: '0.17px', color: caloTheme.palette.text.secondary }}
							>
								{metric.label}
							</Typography>
							<Typography sx={{ fontSize: '24px', color: caloTheme.palette.text.primary, mt: 1 }}>{metric.value}</Typography>
						</CardContent>
					</Card>
				))}
			</Stack>
			<Popup
				ref={ref}
				onClose={() => ref.current?.close()}
				title={`Calculate ${type} cost`}
				showCloseButton={true}
				showDivider={true}
				style={{
					fullWidth: true,
					borderRadius: '16px',
					maxWidth: 'xs'
				}}
			>
				<Stack direction="column" justifyContent="space-between" alignItems="center" spacing={6} sx={{ my: 2 }}>
					<Stack direction="column" justifyContent="space-between" alignItems="start" spacing={4} sx={{ mx: 2, width: '100%' }}>
						<FormControl fullWidth>
							<InputLabel htmlFor="Stations">Stations</InputLabel>
							<InputField
								isOutlined={true}
								id="Stations"
								value={costVariables?.numberOfStations ?? 1}
								onChange={(event) =>
									setCostVariables((old) => ({ ...old, numberOfStations: Number(event) <= 0 ? 1 : Number(event) }))
								}
								label="Stations"
								placeholder="Enter number of stations"
								type="number"
								onFocus={(event) => event.target.select()}
							/>
						</FormControl>

						<FormControl fullWidth>
							<InputLabel htmlFor="Staff per station">Staff per station</InputLabel>
							<InputField
								isOutlined={true}
								id="Staff per station"
								value={costVariables?.staffPerStation ?? 0}
								onChange={(event) => setCostVariables((old) => ({ ...old, staffPerStation: Number(event) }))}
								label="Staff per station"
								placeholder="Enter number of staff"
								type="number"
								onFocus={(event) => event.target.select()}
							/>
						</FormControl>

						<FormControl fullWidth>
							<InputLabel htmlFor="Monthly salary per staff">Monthly salary per staff</InputLabel>
							<InputField
								isOutlined={true}
								id="Monthly salary per staff"
								value={costVariables?.salaryPerStaff ?? 0}
								onChange={(event) => setCostVariables((old) => ({ ...old, salaryPerStaff: Number(event) }))}
								label="Monthly salary per staff"
								placeholder="Enter salary per staff"
								type="number"
								onFocus={(event) => event.target.select()}
								startAdornment={<InputAdornment position="start">{getCurrencyFromKitchen(kitchen)}</InputAdornment>}
							/>
						</FormControl>
					</Stack>
					<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} width={'100%'}>
						<Button variant="outlined" sx={{ width: '40%', p: '8px 22px' }} onClick={clearCostVariables}>
							CLEAR
						</Button>
						<Button
							variant="contained"
							sx={{ color: caloTheme.palette.white, width: '40%', p: '8px 22px' }}
							onClick={handleApplyFilters}
						>
							CALCULATE
						</Button>
					</Stack>
				</Stack>
			</Popup>
		</Stack>
	);
};

export default Header;
