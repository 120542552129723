import { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FoodComponent } from '../../../libs/interfaces';
import { BatchQuantityInput } from '../BatchQuantityInput';
import { CookedWeightTable } from '../CookedWeightTable';
import FinalProduct from '../FinalProduct';
import { IngredientsTable } from '../IngredientsTable';
import MethodsTable from '../MethodsTable';

interface RecipePageProps {
	newCookedWeight: number;
	selectedFoodComponent: FoodComponent;
	setNewCookedWeight: (value: number) => void;
	selectChildComponent: (compId: string, name: string) => void;
}

const RecipePage = ({ newCookedWeight, setNewCookedWeight, selectedFoodComponent, selectChildComponent }: RecipePageProps) => {
	const theme = useTheme();
	const [numberOfBatches, setNumberOfBatches] = useState(0);

	const handleCookedWeightChange = (value: number) => {
		setNewCookedWeight(value);
		setNumberOfBatches(0);
	};

	useEffect(() => {
		setNewCookedWeight(1);
		setNumberOfBatches(0);
	}, [selectedFoodComponent]);

	return (
		<Stack direction={'row'} justifyContent="space-between" alignItems="start" sx={{ width: '96%' }}>
			<Stack
				direction={'column'}
				justifyContent="space-between"
				alignItems="start"
				spacing={1}
				sx={{ minWidth: '30%', maxWidth: '30%', width: '1133px' }}
			>
				<BatchQuantityInput
					setNewCookedWeight={setNewCookedWeight}
					foodComponent={selectedFoodComponent}
					numberOfBatches={numberOfBatches}
					setNumberOfBatches={setNumberOfBatches}
				/>
				<CookedWeightTable cookedWeight={newCookedWeight} setNewCookedWeight={handleCookedWeightChange} />
				<IngredientsTable
					foodComponent={selectedFoodComponent}
					selectChildComponent={selectChildComponent}
					newCookedWeight={newCookedWeight}
				/>
			</Stack>
			<Box sx={{ borderRight: 2, borderColor: theme.palette.neutral300, height: '100%', p: '10px' }} />
			<Stack sx={{ mt: 1, minWidth: '70%', maxWidth: '70%', width: '70%' }}>
				<MethodsTable selectedFoodComponent={selectedFoodComponent} />
				<Stack sx={{ width: '100%', ml: 2, pl: '15px' }}>
					<FinalProduct selectedFoodComponent={selectedFoodComponent} />
				</Stack>
			</Stack>
		</Stack>
	);
};

export default RecipePage;
