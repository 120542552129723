import { useEffect, useState } from 'react';

import { keyBy, uniqBy } from 'lodash-es';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';
import { Dictionary } from '@calo/types';

import { FCStatus } from '../libs/enums';
import {
	FOOD_COMPONENT_STATUS_CHANGE_SUBSCRIPTION,
	GraphQLClient,
	LIST_FOOD_COMPONENTS_BY_RANGE_QUERY,
	SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE
} from '../libs/graphQL';
import { KDSFoodComponent } from '../libs/interfaces';

interface UseListComponentByRangeQueryProps {
	kitchen: string;
	startDate: string;
	endDate: string;
	limit?: number;
	next?: string;
	filters?: Dictionary<boolean>;
}

const useListComponentByRangeQuery = ({
	kitchen,
	startDate,
	endDate,
	limit,
	next,
	filters
}: UseListComponentByRangeQueryProps) => {
	const [foodComponents, setFoodComponents] = useState<KDSFoodComponent[]>([]);
	const [newNext, setNewNext] = useState<string | undefined>(next);
	const { loading, refetch, fetchMore, subscribeToMore } = useQuery(LIST_FOOD_COMPONENTS_BY_RANGE_QUERY, {
		variables: { kitchen, startDate, endDate, limit, next, filters },
		onCompleted: (data) => {
			if (data?.listFoodComponentByRange?.components) {
				setFoodComponents(data.listFoodComponentByRange.components);
			}
			if (data?.listFoodComponentByRange?.next) {
				setNewNext(data.listFoodComponentByRange.next);
			} else {
				setNewNext(undefined);
			}
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite'
	});

	useSubscription(FOOD_COMPONENT_STATUS_CHANGE_SUBSCRIPTION);
	useSubscription(SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE);

	const handleFetchMore = () => {
		if (newNext) {
			fetchMore({
				variables: { kitchen, startDate, endDate, limit, next: newNext, filters },
				updateQuery: (prev: any, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					setNewNext(fetchMoreResult.listFoodComponentByRange.next || undefined);
					const newFoodComponents = uniqBy(
						[
							...(prev.listFoodComponentByRange?.components || []),
							...(fetchMoreResult?.listFoodComponentByRange?.components || [])
						],
						'id'
					);
					return Object.assign({}, prev, {
						listFoodComponentByRange: {
							components: newFoodComponents,
							next: fetchMoreResult?.listFoodComponentByRange?.next
						}
					});
				}
			});
		}
	};

	const subscribeToMoreComponents = () =>
		subscribeToMore({
			document: SUBSCRIBE_TO_BATCH_CREATE_COMPONENT_SHORTAGE,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscribeToBatchCreateComponentShortage = subscriptionData.data.subscribeToBatchCreateComponentShortage;
				const keyedById: Dictionary<KDSFoodComponent> = keyBy(subscribeToBatchCreateComponentShortage, 'id');
				const updatedComponents: KDSFoodComponent[] = (prev.listFoodComponentByRange.components as KDSFoodComponent[]).map(
					(c) => ({
						...c,
						shortages: keyedById[c.id] ? keyedById[c.id].shortages : c.shortages
					})
				);

				for (const [key, value] of Object.entries(keyedById)) {
					if (!updatedComponents.some((c) => c.id === key)) {
						updatedComponents.push(value);
					}
				}
				return Object.assign({}, prev, {
					listFoodComponentByRange: {
						components: [...updatedComponents],
						next: prev.listFoodComponentByRange.next
					}
				});
			}
		});

	useEffect(() => {
		subscribeToMoreComponents();
	}, []);

	const clearData = () => {
		setFoodComponents([]);
		setNewNext(undefined);
		GraphQLClient.clearStore();
	};

	const getNumberOfPendingShortages = () => {
		let numberOfPendingShortages = 0;
		for (const foodComponent of foodComponents) {
			numberOfPendingShortages +=
				foodComponent.shortages?.filter((s) => s.cookingStations.some((c) => c.status === FCStatus.pending)).length ?? 0;
		}
		return numberOfPendingShortages;
	};

	return {
		componentsLoading: loading,
		foodComponents,
		refetchComponents: refetch,
		hasNext: Boolean(newNext),
		handleFetchMoreComponents: handleFetchMore,
		clearComponentsData: clearData,
		numberOfPendingShortages: getNumberOfPendingShortages()
	};
};

export default useListComponentByRangeQuery;
