import { useState } from 'react';

import { Brand } from '@calo/types';
import { Stack, useTheme } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { caloTheme } from '../../../../assets/themes/calo';
import { FoodComponentStation } from '../../../../libs/enums';
import { KDSFoodComponent, KDSFoodComponentStation } from '../../../../libs/interfaces';
import { getTableAttributes } from '../../../../libs/utils/helperFunctions';
import ComponentProcessTime from '../../ComponentProcessTime/ComponentProcessTime';
import ActionButtons from './ActionButtons';

interface CombinedShortages {
	ids: string[];
	totalWeight: number;
	cookingStations: KDSFoodComponentStation[];
}

interface SectionTableRowProps {
	statusAttributes: {
		isCheckable: boolean;
		lastUncheckedStation: string;
	};
	isChecked: boolean;
	foodComponent: KDSFoodComponent;
	selectedComponentStation: FoodComponentStation;
	setSelectedComponentStation: (value: FoodComponentStation) => void;
	combinedShortages?: CombinedShortages;
}

const MobileSectionTableRow = ({
	statusAttributes,
	foodComponent,
	isChecked,
	selectedComponentStation,
	setSelectedComponentStation,
	combinedShortages
}: SectionTableRowProps) => {
	const [isCheckedState, setIsCheckedState] = useState(isChecked);

	const theme = useTheme();
	const tableAttributes = getTableAttributes(statusAttributes.lastUncheckedStation, theme);

	const currentStation = foodComponent.cookingStations.find((station) => station.name === selectedComponentStation);

	const actionButtons = () => {
		if (combinedShortages) {
			return (
				<ActionButtons
					foodComponent={foodComponent}
					statusAttributes={statusAttributes}
					tableName={selectedComponentStation}
					combinedShortages={combinedShortages}
					isChecked={isCheckedState}
					setIsCheckedState={setIsCheckedState}
				/>
			);
		} else {
			return (
				<ActionButtons
					foodComponent={foodComponent}
					tableName={selectedComponentStation}
					statusAttributes={statusAttributes}
					combinedShortages={undefined}
				/>
			);
		}
	};

	return (
		<TableRow>
			<Stack
				sx={{
					borderBottom: '1px solid ' + theme.palette.neutral200,
					display: 'flex',
					flexDirection: 'column',
					gap: 3,
					px: '8px',
					py: '16px',
					width: '100%'
				}}
			>
				<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<Typography
						variant="h2"
						sx={{ fontWeight: 400, fontFamily: caloTheme.typography.fontFamily, lineHeight: '19px', fontSize: '16px', ml: '2px' }}
					>
						{foodComponent.name.en}
					</Typography>
					{!currentStation?.processTime && (
						<Typography
							sx={{
								borderRadius: '16px',
								padding: '8px',
								width: '120px',
								textAlign: 'center',
								bgcolor: tableAttributes.color,
								display: statusAttributes.lastUncheckedStation === selectedComponentStation ? 'none' : '',
								fontWeight: 400,
								fontSize: '14px',
								lineHeight: '17px',
								color: caloTheme.palette.neutral900
							}}
							onClick={() => setSelectedComponentStation(statusAttributes.lastUncheckedStation as FoodComponentStation)}
						>
							{FoodComponentStation[statusAttributes.lastUncheckedStation as keyof typeof FoodComponentStation]}
						</Typography>
					)}
				</Stack>
				{foodComponent.brand !== Brand.CALO && (
					<Typography variant="h4" sx={{ mr: 1, fontWeight: 400, fontSize: '23px', lineHeight: '28px', color: '#FF9900' }}>
						M
					</Typography>
				)}

				<Stack
					sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
				>
					{currentStation && currentStation.processTime && !combinedShortages && (
						<ComponentProcessTime
							combinedShortages={combinedShortages}
							foodComponent={foodComponent}
							processTime={currentStation.processTime}
							endTime={currentStation.endTime}
						/>
					)}
					{statusAttributes.isCheckable || currentStation?.endTime ? (
						(currentStation && !currentStation.endTime) || combinedShortages ? (
							actionButtons()
						) : (
							<Typography
								sx={{ color: theme.palette.primary500, mr: '20px', fontWeight: 600, width: '100%', textAlign: 'center' }}
							>
								Completed
							</Typography>
						)
					) : (
						<Typography sx={{ color: theme.palette.neutral600, fontWeight: 600, mr: 3, width: '100%', textAlign: 'center' }}>
							Pending
						</Typography>
					)}
				</Stack>
				{combinedShortages && (
					<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<Typography sx={{ fontWeight: 400, fontSize: '14px', color: caloTheme.palette.neutral900 }}>
							Quantity: {combinedShortages?.totalWeight || 0} g
						</Typography>

						<Typography sx={{ fontWeight: 400, fontSize: '10px', color: caloTheme.palette.red }}>Shortage</Typography>
					</Stack>
				)}
			</Stack>
		</TableRow>
	);
};

export default MobileSectionTableRow;
