import { orderBy } from 'lodash-es';
import { KDSFoodComponent } from '../../../libs/interfaces';
import ShortageDisplayRow from '../ShortageDisplayRow';

interface ComponentsShortageTableProps {
	foodComponent: KDSFoodComponent;
}

const ShortageDisplayTable = ({ foodComponent }: ComponentsShortageTableProps) => (
	<>
		{foodComponent.shortages &&
			foodComponent.shortages.length > 0 &&
			orderBy(foodComponent.shortages, [(sh) => new Date(sh.createdAt)], ['desc']).map((shortage, index) => (
				<ShortageDisplayRow
					key={shortage.id}
					shortage={shortage}
					foodComponent={foodComponent}
					index={index}
					length={foodComponent?.shortages?.length || 0}
				/>
			))}
	</>
);

export default ShortageDisplayTable;
