import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { caloTheme } from '../../../assets/themes/calo';
import { KDSFoodComponent } from '../../../libs/interfaces';
import { formatDate } from '../../../libs/utils/helperFunctions';

interface SectionHeaderProps {
	foodComponents: KDSFoodComponent[];
}

const SectionHeader = ({ foodComponents }: SectionHeaderProps) => {
	const foodComponent = foodComponents && foodComponents.length > 0 ? foodComponents[0] : null;
	return (
		<Grid container sx={{ width: '75%', mb: 2, mt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
			{foodComponent && foodComponent.date !== '' && (
				<Grid container sx={{ width: '100%' }} direction="row" justifyContent="flex-start" alignItems="center">
					<Box sx={{ mr: '5px', fontFamily: caloTheme.typography.fontFamily, fontSize: '28px' }}>
						{foodComponent.date && formatDate(foodComponent.date, 'dd-MM-yyyy')}
					</Box>
				</Grid>
			)}
		</Grid>
	);
};

export default SectionHeader;
