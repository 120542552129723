import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { KDSChefUser } from '../../../../../libs/interfaces';
import CookItem from './CookItem';

interface CookListProps {
	chefs: KDSChefUser[];
	setEditableCook: React.Dispatch<React.SetStateAction<KDSChefUser | null | undefined>>;
	setIsNewCook: React.Dispatch<React.SetStateAction<boolean>>;
}

const CookList = ({ chefs, setEditableCook, setIsNewCook }: CookListProps) => {
	const theme = useTheme();

	return (
		<>
			<Stack sx={{ maxHeight: '85%' }}>
				<List
					sx={{
						height: '100%',
						gap: '12px',
						display: 'flex',
						flexDirection: 'column',
						overflowX: 'hidden',
						'&::-webkit-scrollbar': { height: '0.4em', width: '12px' },
						'&::-webkit-scrollbar-thumb': { backgroundColor: '#888' }
					}}
				>
					{chefs.map((chef) => (
						<CookItem key={chef.id} chef={chef} setEditableCook={setEditableCook} />
					))}
				</List>
			</Stack>
			<Button
				onClick={() => setIsNewCook(true)}
				sx={{
					marginTop: 'auto',
					fontSize: '18px',
					color: theme.palette.primary500,
					display: 'flex',
					alignSelf: 'flex-end',
					marginLeft: 'auto',
					border: '2px solid ' + theme.palette.primary500,
					borderRadius: '8px',
					marginRight: '32px',
					width: '130px'
				}}
			>
				Add Cook
			</Button>
		</>
	);
};

export default CookList;
