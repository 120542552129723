import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { format } from 'date-fns/fp';
import { chunk } from 'lodash-es';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, CardMedia, IconButton, Typography } from '@mui/material';

import { caloTheme } from '../../../../../assets/themes/calo';
import { KDSFoodComponentBatch } from '../../../../../libs/interfaces';
import { useDocumentMedia } from '../../../../../libs/utils/document';
import { renderDotColor } from '../../../utils';

interface PreviousQualitiesProps {
	foodComponentBatch?: KDSFoodComponentBatch;
	openImagePreview: (imageUrl: string) => void;
}

const PreviousQualities = ({ foodComponentBatch, openImagePreview }: PreviousQualitiesProps) => {
	const [isExtended, setIsExtended] = useState(true);
	const { isMobile } = useDocumentMedia();
	if (!foodComponentBatch || foodComponentBatch.quality.length === 0) {
		return <></>;
	}
	return (
		<Box sx={{ width: '100%', height: 'auto', borderBottom: 1, borderBottomColor: 'lightgrey' }}>
			<Button
				onClick={() => setIsExtended(!isExtended)}
				sx={{
					color: 'black',
					width: '100%',
					height: 50,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<Typography variant="body1" fontWeight={600} sx={{ textTransform: 'none' }}>
					Previous Comments
				</Typography>
				{isExtended ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
			</Button>
			{isExtended && (
				<Box sx={{ width: '95%', height: 'auto', margin: 'auto' }}>
					<Carousel showArrows={!isMobile} showStatus={false}>
						{foodComponentBatch.quality.map((quality, index) => (
							<Box
								key={index}
								sx={{
									borderRadius: 2,
									width: '100%',
									marginBottom: 4,
									padding: 1,
									paddingX: isMobile ? 1 : 4,
									minHeight: 100,
									overflow: 'hidden',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start',
									backgroundColor: caloTheme.palette.neutral50
								}}
							>
								<Box marginBottom={1}>
									{chunk(quality.standards ?? [], 4).map((chunk, index) => (
										<Box
											key={index}
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
										>
											{chunk.map((standard, index) => (
												<Box key={index} display="flex" flexDirection="row" alignItems="center" mr={1}>
													<CircleIcon sx={{ color: renderDotColor(standard.rating), fontSize: '10px', marginRight: '2px' }} />
													<Typography fontWeight={600} variant="body2">
														{standard.name}
													</Typography>
												</Box>
											))}
										</Box>
									))}
								</Box>
								<Box marginBottom={1}>
									<Typography textAlign={'start'} variant="body1">
										{quality.comment}
									</Typography>
									{quality.commentImageURLs && quality.commentImageURLs.length > 0 && (
										<Box display={'flex'} flexDirection={'row'} overflow={'auto'}>
											{quality.commentImageURLs.map((image, index) => (
												<IconButton
													sx={{ padding: 0, margin: 0, marginTop: '10px', marginRight: '10px' }}
													key={index}
													onClick={() => openImagePreview(image)}
												>
													<CardMedia
														component="img"
														image={`${process.env.REACT_APP_BUCKET_URL}/${image}/square@1x.jpg`}
														alt="placeholder"
														sx={{ maxWidth: 50, maxHeight: 50, height: 50, objectFit: 'cover', borderRadius: '8px' }}
													/>
												</IconButton>
											))}
										</Box>
									)}
								</Box>
								<Box display="flex" flexDirection="row" alignItems={'center'}>
									<Typography fontWeight={600} marginRight={1} variant="body1">
										{quality.actor.name}
									</Typography>
									<Typography variant="body2">{format('dd/MM/yyyy, hh:mm a ')(new Date(quality.createdAt))}</Typography>
								</Box>
							</Box>
						))}
					</Carousel>
				</Box>
			)}
		</Box>
	);
};

export default PreviousQualities;
