import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';

import { QualityCheckStatus } from '../../../../libs/interfaces';
import { useDocumentMedia } from '../../../../libs/utils/document';
import { Styles } from './styles';

interface ReviewButtonProps {
	setIsReviewDrawerOpenned: React.Dispatch<React.SetStateAction<boolean>>;
	showJustIcon?: boolean;
	status?: QualityCheckStatus | undefined;
}

const ReviewButton = ({ setIsReviewDrawerOpenned, showJustIcon, status }: ReviewButtonProps) => {
	const { isMobile } = useDocumentMedia();
	if (status === QualityCheckStatus.accepted) {
		return (
			<Button
				onClick={() => setIsReviewDrawerOpenned(true)}
				variant={showJustIcon ? 'contained' : 'text'}
				color={'primary'}
				aria-label="review-quality-track-button"
				endIcon={showJustIcon ? <CheckIcon sx={{ color: 'white' }} /> : <></>}
				sx={showJustIcon ? Styles.justIconStyle : isMobile ? Styles.fullButtonMobile : Styles.fullApprovedButtonDesktop}
			>
				{showJustIcon ? '' : 'Approved'}
			</Button>
		);
	} else if (status === QualityCheckStatus.rejected) {
		return (
			<Button
				onClick={() => setIsReviewDrawerOpenned(true)}
				variant={showJustIcon ? 'contained' : 'text'}
				color={'error'}
				aria-label="review-quality-track-button"
				endIcon={showJustIcon ? <CloseIcon /> : <></>}
				sx={showJustIcon ? Styles.justIconStyle : isMobile ? Styles.fullButtonMobile : Styles.fullRejectedButtonDesktop}
			>
				{showJustIcon ? '' : 'Rejected'}
			</Button>
		);
	}
	return (
		<Button
			onClick={() => setIsReviewDrawerOpenned(true)}
			variant={showJustIcon ? 'contained' : 'outlined'}
			color={'primary'}
			aria-label="review-quality-track-button"
			sx={showJustIcon ? Styles.justIconStyle : isMobile ? Styles.fullButtonMobile : Styles.fullButtonDesktop}
		>
			{showJustIcon ? '' : 'Review'}
		</Button>
	);
};

export default ReviewButton;
