import { format } from 'date-fns/fp';
import enLocale from 'date-fns/locale/en-GB';
import { useState } from 'react';

import { Icon as Iconify } from '@iconify/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	Menu,
	Radio,
	RadioGroup,
	Stack,
	Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { caloTheme } from '../../../assets/themes/calo';
import DatePicker from '../../../components/DatePicker';
import { DeliveryTime, DriverDispatchType } from '../../../libs/enums';
import { KDSDeliveryData, KDSDriver } from '../../../libs/interfaces';
import Export from '../Export';

interface DriverTableHeaderProps {
	kitchen: string;
	driverDate: string;
	drivers: KDSDriver[];
	deliveryTime: DeliveryTime;
	deliveries: KDSDeliveryData[];
	selectedDataType: DriverDispatchType;
	setDriverDate: (value: string) => void;
	setDeliveryTime: (value: DeliveryTime) => void;
	setSelectedDataType: (value: DriverDispatchType) => void;
}

const DriverTableHeader = ({
	selectedDataType,
	setSelectedDataType,
	kitchen,
	drivers,
	deliveryTime,
	driverDate,
	setDriverDate,
	setDeliveryTime,
	deliveries
}: DriverTableHeaderProps) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [preFilters, setPreFilters] = useState({
		deliveryTime: deliveryTime,
		deliveryDate: driverDate
	});

	return (
		<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ m: 2, mb: 0, width: '100%' }}>
			<Stack
				sx={{
					display: 'flex',
					flexDirection: 'row',
					border: 1,
					borderColor: caloTheme.palette.neutral100,
					p: '5px',
					borderRadius: 2
				}}
			>
				<Button
					aria-label="close"
					onClick={() => setSelectedDataType(DriverDispatchType.caloDrivers)}
					sx={{
						backgroundColor: selectedDataType === DriverDispatchType.caloDrivers ? caloTheme.palette.primary100 : null,
						color:
							selectedDataType === DriverDispatchType.caloDrivers ? caloTheme.palette.primary500 : caloTheme.palette.neutral400,
						borderRadius: 2
					}}
				>
					<Iconify icon="carbon:user" width={24} />
				</Button>
				<Button
					aria-label="close"
					onClick={() => setSelectedDataType(DriverDispatchType.others)}
					sx={{
						backgroundColor: selectedDataType === DriverDispatchType.others ? caloTheme.palette.primary100 : null,
						color: selectedDataType === DriverDispatchType.others ? caloTheme.palette.primary500 : caloTheme.palette.neutral400,
						borderRadius: 2,
						mx: '2px'
					}}
				>
					<Iconify icon="iconoir:truck" width={24} />
				</Button>
			</Stack>
			<Stack direction="row" justifyContent="space-between">
				<Stack sx={{ my: 'auto', mr: 2 }}>
					<IconButton
						aria-haspopup="true"
						style={{ borderRadius: '100%' }}
						onClick={(event: any) => setAnchorEl(event.currentTarget)}
					>
						<Iconify icon="mingcute:filter-2-fill" width={21} color={caloTheme.palette.neutral900} />
					</IconButton>
					<Menu
						id="filter-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={() => setAnchorEl(null)}
						sx={{ width: 460, minWidth: 460, maxWidth: 460 }}
					>
						<Box sx={{ paddingTop: 2, paddingX: 2, width: 300 }}>
							<Typography
								sx={{
									fontWeight: 600,
									fontSize: '20px',
									fontStyle: 'normal',
									lineHeight: '24px'
								}}
							>
								Filters
							</Typography>
						</Box>
						<Box sx={{ width: '100%', padding: 2 }}>
							<Box sx={{ paddingBottom: 2 }}>
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
									<DatePicker
										label="Date"
										color={caloTheme.palette.black}
										date={preFilters ? new Date(preFilters.deliveryDate) : null}
										setDate={(date: Date | null | undefined) => {
											setPreFilters({
												...preFilters,
												deliveryDate: date ? format('yyyy-MM-dd')(new Date(date)) : format('yyyy-MM-dd')(Date.now())
											});
										}}
									/>
								</LocalizationProvider>
							</Box>
							<Box>
								<Box>
									<Accordion sx={{ backgroundColor: caloTheme.palette.neutral50, borderWidth: 0 }} variant="outlined">
										<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ width: '100%' }}>
											<Typography
												sx={{
													fontWeight: 600,
													fontSize: '16px',
													fontStyle: 'normal',
													lineHeight: '20px'
												}}
											>
												Delivery Time
											</Typography>
										</AccordionSummary>
										<Divider sx={{ color: caloTheme.palette.neutral300, borderWidth: 1, mx: 2, my: '-8px' }} />
										<AccordionDetails
											sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly' }}
										>
											<RadioGroup
												name="radio-buttons-group"
												defaultValue={deliveryTime}
												aria-labelledby="demo-radio-buttons-group-label"
												sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
											>
												<FormControlLabel
													disableTypography={true}
													sx={{
														display: 'flex',
														fontWeight: 400,
														width: '45%',
														mr: '4px',
														fontSize: '14px',
														lineHeight: '18px',
														fontFamily: caloTheme.typography.fontFamily,
														justifyContent: 'space-between',
														my: '4px'
													}}
													labelPlacement="end"
													value={DeliveryTime.morning}
													onChange={() => setPreFilters({ ...preFilters, deliveryTime: DeliveryTime.morning })}
													control={<Radio />}
													label="Morning"
												/>
												<FormControlLabel
													disableTypography={true}
													sx={{
														display: 'flex',
														fontWeight: 400,
														width: '45%',
														ml: '8px',
														fontSize: '14px',
														lineHeight: '18px',
														fontFamily: caloTheme.typography.fontFamily,
														justifyContent: 'space-between',
														my: '4px'
													}}
													labelPlacement="end"
													value={DeliveryTime.evening}
													onChange={() => setPreFilters({ ...preFilters, deliveryTime: DeliveryTime.evening })}
													control={<Radio />}
													label="Evening"
												/>
											</RadioGroup>
										</AccordionDetails>
									</Accordion>
								</Box>
								<Button
									onClick={() => {
										setDeliveryTime(preFilters.deliveryTime);
										setDriverDate(preFilters.deliveryDate);
										setAnchorEl(null);
									}}
									variant="outlined"
									sx={{
										display: 'flex',
										mx: 'auto',
										textTransform: 'capitalize',
										color: caloTheme.palette.primary500,
										mt: 2,
										borderRadius: '8px',
										fontSize: '16px',
										fontWeight: 600,
										lineHeight: '21px',
										borderWidth: 1,
										borderColor: caloTheme.palette.primary500,
										width: '150px'
									}}
								>
									Apply Filters
								</Button>
							</Box>
						</Box>
					</Menu>
				</Stack>
				<Stack display="flex" flexDirection={'row'}>
					<Export
						drivers={drivers}
						selectedDispatchType={selectedDataType}
						isDownloadDisabled={
							selectedDataType === DriverDispatchType.caloDrivers ? drivers.length === 0 : deliveries.length === 0
						}
						kitchen={kitchen}
						shift={deliveryTime}
						date={driverDate}
						deliveries={deliveries}
					/>
				</Stack>
			</Stack>
		</Grid>
	);
};
export default DriverTableHeader;
