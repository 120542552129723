import { format } from 'date-fns/fp';
import { isNil, keyBy, sortBy } from 'lodash-es';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { QualityMetrics } from '../../../../libs/interfaces';
import SectionTableRow from '../SectionTableRow';

interface Props {
	qualityMetric: QualityMetrics;
	name?: string;
	filterText?: string;
	isExpanded: boolean;
	setIsExpanded: (isExpanded: boolean) => void;
}

const SectionTable = ({ qualityMetric, name, filterText, isExpanded, setIsExpanded }: Props) => {
	const theme = useTheme();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral200,
			color: theme.palette.neutral900,
			fontSize: '12px',
			fontWeight: 600,
			border: 'none',
			width: '18%',
			textAlign: 'center',
			padding: '8px 15px'
		}
	}));
	const filteredQualityMetricFood = qualityMetric.food.filter((f) =>
		!isNil(name) && name?.length > 0 ? f.name.en.toLowerCase().includes(name.toLowerCase()) : true
	);
	const keyedQualityMetricsFoodComponent = keyBy(qualityMetric.components, 'id');
	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 2 }}>
				<Typography sx={{ color: theme.palette.neutral900, fontSize: '24px' }}>
					{format('dd-MM-yyy')(new Date(qualityMetric.day))}
				</Typography>
				{filterText && <Typography sx={{ fontSize: '24px', color: caloTheme.palette.neutral900 }}>{filterText}</Typography>}
			</Stack>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell sx={{ borderRadius: '8px 0 0 8px', textAlign: 'left !important' }}>Name</StyledTableCell>
						<StyledTableCell>Shift</StyledTableCell>
						<StyledTableCell>Weight</StyledTableCell>
						<StyledTableCell>Consistency Score</StyledTableCell>
						<StyledTableCell>Cost</StyledTableCell>
						<StyledTableCell
							onClick={() => setIsExpanded(!isExpanded)}
							sx={{ borderRadius: '0 8px 8px 0', width: '8% !important' }}
						>
							{isExpanded ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredQualityMetricFood.length > 0 ? (
						sortBy(filteredQualityMetricFood, 'name.en').map((food) => (
							<SectionTableRow
								key={`${food.id}#${Math.random()}`}
								qualityMetric={qualityMetric}
								qualityMetricsFood={food}
								isExpanded={isExpanded}
								keyedQualityMetricsFoodComponent={keyedQualityMetricsFoodComponent}
							/>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={9}
								sx={{
									minWidth: 150,
									p: 1,
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									textAlign: 'center',
									border: 'none'
								}}
							>
								No Data
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</>
	);
};

export default SectionTable;
