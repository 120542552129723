import { replace, sortBy } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Dictionary } from '@calo/types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Typography
} from '@mui/material';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import EmptyMessage from '../../../components/EmptyMessage';
import Modal from '../../../components/Modal';
import { BATCH_CREATE_COMPONENT_SHORTAGE } from '../../../libs/graphQL';
import { ComponentShortageInput, ComponentsQuantity, KDSFood, KDSFoodComponent } from '../../../libs/interfaces';
import { useDocumentMedia } from '../../../libs/utils/document';
import { handleMealSize } from '../../../libs/utils/helperFunctions';
import ShortageReportTableRow from '../TableRow';

interface ShortageReportingProps {
	keyedFoodComponents: Dictionary<KDSFoodComponent>;
	foods: KDSFood[];
}

interface MealSize {
	size: string;
	quantity: number;
	componentsQuantity: ComponentsQuantity[];
}

const getComponentQuantityBasedOnMealSize = (compId: string, mealSize: MealSize) =>
	mealSize.componentsQuantity?.find((c) => c.id === compId)?.quantity || 1;

const ShortageReporting = ({ keyedFoodComponents, foods }: ShortageReportingProps) => {
	const [amount, setAmount] = useState(0);
	const [food, setFood] = useState<KDSFood>();
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [shortages, setShortages] = useState<ComponentShortageInput[]>([]);
	const [batchCreateComponentShortage, { loading }] = useMutation(BATCH_CREATE_COMPONENT_SHORTAGE);
	const [mealSize, setMealSize] = useState<MealSize>({ size: '', quantity: 0, componentsQuantity: [] });
	const appContext = useContext(AppContext);
	const { isMobile } = useDocumentMedia();

	const handleAddReport = async () => {
		if (!loading && shortages.length > 0) {
			await batchCreateComponentShortage({
				variables: {
					input: shortages.map((s) => ({ ...s, foodName: { en: s.foodName.en, ar: s.foodName.ar } })),
					kitchen: food?.kitchen
				},
				onCompleted: () => {
					toast.success('Shortage has been reported');
				},
				onError: (error) => {
					toast.error(error.message);
				}
			});
			// eslint-disable-next-line unicorn/no-useless-undefined
			setFood(undefined);
			setMealSize({ size: '', quantity: 0, componentsQuantity: [] });
			setShortages([]);
			setAmount(0);
		} else {
			toast.error('There are no components in the report');
		}
	};

	const handleSelectFood = (food: KDSFood) => {
		setFood(food);
		setMealSize({ size: '', quantity: 0, componentsQuantity: [] });
		setAmount(0);
		setShortages([]);
		if (food.componentIds.length === 0) {
			toast.error('There are no components in this food');
		}
	};

	useEffect(() => {
		setShortages((old) =>
			old.map((shortage) => {
				const componentQtyFactor = getComponentQuantityBasedOnMealSize(
					replace(shortage.componentId, `${food?.date}#`, ''),
					mealSize
				);
				const fc = keyedFoodComponents[shortage.componentId];

				return { ...shortage, weight: Math.round(fc.cookedWeight * amount * componentQtyFactor) };
			})
		);
	}, [amount]);

	const styles = {
		mainBox: {
			mobile: {
				width: '100%',
				marginTop: 4
			},
			desktop: {
				width: '100%'
			}
		},
		firstStack: {
			mobile: {
				width: '100%',
				px: 1
			},
			desktop: {
				width: '50%',
				mr: 2,
				alignItems: 'center'
			}
		},
		secondStack: {
			mobile: {
				width: '100%',
				mr: 2,
				textAlign: 'center'
			},
			desktop: {
				width: '50%',
				alignItems: 'center'
			}
		},
		table: {
			mobile: {
				boxShadow: 'none',
				borderCollapse: 'separate',
				borderSpacing: '0 8px',
				width: '96%',
				mx: 1,
				mt: 1
			},
			desktop: {
				boxShadow: 'none',
				m: 3,
				mt: 0
			}
		},
		tableCellButton: {
			mobile: {
				p: 0,
				pt: 1
			},
			desktop: {}
		},
		input: {
			fontSize: '19px', // set your desired font size here
			border: 'none', // hide the border
			outline: 'none', // hide the outline when focused
			boxShadow: 'none' // hide the box shadow
		},
		label: {
			fontFamily: caloTheme.typography.fontFamily,
			fontWeight: 600,
			lineHeight: '23px',
			fontSize: '19px' // set your desired font size here
		}
	};

	return (
		<Box
			display={'flex'}
			flexDirection={isMobile ? 'column' : 'row'}
			style={{ ...(isMobile ? styles.mainBox.mobile : styles.mainBox.desktop) }}
		>
			<Stack
				display={'flex'}
				flexDirection={'column'}
				sx={{ ...(isMobile ? styles.firstStack.mobile : styles.firstStack.desktop) }}
			>
				{isMobile ? (
					<>
						<Stack sx={{ pt: 2 }}>
							<FormControl sx={{ width: '100%' }}>
								<InputLabel sx={{}}>{'Meal'}</InputLabel>
								<Select
									label="Meal"
									MenuProps={{
										PaperProps: {
											style: {
												marginLeft: '-8px',
												maxHeight: '300px'
											}
										}
									}}
									sx={{
										width: '100%',
										borderRadius: '8px'
									}}
									value={food ? food.name.en : ''}
								>
									{foods.map((food) => (
										<MenuItem key={food.id} value={food.name.en} onClick={() => handleSelectFood(food)}>
											{food.name.en}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Stack>
						<Stack sx={{ py: 2 }}>
							<FormControl sx={{ width: '100%' }}>
								<InputLabel>{'Size'}</InputLabel>
								<Select
									label="Size"
									value={mealSize.size}
									disabled={!food}
									MenuProps={{
										PaperProps: {
											style: {
												marginLeft: '-8px',
												maxHeight: '300px'
											}
										}
									}}
									sx={{
										width: '100%',
										borderRadius: '8px'
									}}
								>
									{food?.sizes.map((foodSize) => (
										<MenuItem
											key={foodSize.size}
											value={foodSize.size}
											onClick={() => {
												setMealSize({
													size: foodSize.size,
													quantity: foodSize.quantity,
													componentsQuantity: foodSize.components as []
												});
												setAmount(0);
												setShortages([]);
											}}
										>
											{handleMealSize(foodSize.size)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Stack>
						<Stack>
							<TextField
								label={'Amount'}
								placeholder={'0'}
								value={amount <= 0 ? '' : amount}
								style={{ width: '100%' }}
								InputProps={{
									inputProps: { min: 0, max: mealSize.quantity }
								}}
								disabled={mealSize.size.length === 0}
								type="number"
								onChange={(event) => setAmount(Number(event.target.value))}
							/>
						</Stack>
					</>
				) : (
					<>
						<Typography
							sx={{
								width: '160px',
								fontSize: '23px',
								fontWeight: 400,
								lineHeight: '28px',
								fontFamily: caloTheme.typography.fontFamily,
								textTransform: 'uppercase',
								textAlign: 'center'
							}}
						>
							Meal
						</Typography>
						<Table stickyHeader sx={{ width: '100%', boxShadow: 'none', m: 3, mt: 0 }}>
							<TableBody>
								<TableRow>
									<TableCell align="center" sx={{ borderColor: 'white' }}>
										<TextField
											sx={{
												boxShadow: 'none',
												'.MuiOutlinedInput-notchedOutline': { border: 0 }
											}}
											InputLabelProps={{ style: styles.label }}
											InputProps={{ style: styles.label }}
											style={{
												minWidth: '100%',
												outline: 'none',
												backgroundColor: caloTheme.palette.neutral50,
												border: 'none'
											}}
											select
											value={food ? food.name.en : 'Select the meal'}
										>
											{foods.map((food) => (
												<MenuItem key={food.id} value={food.name.en} onClick={() => handleSelectFood(food)}>
													{food.name.en}
												</MenuItem>
											))}
										</TextField>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="center" sx={{ borderColor: 'white' }}>
										<TextField
											sx={{
												boxShadow: 'none',
												'.MuiOutlinedInput-notchedOutline': { border: 0 }
											}}
											disabled={!food}
											InputLabelProps={{ style: styles.label }}
											InputProps={{ style: styles.label }}
											style={{
												minWidth: '100%',
												outline: 'none',
												backgroundColor: caloTheme.palette.neutral50,
												border: 'none'
											}}
											select
											placeholder="Select Size"
											value={food ? mealSize.size : 'Select the size'}
										>
											{food ? (
												food.sizes.map((foodSize) => (
													<MenuItem
														key={foodSize.size}
														value={foodSize.size}
														onClick={() => {
															setMealSize({
																size: foodSize.size,
																quantity: foodSize.quantity,
																componentsQuantity: foodSize.components
															});
															setAmount(0);
															setShortages([]);
														}}
													>
														{handleMealSize(foodSize.size)}
													</MenuItem>
												))
											) : (
												<MenuItem key={'0'} value={'0'} />
											)}
										</TextField>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="center" sx={{ height: '35px', borderColor: 'white' }}>
										<TextField
											sx={{
												boxShadow: 'none',
												'.MuiOutlinedInput-notchedOutline': { border: 0 }
											}}
											placeholder={'0'}
											value={amount <= 0 ? ' ' : amount}
											style={{ minWidth: '100%', backgroundColor: caloTheme.palette.neutral50, fontSize: '19px' }}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<IconButton size="small" onClick={() => setAmount(amount - 1)} disabled={amount <= 0}>
															<RemoveIcon
																sx={{ color: amount > 0 ? caloTheme.palette.neutral900 : caloTheme.palette.neutral600 }}
															/>
														</IconButton>
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position="end">
														<IconButton size="small" onClick={() => setAmount(amount + 1)}>
															<AddIcon sx={{ color: caloTheme.palette.neutral900 }} />
														</IconButton>
													</InputAdornment>
												),
												inputProps: {
													min: 0,
													max: mealSize.quantity,
													style: { textAlign: 'center', fontWeight: 400, fontSize: '19px' }
												}
											}}
											type="number"
											onChange={(event) => setAmount(Number(event.target.value))}
										></TextField>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</>
				)}
			</Stack>
			<Stack
				display={'flex'}
				flexDirection={'column'}
				sx={{ ...(isMobile ? styles.secondStack.mobile : styles.secondStack.desktop) }}
			>
				{!isMobile && (
					<Typography
						sx={{
							width: 'auto',
							fontSize: '23px',
							fontWeight: 400,
							lineHeight: '28px',
							fontFamily: caloTheme.typography.fontFamily,
							textTransform: 'uppercase',
							textAlign: 'center'
						}}
					>
						Component Shortage
					</Typography>
				)}
				{food && food.componentIds.length > 0 && mealSize.size ? (
					<>
						<Table stickyHeader sx={{ ...(isMobile ? styles.table.mobile : styles.table.desktop) }}>
							<TableBody>
								{food && mealSize.size
									? sortBy(
											food.componentIds.map((id) => keyedFoodComponents[`${food.date}#${id}`]),
											'name.en'
										).map((fc) => (
											<ShortageReportTableRow
												key={fc.id}
												amount={amount}
												foodComponent={fc}
												foodId={food.id}
												foodName={food.name}
												size={mealSize.size}
												isChecked={shortages.some((s) => s.componentId === fc.id)}
												handleAddShortage={(foodId, foodName, foodSize, weight, componentId) =>
													setShortages((old) => [...old, { foodId, foodName, foodSize, weight, componentId }])
												}
												handleRemoveShortage={(id) => setShortages(shortages.filter((item) => item.componentId !== id))}
												componentQtyFactor={getComponentQuantityBasedOnMealSize(replace(fc.id, `${fc.date}#`, ''), mealSize)}
											/>
										))
									: ' '}
								<TableRow>
									<TableCell
										align="center"
										colSpan={3}
										sx={{ borderColor: 'white', ...(isMobile ? styles.tableCellButton.mobile : styles.tableCellButton.desktop) }}
									>
										<Button
											variant="contained"
											sx={{
												color: 'white',
												width: '100%',
												height: '60px',
												textTransform: 'capitalize',
												borderRadius: '8px',
												fontSize: '19px',
												fontWeight: 600,
												lineHeight: '23px',
												boxShadow: 'none',
												':hover': {
													boxShadow: 'none',
													backgroundColor: caloTheme.palette.primary600
												}
											}}
											disabled={loading || appContext.isOffline || amount <= 0 || shortages.length === 0}
											fullWidth
											onClick={() => setIsModalOpened(true)}
										>
											Report Shortage
										</Button>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</>
				) : (
					<EmptyMessage
						label={'No Components'}
						style={{
							textAlign: 'center',
							width: '90%',
							margin: isMobile ? '30px' : 0,
							marginTop: isMobile ? '20px' : '50px'
						}}
					/>
				)}
			</Stack>
			<Modal
				loading={loading}
				open={isModalOpened}
				setOpen={setIsModalOpened}
				handleClose={() => setIsModalOpened(false)}
				handleAccept={handleAddReport}
				title={'Add the report'}
				message={'Are you sure you want to report this Shortage?'}
			/>
		</Box>
	);
};

export default ShortageReporting;
