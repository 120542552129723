/* eslint-disable unicorn/consistent-function-scoping */
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Kitchen } from '@calo/types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { ShiftSelector } from '../../../components';
import Modal from '../../../components/Modal';
import { MODIFY_BAGGING_SESSION, START_BAGGING_SESSION } from '../../../libs/graphQL';
import { BaggingSessionInput, KDSBaggingSession, Session, SessionStatus } from '../../../libs/interfaces';
import { getTimeAndDateInTimeZone, getTimeAndDateNowInTimeZone } from '../../../libs/utils/helperFunctions';

interface SectionHeaderProps {
	isStarted: boolean;
	setIsStarted: (start: boolean) => void;
	isEnded: boolean;
	setIsEnded: (end: boolean) => void;
	setOpenTemperatureModal: (openTemp: boolean) => void;
	roomTemperature: string;
	handleUpdateSessionValue: (Session: Session) => void;
	handleUpdateActiveSessionID: (ID: string) => void;
	kitchen: Kitchen;
	date: string;
	sessions: KDSBaggingSession[];
	handleRoomTemperatureState: (Temperature: string) => void;
	sessionNewData: KDSBaggingSession;
	refetch: (variables?: Partial<{ kitchen: Kitchen; date: string }>) => void;
	isPreview: boolean;
	shift: Session;
}

const SectionHeader = ({
	isStarted,
	setIsStarted,
	isEnded,
	setIsEnded,
	roomTemperature,
	handleRoomTemperatureState,
	setOpenTemperatureModal,
	handleUpdateSessionValue,
	handleUpdateActiveSessionID,
	kitchen,
	date,
	sessions,
	sessionNewData,
	refetch,
	isPreview,
	shift
}: SectionHeaderProps) => {
	const [sessionValue, setSessionValue] = useState(kitchen === Kitchen.BH1 ? Session.all : Session.morning);
	const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const theme = useTheme();
	const [startBaggingSession, { loading: sLoading }] = useMutation(START_BAGGING_SESSION);
	const [modifyBaggingSession, { loading: mLoading }] = useMutation(MODIFY_BAGGING_SESSION);
	const [allSessions, setAllSessions] = useState<KDSBaggingSession[]>(sessions);
	const [activeSession, setActiveSession] = useState(allSessions.find((s) => s.status === SessionStatus.started));
	const appContext = useContext(AppContext);
	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);

	useEffect(() => {
		if (allSessions.length === 0) {
			setAllSessions(sessions);
			setActiveSession(sessions.find((s) => s.status === SessionStatus.started));
		}
		if (sessions.some((s) => s.status === SessionStatus.started)) {
			setAllSessions(sessions);
			setActiveSession(sessions.find((s) => s.status === SessionStatus.started));
			handleActiveSession(sessions.find((s) => s.status === SessionStatus.started));
		} else if (sessions.some((s) => s.session === sessionValue)) {
			setActiveSession(sessions.find((s) => s.session === sessionValue));
			handleActiveSession(sessions.find((s) => s.session === sessionValue));
		} else if (sessions.length === 0) {
			setAllSessions(sessions);
			handleActiveSession();
		}
	}, [sessions]);

	useEffect(() => {
		setActiveSession(sessionNewData);
		handleActiveSession(sessionNewData);
	}, [sessionNewData]);

	useEffect(() => {
		const shift = kitchen === Kitchen.BH1 ? Session.all : Session.morning;
		setSessionValue(shift);
		handleUpdateSessionValue(shift);
		setActiveSession(allSessions.find((s) => s.session === shift));
		handleActiveSession(allSessions.find((s) => s.session === shift));
	}, [kitchen, date]);

	const handleActiveSession = (activeSession?: KDSBaggingSession) => {
		if (activeSession) {
			handleUpdateActiveSessionID(activeSession.id);
			setSessionValue(activeSession.session as Session);
			handleRoomTemperatureState(activeSession.roomTemperature ? String(activeSession.roomTemperature.toFixed(1)) : '');
			setStartTime(
				activeSession.startTime ? getTimeAndDateInTimeZone(activeSession.startTime, activeSession.kitchen as Kitchen) : ''
			);
			setIsStarted(activeSession.startTime ? true : false);
			setEndTime(activeSession.endTime ? getTimeAndDateInTimeZone(activeSession.endTime, activeSession.kitchen as Kitchen) : '`');
			setIsEnded(activeSession.endTime ? true : false);
		} else {
			setStartTime('');
			handleRoomTemperatureState('');
			setEndTime('');
			setIsEnded(false);
			setIsStarted(false);
		}
	};

	const handleStartBaggingSession = async () => {
		handleCloseConfirmationModal();
		setIsStarted(true);
		setStartTime(getTimeAndDateNowInTimeZone(kitchen));
		const input: BaggingSessionInput = {
			kitchen,
			date,
			startTime: String(new Date().toISOString()),
			roomTemperature: roomTemperature === '' ? undefined : parseFloat(roomTemperature),
			session: sessionValue,
			status: SessionStatus.started
		};
		await startBaggingSession({
			variables: { input },
			onCompleted: () => {
				toast.success('The bagging session started successfully');
				handleUpdateActiveSessionID(`${date}#${sessionValue}`);
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const handleEndBaggingSession = async () => {
		const input: BaggingSessionInput = {
			kitchen,
			date,
			endTime: String(new Date().toISOString()),
			session: sessionValue,
			status: SessionStatus.ended
		};
		await modifyBaggingSession({
			variables: { id: activeSession?.id, input },
			onCompleted: () => {
				toast.success('The bagging session ended successfully');
				refetch({ kitchen, date });
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
	};

	const handleSessionChange = (event: Session) => {
		refetch({ kitchen, date });
		setSessionValue(event as Session);
		handleUpdateSessionValue(event as Session);
		setActiveSession(allSessions.find((s) => s.session === (event as string)));
		handleActiveSession(allSessions.find((s) => s.session === (event as string)));
	};

	const handleEndBagging = () => {
		if (isStarted && roomTemperature !== '') {
			handleCloseConfirmationModal();
			setIsEnded(true);
			setEndTime(getTimeAndDateNowInTimeZone(kitchen));
			handleEndBaggingSession();
		} else if (roomTemperature === '') {
			toast.error('You need to add the room temperature first');
		}
	};

	const handleOpenConfirmationModal = () => {
		if (!isStarted) {
			setIsConfirmationModalOpened(true);
		} else if (isStarted && roomTemperature !== '') {
			setIsConfirmationModalOpened(true);
		} else if (roomTemperature === '') {
			toast.error('You need to add the room temperature first');
		}
	};

	const handleCloseConfirmationModal = () => {
		setIsConfirmationModalOpened(false);
	};

	console.log('sessionValue', sessionValue);
	return (
		<Grid container direction="row" justifyContent="space-between" alignItems="center">
			<Grid item>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					<Box sx={{ mx: '5px', fontFamily: caloTheme.typography.fontFamily, fontSize: '23px' }}>
						{isPreview ? 'Preview' : 'Bagging time start:'}
					</Box>
					<Button
						variant="text"
						color="primary"
						disabled={isStarted || isEnded || sLoading || mLoading || appContext.isOffline}
						sx={{
							textTransform: 'none',
							fontSize: '19px',
							m: 2,
							'&:disabled': { color: `${isEnded || appContext.isOffline ? theme.palette.neutral600 : theme.palette.primary500}` },
							visibility: isPreview ? 'hidden' : 'initial'
						}}
						onClick={() => !isStarted && handleOpenConfirmationModal()}
					>
						{isStarted ? startTime : 'Start Bagging'}
					</Button>
					<Box
						sx={{
							mx: '5px',
							fontFamily: caloTheme.typography.fontFamily,
							fontSize: '23px',
							visibility: isPreview ? 'hidden' : 'initial'
						}}
					>
						Bagging time end:
					</Box>
					<Button
						disabled={!isStarted || isEnded || sLoading || mLoading || appContext.isOffline}
						sx={{
							px: 2,
							textTransform: 'none',
							fontSize: '16px',
							m: 2,
							color: 'white',
							backgroundColor: theme.palette.primary500,
							':hover': { color: theme.palette.primary500, backgroundColor: 'white' },
							':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 },
							visibility: isPreview ? 'hidden' : 'initial'
						}}
						onClick={handleOpenConfirmationModal}
					>
						{isEnded ? endTime : 'End Bagging'}
					</Button>
				</Grid>
			</Grid>
			<Grid item>
				<Button
					sx={{
						px: 2,
						textTransform: 'none',
						fontSize: '16px',
						m: 2,
						color: 'white',
						backgroundColor: theme.palette.primary500,
						':hover': { color: theme.palette.primary500, backgroundColor: 'white' },
						':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 },
						visibility: isPreview ? 'hidden' : 'initial'
					}}
					onClick={() => {
						setOpenTemperatureModal(true);
					}}
					disabled={!isStarted || sLoading || mLoading || appContext.isOffline}
				>
					{roomTemperature === '' ? 'Room Temperature' : `${roomTemperature}°C`}
				</Button>
				<ShiftSelector
					kitchen={kitchen}
					shift={sessionValue}
					allSessions={shift === Session.all}
					key={'Quality-Table'}
					handleShiftChange={handleSessionChange}
					isShiftMenuOpened={isShiftMenuOpened}
					shiftMenuAnchorEl={shiftMenuAnchorEl}
					setShiftMenuAnchorEl={setShiftMenuAnchorEl}
				/>
			</Grid>
			<Modal
				loading={mLoading}
				open={isConfirmationModalOpened}
				setOpen={handleOpenConfirmationModal}
				handleClose={handleCloseConfirmationModal}
				handleAccept={isStarted ? handleEndBagging : handleStartBaggingSession}
				title={isStarted ? 'End the Bagging Session' : 'Start the Bagging Session'}
				message={
					isStarted ? 'Are you sure you want to end the Bagging Session?' : 'Are you sure you want to start the Bagging Session?'
				}
			/>
		</Grid>
	);
};

export default SectionHeader;
