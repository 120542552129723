import { flatten, orderBy, uniq } from 'lodash-es';
import { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';
import { DeliveryTicketFilters, DeliveryTicketStatus, DeliveryTicketType, GetDeliveryTicketsReq } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Button, MenuItem, Select, Stack, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { AppContext } from '../../App';
import { LIST_DELIVERY_TICKETS_QUERY, SUBSCRIBE_TO_DELIVERY_TICKETS_CHANGES } from '../../libs/graphQL';
import { DeliveryTicket, KDSFood } from '../../libs/interfaces';
import { getFoodsWithNonZeroQuantity } from '../../libs/utils/foodHelper';
import Export from './Export';
import Meals from './Meals';
import SwapTab from './SwapTab';

interface LockTimeChangesProps {
	kitchen: string;
	date: string;
	foods: KDSFood[];
	setNotificationReq: (customerReqNumber: number, cxReqNumber: number) => void;
	notificationReq: number;
	refetchFoods: () => void;
}

const LockTimeChanges = ({ kitchen, date, foods, setNotificationReq, notificationReq }: LockTimeChangesProps) => {
	const [tab, setTab] = useState(0);
	const [shiftFilter, setShiftFilter] = useState('all');
	const [typeFilter, setTypeFilter] = useState('all');
	const [availableZoneFilters, setAvailableZoneFilters] = useState<string[]>([]);
	const [zoneFilter, setZoneFilter] = useState('all');
	const [zoneMenuAnchorEl, setZoneMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [allDeliveryTickets, setAllDeliveryTickets] = useState<DeliveryTicket[]>([]);
	const [deliveryTickets, setDeliveryTickets] = useState<DeliveryTicket[]>([]);
	const [manualDeliveryTickets, setManualDeliveryTickets] = useState<DeliveryTicket[]>([]);
	const [otherDeliveryTickets, setOtherDeliveryTickets] = useState<DeliveryTicket[]>([]);

	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [typeMenuAnchorEl, setTypeMenuAnchorEl] = useState<null | HTMLElement>(null);

	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);
	const isTypeMenuOpened = Boolean(typeMenuAnchorEl);
	const isZoneMenuOpened = Boolean(zoneMenuAnchorEl);

	const [isEditing, setIsEditing] = useState(false);

	const appContext = useContext(AppContext);
	const theme = useTheme();

	const filters: DeliveryTicketFilters = {
		type: DeliveryTicketType.MEAL,
		kitchen: kitchen as Kitchen,
		date: { lte: date, gte: date }
	};

	useEffect(() => {
		if (allDeliveryTickets.length > 0) {
			setAvailableZoneFilters(uniq(allDeliveryTickets.filter((d) => d.zone && d.kitchen === kitchen).map((d) => d.zone || '')));
		}
	}, [allDeliveryTickets]);

	const { loading, refetch, subscribeToMore } = useQuery(LIST_DELIVERY_TICKETS_QUERY, {
		variables: { query: { filters } as GetDeliveryTicketsReq },
		onCompleted: (data) => {
			setDeliveryTickets(data?.listDeliveryTickets.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite'
	});

	const { loading: manualLoading, refetch: manualRefetch } = useQuery(LIST_DELIVERY_TICKETS_QUERY, {
		variables: {
			query: {
				filters: { ...filters, type: DeliveryTicketType.MANUAL }
			} as Partial<GetDeliveryTicketsReq>
		},
		onCompleted: (data) => {
			setManualDeliveryTickets(data?.listDeliveryTickets.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite',
		fetchPolicy: 'network-only'
	});

	const { loading: otherLoading, refetch: otherRefetch } = useQuery(LIST_DELIVERY_TICKETS_QUERY, {
		variables: {
			query: {
				filters: { ...filters, type: DeliveryTicketType.OTHER }
			} as Partial<GetDeliveryTicketsReq>
		},
		onCompleted: (data) => {
			setOtherDeliveryTickets(data?.listDeliveryTickets.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite',
		fetchPolicy: 'network-only'
	});

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	useSubscription(SUBSCRIBE_TO_DELIVERY_TICKETS_CHANGES);

	const getMoreData = () => {
		subscribeToMore({
			document: SUBSCRIBE_TO_DELIVERY_TICKETS_CHANGES,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscriberData = subscriptionData.data.subscribeToDeliveryTicketChanges;
				if (!subscriberData) return prev;
				const isDeliveryTicketExist = prev.listDeliveryTickets.data.find(
					(deliveryTicket: DeliveryTicket) => deliveryTicket.id === subscriberData.id
				);
				const isDeliveryTicketValid =
					(subscriberData.data.type === DeliveryTicketType.MEAL ||
						subscriberData.data.type === DeliveryTicketType.MANUAL ||
						subscriberData.data.type === DeliveryTicketType.OTHER) &&
					subscriberData.kitchen === kitchen &&
					subscriberData.date === date;
				appContext.refetchFoods();
				if (!isDeliveryTicketExist && isDeliveryTicketValid) {
					return Object.assign({}, prev, {
						listDeliveryTickets: {
							data: [subscriberData, ...prev.listDeliveryTickets.data]
						}
					});
				}
			}
		});
	};

	useEffect(() => {
		refetch();
		manualRefetch();
		otherRefetch();
		getMoreData();
		setZoneFilter('all');
		setAvailableZoneFilters([]);
	}, [kitchen, date]);

	const foodsWithNonZeroQuantity = getFoodsWithNonZeroQuantity(foods);
	const foodListData = useMemo(
		() =>
			foodsWithNonZeroQuantity.map((food) =>
				food.sizes.map((fs) => ({
					id: fs.id,
					foodId: food.id,
					size: fs.size,
					name: food.name,
					kitchen: food.kitchen as Kitchen,
					type: food.type,
					extraMeals: fs.extraMeals || 0,
					brand: food.brand || Brand.CALO,
					isCustom: food.isCustom
				}))
			),
		[isEditing, foodsWithNonZeroQuantity.length, foodsWithNonZeroQuantity]
	);
	const orderedSizes = orderBy(flatten(foodListData), (f) => f.extraMeals, ['desc']);

	useMemo(() => {
		setAllDeliveryTickets(
			orderBy([...deliveryTickets, ...manualDeliveryTickets, ...otherDeliveryTickets], ['createdAt'], ['asc']).map(
				(ticket, index) => ({
					...ticket,
					index: index + 1
				})
			)
		);
		setNotificationReq(
			[...deliveryTickets, ...manualDeliveryTickets, ...otherDeliveryTickets].filter(
				(ticket) => ticket.status === DeliveryTicketStatus.PENDING
			).length,
			[...deliveryTickets, ...manualDeliveryTickets, ...otherDeliveryTickets].filter(
				(ticket) => ticket.data.autoApprove && !ticket.downloadedAt
			).length
		);
	}, [deliveryTickets, manualDeliveryTickets, otherDeliveryTickets, date, kitchen]);

	return (
		<Box sx={{ width: '100%', mt: 2 }}>
			<Stack direction={'row'} justifyContent="space-between" alignItems="start" sx={{ width: '100%', mt: '4px' }}>
				<Stack
					direction={'column'}
					justifyContent="space-between"
					alignItems="start"
					sx={{ width: appContext.isSidebarOpened ? '64%' : '70%', ml: 3 }}
				>
					<Stack direction={'row'} justifyContent="end" sx={{ width: '100%', mt: '4px' }}>
						<Button
							onClick={(event) => {
								!zoneMenuAnchorEl && setZoneMenuAnchorEl(event.currentTarget);
							}}
							variant="outlined"
							disabled={availableZoneFilters.length === 0}
							endIcon={<ArrowDropDownIcon />}
							sx={{ textTransform: 'capitalize', fontSize: '16px', mr: 2, maxHeight: '40px' }}
						>
							{zoneFilter}
							<Select
								id="demo-simple-select"
								value={zoneFilter}
								open={isZoneMenuOpened}
								onClose={() => setZoneMenuAnchorEl(null)}
								label="session"
								sx={{ visibility: 'hidden', width: 0, height: 0 }}
							>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'all'}
									onClick={() => {
										setZoneFilter('all');
										setZoneMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>All</Box>
								</MenuItem>
								{availableZoneFilters.length > 0 &&
									availableZoneFilters.map((z) => (
										<MenuItem
											key={z}
											sx={{ fontWeight: 600 }}
											value={z}
											onClick={() => {
												setZoneFilter(z);
												setZoneMenuAnchorEl(null);
											}}
										>
											<Box sx={{ px: '15px' }}>{z}</Box>
										</MenuItem>
									))}
							</Select>
						</Button>
						<Button
							onClick={(event) => {
								!typeMenuAnchorEl && setTypeMenuAnchorEl(event.currentTarget);
							}}
							variant="outlined"
							endIcon={<ArrowDropDownIcon />}
							sx={{ textTransform: 'capitalize', fontSize: '16px', mr: 2 }}
						>
							{typeFilter ? typeFilter : 'All'}
							<Select
								id="demo-simple-select"
								value={typeFilter}
								open={isTypeMenuOpened}
								onClose={() => setTypeMenuAnchorEl(null)}
								label="session"
								sx={{ visibility: 'hidden', width: 0, height: 0 }}
							>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'all'}
									onClick={() => {
										setTypeFilter('all');
										setTypeMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>All</Box>
								</MenuItem>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'meal'}
									onClick={() => {
										setTypeFilter('meal');
										setTypeMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>Meal Swap</Box>
								</MenuItem>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'manual'}
									onClick={() => {
										setTypeFilter('manual');
										setShiftMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>Manual Delivery</Box>
								</MenuItem>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'other'}
									onClick={() => {
										setTypeFilter('other');
										setShiftMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>Other</Box>
								</MenuItem>
							</Select>
						</Button>

						<Button
							onClick={(event) => {
								!shiftMenuAnchorEl && setShiftMenuAnchorEl(event.currentTarget);
							}}
							variant="outlined"
							endIcon={<ScheduleIcon />}
							sx={{ textTransform: 'capitalize', fontSize: '16px' }}
						>
							{shiftFilter}
							<Select
								id="demo-simple-select"
								value={shiftFilter}
								open={isShiftMenuOpened}
								onClose={() => setShiftMenuAnchorEl(null)}
								label="session"
								sx={{ visibility: 'hidden', width: 0, height: 0 }}
							>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'all'}
									onClick={() => {
										setShiftFilter('all');
										setShiftMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>All</Box>
								</MenuItem>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'morning'}
									onClick={() => {
										setShiftFilter('morning');
										setShiftMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>Morning Shift</Box>
								</MenuItem>
								<MenuItem
									sx={{ fontWeight: 600 }}
									value={'evening'}
									onClick={() => {
										setShiftFilter('evening');
										setShiftMenuAnchorEl(null);
									}}
								>
									<Box sx={{ px: '15px' }}>Evening Shift</Box>
								</MenuItem>
							</Select>
						</Button>
					</Stack>
					<Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
						<Tabs variant="fullWidth" value={tab} onChange={handleChange} aria-label="basic tabs example" centered>
							<Tab
								sx={{ fontSize: '19px', fontWeight: 600, color: theme.palette.neutral400, textTransform: 'capitalize' }}
								label="New Requests"
								id={'New Requests'}
								aria-controls={'New Requests'}
							/>
							<Tab
								sx={{ fontSize: '19px', fontWeight: 600, color: theme.palette.neutral400, textTransform: 'capitalize' }}
								label={`Done Request (${notificationReq >= 0 ? notificationReq + ' New' : ''})`}
								id={'Done Requests'}
								aria-controls={'Done Requests'}
							/>
						</Tabs>
					</Box>
					<Box
						display={'flex'}
						flexDirection={'row'}
						justifyContent="space-between"
						alignItems="center"
						sx={{ width: '100%', borderRadius: '8px', minHeight: '32px', backgroundColor: theme.palette.neutral50, mt: 2 }}
					>
						<Typography
							sx={{
								ml: 4,
								fontSize: 14,
								fontWeight: 400,
								lineHeight: '14px',
								width: '15%',
								justifyContent: 'start',
								display: 'flex'
							}}
						>
							Type
						</Typography>
						<Typography
							component="div"
							sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '25%', justifyContent: 'center', display: 'flex' }}
						>
							Name
						</Typography>
						<Typography
							component="div"
							sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
						>
							Phone Number
						</Typography>
						<Typography
							component="div"
							sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
						>
							Driver
						</Typography>
						<Typography
							component="div"
							sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
						>
							Invoice
						</Typography>
						<Typography
							component="div"
							sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
						>
							Shift
						</Typography>
						<Typography
							component="div"
							sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '15%', justifyContent: 'center', display: 'flex' }}
						>
							Zone
						</Typography>
						<Typography
							component="div"
							sx={{ fontSize: 14, fontWeight: 400, lineHeight: '14px', width: '7%', justifyContent: 'center', display: 'flex' }}
						></Typography>
					</Box>
					<SwapTab
						value={tab}
						index={0}
						loading={loading}
						deliveryTickets={orderBy(
							allDeliveryTickets.filter(
								(delivery) =>
									(shiftFilter === 'all' ? delivery : delivery.data.deliveryTime?.toLocaleLowerCase() === shiftFilter) &&
									(typeFilter === 'all' ? delivery : delivery.data.type === typeFilter) &&
									(zoneFilter === 'all' ? delivery : delivery.zone === zoneFilter)
							),
							['createdAt'],
							['desc']
						)?.filter((d) => d.status === DeliveryTicketStatus.PENDING)}
					/>
					<SwapTab
						value={tab}
						index={1}
						loading={loading}
						deliveryTickets={orderBy(
							allDeliveryTickets.filter(
								(delivery) =>
									(shiftFilter === 'all' ? delivery : delivery.data.deliveryTime?.toLocaleLowerCase() === shiftFilter) &&
									(typeFilter === 'all' ? delivery : delivery.data.type === typeFilter) &&
									(zoneFilter === 'all' ? delivery : delivery.zone === zoneFilter)
							),
							['createdAt'],
							['desc']
						)?.filter((d) => d.status !== DeliveryTicketStatus.PENDING)}
					/>
				</Stack>
				<Stack
					direction={'column'}
					justifyContent="space-between"
					alignItems="center"
					sx={{ width: appContext.isSidebarOpened ? '36%' : '30%' }}
				>
					{tab === 1 && (
						<Export
							deliveryTickets={orderBy([...allDeliveryTickets], ['createdAt'], ['desc'])?.filter(
								(d) => d.status === DeliveryTicketStatus.APPROVED
							)}
						/>
					)}
					<Meals foods={orderedSizes} isEditing={isEditing} setIsEditing={(value) => setIsEditing(value)} />
				</Stack>
			</Stack>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={otherLoading || loading || (manualLoading && !appContext.isOffline)}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
};
export default LockTimeChanges;
