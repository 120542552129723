import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';
import { Kitchen } from '@calo/types';

import { GraphQLClient, LIST_PRODUCTIVITY_METRICS_QUERY } from '../libs/graphQL';
import { ProductivityMetrics } from '../libs/interfaces';

interface Props {
	startDate: string;
	endDate: string;
	kitchen: Kitchen;
}

const useListProductivityMetricsQuery = ({ startDate, endDate, kitchen }: Props) => {
	const [productivityMetrics, setProductivityMetrics] = useState<ProductivityMetrics[]>([]);

	const { loading, refetch } = useQuery(LIST_PRODUCTIVITY_METRICS_QUERY, {
		variables: { startDate, endDate, kitchen },
		onCompleted: (data) => {
			if (data?.listProductivityMetrics?.data) {
				setProductivityMetrics(data.listProductivityMetrics.data);
			}
		},
		onError: (error) => {
			toast.error(`Error fetching Productivity Metrics data: ${error.message}`);
		}
	});

	const clearData = () => {
		setProductivityMetrics([]);
		GraphQLClient.clearStore();
	};

	useEffect(() => {
		setProductivityMetrics([]);
		refetch();
	}, [startDate, endDate, kitchen]);

	return {
		productivityMetricsLoading: loading,
		productivityMetrics,
		clearProductivityMetricsData: clearData
	};
};

export default useListProductivityMetricsQuery;
