import { intersection, keyBy } from 'lodash-es';

import { KDSFood, PausedInterval, ProductivityMetrics, ProductivityMetricsFood, Session } from '../../libs';
import { CostVariables, Filters } from './ProductivityMetrics';

export const updateTodayProductivityMetrics = (
	productivityMetrics: ProductivityMetrics[],
	foods: KDSFood[],
	date: string
): ProductivityMetrics[] => {
	return productivityMetrics.map((productivityMetric) => {
		if (productivityMetric.day === date) {
			return getTodayProductivityMetrics(productivityMetric, foods);
		}
		return productivityMetric;
	});
};

export const getTodayProductivityMetrics = (productivityMetrics: ProductivityMetrics, foods: KDSFood[]): ProductivityMetrics => {
	const keyedFoods = keyBy(foods, (food) => food.id.split('#')[1]);
	return {
		...productivityMetrics,
		foods: productivityMetrics.foods?.map((food) => {
			const foodData = keyedFoods[food.id];
			return {
				...food,
				metrics: food.metrics?.map((metric) => {
					const foodMetric = foodData?.portioningStatus?.find((p) => p.session === metric.shift);
					return {
						...metric,
						portioningTime: calculateTotalTime(
							foodMetric?.portioningTime?.startedAt,
							foodMetric?.endTime,
							foodMetric?.portioningTime?.intervals
						)
					};
				})
			};
		})
	};
};

const calculateTotalTime = (startedAt?: string, finishedAt?: string, intervals?: PausedInterval[]) => {
	let totalTime = 0;
	if (startedAt && finishedAt) {
		const startTime = new Date(startedAt);
		const endTime = new Date(finishedAt);
		totalTime += endTime.getTime() - startTime.getTime();
		totalTime -= calculatePausedTime(intervals);
	}
	return totalTime;
};

export const calculatePausedTime = (intervals?: PausedInterval[]) => {
	if (!intervals) return 0;

	return intervals.reduce((pausedTime, interval) => {
		if (!interval.resumedAt) return pausedTime;
		const pausedAt = new Date(interval.pausedAt ?? '').getTime();
		const resumedAt = new Date(interval.resumedAt).getTime();
		return pausedTime + (resumedAt - pausedAt);
	}, 0);
};

export const getFilteredFoods = (filters: Filters, foods: ProductivityMetricsFood[]) => {
	return foods.filter((food) => {
		if (filters.onlySandwiches && !food.isSandwich) return false;
		if (filters.type.length > 0 && intersection(filters.type, food.type).length === 0) return false;
		if (filters.tags.length > 0 && intersection(filters.tags, food.tags).length === 0) return false;
		return true;
	});
};

export const getTotalPortioningTime = (
	productivityMetrics: ProductivityMetrics[],
	shift: Session,
	type: 'actual' | 'predicted'
) => {
	return productivityMetrics.reduce((total, productivityMetric) => {
		return (
			total +
			productivityMetric.foods.reduce((total, food) => {
				return (
					total +
					food.metrics.reduce((total, metric) => {
						if (metric.shift === shift) {
							const predictedTime = (metric.predictedPortioningTimePerMeal ?? 5000) * (metric.numberOfFoods ?? 0);
							return total + (type === 'actual' ? metric.portioningTime ?? 0 : predictedTime);
						}
						return total;
					}, 0)
				);
			}, 0)
		);
	}, 0);
};

export const formatTime = (time: number) => (time === 0 ? '-' : formatStationTime(time / 1000));

export const formatStationTime = (totalSeconds: number): string => {
	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const seconds = Math.floor(totalSeconds % 60);

	const paddedHours = hours > 0 ? String(hours).padStart(2, '0') + ':' : '';
	const paddedMinutes = String(minutes).padStart(2, '0');
	const paddedSeconds = String(seconds).padStart(2, '0');

	return `${paddedHours}${paddedMinutes}:${paddedSeconds}`;
};
export const getTotalAverageTimePerMeal = (
	productivityMetrics: ProductivityMetrics[],
	shift: Session,
	type: 'actual' | 'predicted'
) => {
	let nOfFoods = 0;
	const totalPortioningTime = productivityMetrics.reduce((total, productivityMetric) => {
		return (
			total +
			productivityMetric.foods.reduce((total, food) => {
				return (
					total +
					food.metrics.reduce((total, metric) => {
						if (metric.shift === shift && (type === 'predicted' || (metric.numberOfFoods > 0 && metric.portioningTime))) {
							nOfFoods += 1;
							const predictedTime = (metric.predictedPortioningTimePerMeal ?? 5000) * (metric.numberOfFoods ?? 0);
							const portioningTime = type === 'actual' ? metric.portioningTime ?? 0 : predictedTime;
							return total + portioningTime / metric.numberOfFoods;
						}
						return total;
					}, 0)
				);
			}, 0)
		);
	}, 0);

	return totalPortioningTime / nOfFoods || 0;
};

export const getTotalCostPerMinute = (costVariables: CostVariables) => {
	return (costVariables.salaryPerStaff / 26 / 9 / 60) * costVariables.numberOfStations * costVariables.staffPerStation;
};
