import { format } from 'date-fns/fp';
import enLocale from 'date-fns/locale/en-GB';
import { uniq } from 'lodash';
import { isNull, sortBy } from 'lodash-es';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

/* eslint-disable max-len */
import { useMutation } from '@apollo/client';
import { DeliveryTime, Food, FoodType } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	Menu,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { InputField, Popup, SearchBar } from '../../../components';
import DatePicker from '../../../components/DatePicker';
import Icon from '../../../components/Icon';
import { DocsType, FileManagementType } from '../../../libs/enums';
import { EXPORT_DOCUMENTS } from '../../../libs/graphQL';
import {
	CheckboxesState,
	FilterSizeState,
	FilterTimeState,
	FilterTypeState,
	KDSDeliveryData,
	KDSDeliveryListFilters,
	KDSDriver,
	MealCheckBox,
	Options
} from '../../../libs/interfaces';
import {
	createAutoDownloadLinkFromArrayOfUrls,
	getDriverNameById,
	initialFilterSizeState,
	initialFilterTimeState,
	initialFilterTypeState
} from '../../../libs/utils/helperFunctions';
import DownloadMealPopUp from './DownloadMealPopUp/DownladMealPopUp';

interface FileManagementHeaderProps {
	selectedDataType: FileManagementType;
	foodLoading: boolean;
	setSelectedDataType: (value: FileManagementType) => void;
	handleClearData: any;
	date: string;
	deliveriesData: KDSDeliveryData[];
	foodData: any;
	driverData: KDSDriver[];
	driverDataLoading: boolean;
	filterMeals: any;
	setFilterMeals: any;
	refetchDeliveries: any;
	searchLoading: boolean;
	optionsFoodData: Food[];
	filters: KDSDeliveryListFilters;
	optionsDeliveryData: KDSDeliveryData[];
	checkboxes: CheckboxesState;
	filtersSizeAndType: {
		sizes: FilterSizeState;
		type: FilterTypeState;
	};
	filterDeliveryTime: FilterTimeState;
	mealCheckboxes: MealCheckBox;
	setFiltersSizeAndType: React.Dispatch<
		React.SetStateAction<{
			sizes: FilterSizeState;
			type: FilterTypeState;
		}>
	>;
	setFilterDeliveryTime: React.Dispatch<React.SetStateAction<FilterTimeState>>;
	setMealCheckboxes: React.Dispatch<React.SetStateAction<MealCheckBox>>;
	setCheckboxes: React.Dispatch<React.SetStateAction<CheckboxesState>>;
	setSearchResults: React.Dispatch<React.SetStateAction<KDSDeliveryData[]>>;
	setFilters: React.Dispatch<React.SetStateAction<KDSDeliveryListFilters>>;
	setFoodSearchResults: React.Dispatch<React.SetStateAction<Food[]>>;
}

const getTrueKeys = (obj: any) => Object.keys(obj).filter((key) => obj[key]);

const FileManagementHeader = ({
	selectedDataType,
	setSelectedDataType,
	setSearchResults,
	foodLoading,
	filterMeals,
	setFilterMeals,
	mealCheckboxes,
	setMealCheckboxes,
	handleClearData,
	filters,
	date,
	deliveriesData,
	foodData,
	checkboxes,
	setCheckboxes,
	optionsFoodData,
	setFoodSearchResults,
	setFilters,
	optionsDeliveryData,
	searchLoading,
	refetchDeliveries,
	filtersSizeAndType,
	setFiltersSizeAndType,
	filterDeliveryTime,
	setFilterDeliveryTime,
	driverData
}: FileManagementHeaderProps) => {
	const theme = useTheme();
	const appContext = useContext(AppContext);
	const downloadSelectorRef = useRef<any>();
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const [allMealAmount, setAllMealAmount] = useState<number>(1);
	const [exportDocuments, { loading }] = useMutation(EXPORT_DOCUMENTS);
	const [selectedDate, setSelectedDate] = useState<string | null>(null);
	const [disabledDownload, setDisabledDownload] = useState<boolean>(true);
	const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
	const [selectedDriverId, setSelectedDriverId] = useState<string>('');

	useEffect(() => {
		if (selectedDataType === FileManagementType.meal) {
			setFoodSearchResults(foodData);
			setFilters({ ...filters });
		} else {
			setSearchResults(deliveriesData);
			setFilterMeals({ date: undefined, type: [], size: [], name: '' });
		}
	}, [deliveriesData, foodData, selectedDataType, filtersChanged]);

	useEffect(() => {
		const isAnyArrayEmpty = !Object.values(checkboxes).some((array) => array.length > 0);
		setDisabledDownload(isAnyArrayEmpty);
	}, [checkboxes]);

	useEffect(() => {
		const isAnyArrayEmpty = !Object.keys(mealCheckboxes).length;
		setDisabledDownload(isAnyArrayEmpty);
	}, [mealCheckboxes]);

	const options: Options<KDSDeliveryData>[] = useMemo(
		() =>
			sortBy(optionsDeliveryData || [], (f) => `${f.driver?.name}`).map((delivery) => ({
				name: delivery?.name,
				value: delivery.id,
				data: delivery,
				label: `${delivery.name} ${delivery.phoneNumber} ${isNull(delivery.shortId) ? '' : delivery.shortId} (${delivery.time})`
			})),
		[optionsDeliveryData]
	);

	const optionsFood: Options<Food>[] = useMemo(
		() =>
			sortBy(optionsFoodData || [], (f) => `${f.name.en}`).map((meal) => ({
				name: meal?.name.en,
				value: meal.id,
				data: meal,
				label: `${meal.name.en}`
			})),
		[optionsFoodData]
	);

	const handleSearch = (searchText: string, selected: boolean) => {
		if (searchText?.length > 0) {
			if (selectedDataType === FileManagementType.customer) {
				const phoneNumberRegex = /^\d{5,}$/;
				const shortIdRegex = /^[A-Z]-[A-Z0-9]+$/;
				const shortId2Regex = /^[A-Z]-[A-Z0-9]+-[0-9]+$/;
				if (selected) {
					setFilters({ ...filters, userIds: [searchText] });
				} else {
					if (phoneNumberRegex.test(searchText)) {
						setFilters({ ...filters, phoneNumber: searchText });
					} else if (shortIdRegex.test(searchText) || shortId2Regex.test(searchText)) {
						setFilters({ ...filters, shortId: searchText });
					} else {
						setFilters({ ...filters, name: searchText });
					}
				}
			} else {
				if (selected) {
					setFilterMeals({ ...filterMeals, ids: [...searchText], name: undefined });
				} else {
					setFilterMeals({ ...filterMeals, name: searchText, ids: [] });
				}
			}
		} else {
			setFilters({ ...filters });
			setFilterMeals({ ...filterMeals, name: undefined, ids: [] });
			handleClearData();
		}
	};

	const exportDocument = async (docType: DocsType) => {
		if (checkboxes[docType as keyof CheckboxesState].length === 0) return;
		const { data } = await exportDocuments({
			variables: {
				ids: checkboxes[docType as keyof CheckboxesState],
				docTypes: [docType],
				day: filters.day.gte,
				kitchen: filters.kitchen
			},
			onError: (e) => {
				toast.error(`Error while exporting ${docType}, ${e.message} `);
				return;
			}
		});

		if (data?.exportDocuments.data && data.exportDocuments.data.length > 0) {
			return createAutoDownloadLinkFromArrayOfUrls(
				data.exportDocuments.data.filter((exportDoc: any) => exportDoc.includes('pdf'))
			);
		}
	};

	const handleExportDoc = async () => {
		await exportDocument(DocsType.invoice);
		await exportDocument(DocsType.frontLabel);
		await exportDocument(DocsType.backLabel);
		refetchDeliveries();
		setCheckboxes({
			invoice: [],
			backLabel: [],
			frontLabel: []
		});
	};

	const handleMealFilterChange = (filter: string, event: any) => {
		if (filter === 'size') {
			setFiltersSizeAndType({
				type: filtersSizeAndType.type,
				sizes: { ...filtersSizeAndType.sizes, [event.target.name]: event.target.checked }
			});
		} else {
			setFiltersSizeAndType({
				sizes: filtersSizeAndType.sizes,
				type: { ...filtersSizeAndType.type, [event.target.name]: event.target.checked }
			});
		}
	};

	const handleDeliveryFilterChange = (event: any) => {
		setFilterDeliveryTime({
			...filterDeliveryTime,
			[event.target.name]: event.target.checked
		});
	};

	const handleAllMealAmountChange = (event: number) => {
		const newAmount = +event;
		setAllMealAmount(newAmount);
		const updatedMeals: any = Object.fromEntries(
			Object.keys(mealCheckboxes).map((mealId) => [mealId, { ...mealCheckboxes[mealId], amount: newAmount }])
		);
		setMealCheckboxes(updatedMeals);
	};

	const handleClosePopup = () => {
		downloadSelectorRef.current?.close();
		for (const key of Object.keys(mealCheckboxes)) {
			mealCheckboxes[key].amount = 1;
		}
		setAllMealAmount(1);
	};

	useEffect(() => {
		setSelectedDate(null);
	}, [selectedDataType]);

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<Stack
					sx={{
						display: 'flex',
						flexDirection: 'row',
						border: 1,
						borderColor: caloTheme.palette.neutral100,
						p: '5px',
						borderRadius: 2
					}}
				>
					<Button
						aria-label="close"
						onClick={() => setSelectedDataType(FileManagementType.meal)}
						sx={{
							borderRadius: 2,
							mx: '2px',
							backgroundColor: selectedDataType === FileManagementType.meal ? caloTheme.palette.primary100 : null,
							color: selectedDataType === FileManagementType.meal ? caloTheme.palette.primary500 : caloTheme.palette.neutral100
						}}
					>
						<Icon
							name="meal"
							width={24}
							style={{ margin: '2px' }}
							color={selectedDataType === FileManagementType.meal ? caloTheme.palette.primary400 : caloTheme.palette.neutral400}
						/>
					</Button>
					<Button
						aria-label="close"
						onClick={() => setSelectedDataType(FileManagementType.customer)}
						sx={{
							borderRadius: 2,
							backgroundColor: selectedDataType === FileManagementType.customer ? caloTheme.palette.primary100 : null,
							color: (theme) => theme.palette.grey[500]
						}}
					>
						<Iconify icon="carbon:user" width={24} />
					</Button>
				</Stack>
				<Stack sx={{ width: '50%', float: 'right' }}>
					<SearchBar
						key={`search-bar-${selectedDataType}`}
						searchType={selectedDataType}
						onSearch={handleSearch}
						options={selectedDataType === FileManagementType.meal ? optionsFood : options}
						searchLoading={searchLoading || foodLoading}
					/>
				</Stack>
			</Box>

			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', my: 1 }}>
				<Stack direction="row" justifyContent="space-start" alignItems="center" sx={{ width: '100%', mt: 1 }}>
					<Typography
						variant="h6"
						sx={{
							fontFamily: 'Roboto',
							fontSize: '19px',
							lineHeight: '23px',
							fontWeight: 600,
							color: caloTheme.palette.neutral900,
							textTransform: 'capitalize'
						}}
					>
						{`${selectedDataType}`}
					</Typography>
					{selectedDataType === FileManagementType.meal && (
						<Typography
							variant="h6"
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								lineHeight: '17px',
								fontWeight: 400,
								mx: 2,
								color: caloTheme.palette.neutral900,
								textTransform: 'capitalize'
							}}
						>
							({selectedDataType === FileManagementType.meal ? `${Object.keys(mealCheckboxes).length} selected` : ''})
						</Typography>
					)}

					{selectedDataType === FileManagementType.meal && (
						<>
							<IconButton
								aria-haspopup="true"
								style={{ borderRadius: '100%' }}
								onClick={(event: any) => setAnchorEl(event.currentTarget)}
							>
								<Iconify icon="mingcute:filter-2-fill" width={21} color={caloTheme.palette.neutral900} />
							</IconButton>
							<Menu
								id="filter-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={() => setAnchorEl(null)}
								sx={{ width: 460, minWidth: 460, maxWidth: 460 }}
							>
								<Box sx={{ paddingTop: 2, paddingX: 2, width: 300 }}>
									<Typography
										sx={{
											fontWeight: 600,
											fontSize: '20px',
											fontStyle: 'normal',
											lineHeight: '24px'
										}}
									>
										Filters
									</Typography>
								</Box>
								<Box sx={{ width: '100%', padding: 2 }}>
									<Box sx={{ paddingBottom: 0.5 }}>
										<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
											<DatePicker
												label="Date"
												color={caloTheme.palette.black}
												disabled={filtersSizeAndType.sizes.r || filtersSizeAndType.sizes.xl || filtersSizeAndType.sizes.xs}
												showClearIcon={true}
												date={selectedDate ? new Date(selectedDate) : null}
												setDate={(date: Date | null | undefined) =>
													setSelectedDate(date ? format('yyyy-MM-dd')(new Date(date)) : null)
												}
											/>
										</LocalizationProvider>
									</Box>
									<Box minWidth={'100%'} sx={{ marginBottom: 0.5 }}>
										<Accordion sx={{ borderWidth: 0, borderBottom: 1, borderColor: 'divider' }} variant="outlined">
											<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ width: '100%' }}>
												<Typography
													sx={{
														fontWeight: 600,
														fontSize: '16px',
														fontStyle: 'normal',
														lineHeight: '20px'
													}}
												>
													Size
												</Typography>
											</AccordionSummary>
											<AccordionDetails sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
												<Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
													<FormControlLabel
														sx={{ float: 'left', width: '75%' }}
														control={
															<Checkbox
																name="l"
																checked={filtersSizeAndType.sizes.l}
																onChange={(event) => handleMealFilterChange('size', event)}
															/>
														}
														label="Large"
													/>
													<FormControlLabel
														sx={{ float: 'right', width: '50%' }}
														control={
															<Checkbox
																name="xl"
																checked={filtersSizeAndType.sizes.xl}
																disabled={!!selectedDate}
																onChange={(event) => handleMealFilterChange('size', event)}
															/>
														}
														label="X Large"
													/>
												</Stack>
												<Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
													<FormControlLabel
														sx={{ float: 'left', width: '75%' }}
														control={
															<Checkbox
																name="r"
																checked={filtersSizeAndType.sizes.r}
																disabled={!!selectedDate}
																onChange={(event) => handleMealFilterChange('size', event)}
															/>
														}
														label="R"
													/>
													<FormControlLabel
														sx={{ float: 'right', width: '50%' }}
														control={
															<Checkbox
																name="xs"
																checked={filtersSizeAndType.sizes.xs}
																disabled={!!selectedDate}
																onChange={(event) => handleMealFilterChange('size', event)}
															/>
														}
														label="X Small"
													/>
												</Stack>
												<Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
													<FormControlLabel
														sx={{ float: 'left', width: '75%' }}
														control={
															<Checkbox
																name="s"
																checked={filtersSizeAndType.sizes.s}
																onChange={(event) => handleMealFilterChange('size', event)}
															/>
														}
														label="Small"
													/>
													<FormControlLabel
														sx={{ float: 'right', width: '50%' }}
														control={
															<Checkbox
																name="m"
																checked={filtersSizeAndType.sizes.m}
																onChange={(event) => handleMealFilterChange('size', event)}
															/>
														}
														label="Medium"
													/>
												</Stack>
											</AccordionDetails>
										</Accordion>
									</Box>
									<Box>
										<Accordion sx={{ borderWidth: 0, borderBottom: 1, borderColor: 'divider' }} variant="outlined">
											<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ width: '100%' }}>
												<Typography
													sx={{
														fontWeight: 600,
														fontSize: '16px',
														fontStyle: 'normal',
														lineHeight: '20px'
													}}
												>
													Type
												</Typography>
											</AccordionSummary>
											<AccordionDetails sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
												<Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
													<FormControlLabel
														sx={{ float: 'left', width: '70%' }}
														control={
															<Checkbox
																name="breakfast"
																checked={filtersSizeAndType.type.breakfast}
																onChange={(event) => handleMealFilterChange('type', event)}
															/>
														}
														label="Breakfast"
													/>
													<FormControlLabel
														sx={{ float: 'right', width: '40%' }}
														control={
															<Checkbox
																name="lunch"
																checked={filtersSizeAndType.type.lunch}
																onChange={(event) => handleMealFilterChange('type', event)}
															/>
														}
														label="Lunch"
													/>
												</Stack>
												<Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
													<FormControlLabel
														sx={{ float: 'left', width: '70%' }}
														control={
															<Checkbox
																name="dessert"
																checked={filtersSizeAndType.type.dessert}
																onChange={(event) => handleMealFilterChange('type', event)}
															/>
														}
														label="Dessert"
													/>
													<FormControlLabel
														sx={{ float: 'right', width: '40%' }}
														control={
															<Checkbox
																name="dinner"
																checked={filtersSizeAndType.type.dinner}
																onChange={(event) => handleMealFilterChange('type', event)}
															/>
														}
														label="Dinner"
													/>
												</Stack>
												<Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
													<FormControlLabel
														sx={{ float: 'left', width: '70%' }}
														control={
															<Checkbox
																name="coffee"
																checked={filtersSizeAndType.type.coffee}
																onChange={(event) => handleMealFilterChange('type', event)}
															/>
														}
														label="Coffee"
													/>
													<FormControlLabel
														sx={{ float: 'right', width: '40%' }}
														control={
															<Checkbox
																name="snack"
																checked={filtersSizeAndType.type.snack}
																onChange={(event) => handleMealFilterChange('type', event)}
															/>
														}
														label="Snack"
													/>
												</Stack>
												<Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
													<FormControlLabel
														sx={{ float: 'left', width: '70%' }}
														control={
															<Checkbox
																name="caloKids"
																checked={filtersSizeAndType.type.caloKids}
																onChange={(event) => handleMealFilterChange('type', event)}
															/>
														}
														label="Calo Kids"
													/>
													<FormControlLabel
														sx={{ float: 'right', width: '40%' }}
														control={
															<Checkbox
																name="juice"
																checked={filtersSizeAndType.type.juice}
																onChange={(event) => handleMealFilterChange('type', event)}
															/>
														}
														label="Juice"
													/>
												</Stack>
											</AccordionDetails>
										</Accordion>
									</Box>
								</Box>
								<Button
									onClick={() => {
										setFilterMeals({
											...filterMeals,
											size: getTrueKeys(filtersSizeAndType.sizes),
											type: getTrueKeys(filtersSizeAndType.type),
											date: selectedDate
										});
										setAnchorEl(null);
									}}
									variant="outlined"
									sx={{
										display: 'flex',
										mx: 'auto',
										textTransform: 'capitalize',
										color: theme.palette.primary500,
										mb: 1,
										borderRadius: '8px',
										fontSize: '16px',
										fontWeight: 600,
										lineHeight: '21px',
										borderWidth: 1,
										borderColor: theme.palette.primary500
									}}
								>
									Apply Filters
								</Button>
							</Menu>
						</>
					)}

					{selectedDataType === FileManagementType.meal && selectedDate && (
						<Typography
							variant="h6"
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								backgroundColor: caloTheme.palette.primary100,
								lineHeight: '18px',
								fontWeight: 600,
								mx: 1,
								padding: 1,
								borderRadius: '16px',
								display: 'flex',
								flexDirection: 'row',
								color: caloTheme.palette.primary700
							}}
						>
							{`Date: ${selectedDate}`}
							<Stack
								sx={{ marginY: 'auto', cursor: 'pointer' }}
								onClick={() => {
									setSelectedDate(null);
								}}
							>
								<Iconify icon="ph:x-bold" style={{ marginLeft: 8 }} />
							</Stack>
						</Typography>
					)}

					{selectedDataType === FileManagementType.meal && filterMeals.size.length > 0 && (
						<Typography
							variant="h6"
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								backgroundColor: caloTheme.palette.primary100,
								lineHeight: '18px',
								fontWeight: 600,
								mx: 1,
								padding: 1,
								borderRadius: '16px',
								display: 'flex',
								flexDirection: 'row',
								color: caloTheme.palette.primary700
							}}
						>
							{`Size: ${filterMeals.size.map((size: string) => size.toUpperCase()).join(', ')}`}
							<Stack
								sx={{ marginY: 'auto', cursor: 'pointer' }}
								onClick={() => {
									setFilterMeals({ ...filterMeals, size: [] });
									setFiltersSizeAndType({ type: filtersSizeAndType.type, sizes: initialFilterSizeState() });
								}}
							>
								<Iconify icon="ph:x-bold" style={{ marginLeft: 8 }} />
							</Stack>
						</Typography>
					)}

					{selectedDataType === FileManagementType.meal && filterMeals.type.length > 0 && (
						<Typography
							variant="h6"
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								backgroundColor: caloTheme.palette.primary100,
								lineHeight: '18px',
								fontWeight: 600,
								mx: 1,
								padding: 1,
								borderRadius: '16px',
								display: 'flex',
								flexDirection: 'row',
								color: caloTheme.palette.primary700,
								textTransform: 'capitalize'
							}}
						>
							{`Type: ${filterMeals.type?.map((type: FoodType) => type).join(', ')}`}
							<Stack
								sx={{ marginY: 'auto', cursor: 'pointer' }}
								onClick={() => {
									setFilterMeals({ ...filterMeals, type: [] });
									setFiltersSizeAndType({ sizes: filtersSizeAndType.sizes, type: initialFilterTypeState() });
								}}
							>
								<Iconify icon="ph:x-bold" style={{ marginLeft: 8, color: caloTheme.palette.primary700 }} />
							</Stack>
						</Typography>
					)}

					{selectedDataType === FileManagementType.customer && (
						<Typography
							variant="h6"
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								lineHeight: '17px',
								fontWeight: 400,
								mx: 2,
								color: caloTheme.palette.neutral900,
								textTransform: 'capitalize'
							}}
						>
							{uniq(Object.values(checkboxes).flat()).length + ' selected'}
						</Typography>
					)}

					{selectedDataType === FileManagementType.customer && (
						<>
							<IconButton
								aria-haspopup="true"
								style={{ borderRadius: '100%' }}
								onClick={(event: any) => setAnchorEl(event.currentTarget)}
							>
								<Iconify icon="mingcute:filter-2-fill" width={21} color={caloTheme.palette.neutral900} />
							</IconButton>
							<Menu
								id="filter-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={() => setAnchorEl(null)}
								sx={{ width: 460, minWidth: 460, maxWidth: 460 }}
							>
								<Box sx={{ paddingTop: 2, paddingX: 2, width: 300 }}>
									<Typography
										sx={{
											fontWeight: 600,
											fontSize: '20px',
											fontStyle: 'normal',
											lineHeight: '24px'
										}}
									>
										Filters
									</Typography>
								</Box>
								<Box sx={{ width: '100%', padding: 2 }}>
									<Box sx={{ marginBottom: 0.5 }}>
										<Accordion sx={{ borderWidth: 0, borderBottom: 1, borderColor: 'divider' }} variant="outlined">
											<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ width: '100%' }}>
												<Typography
													sx={{
														fontWeight: 600,
														fontSize: '16px',
														fontStyle: 'normal',
														lineHeight: '20px'
													}}
												>
													Delivery Time
												</Typography>
											</AccordionSummary>
											<AccordionDetails sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
												<Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly' }}>
													<FormControlLabel
														control={
															<Checkbox
																name="morning"
																checked={filterDeliveryTime[DeliveryTime.morning]}
																onChange={(event) => handleDeliveryFilterChange(event)}
															/>
														}
														label="Morning"
													/>
													<FormControlLabel
														control={
															<Checkbox
																name="evening"
																checked={filterDeliveryTime[DeliveryTime.evening]}
																onChange={(event) => handleDeliveryFilterChange(event)}
															/>
														}
														label="Evening"
													/>
												</Stack>
											</AccordionDetails>
										</Accordion>
									</Box>
									<Box minWidth={'100%'} sx={{ marginBottom: 0.5 }}>
										<Accordion sx={{ borderWidth: 0, borderBottom: 1, borderColor: 'divider' }} variant="outlined">
											<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ width: '100%' }}>
												<Typography
													sx={{
														fontWeight: 600,
														fontSize: '16px',
														fontStyle: 'normal',
														lineHeight: '20px'
													}}
												>
													Driver
												</Typography>
											</AccordionSummary>
											<AccordionDetails sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
												<Autocomplete
													disablePortal
													id="driver-filter"
													options={[...new Set(driverData.map((driver: KDSDriver) => driver.driver.driverName))]}
													getOptionLabel={(option) => option || ''}
													sx={{
														width: 300,
														height: 60,
														mt: 0
													}}
													ListboxProps={{
														style: {
															maxHeight: '100px'
														}
													}}
													onChange={(event, value) => {
														const driver = driverData.find((driver) => driver.driver.driverName === value);
														setSelectedDriverId(driver ? driver.driver.id : '');
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															size="small"
															placeholder={'Search for driver'}
															InputProps={{
																...params.InputProps,
																onKeyDown: (e) => {
																	if (e.key === 'a') {
																		e.stopPropagation();
																	}
																},
																style: {
																	fontSize: '17px',
																	fontWeight: 400,
																	borderRadius: '8px',
																	backgroundColor: caloTheme.palette.white
																},
																inputProps: {
																	...params.inputProps,
																	className: '',
																	style: {
																		fontSize: '17px',
																		fontWeight: 400
																	}
																}
															}}
														/>
													)}
													disableListWrap
												/>
											</AccordionDetails>
										</Accordion>
									</Box>
								</Box>
								<Button
									onClick={() => {
										const deliveryTimeKeys = getTrueKeys(filterDeliveryTime);
										console.log({ selectedDriverId });
										const deliveryTimeValue = deliveryTimeKeys.length === 1 ? (deliveryTimeKeys[0] as DeliveryTime) : undefined;
										setFilters((prevFilters) => ({
											...prevFilters,
											...(deliveryTimeValue !== undefined && { deliveryTime: deliveryTimeValue }),
											...(selectedDriverId && { driverId: selectedDriverId })
										}));
										setAnchorEl(null);
									}}
									variant="outlined"
									sx={{
										display: 'flex',
										mx: 'auto',
										textTransform: 'capitalize',
										color: theme.palette.primary500,
										mb: 1,
										borderRadius: '8px',
										fontSize: '16px',
										fontWeight: 600,
										lineHeight: '21px',
										borderWidth: 1,
										borderColor: theme.palette.primary500
									}}
								>
									Apply Filters
								</Button>
							</Menu>
						</>
					)}
					{filters.deliveryTime && selectedDataType === FileManagementType.customer && filters.deliveryTime?.length > 0 && (
						<Typography
							variant="h6"
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								backgroundColor: caloTheme.palette.primary100,
								lineHeight: '18px',
								fontWeight: 600,
								mx: 1,
								padding: 1,
								borderRadius: '16px',
								display: 'flex',
								flexDirection: 'row',
								color: caloTheme.palette.primary700
							}}
						>
							{`Delivery Time: ${filters.deliveryTime}`}
							<Stack
								sx={{ marginY: 'auto', cursor: 'pointer' }}
								onClick={() => {
									setFilters({ ...filters, deliveryTime: undefined });
									setFilterDeliveryTime(initialFilterTimeState());
									setFiltersChanged(true);
								}}
							>
								<Iconify icon="ph:x-bold" style={{ marginLeft: 8 }} />
							</Stack>
						</Typography>
					)}

					{filters.driverId && selectedDataType === FileManagementType.customer && filters.driverId?.length > 0 && (
						<Typography
							variant="h6"
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								backgroundColor: caloTheme.palette.primary100,
								lineHeight: '18px',
								fontWeight: 600,
								mx: 1,
								padding: 1,
								borderRadius: '16px',
								display: 'flex',
								flexDirection: 'row',
								color: caloTheme.palette.primary700
							}}
						>
							{`Driver: ${getDriverNameById(filters.driverId, deliveriesData)}`}
							<Stack
								sx={{ marginY: 'auto', cursor: 'pointer' }}
								onClick={() => {
									setFilters({ ...filters, driverId: '' });
									setSelectedDriverId('');
									setFiltersChanged(true);
								}}
							>
								<Iconify icon="ph:x-bold" style={{ marginLeft: 8 }} />
							</Stack>
						</Typography>
					)}
				</Stack>

				<LoadingButton
					loading={loading}
					variant="contained"
					disabled={disabledDownload}
					sx={{
						textTransform: 'capitalize',
						fontSize: '19px',
						borderRadius: '8px',
						lineHeight: '23px',
						color: 'white',
						fontWeight: 600,
						boxShadow: 'none',
						p: '10.5px 20px',
						':hover': {
							boxShadow: 'none'
						}
					}}
					onClick={() => {
						if (selectedDataType === FileManagementType.meal) {
							downloadSelectorRef.current?.open();
						} else {
							handleExportDoc();
						}
					}}
				>
					Download
				</LoadingButton>
			</Stack>

			<Popup
				style={{
					fullWidth: true,
					maxWidth: 'laptop'
				}}
				title={'Download'}
				ref={downloadSelectorRef}
				onClose={handleClosePopup}
				subTitle={
					<Stack display={'flex'} sx={{ mr: 13 }}>
						<Typography>All Meals</Typography>
						<InputField
							type="number"
							value={allMealAmount}
							disabled={appContext.isOffline}
							onChange={(event) => handleAllMealAmountChange(+event)}
							debounce
							fullWidth={true}
							inputMode="numeric"
							sx={{
								fontSize: '16px',
								fontWeight: 600,
								border: '1px solid' + caloTheme.palette.neutral900,
								borderRadius: '8px',
								width: '136px',
								height: '44px',
								textAlign: 'center',
								p: '10px',
								input: { textAlign: 'center' },
								color: appContext.isOffline ? caloTheme.palette.neutral600 : caloTheme.palette.neutral900,
								':disabled': { color: caloTheme.palette.neutral600, backgroundColor: caloTheme.palette.neutral100 }
							}}
						/>
					</Stack>
				}
			>
				<DownloadMealPopUp
					date={filterMeals.date ?? date}
					mealCheckboxes={mealCheckboxes}
					setMealCheckboxes={setMealCheckboxes}
				/>
			</Popup>
		</Box>
	);
};
export default FileManagementHeader;
