import { useContext, useEffect, useState } from 'react';

import { format, subDays } from 'date-fns/fp';
import { flatMap, groupBy, sortBy } from 'lodash-es';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../App';
import { caloTheme } from '../../assets/themes/calo';
import EmptyMessage from '../../components/EmptyMessage';
import { LIST_FOOD_COMPONENTS_BY_RANGE_QUERY } from '../../libs/graphQL';
import { FoodComponentWastage, KDSFoodComponent } from '../../libs/interfaces';
import Filter from './Filter';
import SectionPage from './SectionPage';

interface Filters {
	startDate: string;
	endDate: string;
}

interface WastageArchiveProps {
	kitchen: string;
	date: string;
}

const WastageArchive = ({ kitchen, date }: WastageArchiveProps) => {
	const [filters, setFilters] = useState<Filters>({
		startDate: format('yyyy-MM-dd')(subDays(5)(Date.parse(date))),
		endDate: format('yyyy-MM-dd')(Date.parse(date))
	});
	const [isFilterEnabled, setIsFilterEnabled] = useState(false);
	const theme = useTheme();
	const limit = 20;
	const [groupedFoodComponents, setGroupedFoodComponents] = useState<KDSFoodComponent[][]>([]);
	const [next, setNext] = useState<string | null>(null);
	const [lastDayFoodComponents, setLastDayFoodComponents] = useState<string>(format('yyyy-MM-dd')(Date.parse(date)));
	const queryVariables = {
		kitchen,
		startDate: filters.startDate,
		endDate: filters.endDate
	};
	const appContext = useContext(AppContext);

	const { fetchMore, refetch, loading } = useQuery(LIST_FOOD_COMPONENTS_BY_RANGE_QUERY, {
		variables: { ...queryVariables, limit: isFilterEnabled ? limit : null, next: null },
		onCompleted: (data) => {
			const groupedFoodComponentsByDate: KDSFoodComponent[][] = Object.values(
				groupBy(data.listFoodComponentByRange.components, 'date')
			);
			if (isFilterEnabled) {
				setGroupedFoodComponents(groupedFoodComponentsByDate);
				setNext(data.listFoodComponentByRange.next);
			} else {
				setGroupedFoodComponents([groupedFoodComponentsByDate[groupedFoodComponentsByDate.length - 1]]);
				const lastDayFoodComponents =
					groupedFoodComponentsByDate && groupedFoodComponentsByDate.length > 0
						? groupedFoodComponentsByDate[groupedFoodComponentsByDate.length - 1][0]
						: null;
				setLastDayFoodComponents(lastDayFoodComponents ? lastDayFoodComponents.date : '');
			}
			if (next) scrollingPagination.pageLoader = false;
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite'
	});

	useEffect(() => {
		refetch({ ...queryVariables, limit: isFilterEnabled ? limit : null, next: null });
	}, [filters]);

	useEffect(() => {
		setFilters({
			startDate: format('yyyy-MM-dd')(subDays(5)(Date.parse(date))),
			endDate: format('yyyy-MM-dd')(Date.parse(date))
		});
		setIsFilterEnabled(false);
	}, [kitchen, date]);

	const handleFetchMore = function () {
		if (next) {
			fetchMore({
				variables: { ...queryVariables, limit: limit, next: next },
				updateQuery: (prev: FoodComponentWastage | undefined, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					if (prev) {
						setNext(fetchMoreResult?.listFoodComponentByRange?.next ? fetchMoreResult.listFoodComponentByRange.next : null);
						setGroupedFoodComponents((components) =>
							Object.values(
								groupBy(sortBy([...flatMap(components), ...fetchMoreResult.listFoodComponentByRange.components], 'date'), 'date')
							)
						);
					}
				}
			});
		}
	};

	const scrollingPagination = {
		pageLoader: false,
		handleScroll: () => {
			const userScrollHeight = window.innerHeight + window.scrollY;
			const windowBottomHeight = document.documentElement.offsetHeight;
			if (userScrollHeight >= windowBottomHeight - 2000 && scrollingPagination.pageLoader === false) {
				scrollingPagination.pageLoader = true;
				handleFetchMore();
			}
		},
		init: () => {
			scrollingPagination.pageLoader = false;
			window.addEventListener('scroll', scrollingPagination.handleScroll, true);
		}
	};

	useEffect(() => {
		if (next) {
			scrollingPagination.init();
		}
		return () => window.removeEventListener('scroll', scrollingPagination.handleScroll);
	}, [next]);

	return (
		<Box sx={{ width: '97%' }}>
			<Stack direction="column" sx={{ ml: '20px', mt: '15px', mb: '10px', width: '100%' }}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					<Filter
						filters={filters}
						setFilters={setFilters}
						setIsFilterEnabled={setIsFilterEnabled}
						isFilterEnabled={isFilterEnabled}
						date={date}
						kitchen={kitchen}
						lastDayDate={lastDayFoodComponents}
						isDownloadDisabled={!(groupedFoodComponents.length > 0)}
					/>
				</Grid>
				<Box sx={{ minHeight: '100vh', mt: `${!isFilterEnabled && '-45px'}` }}>
					{groupedFoodComponents.length > 0 && !appContext.isOffline ? (
						loading ? (
							<Box
								sx={{
									fontFamily: caloTheme.typography.fontFamily,
									fontSize: '28px',
									textTransform: 'uppercase',
									color: theme.palette.neutral600,
									mt: `${isFilterEnabled ? '10px' : '55px'}`
								}}
							>
								Loading ...
							</Box>
						) : (
							groupedFoodComponents.map((foodComponents, index) => (
								<SectionPage key={index} foodComponents={sortBy(foodComponents, (f) => f.name.en)} />
							))
						)
					) : (
						<EmptyMessage label={'no data'} style={{ margin: 0, marginTop: '30px' }} />
					)}
				</Box>
				{!next && isFilterEnabled && !loading && !scrollingPagination.pageLoader ? (
					<Box
						sx={{
							mt: 2,
							fontFamily: caloTheme.typography.fontFamily,
							fontSize: '28px',
							textTransform: 'uppercase',
							color: theme.palette.neutral600,
							textAlign: 'center'
						}}
					>
						The End
					</Box>
				) : (
					isFilterEnabled &&
					!loading && (
						<Stack direction="column" alignItems="center">
							<CircularProgress sx={{ textAlign: 'center', mt: 2 }} />
						</Stack>
					)
				)}
			</Stack>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading && !appContext.isOffline}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
};

export default WastageArchive;
