import { CupSizes, MeasurementUnit } from '@calo/dashboard-types';
import { ComponentService } from '@calo/services';

import { CupsQuantity, FoodComponent, KDSFoodComponent } from '../interfaces';

export const getCookedRawFactor = (foodComponent: KDSFoodComponent) => foodComponent.weight / foodComponent.cookedWeight;

export const weightCalculator = (
	foodComponent: KDSFoodComponent | FoodComponent,
	weight: number,
	count: number,
	cupsQuantity?: CupsQuantity[],
	quantities?: number[],
	cookedRawFactor = 1
) => {
	if (foodComponent.measurementUnit === MeasurementUnit.cup && foodComponent.cups && cupsQuantity) {
		return cupsCalculatorUsingCupsQuantity(cupsQuantity, foodComponent.cups) * cookedRawFactor;
	} else if (foodComponent.measurementUnit === MeasurementUnit.cup && foodComponent.cups && quantities) {
		const totalWeight = ComponentService.getWeightFromAllCupsQuantities(
			quantities.map((q) => ({ component: q })),
			foodComponent.cups
		);
		return Math.round(totalWeight * cookedRawFactor);
	}

	return Math.round(weight * count);
};

export const cupsCalculatorUsingCupsQuantity = (cupsQuantity: CupsQuantity[], cups: CupSizes[]) => {
	let result = 0;
	for (const cupQuantity of cupsQuantity) {
		result += ComponentService.calculateComponentWeightFromCups(cupQuantity.cup, cups) * cupQuantity.quantity;
	}

	return result;
};

export const getComponentsWithNonZeroCount = (foodComponents: KDSFoodComponent[]) => foodComponents.filter((c) => c.count > 0);
