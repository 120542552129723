import { useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import {
	Button,
	Drawer,
	FormControl,
	FormControlLabel,
	MenuItem,
	Paper,
	Select,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../../../App';
import { caloTheme } from '../../../../assets/themes/calo';
import { InputField } from '../../../../components';
import { LeftoverAction } from '../../../../libs/enums';
import { UPDATE_WASTAGE_TRACKING } from '../../../../libs/graphQL';
import { KDSFoodComponent } from '../../../../libs/interfaces';

interface WastageTableRowProps {
	foodComponent: KDSFoodComponent;
}

const preventMinus = (e: { code: string; preventDefault: () => void }) => {
	if (e.code === 'Minus') {
		e.preventDefault();
	}
};

const handlePreppedWeightError = (selectedFoodComponent: KDSFoodComponent, value: number) =>
	Math.abs(Math.round(selectedFoodComponent.weight * selectedFoodComponent.count) - Number(value)) > Number(value) ||
	Math.abs(Math.round(selectedFoodComponent.weight * selectedFoodComponent.count) - Number(value)) >
		Math.round(selectedFoodComponent.weight * selectedFoodComponent.count);

const handleChilledWeightError = (selectedFoodComponent: KDSFoodComponent, value: number) =>
	Math.abs(Math.round(selectedFoodComponent.cookedWeight * selectedFoodComponent.count) - Number(value)) > Number(value) ||
	Math.abs(Math.round(selectedFoodComponent.cookedWeight * selectedFoodComponent.count) - Number(value)) >
		Math.round(selectedFoodComponent.cookedWeight * selectedFoodComponent.count);

const WastageTableRow = ({ foodComponent }: WastageTableRowProps) => {
	const [wastageEdit, setWastageEdit] = useState<string>('');
	const [actionType, setActionType] = useState<string>('action');
	const [componentWastageValues, setComponentWastageValues] = useState<{
		preppedWeight: number | undefined;
		chilledWeight: number | undefined;
		wastage: number | undefined;
		action: string | undefined;
	}>({
		preppedWeight: foodComponent.kitchenWastageInputs?.preppedWeight,
		chilledWeight: foodComponent.kitchenWastageInputs?.chilledWeight,
		wastage: foodComponent.kitchenWastageInputs?.wastage,
		action: foodComponent.kitchenWastageInputs?.action
	});

	const theme = useTheme();
	const appContext = useContext(AppContext);
	const [popupOpen, setPopupOpen] = useState(false);

	const [mutateWastageTracking, { loading }] = useMutation(UPDATE_WASTAGE_TRACKING, {
		onCompleted() {
			toast.success('Updated successfully');
		},
		onError() {
			toast.error('error');
		}
	});

	useEffect(() => {
		if (!loading && foodComponent.kitchenWastageInputs) {
			const kitchenWastage = foodComponent.kitchenWastageInputs;
			if (kitchenWastage.chilledWeight && kitchenWastage.chilledWeight !== foodComponent.kitchenWastageInputs.chilledWeight)
				setComponentWastageValues({
					...componentWastageValues,
					chilledWeight: foodComponent.kitchenWastageInputs.chilledWeight || undefined
				});
			if (kitchenWastage.preppedWeight && kitchenWastage.preppedWeight !== foodComponent.kitchenWastageInputs.preppedWeight)
				setComponentWastageValues({
					...componentWastageValues,
					preppedWeight: foodComponent.kitchenWastageInputs.preppedWeight || undefined
				});
			if (kitchenWastage.wastage && kitchenWastage.wastage !== foodComponent.kitchenWastageInputs.wastage) {
				setComponentWastageValues({
					...componentWastageValues,
					wastage: foodComponent.kitchenWastageInputs.wastage || undefined
				});
			}
			if (kitchenWastage.action && kitchenWastage.action !== foodComponent.kitchenWastageInputs.action) {
				setComponentWastageValues({ ...componentWastageValues, action: foodComponent.kitchenWastageInputs.action || '' });
			}
		}
	}, [foodComponent]);

	useEffect(() => {
		if (wastageEdit === foodComponent.id) {
			setPopupOpen(true);
			setComponentWastageValues({
				preppedWeight: foodComponent.kitchenWastageInputs?.preppedWeight || undefined,
				chilledWeight: foodComponent.kitchenWastageInputs?.chilledWeight || undefined,
				wastage: foodComponent.kitchenWastageInputs?.wastage || undefined,
				action: foodComponent.kitchenWastageInputs?.action || ''
			});
			if (
				componentWastageValues.action &&
				!(
					componentWastageValues.action === 'waste' ||
					componentWastageValues.action === 'staffMeal' ||
					componentWastageValues.action === 'useTomorrow'
				)
			) {
				setActionType('other');
			}
		} else {
			setPopupOpen(false);
			setActionType('action');
		}
	}, [wastageEdit]);

	const handleSubmitEditWastage = async () => {
		await mutateWastageTracking({
			variables: {
				id: foodComponent.id,
				kitchen: foodComponent.kitchen,
				inputs: {
					preppedWeight: componentWastageValues.preppedWeight || null,
					chilledWeight: componentWastageValues.chilledWeight || null,
					wastage: componentWastageValues.wastage || null,
					action: componentWastageValues.action
				}
			}
		});
		setWastageEdit('');
	};

	return (
		<>
			<Box
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'space-between'}
				key={foodComponent.id}
				onClick={() => {
					setWastageEdit(foodComponent.id);
				}}
			>
				<Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
					<Typography
						variant="h6"
						sx={{
							fontWeight: 600,
							fontSize: '16px',
							lineHeight: '19px',
							color: caloTheme.palette.black,
							my: 2,
							ml: 1
						}}
					>
						{foodComponent.name.en}
					</Typography>
					<Typography
						variant="caption"
						sx={{
							fontWeight: 400,
							fontSize: '12px',
							lineHeight: '19px',
							m: 2,
							textTransform: 'capitalize'
						}}
					>
						{foodComponent.kitchenWastageInputs?.action ? `${foodComponent.kitchenWastageInputs?.action}` : 'No Action'}
					</Typography>
				</Stack>

				<Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ mx: '12px' }}>
					<Stack
						sx={{
							textTransform: 'none',
							fontSize: '16px',
							fontWeight: 600,
							border: '1px solid transparent',
							borderRadius: '4px',
							width: '100%',
							mr: '12px',
							height: '64px',
							textAlign: 'center',
							backgroundColor:
								foodComponent.kitchenWastageInputs?.preppedWeight &&
								handlePreppedWeightError(foodComponent, foodComponent.kitchenWastageInputs?.preppedWeight)
									? theme.palette.secondaryRed50
									: !foodComponent.kitchenWastageInputs?.preppedWeight || appContext.isOffline
										? theme.palette.neutral100
										: theme.palette.primary100
						}}
					>
						<Typography sx={{ pt: '8px', fontSize: '10px', fontWeight: 400, lineHeight: '12px' }}>Actual Raw</Typography>
						<Typography
							sx={{
								pt: '6px',
								fontSize: '19px',
								fontWeight: 600,
								lineHeight: '23px',
								color:
									foodComponent.kitchenWastageInputs?.preppedWeight &&
									handlePreppedWeightError(foodComponent, foodComponent.kitchenWastageInputs?.preppedWeight)
										? theme.palette.secondaryRed500
										: !foodComponent.kitchenWastageInputs?.preppedWeight || appContext.isOffline
											? theme.palette.neutral600
											: theme.palette.primary500
							}}
						>
							{foodComponent.kitchenWastageInputs?.preppedWeight
								? `${foodComponent.kitchenWastageInputs?.preppedWeight} g`
								: '0 g'}
						</Typography>
					</Stack>

					<Stack
						sx={{
							display: 'flex',
							flexDirection: 'column',
							textTransform: 'none',
							fontSize: '16px',
							fontWeight: 600,
							border:
								foodComponent.kitchenWastageInputs?.chilledWeight &&
								handlePreppedWeightError(foodComponent, foodComponent.kitchenWastageInputs?.chilledWeight)
									? '1px solid red'
									: '1px solid transparent',
							borderRadius: '4px',
							width: '100%',
							mr: '12px',
							height: '64px',
							textAlign: 'center',
							input: { textAlign: 'center' },
							backgroundColor:
								foodComponent.kitchenWastageInputs?.chilledWeight &&
								handlePreppedWeightError(foodComponent, foodComponent.kitchenWastageInputs?.chilledWeight)
									? theme.palette.secondaryRed50
									: !foodComponent.kitchenWastageInputs?.chilledWeight || appContext.isOffline
										? theme.palette.neutral100
										: theme.palette.primary100,
							':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
						}}
					>
						<Typography sx={{ pt: '8px', fontSize: '10px', fontWeight: 400, lineHeight: '12px' }}>Actual Cooked</Typography>
						<Typography
							sx={{
								pt: '6px',
								fontSize: '19px',
								fontWeight: 600,
								lineHeight: '23px',
								color:
									foodComponent.kitchenWastageInputs?.chilledWeight &&
									handlePreppedWeightError(foodComponent, foodComponent.kitchenWastageInputs?.chilledWeight)
										? theme.palette.secondaryRed500
										: !foodComponent.kitchenWastageInputs?.preppedWeight || appContext.isOffline
											? theme.palette.neutral600
											: theme.palette.primary500
							}}
						>
							{foodComponent.kitchenWastageInputs?.chilledWeight
								? `${foodComponent.kitchenWastageInputs?.chilledWeight} g`
								: '0 g'}
						</Typography>
					</Stack>

					<Stack
						sx={{
							textTransform: 'none',
							fontSize: '16px',
							fontWeight: 600,
							border: '1px solid transparent',
							borderRadius: '4px',
							width: '100%',
							height: '64px',
							textAlign: 'center',
							input: { textAlign: 'center' },
							backgroundColor:
								foodComponent.kitchenWastageInputs?.wastage &&
								handleChilledWeightError(foodComponent, foodComponent.kitchenWastageInputs?.wastage)
									? theme.palette.secondaryRed50
									: !foodComponent.kitchenWastageInputs?.wastage || appContext.isOffline
										? theme.palette.neutral100
										: theme.palette.primary100,
							':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
						}}
					>
						<Typography sx={{ pt: '8px', fontSize: '10px', fontWeight: 400, lineHeight: '12px' }}>Left Over</Typography>
						<Typography
							sx={{
								pt: '6px',
								fontSize: '19px',
								fontWeight: 600,
								lineHeight: '23px',
								color:
									foodComponent.kitchenWastageInputs?.wastage &&
									handleChilledWeightError(foodComponent, foodComponent.kitchenWastageInputs?.wastage)
										? theme.palette.secondaryRed500
										: !foodComponent.kitchenWastageInputs?.preppedWeight || appContext.isOffline
											? theme.palette.neutral600
											: theme.palette.primary500
							}}
						>
							{foodComponent.kitchenWastageInputs?.wastage ? `${foodComponent.kitchenWastageInputs?.wastage} g` : '0 g'}
						</Typography>
					</Stack>
				</Box>
			</Box>

			<Drawer
				anchor="bottom"
				onClose={() => {
					setWastageEdit('');
					setPopupOpen(false);
				}}
				open={popupOpen}
			>
				<Paper sx={{ flexGrow: 1, overflow: 'hidden' }}>
					<Stack display={'flex'} flexDirection="column" sx={{ width: '96%' }}>
						<Typography
							component="div"
							sx={{
								m: 2,
								fontSize: '16px',
								lineHeight: '19px',
								fontWeight: 600,
								width: '100%',
								justifyContent: 'start',
								display: 'flex',
								flexDirection: 'row'
							}}
						>
							{`Enter Wastage For ${foodComponent.name.en}`}
						</Typography>

						<FormControl sx={{ width: '96%', ml: 2 }}>
							<FormControlLabel
								control={<Typography />}
								label={
									<Typography
										variant="body1"
										sx={{
											ml: 1
										}}
									>
										Actual Raw Weight
									</Typography>
								}
							/>
							<TextField
								sx={{
									boxShadow: 'none',
									my: 1,
									'.MuiOutlinedInput-notchedOutline': { border: 0 }
								}}
								placeholder={'0'}
								value={componentWastageValues.preppedWeight || undefined}
								type="number"
								inputMode="numeric"
								onKeyPress={preventMinus}
								style={{
									backgroundColor:
										componentWastageValues.preppedWeight &&
										handlePreppedWeightError(foodComponent, componentWastageValues.preppedWeight)
											? theme.palette.secondaryRed50
											: !componentWastageValues.preppedWeight || appContext.isOffline
												? theme.palette.neutral100
												: theme.palette.primary100
								}}
								onChange={(event) => setComponentWastageValues({ ...componentWastageValues, preppedWeight: +event.target.value })}
							/>
						</FormControl>

						<FormControl sx={{ width: '96%', ml: 2 }}>
							<FormControlLabel
								control={<Typography />}
								label={
									<Typography
										variant="body1"
										sx={{
											ml: 1
										}}
									>
										Actual Cooked Weight
									</Typography>
								}
							/>
							<TextField
								sx={{
									boxShadow: 'none',
									my: 1,
									'.MuiOutlinedInput-notchedOutline': { border: 0 }
								}}
								placeholder={'0'}
								value={componentWastageValues.chilledWeight || undefined}
								type="number"
								inputMode="numeric"
								onKeyPress={preventMinus}
								style={{
									minWidth: '94%',
									backgroundColor:
										componentWastageValues.chilledWeight &&
										handlePreppedWeightError(foodComponent, componentWastageValues.chilledWeight)
											? theme.palette.secondaryRed50
											: !componentWastageValues.chilledWeight || appContext.isOffline
												? theme.palette.neutral100
												: theme.palette.primary100,
									fontSize: '19px'
								}}
								onChange={(event) => setComponentWastageValues({ ...componentWastageValues, chilledWeight: +event.target.value })}
							></TextField>
						</FormControl>

						<FormControl sx={{ width: '96%', ml: 2 }}>
							<FormControlLabel
								control={<Typography />}
								label={
									<Typography
										variant="body1"
										sx={{
											ml: 1
										}}
									>
										Left Over
									</Typography>
								}
							/>
							<TextField
								sx={{
									boxShadow: 'none',
									my: 1,
									'.MuiOutlinedInput-notchedOutline': { border: 0 },
									':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
								}}
								placeholder={'0'}
								value={componentWastageValues.wastage || undefined}
								type="number"
								inputMode="numeric"
								onKeyPress={preventMinus}
								style={{
									minWidth: '94%',
									backgroundColor:
										componentWastageValues.wastage && handleChilledWeightError(foodComponent, componentWastageValues.wastage)
											? theme.palette.secondaryRed50
											: !componentWastageValues.wastage || appContext.isOffline
												? theme.palette.neutral100
												: theme.palette.primary100,
									fontSize: '19px'
								}}
								onChange={(event) => setComponentWastageValues({ ...componentWastageValues, wastage: +event.target.value })}
							/>
						</FormControl>

						<FormControl sx={{ width: '96%', ml: 2 }}>
							<FormControlLabel
								control={<Typography />}
								label={
									<Typography
										variant="body1"
										sx={{
											ml: 1,
											mb: 1
										}}
									>
										Action
									</Typography>
								}
							/>
							<Select
								sx={{
									width: '100%',
									borderRadius: '8px'
								}}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: '300px'
										}
									}
								}}
								value={componentWastageValues.action ? `${componentWastageValues.action}` : 'Action'}
							>
								{Object.entries(LeftoverAction).map(([key, value]) => (
									<MenuItem
										key={key}
										value={key}
										onClick={() => {
											key === 'other' ? setActionType('other') : setActionType('action');
											setComponentWastageValues({ ...componentWastageValues, action: key });
										}}
									>
										{value}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						{actionType === 'other' && (
							<InputField
								type="text"
								inputMode="text"
								disabled={appContext.isOffline}
								debounce
								fullWidth={true}
								value={componentWastageValues.action}
								onChange={(event) => setComponentWastageValues({ ...componentWastageValues, action: event.toString() })}
								sx={{
									color: theme.palette.neutral600,
									backgroundColor: theme.palette.neutral100,
									border: '1px solid ' + theme.palette.neutral600,
									fontWeight: 600,
									width: '96%',
									height: '50px',
									px: 1,
									mx: 2,
									mt: 1,
									borderRadius: '5px'
								}}
							/>
						)}
					</Stack>

					<Button
						variant="contained"
						sx={{
							my: 2,
							mx: 2,
							width: '92%',
							backgroundColor: caloTheme.palette.primary500,
							boxShadow: 'none',
							textTransform: 'none',
							color: 'white',
							textAlign: 'center',
							height: '39px',
							borderRadius: '8px',
							fontWeight: 600,
							fontSize: '16px'
						}}
						onClick={() => handleSubmitEditWastage()}
					>
						Done
					</Button>
				</Paper>
			</Drawer>
		</>
	);
};

export default WastageTableRow;
