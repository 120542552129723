import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { sortBy, startCase } from 'lodash-es';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { FoodDietType, FoodType } from '@calo/types';
import CloseIcon from '@mui/icons-material/Close';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { DateRange } from '@mui/lab/DateRangePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Box,
	Chip,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	Select,
	Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import DateRangePicker from '../../../components/DateRangePicker';
import { ProductivityMetrics, Session } from '../../../libs/interfaces';
import { getDate, roundToTwoDecimals } from '../../../libs/utils/helperFunctions';
import { formatTime, getFilteredFoods, getTotalCostPerMinute } from '../helpers';
import { Filters } from '../ProductivityMetrics';

interface FilterProps {
	filters: Filters;
	setFilters: React.Dispatch<React.SetStateAction<Filters>>;
	clearFilters: () => void;
	productivityMetrics: ProductivityMetrics[];
}

const Filter = ({ filters, setFilters, clearFilters, productivityMetrics }: FilterProps) => {
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [isFilterApplied, setIsFilterApplied] = useState(false);
	const [dateRange, setDateRange] = useState<DateRange<Date>>([new Date(filters.startDate), new Date(filters.endDate)]);
	const [shiftState, setShiftState] = useState<Session>(filters.shift);
	const [type, setType] = useState<FoodType[]>(filters.type);
	const [tags, setTags] = useState<FoodDietType[]>(filters.tags);
	const [onlySandwiches, setOnlySandwiches] = useState<boolean>(filters.onlySandwiches);

	const appContext = useContext(AppContext);

	useEffect(() => {
		setDateRange([new Date(filters.startDate), new Date(filters.endDate)]);
		setShiftState(filters.shift);
		setType(filters.type);
		setTags(filters.tags);
		setOnlySandwiches(filters.onlySandwiches);
	}, [filters]);

	const handleApplyFilters = () => {
		setIsFilterApplied(true);
		if (dateRange[0] && dateRange[1]) {
			setIsFilterOpened(false);
			setFilters({
				...filters,
				startDate: dateRange[0] ? getDate(dateRange[0]) : '',
				endDate: dateRange[1] ? getDate(dateRange[1]) : '',
				shift: shiftState,
				type: type,
				tags: tags,
				onlySandwiches: onlySandwiches
			});
		} else {
			toast.error('Please select a valid date range');
		}
	};
	const onExport = useCallback(async () => {
		if (!productivityMetrics || productivityMetrics.length === 0) {
			return;
		}
		const headers = [
			'Meal name',
			'No. of components',
			'Modified',
			'Total',
			'Actual portioning',
			'Predicted portioning',
			'Time per meal',
			'Cost',
			'Predicted Time per meal',
			'Predicted Cost'
		];
		const workbook = new ExcelJS.Workbook();
		const totalCostPerMinute = getTotalCostPerMinute(filters.actual);
		const totalPredictedCostPerMinute = getTotalCostPerMinute(filters.predicted);
		for (const productivityMetric of productivityMetrics) {
			const worksheet = workbook.addWorksheet(productivityMetric.day, {
				pageSetup: { fitToPage: true, orientation: 'portrait' }
			});
			const headerRow = worksheet.getRow(1);
			headerRow.values = headers;
			headerRow.eachCell((cell) => {
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: { argb: 'F7F7F7' }
				};
				cell.alignment = { horizontal: 'left', vertical: 'middle' };
				cell.border = {
					top: { style: 'thin' },
					left: { style: 'thin' },
					bottom: { style: 'thin' },
					right: { style: 'thin' }
				};
			});
			worksheet.columns = headers.map((header) => ({ header, key: header, width: 20 }));
			worksheet.getColumn('B').alignment = { horizontal: 'center' };
			worksheet.getColumn('C').alignment = { horizontal: 'center' };
			worksheet.getColumn('D').alignment = { horizontal: 'center' };
			worksheet.getColumn('E').alignment = { horizontal: 'center' };
			worksheet.getColumn('F').alignment = { horizontal: 'center' };
			worksheet.getColumn('G').alignment = { horizontal: 'center' };
			worksheet.getColumn('H').alignment = { horizontal: 'center' };
			worksheet.getColumn('I').alignment = { horizontal: 'center' };
			worksheet.getColumn('J').alignment = { horizontal: 'center' };
			const filteredFoods = getFilteredFoods(filters, productivityMetric.foods);

			for (const food of sortBy(filteredFoods, 'name.en')) {
				const shiftData = food.metrics.find((metric) => metric.shift === filters.shift);

				worksheet.addRow({
					'Meal name': food.name.en,
					'No. of components': food.numberOfComponents,
					Modified: shiftData?.numberOfModified ?? 0,
					Total: shiftData?.numberOfFoods ?? 0,
					'Actual portioning': formatTime(shiftData?.portioningTime ?? 0),
					'Predicted portioning': formatTime(
						(shiftData?.predictedPortioningTimePerMeal ?? 5000) * (shiftData?.numberOfFoods ?? 0)
					),
					'Time per meal': formatTime((shiftData?.portioningTime ?? 0) / (shiftData?.numberOfFoods || 1)),
					Cost: roundToTwoDecimals(((shiftData?.portioningTime ?? 0) / 1000 / 60) * totalCostPerMinute),
					'Predicted Time per meal': formatTime(shiftData?.predictedPortioningTimePerMeal ?? 5000),
					'Predicted Cost': roundToTwoDecimals(
						(((shiftData?.predictedPortioningTimePerMeal ?? 5000) * (shiftData?.numberOfFoods ?? 0)) / 1000 / 60) *
							totalPredictedCostPerMinute
					)
				});
			}
		}
		const buffer = await workbook.xlsx.writeBuffer();
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const fileExtension = '.xlsx';
		const blob = new Blob([buffer], { type: fileType });
		saveAs(
			blob,
			`Productivity Metrics ${filters.kitchen}-${filters.shift}-${filters.startDate}-${filters.endDate}` + fileExtension
		);
	}, [filters, productivityMetrics]);

	return (
		<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} width={'98%'}>
			<Button
				variant="outlined"
				disabled={appContext.isOffline}
				onClick={() => setIsFilterOpened(true)}
				startIcon={<FilterAltRoundedIcon />}
				sx={{
					color: isFilterApplied ? caloTheme.palette.primary.main : caloTheme.palette.text.primary,
					borderColor: isFilterApplied ? caloTheme.palette.primary.main : caloTheme.palette.text.primary
				}}
			>
				Filter
			</Button>
			<LoadingButton
				loading={false}
				disableElevation
				disabled={appContext.isOffline}
				onClick={onExport}
				variant="outlined"
				startIcon={<DownloadRoundedIcon />}
				sx={{ color: caloTheme.palette.text.primary, borderColor: caloTheme.palette.text.primary }}
			>
				Download
			</LoadingButton>
			<Drawer anchor={'right'} open={isFilterOpened} onClose={() => setIsFilterOpened(false)}>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					sx={{ m: 2, width: 300, height: '100%', my: 4 }}
				>
					<Stack direction="column" justifyContent="space-between" alignItems="start" spacing={4} sx={{ mx: 2, width: '100%' }}>
						<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width={'100%'}>
							<Typography sx={{ fontSize: '24px', fontWeight: 700, color: caloTheme.palette.text.primary }}>Filter</Typography>
							<IconButton
								aria-label="close"
								onClick={() => setIsFilterOpened(false)}
								sx={{
									color: caloTheme.palette.text.primary
								}}
							>
								<CloseIcon sx={{ fontSize: '24px', fontWeight: 700 }} />
							</IconButton>
						</Stack>
						<FormControl fullWidth>
							<InputLabel>Delivery shift</InputLabel>
							<Select label="Delivery shift" value={shiftState} onChange={(e) => setShiftState(e.target.value as Session)}>
								{filters.availableShifts.map((shiftValue) => (
									<MenuItem key={shiftValue} value={shiftValue}>
										{startCase(shiftValue)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<DateRangePicker
							dateRange={dateRange}
							setDateRange={setDateRange}
							dayRangeLimit={30}
							stackDirection="row"
							startLabel="From"
							endLabel="To"
							disableFutureDates={true}
							showCalendarIcon={true}
						/>
						<FormControl fullWidth>
							<InputLabel>{type.length === 0 ? 'Select Meal types' : 'Meal types'}</InputLabel>
							<Select
								label={type.length === 0 ? 'Select Meal types' : 'Meal types'}
								value={type}
								multiple
								onChange={(e) => setType(e.target.value as FoodType[])}
								renderValue={(selected) => (
									<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
										{selected.map((value) => (
											<Chip
												key={value}
												label={value}
												onMouseDown={(event) => event.stopPropagation()}
												onDelete={() => setType((old) => old.filter((type) => type !== value))}
											/>
										))}
									</Box>
								)}
							>
								{Object.values(FoodType).map((typeValue) => (
									<MenuItem key={typeValue} value={typeValue}>
										<Checkbox checked={type.includes(typeValue)} />
										<ListItemText primary={startCase(typeValue)} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl fullWidth>
							<InputLabel>{tags.length === 0 ? 'Select Dietary plan' : 'Dietary plan'}</InputLabel>
							<Select
								label={tags.length === 0 ? 'Select Dietary plan' : 'Dietary plan'}
								value={tags}
								multiple
								onChange={(e) => setTags(e.target.value as FoodDietType[])}
								renderValue={(selected) => (
									<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
										{selected.map((value) => (
											<Chip
												key={value}
												label={value}
												onMouseDown={(event) => event.stopPropagation()}
												onDelete={() => setTags((old) => old.filter((tag) => tag !== value))}
											/>
										))}
									</Box>
								)}
							>
								{Object.values(FoodDietType).map((planValue) => (
									<MenuItem key={planValue} value={planValue}>
										<Checkbox checked={tags.includes(planValue)} />
										<ListItemText primary={startCase(planValue)} />
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl fullWidth>
							<InputLabel>Only Sandwiches</InputLabel>
							<Select
								label="Only Sandwiches"
								value={onlySandwiches}
								onChange={(e) => setOnlySandwiches(Boolean(e.target.value))}
								renderValue={(selected) => (selected ? 'Yes' : 'No')}
							>
								<MenuItem value={1}>
									<FormControlLabel value={1} control={<Radio checked={onlySandwiches} />} label="Yes" />
								</MenuItem>
								<MenuItem value={0}>
									<FormControlLabel value={0} control={<Radio checked={!onlySandwiches} />} label="No" />
								</MenuItem>
							</Select>
						</FormControl>
					</Stack>
					<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width={'100%'}>
						<Button
							variant="outlined"
							sx={{ width: '50%' }}
							onClick={() => {
								clearFilters();
								setIsFilterApplied(false);
								setIsFilterOpened(false);
							}}
						>
							clear filter
						</Button>
						<Button variant="contained" sx={{ color: caloTheme.palette.white, width: '50%' }} onClick={handleApplyFilters}>
							apply
						</Button>
					</Stack>
				</Stack>
			</Drawer>
		</Stack>
	);
};
export default Filter;
