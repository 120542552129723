import { upperFirst } from 'lodash-es';

import { DeliveryTicketType } from '@calo/dashboard-types';
import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { caloTheme } from '../../../assets/themes/calo';
import Icon from '../../../components/Icon/Icon';
import { DeliveryTicket } from '../../../libs/interfaces';

interface InvoiceCardProps {
	deliveryTicket: DeliveryTicket;
	isSelected: boolean;
	setIsSelected: (id: string, isSelected: boolean) => void;
}

const InvoiceCard = ({ deliveryTicket, isSelected, setIsSelected }: InvoiceCardProps) => {
	const theme = useTheme();
	return (
		<Card
			onClick={() => setIsSelected(deliveryTicket.id, !isSelected)}
			sx={{
				boxShadow: 0,
				width: '100%',
				border: '2px solid' + (isSelected ? theme.palette.neutral100 : theme.palette.primary500),
				borderRadius: '8px'
			}}
		>
			<CardContent>
				<Stack direction={'row'} justifyContent="space-between" alignItems="start">
					<Typography
						component="div"
						sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: 19, fontWeight: 400, lineHeight: '24px' }}
					>
						#{deliveryTicket.index}{' '}
						{deliveryTicket.data.type === DeliveryTicketType.MEAL
							? 'Meal Swap'
							: deliveryTicket.data.type === DeliveryTicketType.MANUAL
								? 'Manual Delivery'
								: 'Other'}
					</Typography>
					<Typography component="div" sx={{ ml: 1, fontSize: 16, fontWeight: 600, lineHeight: '19px' }}>
						{upperFirst(deliveryTicket.data.userName)}
					</Typography>
					<Typography component="div" sx={{ ml: 1, fontSize: 16, fontWeight: 600, lineHeight: '19px' }}>
						{deliveryTicket.data.phoneNumber}
					</Typography>
					<Typography component="div" sx={{ ml: 1, fontSize: 16, fontWeight: 600, lineHeight: '19px' }}>
						{deliveryTicket.data.driver?.name || '---'}
					</Typography>
					<Typography component="div" sx={{ ml: 1, fontSize: 16, fontWeight: 600, lineHeight: '19px' }}>
						#{deliveryTicket.data.deliveryShortId || 'XXX-XXX'}
					</Typography>
					<Typography component="div" sx={{ ml: 1, fontSize: 16, fontWeight: 600, lineHeight: '19px' }}>
						{upperFirst(deliveryTicket.data.deliveryTime)}
					</Typography>
					{deliveryTicket.downloadedAt && (
						<Typography>
							{deliveryTicket.downloadedAt && (
								<Icon name="downloaded" width={'24px'} style={{ minWidth: '22px', marginRight: '8px', marginTop: '-2px' }} />
							)}
						</Typography>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default InvoiceCard;
