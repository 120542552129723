import { Kitchen } from '@calo/types';
import { Card, CardContent, Stack, Typography } from '@mui/material';

import { caloTheme } from '../../../assets/themes/calo';
import { QualityMetrics } from '../../../libs/interfaces';
import { getCurrencyFromKitchen, getPercentage, roundToTwoDecimals } from '../../../libs/utils/helperFunctions';

interface Props {
	qualityMetrics: QualityMetrics[];
	kitchen: Kitchen;
}

const Header = ({ qualityMetrics, kitchen }: Props) => {
	const allComponents = qualityMetrics.flatMap((q) => q.components);
	const numberOfRejected = allComponents.reduce((acc, curr) => acc + curr.numberOfRejected, 0);
	const totalReviewed = allComponents.reduce((acc, curr) => acc + curr.totalReviewed, 0);
	const consistencyScore = allComponents.reduce((acc, curr) => acc + curr.consistencyScore, 0);
	const costOfPoorQuality = allComponents.reduce((acc, curr) => acc + curr.weightOfRejected * curr.cost, 0);
	const ratedComponents = allComponents.filter((c) => c.totalReviewed > 0);

	const metrics = [
		{
			label: 'REJECTED',
			value: getPercentage(numberOfRejected / (totalReviewed || 1)),
			detail: `${numberOfRejected} / ${totalReviewed}`
		},
		{
			label: 'REVIEW RATE',
			value: getPercentage(ratedComponents.length / (allComponents.length || 1)),
			detail: `${ratedComponents.length} / ${allComponents.length}`
		},
		{ label: 'CONSISTENCY SCORE', value: getPercentage(consistencyScore / (ratedComponents.length || 1)), detail: '' },
		{
			label: 'COST OF POOR QUALITY',
			value: getCurrencyFromKitchen(kitchen) + ' ' + roundToTwoDecimals(costOfPoorQuality),
			detail: ''
		}
	];

	return (
		<Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
			{metrics.map((metric, index) => (
				<Card
					key={index}
					sx={{ backgroundColor: caloTheme.palette.neutral50, boxShadow: 'none', borderRadius: '8px', width: '25%' }}
				>
					<CardContent sx={{ '&:last-child': { paddingBottom: 1 } }}>
						<Typography sx={{ fontSize: '14px', fontWeight: 600, letterSpacing: '1.4px', color: caloTheme.palette.neutral600 }}>
							{metric.label}
						</Typography>
						<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
							<Typography sx={{ fontSize: '24px', color: caloTheme.palette.neutral900 }}>{metric.value}</Typography>
							{metric.detail && (
								<Typography sx={{ fontSize: '16px', color: caloTheme.palette.neutral600 }}>{metric.detail}</Typography>
							)}
						</Stack>
					</CardContent>
				</Card>
			))}
		</Stack>
	);
};

export default Header;
