import { ComponentService } from '@calo/services';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';

import { FoodComponent, IngredientHeaderItem } from '../../../../libs/interfaces';
import { roundToTwoDecimals } from '../../../../libs/utils/helperFunctions';
import { calculateFractionWeight } from '../../helpers';
import HeaderRowItem from './HeaderRowItem';
import IngredientRowItem from './IngredientRowItem';

interface IngredientsTableRowProps {
	foodComponent: FoodComponent;
	newCookedWeight: number;
	selectChildComponent: (componentId: string, name: string) => void;
}

const orderIngredients = (foodComponent: FoodComponent) => {
	const withHeader = foodComponent.ingredients.filter((ing) => ing.header);
	const withoutHeader = foodComponent.ingredients.filter((ing) => !ing.header);
	return [...withoutHeader, ...withHeader];
};

const IngredientsTableRow = ({ foodComponent, selectChildComponent, newCookedWeight }: IngredientsTableRowProps) => {
	const theme = useTheme();

	const StyledChildComponentCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.body}`]: {
			fontSize: 19,
			fontWeight: 600,
			backgroundColor: theme.palette.secondaryBlue100,
			color: theme.palette.black,
			border: '0',
			fontFamily: 'Roboto',
			lineHeight: '28px',
			height: '50px'
		}
	}));

	const structuredIngredients = (() => {
		let currentHeader: string | null | undefined;
		const sortedIngredients = orderIngredients(foodComponent);

		const updatedStructuredIngredients: IngredientHeaderItem[] = [];
		for (const ingredient of sortedIngredients) {
			if (ingredient.header !== currentHeader && ingredient.header !== null && ingredient.header !== undefined) {
				currentHeader = ingredient.header;
				updatedStructuredIngredients.push({ type: 'header', header: currentHeader });
			}
			updatedStructuredIngredients.push({ type: 'ingredient', ingredient });
		}

		return updatedStructuredIngredients;
	})();

	return (
		<>
			{structuredIngredients.map((sIngredient, index) => {
				if (sIngredient.type === 'header') {
					return <HeaderRowItem key={`${sIngredient.header}#${index}`} sIngredient={sIngredient} />;
				} else {
					return (
						<IngredientRowItem
							key={`${sIngredient.ingredient.id}#${index}`}
							ingredient={sIngredient.ingredient}
							foodComponent={foodComponent}
							newCookedWeight={newCookedWeight}
						/>
					);
				}
			})}
			{(foodComponent.childComponents || []).map((comp, index) => (
				<TableRow
					key={`${comp.id}#${index}`}
					sx={{
						cursor: 'pointer',
						border: '1px solid white',
						'&:hover': { border: '1px solid' + theme.palette.secondaryBlue200 }
					}}
					onClick={() => selectChildComponent(comp.id, comp?.name.en || '')}
				>
					<TableCell sx={{ border: 'none', p: 1, flexDirection: 'row', display: 'flex' }}>
						<StyledChildComponentCell sx={{ p: 1, pl: 2, width: '100%' }}>
							<Stack direction={'row'} justifyContent="space-between" alignItems="start" spacing={2}>
								<Box>{(foodComponent.childComponents || []).find((r) => r.id.includes(comp.id))?.name.en}</Box>
								<Box>
									{roundToTwoDecimals(
										calculateFractionWeight(
											foodComponent,
											ComponentService.calculateComponentWeight(comp.cups, comp.measurementUnit, comp.weight, comp.quantity || 0),
											1,
											newCookedWeight
										)
									)}{' '}
									g
								</Box>
							</Stack>
						</StyledChildComponentCell>
					</TableCell>
				</TableRow>
			))}
		</>
	);
};

export default IngredientsTableRow;
