import { useMutation } from '@apollo/client';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, TextField } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { uploadImage } from '../../../../../libs/actions';
import { GET_IMAGE_UPLOAD_URL } from '../../../../../libs/graphQL';

interface ReviewDrawerCommentSectionProps {
	comment: string;
	setComment: React.Dispatch<React.SetStateAction<string>>;
	displayImages: { url: string; path: string }[];
	removeAddedImage: (image: string) => void;
	foodComponentId: string;
	setDisplayImages: React.Dispatch<React.SetStateAction<{ url: string; path: string }[]>>;
}

const ReviewDrawerCommentSection = ({
	comment,
	setComment,
	displayImages,
	removeAddedImage,
	foodComponentId,
	setDisplayImages
}: ReviewDrawerCommentSectionProps) => {
	const [getImageUrlLink] = useMutation(GET_IMAGE_UPLOAD_URL);

	const onDrop = useCallback(
		async (files: any) => {
			if (!files) {
				return;
			}
			if (files && files[0].size > 62914560) {
				toast('Image size is too big', { type: 'error', autoClose: 2000 });
				return;
			}

			if (files && !files[0].type.includes('image')) {
				toast('Image only', { type: 'error', autoClose: 2000 });
				return;
			}

			const file = files[0];
			const image = new Image();
			image.src = URL.createObjectURL(file);

			const imageId = uuid();
			const imagePath = `quality-track/food-component/${foodComponentId.replace('#', '/')}/${imageId}`;
			const response = await getImageUrlLink({
				variables: { path: imagePath },
				onError: (error) => {
					console.log(error);
					toast.error('Error on getting image url link');
					return;
				}
			});
			if (response.data?.getImageUploadURL?.data) {
				const { url, fields } = JSON.parse(response.data?.getImageUploadURL.data);
				const formData = new FormData();

				for (const key of Object.keys(fields)) {
					formData.append(key, fields[key]);
				}

				formData.append('file', file);
				setDisplayImages([...displayImages, { url: URL.createObjectURL(file), path: imagePath }]);
				try {
					await uploadImage(url, formData);
				} catch (error) {
					console.log(error);
					toast.error('Something went wrong with image upload');
				}
			} else {
				toast.error('Something went wrong with get link');
			}
		},
		[getImageUrlLink, displayImages, foodComponentId]
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'image/*': ['.jpg', '.jpeg', '.png'] } });

	return (
		<Box sx={{ width: '100%', marginBottom: 2, border: '1px solid', borderColor: 'lightgrey', borderRadius: 3 }}>
			<TextField
				multiline
				rows={4}
				color="warning"
				defaultValue="Default Value"
				variant="standard"
				value={comment}
				sx={{
					mx: 1,
					width: '95%',
					'& .MuiInput-underline:before': { borderBottom: 'none' },
					'& .MuiInput-underline:after': { borderBottom: 'none' },
					'& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
					'& .MuiInputBase-input': { border: 'none' }
				}}
				onChange={(data: any) => setComment(data.target.value)}
			/>
			{displayImages.length > 0 && (
				<Box display={'flex'} flexDirection={'row'} overflow={'auto'}>
					{displayImages.map((image, index) => (
						<Box sx={{ position: 'relative' }} key={index}>
							<CardMedia
								key={index}
								component="img"
								image={image.url}
								alt="placeholder"
								sx={{ width: 50, maxHeight: 50, height: 50, objectFit: 'cover', borderRadius: '8px', marginLeft: '10px' }}
							/>
							<IconButton
								aria-label="right"
								onClick={() => removeAddedImage(image.url)}
								sx={{
									position: 'absolute',
									padding: 0,
									margin: 0,
									backgroundColor: '#FF000080',
									borderRadius: 10,
									top: -0,
									right: -0,
									color: 'white'
								}}
							>
								<CloseIcon />
							</IconButton>
						</Box>
					))}
				</Box>
			)}

			<Button
				variant="text"
				sx={{ height: 30, minWidth: 40, borderRadius: 2, color: 'grey', margin: 0, padding: 0 }}
				{...getRootProps()}
			>
				<input {...getInputProps()} accept="image/*" />
				<CameraAltOutlinedIcon />
			</Button>
		</Box>
	);
};

export default ReviewDrawerCommentSection;
