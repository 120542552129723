import { caloTheme } from '../../assets/themes/calo';
import { QualityRating } from '../../libs/enums';
import { KDSFoodComponentBatch, QualityCheckStatus } from '../../libs/interfaces';

export const applyStatusFilterOnQualityTracking = (
	selectedCheckStatuses: QualityCheckStatus[],
	foodComponentBatch?: KDSFoodComponentBatch
) => {
	return (
		(foodComponentBatch?.quality?.[0]?.status && selectedCheckStatuses.includes(foodComponentBatch?.quality?.[0]?.status)) ||
		(selectedCheckStatuses.includes(QualityCheckStatus.pending) && (!foodComponentBatch || !foodComponentBatch.quality[0]))
	);
};

export const renderDotColor = (standardRating: QualityRating | null | QualityCheckStatus) => {
	switch (standardRating) {
		case QualityRating.bad:
		case QualityCheckStatus.rejected:
			return caloTheme.palette.secondaryAlert500;
		case QualityRating.neutral:
		case QualityCheckStatus.pending:
			return caloTheme.palette.secondaryYellow500;
		case QualityRating.good:
		case QualityCheckStatus.accepted:
			return caloTheme.palette.primary.main;
		default:
			return caloTheme.palette.white;
	}
};
